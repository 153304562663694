import React from 'react'
import { useDestroyExport } from 'api/hooks/use-exports'
import ConfirmDelete from 'shared/confirm-delete'
import { useQueryClient } from 'react-query'

function DeleteExport({ exportData }) {
  const queryClient = useQueryClient()

  const { mutate: destroyExport } = useDestroyExport({
    id: exportData.id,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(['exports'])
      }
    }
  })

  return (
    <ConfirmDelete
      id={exportData.id}
      handleDelete={destroyExport}
      extraConfirm={false}
    />
  )
}

export default DeleteExport
