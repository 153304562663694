import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { useDebounce } from 'api/hooks/use-debounce'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'

function AmountFilter({ params, setParams, accessorKey, title }) {
  const [amount, setAmount] = useState(params[accessorKey])
  const debounceAmount = useDebounce(amount, 300)

  useEffect(() => {
    setParams((params) => ({
      ...params,
      [accessorKey]: debounceAmount
    }))
  }, [accessorKey, debounceAmount, setAmount, setParams])

  return (
    <>
      <label className="mr-2 fw-light text-muted sr-only">
        <small>{title} Amount</small>
      </label>
      <InputGroup>
        <InputGroup.Text>
          <FontAwesomeIcon icon={faDollarSign} />
        </InputGroup.Text>
        <Form.Control
          type="number"
          placeholder=""
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </InputGroup>
    </>
  )
}

export default AmountFilter
