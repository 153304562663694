import Form from 'react-bootstrap/Form'
import { useContext } from 'react'
import UserContext from 'context/user-context'

function Stale({ params, setParams }) {
  const { municipality } = useContext(UserContext)
  const checked = params.stale === true
  return (
    <>
      <Form.Check
        inline
        checked={checked}  
        label="Stale Vendors"
        name="stale-vendors"
        type={'checkbox'}
        onChange={() => setParams({ ...params, stale: !params.stale })}
        id={'stale-vendors-checkbox'}
      />
      <div className='ms-4 text-muted fw-light'>No invoice or check in past {municipality.stale_vendor_months} months</div>
    </>
  )
}

export default Stale
