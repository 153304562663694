import React from 'react'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'

function Transaction() {
  return (
    <Page title="transaction 123">
      {/*
        TODO: https://thirdline.atlassian.net/browse/PD-37
      */}
      <PageTitle />
    </Page>
  )
}

export default Transaction
