import React, { useRef, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import smallDot from 'shared/small-dot.svg'
import ListGroup from 'react-bootstrap/ListGroup'
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'
import { useAnalytics } from 'api/hooks/use-analytics'
import { reject, filter, isEmpty } from 'lodash'

type AnalyticFilterProps = {
  setFilterParams: Function,
  filterParams: {
    section_id: string,
    analytic_ids: AnalyticProps[]
  },
  setActiveOverlay: Function,
  activeOverlay: string
}

type AnalyticProps = {
  id: string,
  name: string,
  description: string,
  analytics_count: string
}

function AnalyticFilter({
  setFilterParams,
  filterParams,
  setActiveOverlay,
  activeOverlay
}: AnalyticFilterProps) {
  const analyticTarget = useRef(null)
  const [analyticSearch, setAnalyticSearch] = useState('')

  const params = {
    section_id: filterParams.section_id || '',
    search: analyticSearch,
    per_page: 100
  }
  const options = {
    enabled: activeOverlay === 'analytic'
  }

  const { isSuccess, isLoading, data: analytics } = useAnalytics({ params, options })

  const addOrRemoveAnalytic = (analytic: any) => {
    const findAnalytic = filter(filterParams.analytic_ids, {
      id: analytic.id
    })
    if (findAnalytic.length > 0) {
      const newList = reject(filterParams.analytic_ids, { id: analytic.id })
      updateAnalytics(newList)
    } else {
      const newList = filterParams.analytic_ids
      newList.push(analytic)
      updateAnalytics(newList)
    }
  }

  const updateAnalytics = (newList: any) => {
    setFilterParams({
      ...filterParams,
      analytic_ids: newList
    })
  }

  const selectedAnalytics = filterParams.analytic_ids.map((a) => a.id)
  const analyticCount = selectedAnalytics.length
  const filterApplied = !isEmpty(filterParams.analytic_ids)

  return (
    <>
      <Button
        className={
          filterApplied
            ? 'bg-white text-primary'
            : 'bg-white text-info'
        }
        data-toggle="dropdown"
        ref={analyticTarget}
        onClick={() => setActiveOverlay('analytic')}
        style={{ border: filterApplied ? '' : '1px solid #ced4da' }}
        variant={
          filterApplied || activeOverlay === 'analytic'
            ? 'outline-primary'
            : 'outline-light'
        }
        aria-haspopup="true"
        aria-expanded="false"
      >
        {filterApplied ? (
          <>
            <img src={smallDot} className="img-fluid" alt={'Filter Applied'} />
            {analyticCount} Analytics
          </>
        ) : (
          'Analytic'
        )}
      </Button>

      <Overlay
        target={analyticTarget.current}
        show={activeOverlay === 'analytic'}
        placement="bottom-start"
      >
        <Popover
          id="popover-basic"
          style={{ maxHeight: 400, minWidth: '35%', overflowY: 'auto' }}
        >
          <Popover.Header className="sticky-top">
            <Row className="mt-2">
              <Col sm={8}>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>

                  <Form.Control
                    type="text"
                    placeholder="Search Analytics"
                    value={analyticSearch}
                    onChange={(e) => {
                      setFilterParams({ ...filterParams, page: 1 })
                      setAnalyticSearch(e.target.value)
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm={4}>
                <Button
                  variant="primary"
                  size="sm"
                  className="float-end mt-1"
                  onClick={() => setActiveOverlay('')}
                >
                  Apply
                </Button>
                {!isEmpty(filterParams.analytic_ids) ? (
                  <Button
                    variant="link"
                    size="sm"
                    className="float-end mt-1"
                    onClick={() =>
                      setFilterParams({ ...filterParams, analytic_ids: [] })
                    }
                  >
                    Clear
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Popover.Header>
          <ListGroup variant="flush">
            {isSuccess &&
              analytics.data.map((analytic: AnalyticProps) => (
                <React.Fragment key={`analytic-${analytic.id}`}>
                  <ListGroup.Item
                    action
                    active={
                      selectedAnalytics.includes(analytic.id)
                        ? true
                        : false
                    }
                    className={
                      selectedAnalytics.includes(analytic.id)
                        ? 'bg-light'
                        : ''
                    }
                    onClick={() => {
                      addOrRemoveAnalytic(analytic)
                    }}
                  >
                    <span className='text-primary'>{analytic.name}</span>
                    {selectedAnalytics.includes(analytic.id) ?
                      <FontAwesomeIcon icon={faCheckCircle} className="text-primary ms-1" /> : null}
                    <div className="fw-light text-muted">{analytic.description}</div>
                  </ListGroup.Item>
                </React.Fragment>
              ))}
            {isLoading && (
              <ListGroup.Item>
                <Spinner animation="grow" variant="warning" />
              </ListGroup.Item>
            )}
          </ListGroup>
        </Popover>
      </Overlay>
    </>
  )
}

export default AnalyticFilter
