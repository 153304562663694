import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import useMunicipalityUsers from 'api/hooks/use-municipality-users'
import { useMutation } from 'react-query'
import { apiPatch } from 'api'
import { useAuth0 } from '@auth0/auth0-react'
import { useQueryClient } from 'react-query'
import { audience } from 'api/helpers'

function EditModal({ show, setShow, mole }) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const [report, setReport] = useState(mole)

  const { isSuccess: isUsersSuccess, data: users } = useMunicipalityUsers({})

  const updateMonitoring = useMutation(({ token, params }) => {
    const objectName = 'mole'
    const url = `moles/${report.id}`
    return apiPatch({ url, token, objectName, params })
  })

  const handleSave = async () => {
    const token = await getAccessTokenSilently({ audience })
    const params = {
      id: report.id,
      frequency: report.frequency,
      alert_user_id: report.alertUserId
    }
    updateMonitoring.mutate(
      {
        params,
        token
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(`mole/${mole.id}`)
          setShow(false)
        }
      }
    )
  }

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Monitoring Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-2">
          <Col sm={3}>
            <span className="fw-bold">Vendors</span>
          </Col>
          <Col sm={9}>
            <ul>
              {mole.vendors.length === 0 && <li>All Vendors</li>}
              {mole.vendors.map((v) => (
                <li key={`vendor-list-${v.id}`}>{v.name}</li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm={3}>
            <span className="fw-bold">Employees</span>
          </Col>
          <Col sm={9}>
            <ul>
              {mole.employees.map((e) => (
                <li key={`employee-list-${e.id}`}>{e.full_name}</li>
              ))}
              {mole.employees.length === 0 && <li>All Employees</li>}
            </ul>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={3}>
            <span className="fw-bold">User Email</span>
          </Col>
          <Col sm={9}>
            <Form.Group>
              <Form.Control
                as="select"
                onChange={(e) =>
                  setReport({ ...report, alert_user_id: e.target.value })
                }
                value={report.alert_user.id}
              >
                <option>Email</option>
                {isUsersSuccess &&
                  users.map((u) => (
                    <option key={`user-${u.id}`} value={u.id}>
                      {u.email}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={3}>
            <span className="fw-bold">Frequency</span>
          </Col>
          <Col sm={9}>
            <Button
              className="me-2"
              variant={report.frequency === 'daily' ? 'primary' : 'light'}
              onClick={() => setReport({ ...report, frequency: 'daily' })}
            >
              Daily
            </Button>
            <Button
              className="me-2"
              variant={report.frequency === 'weekly' ? 'primary' : 'light'}
              onClick={() => setReport({ ...report, frequency: 'weekly' })}
            >
              Weekly
            </Button>
            <Button
              className="me-2"
              variant={report.frequency === 'monthly' ? 'primary' : 'light'}
              onClick={() => setReport({ ...report, frequency: 'monthly' })}
            >
              Monthly
            </Button>
            <div className="text-muted fw-light mt-1">
              Alerts are only sent if a transaction was flagged during time
              period
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditModal
