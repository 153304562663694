import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Shield from 'assets/Shield/Gold/ThirdLineShieldGold.svg'
import Select, { components } from 'react-select'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useQueryClient } from 'react-query'
import { useCreateReportFeature } from 'api/hooks/use-report-features'
import { useEffect } from 'react'
import { isNull } from 'lodash'
import UserContext from 'context/user-context'
import useMunicipalityFeatures from 'api/hooks/use-municipality-features'
import InputHelpText from 'shared/forms/input-help-text'
import { useNavigate } from 'react-router-dom'

function AddAttribute({ analytics, reportId, selectedAnalytics }) {
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [selected, setSelected] = useState('')
  const [municipalityFeatureId, setMunicipalityFeatureId] = useState('')
  const queryClient = useQueryClient()
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const { municipality } = useContext(UserContext)
  const { isSuccess: featuresSuccess, data: features } =
    useMunicipalityFeatures({})

  const params = {
    audit_report_id: reportId,
    municipality_feature_id: municipalityFeatureId
  }

  const queryOptions = {
    onSuccess: () => {
      setTimeout(function () {
        queryClient.invalidateQueries([`reports/${reportId}`])
      }, 500)
    }
  }

  const { isSuccess, mutate } = useCreateReportFeature({
    params,
    options: queryOptions
  })

  const options = featuresSuccess
    ? features.map((obj) => ({
        label: obj['name'],
        value: obj['id'],
        description: obj['description'],
        key: obj['key']
      }))
    : []
  const { Option } = components

  const CustomOption = (props) => (
    <Option {...props}>
      <span className="me-1">
        {municipality.abbreviation}-{props.data.key}: {props.data.label}
      </span>
      <div className="ms-2 fw-light text-muted">{props.data.description}</div>
    </Option>
  )
  const SingleValue = ({ children, ...props }) => (
    <React.Fragment>
      <components.SingleValue {...props}>
        <span className="me-1">
          {municipality.abbreviation}-{props.data.key}:
        </span>
        {children}
      </components.SingleValue>
    </React.Fragment>
  )

  useEffect(() => {
    if (isSuccess) {
      new Promise((resolve) => {
        setTimeout(resolve, 2000)
        queryClient.invalidateQueries(`reports/${reportId}`)
      })
      handleClose()
    }
  }, [isSuccess, queryClient, reportId])

  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow}>
        <img src={Shield} style={{ width: 15 }} alt="ThirdLine Shield" /> Attach
        Attribute
      </Button>{' '}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Attach Attribute to Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <InputHelpText content="Attributes are created at the municipality level" />
            </Col>
            <Col>
              <a
                className="btn btn-sm btn-outline-primary float-end"
                href="/settings"
                onClick={(e) => {
                  e.preventDefault()
                  navigate('/settings')
                }}
                target="_blank"
              >
                Add New Attribute
              </a>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Select
                options={options}
                value={selected}
                onChange={(val) => {
                  setSelected(val)
                  setMunicipalityFeatureId(val.value)
                }}
                id="report-analytic-selector"
                isOptionDisabled={(option) => option.disabled}
                components={{ SingleValue, Option: CustomOption }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="float-end"
            variant="primary"
            disabled={isNull(params.municipality_feature_id)}
            onClick={() => mutate()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default AddAttribute
