import React from 'react'
import WorkflowTable from '../table'
import { useMunicipalityWorkflows } from 'api/hooks/use-municipality-workflows'
import WorkflowFilters from 'workflow/filters'
import TableFooter from 'shared/tables/table-footer'

function AllWorkflows({ params, setParams }) {
  const { isSuccess, isLoading, data: workflows } = useMunicipalityWorkflows({ params })

  return (
    <div>
      <div className="mb-4">
        <WorkflowFilters
          params={params}
          setParams={setParams}
        />
      </div>
      <div className="mb-4">
        <WorkflowTable
          isSuccess={isSuccess}
          isLoading={isLoading}
          workflows={workflows}
          params={params}
          setParams={setParams}
          showEmployee={true}
        />
      </div>

      <div>
        {isSuccess && (
          <TableFooter
            meta={workflows.meta}
            params={params}
            setParams={setParams}
          />
        )}
      </div>
    </div>
  )
}

export default AllWorkflows
