import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import { split } from 'lodash'
import WeightForm from './WeightForm'
import AnalyticDescription from 'analytics/description'
import FlagCount from 'shared/flag-count'
import TransactionTable from 'transactions/table'
import { useAnalytic } from 'api/hooks/use-analytics'
import EmptyPage from 'layout/empty-page'
import TagIcon from 'tag/icon'
import { DefaultError } from 'shared/default-error'
import { ErrorBoundary } from '@sentry/react'
import { useQueryClient } from 'react-query'
import { useUpdateMunicipalityAnalytic } from 'api/hooks/use-municipality-analytics'
import MunicipalityAnalyticCountContainer from './MunicipalityAnalyticCountContainer'
import StatusAlert from './StatusAlert'

function Analytic() {
  const queryString = window.location.pathname
  const queryClient = useQueryClient()
  const analyticId = split(queryString, '/')[2]
  const params = {
    include_chart: true,
    id: analyticId
  }

  const {
    isSuccess,
    isLoading,
    data: analytic
  } = useAnalytic({ params })

  const crumbs = [{ name: 'Analytics', url: 'analytics' }]

  const [activeBadge, setActiveBadge] = useState('')

  const analyticParams = {
    id: analytic?.municipality_analytic?.id || '',
    weight: activeBadge
  }

  useEffect(() => {
    if (isSuccess) {
      setActiveBadge(analytic.municipality_analytic.weight)
    }
  }, [isSuccess, setActiveBadge, analytic])

  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(['analytic'])
    }
  }
  const { mutate } = useUpdateMunicipalityAnalytic({ params: analyticParams, options })

  useEffect(() => {
    if (
      isSuccess &&
      activeBadge !== analytic?.municipality_analytic?.weight &&
      activeBadge !== ''
    ) {
      mutate()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBadge, analytic?.municipality_analytic?.weight, isSuccess])

  if (isLoading) {
    return <EmptyPage />
  }


  return (
    <Page
      title={`${analytic.name}`}
      pageTitle={`Analytic - ${analytic.name}`}
      subtitle={
        <>
          <FlagCount count={analytic.analytic_count} />
          {analytic.family_tags.length > 0 &&
            analytic.family_tags.map((t) => (
              <span
                className="me-1"
                key={`analytic-${analytic.id}-tag-${t.id}`}
              >
                <TagIcon tagName={t.name} tagDescription={t.description} />
              </span>
            ))}
        </>
      }
    >
      {isSuccess && (
        <React.Fragment>
          <PageTitle breadcrumbs={crumbs} />
          <StatusAlert status={analytic.municipality_analytic.status} />
          <Row>
            <Col sm={4}>
              <ErrorBoundary
                fallback={DefaultError}
                beforeCapture={(scope) => {
                  scope.setTag('component', 'analyticDescription')
                }}
              >
                <AnalyticDescription analytic={analytic} />
              </ErrorBoundary>
              <ErrorBoundary
                fallback={DefaultError}
                beforeCapture={(scope) => {
                  scope.setTag('component', 'WeightForm')
                }}
              >
                <WeightForm
                  activeBadge={activeBadge}
                  setActiveBadge={setActiveBadge}
                />
              </ErrorBoundary>
            </Col>

            {analytic ? (
              <Col sm={8}>
                <Card>
                  <Card.Header className="bg-white">
                    Analytics Count Over Time
                  </Card.Header>
                  <Card.Body>
                    <ErrorBoundary
                      fallback={DefaultError}
                      beforeCapture={(scope) => {
                        scope.setTag('component', 'analyticByMonth')
                      }}
                    >
                      <MunicipalityAnalyticCountContainer
                        municipalityAnalyticId={
                          analytic.municipality_analytic.id
                        }
                      />
                    </ErrorBoundary>
                  </Card.Body>
                </Card>
              </Col>
            ) : null}
          </Row>

          <Row className="mt-4">
            <Col>
              <TransactionTable
                analytic={[analytic]}
                sectionId={analytic.section_id}
                filtersEnabled={true}
                filters={[
                  'external-id',
                  'employee',
                  'vendor',
                  'amount',
                  'date',
                  'flag-count',
                  'more-filters'
                ]}
                source={analytic.name}
                columns={[
                  'save',
                  'id',
                  'risk_score',
                  'analytics_count',
                  'transaction_on',
                  'line_amount',
                  'vendor',
                  'employee'
                ]}
                title="Transactions for Analytic"
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </Page>
  )
}

export default Analytic
