import React, { useState, useRef, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import { useQuery } from 'react-query'
import InputGroup from 'react-bootstrap/InputGroup'
import StatusBadge from 'shared/badges/status-badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faTimesCircle,
  faUserSlash,
  faCreditCard,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons'
import smallDot from 'shared/small-dot.svg'
import { reject, filter, uniqBy } from 'lodash'
import ListGroup from 'react-bootstrap/ListGroup'
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { apiGet } from 'api'
import UserContext from 'context/user-context'
import { truncate } from 'lodash'

function EmployeeFilter({
  setFilterParams,
  filterParams,
  activeOverlay,
  setActiveOverlay,
  title = 'Employee',
  wideButton = false
}) {
  const [employeePage, setEmployeePage] = useState(1)
  const [employeeSearch, setEmployeeSearch] = useState('')
  const [allEmployees, setAllEmployees] = useState([])
  const employeeTarget = useRef(null)

  const handleEmployeeScroll = (event) => {
    const target = event.target
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      setEmployeePage(employeePage + 1)
    }
  }

  const employeeParams = {
    search: employeeSearch,
    page: employeePage
  }

  const addOrRemoveEmployee = (employee) => {
    const findEmployee = filter(filterParams.employees, {
      id: employee.id
    })
    if (findEmployee.length > 0) {
      const newList = reject(filterParams.employees, { id: employee.id })
      updateEmployees(newList)
    } else {
      const newList = filterParams.employees
      newList.push(employee)
      updateEmployees(newList)
    }
  }

  const updateEmployees = (newList) => {
    setFilterParams({
      ...filterParams,
      employees: newList
    })
  }

  const { getAccessTokenSilently } = useAuth0()
  const { municipality_id } = useContext(UserContext)
  const { isLoading: isEmployeesLoading } = useQuery(
    ['employees', employeeParams],
    async () => {
      const url = `municipalities/${municipality_id}/employees`
      const token = await getAccessTokenSilently({ audience })
      const data = await apiGet({ url, token, params: employeeParams })
      return data
    },
    {
      onSuccess: (data) => {
        setAllEmployees(uniqBy(allEmployees.concat(data.data), 'id'))
      },
      enabled: activeOverlay === 'employee'
    }
  )

  useEffect(() => {
    setAllEmployees([])
    setEmployeePage(1)
  }, [employeeParams.search])

  const employeeCount = filterParams.employees.length
  const selectedEmployees = filterParams.employees.map((e) => e.id)

  return (
    <>
      <Button
        className="bg-white text-info"
        data-toggle="dropdown"
        ref={employeeTarget}
        onClick={() => setActiveOverlay('employee')}
        style={{ border: employeeCount > 0 ? '' : '1px solid #ced4da' }}
        variant={
          employeeCount || activeOverlay === 'employee'
            ? 'outline-primary'
            : 'outline-light'
        }
        aria-haspopup="true"
        aria-expanded="false"
      >
        {employeeCount === 0 && title}
        {employeeCount === 1 && (
          <>
            <img src={smallDot} alt="Filter applied" className="img-fluid" />{' '}
            <span className="text-primary">
              {filterParams.employees[0].external_id
                ? truncate(filterParams.employees[0].external_id, {
                    length: wideButton ? 70 : 10
                  })
                : `${filterParams.employees.length} Employee`}
            </span>
          </>
        )}
        {employeeCount > 1 && (
          <>
            <img src={smallDot} className="img-fluid" alt="Filter Applied" />{' '}
            <span className="text-primary">
              {filterParams.employees.length} Employees
            </span>
          </>
        )}
      </Button>

      <Overlay
        target={employeeTarget.current}
        show={activeOverlay === 'employee'}
        placement="bottom-start"
        style={{ paddingLeft: '300px' }}
      >
        <Popover
          id="popover-basic"
          style={{
            maxHeight: 400,
            minWidth: wideButton ? '35%' : '25%',
            overflowY: 'auto'
          }}
          onScroll={handleEmployeeScroll}
        >
          <Popover.Header className="sticky-top">
            <Row className="mt-2">
              <Col sm={8}>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>

                  <Form.Control
                    type="text"
                    placeholder="Search Employees"
                    onChange={(e) => {
                      setFilterParams({ ...filterParams, page: 1 })
                      setEmployeeSearch(e.target.value)
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Popover.Header>

          <ListGroup variant="flush">
            {allEmployees.length > 0 &&
              allEmployees.map((e) => (
                <React.Fragment key={`employee-${e.id}-${e.external_id}`}>
                  <ListGroup.Item
                    action
                    active={selectedEmployees.includes(e.id) ? true : false}
                    className={
                      selectedEmployees.includes(e.id) ? 'bg-light' : ''
                    }
                    onClick={() => {
                      addOrRemoveEmployee(e)
                    }}
                  >
                    <>
                      <span className="text-primary fw-bold">
                        {e.external_id}
                      </span>{' '}
                      {e.status === 'inactive' ? (
                        <span className="ms-2">
                          <StatusBadge
                            title="Disabled"
                            icon={faUserSlash}
                            tooltipText="No longer an active employee"
                          />
                        </span>
                      ) : null}
                      {e.is_pcard ? (
                        <span className="ms-2">
                          <StatusBadge
                            title="PCard"
                            icon={faCreditCard}
                            tooltipText="Employee generated from PCard file"
                          />
                        </span>
                      ) : null}
                      <span className="text-muted fw-light float-end">
                        {e.department_name !== 'NA' &&
                        e.department_name !== 'None'
                          ? e.department_name
                          : ''}
                      </span>
                      {selectedEmployees.includes(e.id) ? (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-primary ms-1"
                        />
                      ) : null}
                    </>
                  </ListGroup.Item>
                </React.Fragment>
              ))}
            {isEmployeesLoading && (
              <ListGroup.Item>
                <Spinner animation="grow" variant="warning" />
              </ListGroup.Item>
            )}
          </ListGroup>
          <Popover.Header className="sticky-bottom border-top rounded-0">
            <Row>
              <Col sm={8}>
                <Button
                  variant="primary"
                  size="sm"
                  className="mt-1"
                  onClick={() => {
                    setActiveOverlay('')
                    setEmployeeSearch('')
                  }}
                >
                  Apply
                </Button>
              </Col>

              <Col sm={4}>
                {employeeCount > 0 ? (
                  <Button
                    variant="link"
                    size="sm"
                    className="float-end mt-1"
                    onClick={() =>
                      setFilterParams({ ...filterParams, employees: [] })
                    }
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                    Clear
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Popover.Header>
        </Popover>
      </Overlay>
    </>
  )
}

EmployeeFilter.propTypes = {
  setFilterParams: PropTypes.func.isRequired,
  filterParams: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    orderBy: PropTypes.string.isRequired,
    orderDirection: PropTypes.string.isRequired,
    employees: PropTypes.array.isRequired,
    unviewedOnly: PropTypes.bool.isRequired,
    needsFollowUp: PropTypes.bool.isRequired,
    exception: PropTypes.bool.isRequired
  }),
  activeOverlay: PropTypes.string.isRequired,
  setActiveOverlay: PropTypes.func.isRequired,
  title: PropTypes.string
}

export default EmployeeFilter
