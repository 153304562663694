import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getTimePeriods = async (token, params) => {
  const url = 'time_periods'

  const data = await apiGet({ url, token, params })
  return data
}

export default function useTimePeriods({ params, options }) {
  const token = useContext(AuthContext)
  const queryFn = () => getTimePeriods(token, params)
  const queryKey = ['time_periods', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
