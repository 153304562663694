import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import TableError from 'shared/tables/table-error'
import TableLoading from 'shared/tables/table-loading'
import TableFooter from 'shared/tables/table-footer'
import HighRiskPermissionBadge from '../badges/high-risk-permission'
import SuperUserPermissionBadge from 'duties/badges/superuser-permission'
import { useEmployeePermissions } from 'api/hooks/use-employee-permissions'

function PermissionsTab({ employeeId }) {
  const defaultParams = {
    id: employeeId,
    is_high_risk: false,
    is_super_user: false,
    is_expansive: true,
    page: 1,
    perPage: 25
  }

  const [params, setParams] = useState(defaultParams)

  const { data, isSuccess, isLoading, isError } = useEmployeePermissions({
    params
  })

  return (
    <Card className="border">
      <Card.Header className="bg-white">
        <Row>
          <Col sm="auto">
            <div className="text-info">Permissions</div>
          </Col>
          <Col sm="auto">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="show-expansive-values"
                onChange={(e) =>
                  setParams({ ...params, is_expansive: e.target.checked })
                }
                checked={params.is_expansive}
              />
              <label className="form-check-label" for="show-expansive-values">
                Show "On" Values Only
              </label>
            </div>
          </Col>

          <Col sm="auto">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="show-high-risk-only"
                onChange={(e) =>
                  setParams({ ...params, is_high_risk: e.target.checked })
                }
                checked={params.is_high_risk}
              />
              <label className="form-check-label" for="show-high-risk-only">
                Show High Risk Only
              </label>
            </div>
          </Col>
          <Col sm="auto">
            <div className="form-check form-switch ms-4">
              <input
                className="form-check-input"
                type="checkbox"
                id="show-superuser-only"
                onChange={(e) =>
                  setParams({ ...params, is_super_user: e.target.checked })
                }
                checked={params.is_super_user}
              />
              <label className="form-check-label" for="show-superuser-only">
                Show Super User Only
              </label>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Category</th>
              <th>Description</th>
              <th>Value</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && <TableLoading columns={4} />}
            {isSuccess &&
              data.data.map((p) => (
                <tr key={`permissions-row-${p.id}`}>
                  <td>
                    {p.name}
                    {p.is_high_risk && (
                      <HighRiskPermissionBadge count={1} displayCount={false} />
                    )}
                    {p.name.includes('super') && (
                      <SuperUserPermissionBadge
                        count={1}
                        displayCount={false}
                      />
                    )}
                  </td>
                  <td>
                    <div className="fw-light text-muted">{p.category}</div>
                  </td>
                  <td>{p.description}</td>
                  <td>{p.value}</td>
                  <td>
                    <div className="fw-light text-muted">{p.role_name}</div>
                  </td>
                </tr>
              ))}
            {isError && <TableError columns={3} />}
          </tbody>
        </Table>
        {isSuccess && (
          <TableFooter
            meta={data.meta}
            params={params}
            setParams={setParams}
            total={data.data.length}
          />
        )}
      </Card.Body>
    </Card>
  )
}

export default PermissionsTab
