import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getEmployeeWorkflows = async (token, params) => {
  const url = `employees/${params.employee_id}/workflows`

  const data = await apiGet({ url, token, params })
  return data
}

export default function useEmployeeWorkflows({ params, options }) {
  const token = useContext(AuthContext)
  const queryKey = [`employees/${params.employee_id}/workflows`, params]
  const queryFn = () => getEmployeeWorkflows(token, params)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
