import React, { useState, useEffect } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'
import RiskLineChart from './risk-line-chart'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import GroupedChartContainer from './grouped-chart-container'
import StatusBadge from 'shared/badges/status-badge'
import {
  faUserSlash,
  faCreditCard,
} from '@fortawesome/free-solid-svg-icons'
import { titleize } from 'helpers/utils'
import ChangeStat from './change-stat'
import { useRiskAssessment } from 'api/hooks/use-risk-assessments'

function RiskDrawer({ show, setShow, source, id }) {
  const allOptions = ['Analytic', 'Section', 'Employee', 'Vendor', 'Tag']
  const [timeframe, setTimeframe] = useState('12')

  const options =
    source === 'AccountCode'
      ? ['Section', 'Employee', 'Vendor']
      : allOptions.filter((i) => i !== source)

  const [grouping, setGrouping] = useState('analytic')

  const displayOption = (option) => {
    if (option === 'Section') {
      return 'Module'
    } else {
      return option
    }
  }

  useEffect(() => {
    if (source === 'AccountCode') {
      setGrouping('section')
    }
  }, [source])

  const handleClose = () => {
    setShow(false)
    setTimeframe('12')
    setGrouping('analytic')
  }

  const params = {
    source_type: source,
    id: id,
    timeframe: timeframe
  }
  const queryOptions = {
    enabled: show
  }

  const {
    data: risk,
    isSuccess,
    isLoading
  } = useRiskAssessment({ params, options: queryOptions })

  return (
    <Offcanvas
      show={show}
      placement="end"
      scroll="true"
      backdrop="true"
      style={{ width: '60%' }}
      onHide={handleClose}
    >
      <Offcanvas.Header
        className="sticky d-flex flex-row justify-content-between"
        closeButton
      >
        {isSuccess && (
          <>
            <div className="d-flex fw-lighter">
              <h5>{risk.source.name}</h5>
              {['Employee', 'Vendor'].includes(risk.source_type) &&
                risk.source.is_pcard && (
                  <span className="ms-2">
                    <StatusBadge
                      title="PCard"
                      icon={faCreditCard}
                      tooltipText="Employee generated from PCard file"
                    />
                  </span>
                )}
              {risk.source_type === 'Employee' &&
              risk.source.status === 'inactive' ? (
                <span className="ms-2">
                  <StatusBadge
                    title="Disabled"
                    icon={faUserSlash}
                    tooltipText="No longer an active employee"
                  />
                </span>
              ) : null}
              {risk.source_type === 'AccountCode' && (
                <div className="text-muted fw-light ms-4">
                  {titleize(risk.source.account_type)}
                </div>
              )}
            </div>
          </>
        )}
      </Offcanvas.Header>
      <hr className="my-0 py-0" />

      <Offcanvas.Body className="px-4 mt-3">
        <Row>
          <Col>
            <Form.Group>
              <Form.Label className="text-muted">Timeframe</Form.Label>
              <Form.Select
                as="select"
                value={timeframe}
                onChange={(e) => setTimeframe(e.target.value)}
              >
                <option value="12">12-months</option>
                <option value="18">18-months</option>
                <option value="24">24-months</option>
                <option value="36">36-months</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>{isSuccess && <ChangeStat data={risk.change} />}</Col>
        </Row>
        <div className="my-4 min-vh-50">
          {isLoading && (
            <div
              style={{ minHeight: '300px' }}
              className="d-flex align-items-center justify-content-center"
            >
              <Spinner animation="grow" variant="warning" />
            </div>
          )}
          {isSuccess && <RiskLineChart data={risk.risk} />}
        </div>

        <hr />

        <Row className="mt-4">
          <Col sm={2}>
            <Form.Label className="mt-2 text-muted">Group Risk By</Form.Label>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Select
                as="select"
                onChange={(e) => setGrouping(e.target.value)}
              >
                {options.map((o) => (
                  <option value={o} key={`option-${o}`}>
                    {displayOption(o)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-4">
          {isLoading && (
            <div
              style={{ minHeight: '300px' }}
              className="d-flex align-items-center justify-content-center"
            >
              <Spinner animation="grow" variant="warning" />
            </div>
          )}
          {isSuccess && (
            <GroupedChartContainer
              source={source}
              id={id}
              timeframe={timeframe}
              grouping={grouping}
            />
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default RiskDrawer
