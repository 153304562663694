import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getPermissions = async (token, params, municipalityId) => {
  const url = `municipalities/${municipalityId}/permissions`
  const data = await apiGet({ url, params, token })
  return data
}

export function usePermissions({ params, options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const queryFn = () => getPermissions(token, params, municipality_id)
  const queryKey = ['permissions', params]
  return useQuery(queryKey, queryFn, { ...options })
}

const getPermission = async (token, id, municipality_id) => {
  const url = `municipalities/${municipality_id}/permissions/${id}`
  const data = await apiGet({ url, token })
  return data
}

export function usePermission({ params, options }) {
  const id = params.id
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const queryFn = () => getPermission(token, id, municipality_id)
  const queryKey = ['permissions', id]
  return useQuery(queryKey, queryFn, { ...options })
}