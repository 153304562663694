import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function TableFooter({ meta, params, setParams }) {
  return (
    <Row>
      <Col xs="auto">
      </Col>
      <Col xs="auto">
      </Col>
      <Col xs="auto">
      </Col>
    </Row>
  )
}

export default TableFooter
