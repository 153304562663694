import React from 'react'
import Table from 'react-bootstrap/Table'
import { formatDateSlash } from 'helpers/datetime'
import { startCase } from 'lodash'
import FlagCount from 'shared/flag-count'

function DetailTable({ mole }) {
  return (
    <Table bordered className="bg-white" responsive size="sm">
      <thead>
        <tr className="fw-bold">
          <th>Analytic Name</th>
          <th>Module</th>
          <th>Vendors</th>
          <th>Employees</th>
          <th>Created On</th>
          <th>Alert</th>
          <th>Frequency</th>
          <th>Flags to Date</th>
        </tr>
      </thead>
      <tbody>
        <tr className="text-muted">
          <td>{mole.analytic.name}</td>
          <td>{mole.section.name}</td>
          <td>{mole.vendors.length === 0 ? 'All' : mole.vendors.length}</td>
          <td>{mole.employees.length === 0 ? 'All' : mole.employees.length}</td>
          <td>{formatDateSlash(mole.created_at)}</td>
          <td>{mole.alert_user.email}</td>
          <td>{startCase(mole.frequency)}</td>
          <td>
            <FlagCount count={mole.reviews_count} />
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default DetailTable
