import React, { useState } from 'react'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

function RolesTab({ permission }) {
  const [nonZeroValues, setNonZeroValues] = useState(true)

  let rolePermissions = nonZeroValues
    ? permission.roles.filter((rp) => rp.is_expansive)
    : permission.roles

  return (
    <Row>
      <Col sm={12}>
        <Card className="border">
          <Card.Header className="bg-white">
            <Row>
              <Col sm="auto">
                <div className="text-info">Roles</div>
              </Col>
              <Col sm="auto">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="show-non-zero-values"
                    onChange={(e) => setNonZeroValues(e.target.checked)}
                    checked={nonZeroValues}
                  />
                  <label
                    className="form-check-label"
                    for="show-non-zero-values"
                  >
                    Show "On" Values Only
                  </label>
                </div>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <Table responsive size="sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Caption</th>
                  <th>Status</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {rolePermissions.map((role) => (
                  <tr>
                    <td>{role.name}</td>
                    <td>{role.caption}</td>
                    <td>{role.status}</td>
                    <td>{role.permission_value}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default RolesTab
