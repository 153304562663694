import React, { useState } from 'react'
import Page from 'layout/sod/page'
import PageTitle from 'layout/page-title'
import { split } from 'lodash'
import { useRole } from 'api/hooks/use-roles'
import Button from 'react-bootstrap/Button'
import EmptyPage from 'layout/sod/empty-page'
import ConflictsTab from './conflicts'
import EmployeesTab from './employees-tab'
import PermissionsTab from './permissions-tab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import Badge from 'react-bootstrap/Badge'
import Stats from './stats'
import AuditLogTab from './audit-log-tab'
import SimilarRoles from './similar-roles'
import { formatDate } from 'helpers/datetime'
import FunctionalCategories from './functional-categories'

function Duty() {
  const path = window.location.pathname
  const [activeTab, setActiveTab] = useState('conflicts')
  const [roleModalShow, setRoleModalShow] = useState(false)
  const [functionalModalShow, setFunctionalModalShow] = useState(false)
  const roleId = split(path, '/')[2]

  const params = {
    id: roleId
  }

  const { isSuccess, isLoading, data: role } = useRole({ params })

  if (isLoading) {
    return <EmptyPage />
  }

  const crumbs = [
    { name: 'Segregation of Duties', url: 'duties' },
    { name: 'Roles', url: 'roles' },
    { name: role.name, url: '' }
  ]
  return (
    <Page title={isSuccess ? role.name : ''} pageTitle={'Role'} subtitle="">
      <PageTitle breadcrumbs={crumbs} />

      <h4 className="mt-4">
        {role.name}
        <Button
          variant="light-outline"
          className="bg-white ms-2 border-secondary"
          onClick={() => setRoleModalShow(true)}
        >
          Similar Roles
        </Button>
        <SimilarRoles
          roleId={roleId}
          show={roleModalShow}
          setShow={setRoleModalShow}
        />
        <Button
          variant="light-outline"
          className="bg-white ms-2 border-secondary"
          onClick={() => setFunctionalModalShow(true)}
        >
          {Object.keys(role.functional_categories || {}).length} Functional
          Categories
        </Button>
        {isSuccess && (
          <FunctionalCategories
            role={role}
            show={functionalModalShow}
            setShow={setFunctionalModalShow}
            setActiveTab={setActiveTab}
          />
        )}

        <Badge
          className="float-end fw-light border p-2 bg-white text-muted mx-1"
          variant="light"
        >
          Last Updated: {formatDate(role.role_last_updated_at)}
        </Badge>
        {false && (
          <Button
            variant="light-outline"
            className="bg-white ms-2 border-secondary"
            onClick={() =>
              toast.info('Holden will hand you fake PDF', {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: true,
                progress: undefined,
                theme: 'light'
              })
            }
          >
            <FontAwesomeIcon icon={faFilePdf} className="me-2" />
            Export
          </Button>
        )}
      </h4>
      <hr />

      <div className="mb-4">
        <Stats role={role} />
      </div>

      <div className="mb-4">
        <Button
          variant={activeTab === 'conflicts' ? 'primary' : 'light'}
          onClick={() => setActiveTab('conflicts')}
        >
          Conflicts
        </Button>
        <Button
          variant={activeTab === 'employees' ? 'primary' : 'light'}
          onClick={() => setActiveTab('employees')}
          className="ms-2"
        >
          Employees
        </Button>
        <Button
          variant={activeTab === 'permissions' ? 'primary' : 'light'}
          onClick={() => setActiveTab('permissions')}
          className="ms-2"
        >
          Permissions
        </Button>
        <Button
          variant={activeTab === 'audit-log' ? 'primary' : 'light'}
          onClick={() => setActiveTab('audit-log')}
          className="ms-2"
        >
          Audit Log
        </Button>
      </div>

      {activeTab === 'conflicts' && <ConflictsTab role={role} />}

      {activeTab === 'employees' && <EmployeesTab role={role} />}

      {activeTab === 'permissions' && <PermissionsTab role={role} />}

      {activeTab === 'audit-log' && <AuditLogTab role={role} />}
    </Page>
  )
}

export default Duty
