import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ProcessFilter from './process'
import ActionFilter from './action'
import TimeInQueueFilter from './hours'
import Export from './export'

function WorkflowFilters({ params, setParams }) {
  const [activeOverlay, setActiveOverlay] = useState('')

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col className="my-auto" sm="auto">
              <h6 className="text-muted">Filters</h6>
            </Col>
            <Col sm={'auto'}>
              <ProcessFilter
                params={params}
                setParams={setParams}
                activeOverlay={activeOverlay}
                setActiveOverlay={setActiveOverlay}
              />
            </Col>
            <Col sm={'auto'}>
              <ActionFilter
                params={params}
                setParams={setParams}
                activeOverlay={activeOverlay}
                setActiveOverlay={setActiveOverlay}
              />
            </Col>
            <Col sm={'auto'}>
              <TimeInQueueFilter
                params={params}
                setParams={setParams}
                activeOverlay={activeOverlay}
                setActiveOverlay={setActiveOverlay}
              />
            </Col>
            <Col sm={'auto'}>
              <Export params={params} setParams={setParams} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default WorkflowFilters
