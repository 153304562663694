import * as React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'

HighchartsExporting(Highcharts)

type AnalyticCountChartProps = {
  analyticCounts: object
}

function AnalyticCountChart({ analyticCounts }: AnalyticCountChartProps) {

  const options = {
    chart: {
      type: 'bar'
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    exporting: {
      enabled: true
    },
    xAxis: {
      categories: Object.keys(analyticCounts),
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Analytic Count'
      },
      labels: {
        overflow: 'justify'
      }
    },
    tooltip: {
      valueSuffix: ''
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        }
      }
    },
    legend: {
      enabled: false,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 20,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts?.defaultOptions?.legend?.backgroundColor || '#FFFFFF',
      shadow: true
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Number of Flags',
        data: Object.values(analyticCounts),
        color: '#E9C46A'
      }
    ]
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options}
      oneToOne={true}
       />
    </div>
  )
}

export default AnalyticCountChart
