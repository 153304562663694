import React, { useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { useSimilarRoles } from 'api/hooks/use-similar-roles'
import TableLoading from 'shared/tables/table-loading'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'

function SimilarRoles({ setShow, show, roleId }) {
  const navigate = useNavigate()
  const handleClose = () => setShow(false)
  const handleNavigate = (path) => {
    handleClose()
    navigate(path)
  }
  const [showRowDetails, setShowRowDetails] = useState(null)
  const params = { role_id: roleId }

  const { data, isSuccess, isLoading } = useSimilarRoles({params})

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Roles with similar permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive>
          <thead>
            <tr>
              <th>Similarity Score</th>
              <th>Comparison Role</th>
              <th>Permission differences</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isSuccess &&
              data.map((role, index) => (
                <>
                  <tr key={index}>
                    <td>{Math.round(100 * role.similarity_score) / 100}</td>
                    <td>
                      <Button
                        variant="link"
                        className="btn-sm"
                        onClick={() =>
                          handleNavigate(`/roles/${role.comparison_role_id}`)
                        }
                      >
                        {role.comparison_role}
                      </Button>
                    </td>
                    <td>
                      {role.role_diff.length + role.comparison_role_diff.length}
                    </td>
                    <td>
                      <Button
                        className="btn-sm"
                        onClick={() => setShowRowDetails(index)}
                        variant="link"
                      >
                        View Differences
                      </Button>
                    </td>
                  </tr>
                  {showRowDetails === index && (
                    <tr className="bg-light">
                      <td colSpan={2}>
                        <Table>
                          <thead>
                            <th colSpan={2}>
                              <span className="fw-bold">{role.role_name}</span>
                            </th>
                          </thead>
                          <tbody>
                            {role.role_diff.map((p) => (
                              <tr key={p.id}>
                                <td>{p.description}</td>
                                <td>{p.value}</td>
                              </tr>
                            ))}
                            {role.role_diff.length === 0 && (
                              <tr>
                                <td colSpan={2}>
                                  <span className="text-muted text-center">
                                    No additional permissions
                                  </span>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </td>
                      <td colSpan={2}>
                        <Table>
                          <thead>
                            <th colSpan={2}>
                              <span className="fw-bold">
                                {role.comparison_role}
                              </span>
                            </th>
                          </thead>
                          <tbody>
                            {role.comparison_role_diff.map((p) => (
                              <tr key={p.id}>
                                <td>{p.description}</td>
                                <td>{p.value}</td>
                              </tr>
                            ))}
                            {role.comparison_role_diff.length === 0 && (
                              <tr>
                                <td colSpan={2}>
                                  <span className="text-muted text-center">
                                    No additional permissions
                                  </span>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            {isLoading && <TableLoading columns={3} />}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default SimilarRoles
