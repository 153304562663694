import React, { useState, useEffect } from 'react'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import RiskBadge from 'shared/badges/risk-badge'
import SparkLineTable from 'charts/sparkline-table'
import TableFooter from 'shared/tables/table-footer'
import TableLoading from 'shared/tables/table-loading'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import StatusBadge from '../shared/badges/status-badge'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faCreditCard,
  faUserSlash
} from '@fortawesome/free-solid-svg-icons'
import { noDigitMoney } from 'helpers/utils'
import useDepartments from 'api/hooks/use-departments'
import { useEmployees } from 'api/hooks/use-employees'
import { useDebounce } from 'api/hooks/use-debounce'
import { ErrorBoundary } from '@sentry/react'
import TransactionCount from 'shared/filters/transactions-count'
import SortArrow from 'shared/tables/sort-arrow'
import { useNavigate } from 'react-router-dom'

function Employees() {
  const emptyState = {
    page: 1,
    perPage: 25,
    search: '',
    department: '',
    status: '',
    orderBy: 'analytics_per_transaction',
    orderDirection: 'desc',
    minimumAmount: '',
    maximumAmount: '',
    transactionsCount: ''
  }
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [filterParams, setFilterParams] = useState(emptyState)

  const updateOrdering = (column) => {
    const isCurrentColumn = filterParams.orderBy === column
    const columnOrder = getColumnOrder(isCurrentColumn)

    setFilterParams({
      ...filterParams,
      orderBy: column,
      orderDirection: columnOrder,
      page: 1
    })
  }

  const getColumnOrder = (isCurrentColumn) => {
    if (isCurrentColumn) {
      return filterParams.orderDirection === 'asc' ? 'desc' : 'asc'
    } else {
      return 'desc'
    }
  }

  const debouncedSearch = useDebounce(search, 300)

  useEffect(() => {
    setFilterParams((filterParams) => ({
      ...filterParams,
      search: debouncedSearch
    }))
  }, [debouncedSearch])

  const { isSuccess: isDepartmentSuccess, data: departments } = useDepartments(
    {}
  )
  const {
    isSuccess,
    isLoading,
    data: employees
  } = useEmployees({ params: filterParams })

  return (
    <Page title="Employees">
      <PageTitle />

      <Row>
        <Col sm="4">
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <FormControl
              placeholder="Search by Employee ID"
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col sm="2">
          {isDepartmentSuccess && (
            <Form.Group>
              <Form.Select
                as="select"
                className="text-info"
                value={filterParams.department}
                onChange={(e) =>
                  setFilterParams({
                    ...filterParams,
                    department: e.target.value
                  })
                }
              >
                <option value="">All Departments</option>
                {departments.map((d) => (
                  <option key={`department-${d.id}`} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </Col>

        <Col sm="auto">
          <Form.Group>
            <Form.Select
              as="select"
              value={filterParams.status}
              className="text-info"
              onChange={(e) =>
                setFilterParams({
                  ...filterParams,
                  status: e.target.value
                })
              }
            >
              <option value="">All Statuses</option>
              <option value="active">Enabled</option>
              <option value="inactive">Disabled</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col sm={2}>
          <ErrorBoundary
            fallback={<></>}
            beforeCapture={(scope) => {
              scope.setTag('component', 'TransactionCount')
            }}
          >
            <TransactionCount
              filterParams={filterParams}
              setFilterParams={setFilterParams}
            />
          </ErrorBoundary>
        </Col>
      </Row>

      <SparkLineTable>
        <thead>
          <tr className="text-primary">
            <th
              onClick={() => updateOrdering('external_id')}
              style={{ cursor: 'pointer' }}
            >
              Name
              <SortArrow
                column="external_id"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              onClick={() => updateOrdering('risk_rate')}
              style={{ cursor: 'pointer' }}
            >
              Risk{' '}
              <SortArrow
                column="risk_rate"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              onClick={() => updateOrdering('analytics_count')}
              style={{ cursor: 'pointer' }}
            >
              Analytics{' '}
              <SortArrow
                column="analytics_count"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            {/* TODO: <th>Monthly Trend</th> */}
            <th
              onClick={() => updateOrdering('transactions_count')}
              style={{ cursor: 'pointer' }}
            >
              Transactions{' '}
              <SortArrow
                column="vendor_invoice_amount"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              onClick={() => updateOrdering('transactions_amount')}
              style={{ cursor: 'pointer' }}
            >
              Spend{' '}
              <SortArrow
                column="transactions_amount"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <TableLoading columns={5} />}

          {isSuccess &&
            employees.data.map((e) => (
              <tr
                key={`employee-row-${e.id}`}
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/employees/${e.id}`)}
              >
                <td>
                  {e.external_id}
                  <small className="text-muted ms-2">{e.department_name}</small>
                  {e.is_pcard && (
                    <span className="mx-2">
                      <StatusBadge
                        title="PCard"
                        icon={faCreditCard}
                        tooltipText="Employee generated from PCard file"
                      />
                    </span>
                  )}
                  {e.status === 'inactive' ? (
                    <span className="ms-2">
                      <StatusBadge
                        title="Disabled"
                        icon={faUserSlash}
                        tooltipText="No longer an active employee"
                      />
                    </span>
                  ) : null}
                </td>
                <td>
                  <RiskBadge count={e.risk} showCount={true} />
                </td>
                <td>{Math.floor(e.analytics_count).toLocaleString()}</td>
                {/* TODO: <td data-sparkline={`${fourNumbers()}`}></td>  */}
                <td className="text-muted">
                  {Math.floor(e.transactions_count).toLocaleString()}
                </td>

                <td className="text-muted">
                  {noDigitMoney(e.transactions_amount)}
                </td>
              </tr>
            ))}
        </tbody>
      </SparkLineTable>
      {isSuccess && (
        <TableFooter
          meta={employees.meta}
          params={filterParams}
          setParams={setFilterParams}
        />
      )}
    </Page>
  )
}

export default Employees
