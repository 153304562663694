import React, { useState } from 'react'
import Table from 'react-bootstrap/Table'
import TableLoading from 'shared/tables/table-loading'
import TableFooter from 'shared/tables/table-footer'
import useExports from 'api/hooks/use-exports'
import { formatDateTime } from 'helpers/datetime'
import StatusBadge from './status-badge'
import { baseUrl } from 'api/helpers'
import DeleteExport from './delete-export'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

function ExportTable() {
  const filterParams = {
    perPage: 10,
    page: 1
  }
  const [params, setParams] = useState(filterParams)
  const {
    isSuccess,
    isLoading,
    data: exports
  } = useExports({ params })

  return (
    <>
      <Table
        responsive
        className="shadow-sm bg-white"
        size="sm"
        style={{ border: '1px solid rgb(222 226 230)' }}
      >
        <thead>
          <tr>
            <th className="w-25">Name</th>
            <th>Status</th>
            <th>Date</th>
            <th>Source</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <TableLoading columns={6} />}
          {isSuccess && exports.data.length === 0 && (
            <tr>
              <td colSpan="7" className="text-center py-4">
                <div className="mb-2 h6">No Exports</div>
                <div className="text-muted fw-lighter mb-3">
                  When you export data, it will appear here
                </div>
              </td>
            </tr>
          )}

          {isSuccess &&
            exports.data.length > 0 &&
            exports.data.map((e) => (
              <tr key={`export-${e.id}`}>
                <td>{e.name}</td>
                <td>
                  <StatusBadge status={e.status} />
                </td>
                <td>{formatDateTime(e.created_at)}</td>
                <td>
                  {e.file && (
                    <a
                      href={`${baseUrl}${e.file}`}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-sm btn-light me-1"
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        className="text-primary"
                      />
                    </a>
                  )}
                  <DeleteExport exportData={e} />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {isSuccess && (
        <TableFooter
          meta={exports.meta}
          params={params}
          setParams={setParams}
        />
      )}
    </>
  )
}

export default ExportTable
