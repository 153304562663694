import React, { useContext } from 'react'
import Table from 'react-bootstrap/Table'
import TagIcon from 'tag/icon'
import UserContext from 'context/user-context'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faFilter, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import WeightBadge from 'shared/badges/weight-badge'


function AnalyticsTable({ analytics, reportAnalytics = [] }) {
  const { municipality } = useContext(UserContext)

  const analyticsOn = analytics.filter(
    (a) => !municipality.analytics_turned_off.includes(a.analytic.id) &&
      !a.is_resolved
  )
  const analyticsOff = analytics.filter((a) =>
    municipality.analytics_turned_off.includes(a.analytic.id) ||
    a.is_resolved
  )


  return (
    <Table responsive className="border">
      <thead>
        <tr>
          <th style={{ width: '25%' }} className="text-info">
            Name
          </th>
          <th style={{ width: '30%' }} className="text-info">
            Description
          </th>
        </tr>
      </thead>
      <tbody>
        {analyticsOn.map((a) => (
          <tr key={`transaction-analytic-row-${a.id}`}>
            <td className="fw-bold" colSpan={2}>
              {a.analytic.name}{' '}
              {a.analytic.is_control && (
                <TagIcon
                  tagName="Control"
                  tagDescription="Related to a control"
                />
              )}
              {a.analytic?.weight && <WeightBadge weight={a.analytic.weight} />}
              {reportAnalytics.includes(a.analytic.id) && (
                <OverlayTrigger
                  placement="bottom"
                  trigger={['hover', 'focus']}
                  overlay={
                    <Tooltip id={'tooltip'}>
                      This analytic was used as a filter for the report
                    </Tooltip>
                  }
                >
                  <Badge
                    className="text-primary bg-white float-end"
                    style={{ cursor: 'help' }}
                  >
                    <FontAwesomeIcon icon={faFilter} className="me-1" /> Filter
                  </Badge>
                </OverlayTrigger>
              )}
              <div className="small fw-light ms-2">
                {a.analytic.description}
              </div>
              <div className="text-muted fw-light mt-2">
                {a.details?.split('||').map((d, i) => (
                  <p key={`analytic-detail-${d}-${i}`} className="mb-0">
                    {d}
                  </p>
                ))}
              </div>
            </td>
          </tr>
        ))}
        {analyticsOff.map((a) => (
          <tr
            key={`transaction-analytic-row-${a.id}`}
            className="text-muted bg-light"
          >
            <td className="fw-bold" colSpan={2}>
              {a.analytic.name}{' '}
              {municipality.analytics_turned_off.includes(a.analytic.id) && (
                <OverlayTrigger
                  placement="bottom"
                  trigger={['hover', 'focus']}
                  overlay={
                    <Tooltip id={'tooltip'}>
                      This analytic has been turned off. It will not be used in
                      calculating the transaction risk.
                    </Tooltip>
                  }
                >
                  <Badge
                    className="bg-white text-info"
                    style={{ cursor: 'help' }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="me-1" />{' '}
                    Off
                  </Badge>
                </OverlayTrigger>
              )}
              {a.is_resolved && (
                <OverlayTrigger
                  placement="top"
                  trigger={['hover', 'focus']}
                  overlay={
                    <Tooltip id={'tooltip'}>
                      This analytic has been resolved and is no longer active.
                    </Tooltip>
                  }
                >
                  <Badge
                    className="bg-white text-success"
                    style={{ cursor: 'help' }}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} className="me-1" />{' '}
                    Resolved
                  </Badge>
                </OverlayTrigger>
              )}
              <div className="small fw-light ms-2">
                {a.analytic.description}
              </div>
              <div className="text-muted fw-light mt-2">
                {a.details?.split('||').map((d, i) => (
                  <p key={`analytic-detail-${d}-${i}`} className="mb-0">
                    {d}
                  </p>
                ))}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default AnalyticsTable
