import { useState, useRef, useEffect } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form'
import { useAccountCodes } from 'api/hooks/use-account-codes'
import smallDot from 'shared/small-dot.svg'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useDebounce } from 'api/hooks/use-debounce'
import { snakeCase } from 'lodash'

function FilterAccountCode({
  params,
  setParams,
  codeType,
  activeOverlay,
  setActiveOverlay
}) {
  const accountCodeTarget = useRef(null)
  const overlayTarget = useRef(null)
  const isActive = activeOverlay === codeType
  const [search, setSearch] = useState(params[`${snakeCase(codeType)}_search`] || '')
  const debouncedSearch = useDebounce(search, 500)

  const toggleOverlay = () => {
    setActiveOverlay(codeType)
  }

  const options = {
    enabled: isActive,
    keepPreviousData: true
  }
  const { data, isSuccess } = useAccountCodes({
    params: { ...params, code_type: codeType },
    options
  })
  const isFiltered = params[codeType].length > 0

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        activeOverlay === codeType &&
        overlayTarget.current &&
        !overlayTarget.current.contains(event.target)
      ) {
        setActiveOverlay(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
  }, [accountCodeTarget, setActiveOverlay, activeOverlay, codeType])

  useEffect(() => {
    if (isActive) {
      setParams((params) => ({
        ...params,
        [`${snakeCase(codeType)}_search`]: debouncedSearch
      }))
    }
  }, [codeType, debouncedSearch, isActive, setParams])

  return (
    <>
      <Button
        size="sm"
        className={`bg-white ${isFiltered ? 'text-primary' : 'text-info'}`}
        style={{ border: isFiltered ? '' : '1px solid #ccc' }}
        ref={accountCodeTarget}
        variant={isFiltered ? 'outline-primary' : 'outline-light'}
        onClick={() => toggleOverlay()}
      >
        {isFiltered && (
          <img
            alt="small dot"
            src={smallDot}
            style={{ height: '10px', width: '10px' }}
          />
        )}
        <FontAwesomeIcon icon={faFilter} className="text-primary" />
      </Button>

      <Overlay
        target={accountCodeTarget.current}
        show={activeOverlay === codeType}
        placement="bottom"
        ref={overlayTarget}
      >
        <Popover
          id={`popover-positioned-${codeType}`}
          style={{
            maxHeight: 400,
            minWidth: '25%',
            overflowY: 'auto'
          }}
          className="shadow-sm"
        >
          <Popover.Body className="bg-light">
            <Row>
              <Col>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <FormControl
                    placeholder={'Search by ID or Name'}
                    size="sm"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    autoFocus
                  />
                </InputGroup>
              </Col>
              <Col xs="auto">
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => {
                    setActiveOverlay(null)
                  }}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Popover.Body>
          <hr className="m-0" />
          <Popover.Body>
            {isSuccess && data.length === 0 && (
              <div className="text-center">
                No accounts found. Clear filters to expand your search.
              </div>
            )}

            {isSuccess &&
              data.map((accountCode) => (
                <Form.Check
                  key={accountCode.id}
                  type="checkbox"
                  id={`filter-account-code-${accountCode.id}`}
                  label={
                    <>
                      <span>{accountCode.name}</span>{' '}
                      <span className="text-muted">{accountCode.code}</span>
                    </>
                  }
                  checked={params[codeType].includes(accountCode.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setParams({
                        ...params,
                        [codeType]: [...params[codeType], accountCode.id]
                      })
                    } else {
                      setParams({
                        ...params,
                        [codeType]: params[codeType].filter(
                          (id) => id !== accountCode.id
                        )
                      })
                    }
                  }}
                />
              ))}
          </Popover.Body>
          <Popover.Body className="text-center"></Popover.Body>
        </Popover>
      </Overlay>
    </>
  )
}

export default FilterAccountCode
