import React, { useState } from 'react'
import Page from 'layout/sod/page'
import PageTitle from 'layout/page-title'
import Table from './role-table'
import Filters from 'duties/filters'

function Roles() {
  const emptyParams = {
    search: '',
    department_id: '',
    permission_id: '',
    conflict_id: '',
    role_id: '',
    orderBy: 'role_sod_counts.conflicts_count',
    orderDirection: 'desc',
    groupBy: 'role',
    perPage: 10,
    page: 1
  }
  const [filterParams, setFilterParams] = useState(emptyParams)
  
  const crumbs = [
    { name: 'Segregation of Duties', url: 'duties' },
    { name: 'Roles', url: '' }
  ]

  return (
    <Page title={'Roles'}>
      <PageTitle breadcrumbs={crumbs} />

      <div className="my-4">
        <Filters
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataType="Role"
        />
      </div>

      <Table filterParams={filterParams} setFilterParams={setFilterParams} />
    </Page>
  )
}

export default Roles
