import React from 'react'
import Form from 'react-bootstrap/Form'
import orderBy from 'lodash/orderBy'

function Category({ params, setParams, role }) {
  return (
    <Form.Group>
      <Form.Select
        as="select"
        size="sm"
        value={params.category}
        onChange={(e) =>
          setParams({
            ...params,
            category: e.target.value
          })
        }
      >
        <option value="">Select Category</option>
        {orderBy(Object.entries(role.functional_categories), [1], ['desc']) // Sort by values in descending order
          .map(([key, value], index) => (
            <option key={key} value={key}>
              {key} ({value})
            </option>
          ))}
      </Form.Select>
    </Form.Group>
  )
}

export default Category
