import React from 'react'
import AuditLog from 'duties/overview/audit-log'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

function AuditLogTab({ permission }) {
  
  return (
    <Row>
      <Col sm={12}>
        <Card className="border">
          <Card.Header className="bg-white">
            <Row>
              <Col sm="auto">
                <div className="text-info">Audit Log</div>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            <AuditLog permissionId={permission.id} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default AuditLogTab