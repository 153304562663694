import React, { useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { useDebounce } from 'api/hooks/use-debounce'

function Amount({ filterParams, setFilterParams }) {
  const [lowAmount, setLowAmount] = React.useState('')
  const [highAmount, setHighAmount] = React.useState('')
  const debouncedLowAmount = useDebounce(lowAmount, 300)
  const debouncedHighAmount = useDebounce(highAmount, 300)

  useEffect(() => {
    setFilterParams((params) => ({
      ...params,
      minimumAmount: debouncedLowAmount,
      page: 1
    }))
  }, [debouncedLowAmount, setFilterParams])

   useEffect(() => {
     setFilterParams((params) => ({
       ...params,
       maximumAmount: debouncedHighAmount,
       page: 1
     }))
   }, [debouncedHighAmount, setFilterParams])

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h5>
            Transaction Amount
            {filterParams.minimumAmount || filterParams.maximumAmount ? (
              <Button
                variant="link"
                size="sm"
                className="ms-4"
                onClick={() => {
                  setFilterParams({
                    ...filterParams,
                    minimumAmount: '',
                    maximumAmount: ''
                  })
                  setLowAmount('')
                  setHighAmount('')
                }}
              >
                Clear
              </Button>
            ) : null}
          </h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
            <Form.Control
              type="number"
              onChange={(e) => setLowAmount(e.target.value)}
              min={0}
              value={lowAmount}
              required
              placeholder=""
            />
            <InputGroup.Text id="inputGroupPrepend">min</InputGroup.Text>
          </InputGroup>
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>

            <Form.Control
              type="number"
              onChange={(e) => setHighAmount(e.target.value)}
              min={0}
              value={highAmount}
              required
              placeholder=""
            />
            <InputGroup.Text id="inputGroupPrepend">max</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
    </>
  )
}

export default Amount
