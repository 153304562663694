import React from 'react'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArchive,
  faCheckCircle,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import Spinner from 'react-bootstrap/Spinner'

function ReportStatusBadge({ status }) {
  return (
    <React.Fragment>
      {status === 'generating' && (
        <Spinner animation="border" variant="primary" size="sm"></Spinner>
      )}
      {status === 'available' && (
        <Badge pill className="text-success bg-light">
          <FontAwesomeIcon icon={faCheckCircle} className="me-1" />
          Available
        </Badge>
      )}
      {status === 'archived' && (
        <Badge pill className="text-primary bg-light">
          <FontAwesomeIcon icon={faArchive} className="me-1" />
          Archived
        </Badge>
      )}
      {status === 'deleting' && (
        <Badge pill className="text-danger bg-light">
          <FontAwesomeIcon icon={faTrash} className="me-1" />
          Deleting
        </Badge>
      )}
    </React.Fragment>
  )
}

export default ReportStatusBadge
