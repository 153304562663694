import React from 'react'
import Page from 'layout/sod/page'
import PageTitle from 'layout/page-title'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import OverviewTab from './overview'

function Duties() {
  return (
    <Page title={'Segregation of Duties'}>
      <PageTitle />

      <Row>
        <Col sm={12}>
          <OverviewTab />
        </Col>
      </Row>
    </Page>
  )
}

export default Duties
