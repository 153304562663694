import React from 'react'
import NumberField from 'shared/forms/NumberField'

function PoMaxPoRemainingAmount({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="PO Max Remaining Amount"
      showTitle={true}
      accessorKey="poMaxPoRemainingAmount"
    />
  )
}

export default PoMaxPoRemainingAmount
