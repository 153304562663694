import React, { useState } from 'react'
import Page from 'layout/sod/page'
import PageTitle from 'layout/page-title'
import { usePermissions } from 'api/hooks/use-permissions'
import Filters from './filters'
import PermissionsTable from './table'

function Permissions() {
  const defaultParams = {
    page: 1,
    perPage: 25,
    orderBy: 'name',
    orderDirection: 'asc',
    search: '',
    includeCategories: true
  }
  const [params, setParams] = useState(defaultParams)
  const crumbs = [
    { name: 'Segregation of Duties', url: 'duties' },
    { name: 'Permissions', url: '' }
  ]

  const queryOptions = {
    keepPreviousData: true,
  }

  const {
    isLoading,
    isError,
    isSuccess,
    data: permissions
  } = usePermissions({ params, options: queryOptions })

  return (
    <Page title={'Permissions'}>
      <PageTitle breadcrumbs={crumbs} />

      <div className="my-4">
        {isSuccess && (
          <Filters
            params={params}
            setParams={setParams}
            categories={permissions.categories}
          />
        )}
      </div>

      <PermissionsTable
        params={params}
        setParams={setParams}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        permissions={permissions}
      />
    </Page>
  )
}

export default Permissions
