import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useCreateReport } from 'api/hooks/use-reports'
import { useNavigate } from 'react-router-dom'

export default function NewSample({ show, setShow, params }) {
  const handleClose = () => setShow(false)
  const navigate = useNavigate()
  const [sampleName, setSampleName] = useState('')
  const [highRisk, setHighRisk] = useState(0)
  const [randomSample, setRandomSample] = useState(0)
  const { mutate } = useCreateReport({
    params: {
      name: sampleName,
      section_id: params.section_id,
      start_on: params.start_on,
      end_on: params.end_on,
      high_risk: highRisk,
      random_sample: randomSample,
      minimum_amount: params.minimum_amount,
      maximum_amount: params.maximum_amount,
      params: params.params,
      audit_report_analytics_attributes:
        params.analytics.length > 0
          ? params?.analytics?.split('+')?.map((a) => ({
              analytic_id: a
            }))
          : null,
      audit_report_employees_attributes:
        params.employees.length > 0
          ? params?.employees?.split('+')?.map((e) => ({
              employee_id: e
            }))
          : null,
      audit_report_vendors_attributes:
        params.vendors.length > 0
          ? params.vendors.split('+')?.map((v) => ({
              vendor_id: v
            }))
          : null
    },
    options: {
      onSuccess: (data) => {
        handleClose()
        navigate(`/samples/${data.id}`)
      }
    }
  })

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>New Sample</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Sample Name</Form.Label>
          <Form.Control
            type="text"
            value={sampleName}
            required
            onChange={(e) => setSampleName(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>High Risk</Form.Label>
          <Form.Control
            type="number"
            value={highRisk}
            required
            onChange={(e) => setHighRisk(e.target.value)}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Random Sample</Form.Label>
          <Form.Control
            type="number"
            value={randomSample}
            required
            onChange={(e) => setRandomSample(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => mutate()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
