import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import UserContext from 'context/user-context'
import { ListGroup } from 'react-bootstrap'
import { getIcon } from 'shared/icons'

function SidebarLink({ path, url, title, icon }) {
  const currentPath = window.location.pathname
  const navigate = useNavigate()
  const { sections } = useContext(UserContext)

  const activeLink = () => {
    if (path === '/') {
      return currentPath === path
    } else {
      return currentPath.includes(path)
    }
  }

  const handleNavigate = (e, url) => {
    e.preventDefault()
    navigate(`/${url}`)
  }

  const getUrlPath = (title) => {
    return title.replace(/\s+/g, '-').toLowerCase()
  }

  const isSubActive = (title) => {
    return currentPath.includes(getUrlPath(title))
      ? 'sub-active fw-normal'
      : 'fw-light'
  }

  const subOptions =
    path === 'dashboards' && sections
      ? sections
          .filter((s) =>
            ['PC', 'AP', 'Vendor', 'PO', 'GL'].includes(s.abbreviation)
          )
          .map((s) => ({
            id: s.id,
            title: s.name,
            abbreviation: s.abbreviation
          }))
      : []
  // Popover content with sub-options
  const popover = (
    <Popover
      id="popover-basic"
      className="custom-popover"
      arrowProps={{ style: { display: 'none' } }}
    >
      <Popover.Body>
        <ListGroup variant="flush">
          {subOptions.map((option, index) => (
            <ListGroup.Item
              key={index}
              action
              onClick={() =>
                navigate(
                  `/${path}/${getUrlPath(option.title)}`
                )
              }
              className={`nav-link d-flex align-items-center p-3 ${isSubActive(option.title)}`}
            >
              <img
                src={getIcon(option.abbreviation)}
                className="img-fluid mx-2"
                style={{ height: '20px' }}
                alt={option.title}
              />
              {/* SVG icon with right margin */}
              {option.title}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Popover.Body>
    </Popover>
  )

  return subOptions.length > 0 ? (
    <li
      className={`nav-item ${
        activeLink() ? 'border-right border-primary' : ''
      }`}
    >
      <OverlayTrigger
        trigger={['click']}
        placement="right-start"
        overlay={popover}
      >
        <span
          role="button" // Adds button-like behavior
          tabIndex="0" // Makes it focusable
          className={`nav-link ${activeLink() ? 'active' : 'fw-light'}`}
        >
          <FontAwesomeIcon
            className={activeLink() ? '' : 'feather'}
            style={{ minWidth: '30' }}
            icon={icon}
          />{' '}
          {title}
        </span>
      </OverlayTrigger>
    </li>
  ) : (
    <li
      className={`nav-item ${
        activeLink() ? 'border-right border-primary' : ''
      }`}
    >
      <a
        className={`nav-link ${activeLink() ? 'active' : 'fw-light'}`}
        href={`/${path}`}
        onClick={(e) => handleNavigate(e, url)}
      >
        <FontAwesomeIcon
          className={activeLink() ? '' : 'feather'}
          style={{ minWidth: '30' }}
          icon={icon}
        />{' '}
        {title}
      </a>
    </li>
  )
}

export default SidebarLink
