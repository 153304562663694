import React from 'react'
import DutiesOverview from './duties/index.jsx'
import EmployeeSods from './duties/employees/index.jsx'
import EmployeeSod from './duties/employees/show.jsx'
import Conflicts from './duties/conflicts/index.jsx'
import Conflict from './duties/conflicts/show.jsx'
import Permissions from 'duties/permissions/index.jsx'
import Permission from 'duties/permissions/show.jsx'
import Roles from './duties/roles/index.jsx'
import Role from './duties/roles/show.jsx'
import { Route, Routes, Navigate } from 'react-router-dom'

function AuthenticatedSod() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate replace to="/duties" />} />
        <Route path="/conflicts" exact={true} element={<Conflicts />} />
        <Route path="/conflicts/:id" element={<Conflict />} />
        <Route path="/duties" exact={true} element={<DutiesOverview />} />
        <Route path="/users" exact={true} element={<EmployeeSods />} />
        <Route path="/users/:id" element={<EmployeeSod />} />
        <Route path="/roles" exact={true} element={<Roles />} />
        <Route path="/roles/:id" element={<Role />} />
        <Route path="/permissions" exact={true} element={<Permissions />} />
        <Route path="/permissions/:id" element={<Permission />} />
      </Routes>
    </>
  )
}

export default AuthenticatedSod
