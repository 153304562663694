import * as React from 'react'
import PropTypes from 'prop-types'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import FormControl from 'react-bootstrap/FormControl'
import { useDebounce } from 'api/hooks/use-debounce'

type SearchProps = {
  params: object,
  setParams: Function,
  placeholder?: string
}

function Search({ params, setParams, placeholder = 'Search Name' }: SearchProps) {
  const [search, setSearch] = React.useState('')
  const debouncedSearch = useDebounce(search, 300)

  React.useEffect(() => {
    setParams((params: object) => ({ ...params, search: debouncedSearch, page: 1 }))
  }, [debouncedSearch, setParams])

  return (
    <InputGroup>
      <InputGroup.Text>
        <FontAwesomeIcon icon={faSearch} />
      </InputGroup.Text>
      <FormControl
        placeholder={placeholder}
        data-testid="search-input"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </InputGroup>
  )
}

Search.propTypes = {
  setParams: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired
}

export default Search
