import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { calculatePercent } from 'helpers/utils'
import { isNull } from 'lodash'
import { useDestroyReportFeature } from 'api/hooks/use-report-features'
import ConfirmDelete from 'shared/confirm-delete'
import { useQueryClient } from 'react-query'
import UserContext from 'context/user-context'

function FeatureRow({ feature, report }) {
  const queryClient = useQueryClient()
  const { municipality } = useContext(UserContext)
  const queryOptions = {
    onSuccess: () => {
      setTimeout(function () {
        queryClient.invalidateQueries([`reports/${report.id}`])
      }, 500)
    }
  }
  const { mutate } = useDestroyReportFeature({
    id: feature.id,
    options: queryOptions
  })

  return (
    <tr key={`feature-${feature.id}`}>
      <td>
        {municipality.abbreviation}-{feature.key}
      </td>
      <td width="33%">
        <>
          {isNull(feature.name) ? feature.analytic.name : feature.name}
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip>{feature.description}</Tooltip>}
          >
            <span style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="ms-2 text-primary"
              />
            </span>
          </OverlayTrigger>
        </>
      </td>
      <td className="text-center">{feature.open_count}</td>
      <td className="text-center">{feature.exception_count}</td>
      <td className="text-center">{feature.no_exception_count}</td>
      <td>
        <div className="progress mt-2">
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${calculatePercent(
                feature.complete_count,
                feature.total_count
              )}%`,
              backgroundColor: '#35517E'
            }}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {calculatePercent(feature.complete_count, feature.total_count) > 15
              ? `${calculatePercent(
                  feature.complete_count,
                  feature.total_count
                )}%`
              : null}
          </div>
        </div>
      </td>
      <td>
        <ConfirmDelete
          id={feature.id}
          handleDelete={mutate}
          extraConfirm={true}
        />
      </td>
    </tr>
  )
}

export default FeatureRow
