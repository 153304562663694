import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function RiskLineChart({ data }) {
  const options = {
    chart: {
      type: 'areaspline',
      height: 250
    },
    title: {
      text: '',
      align: 'left'
    },
    credits: {
      enabled: false
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        format: '{text}'
      }
    },
    xAxis: {
      categories: Object.keys(data)
    },
    legend: {
      enabled: false
    },
    tooltip: {
      shared: true,
      useHTML: true,
      borderRadius: 7,
      headerFormat:
        '<div style="background-color: #FFF; border-radius: 5px"><h6 style="color: #1E2E48; text-align: center"></h6>' +
        '<table><tr><td style="font-size: 14px">Risk rate of <b>{point.y}</b></td></tr><tr><td style="font-size: 14px">in the month of <b>{point.key}</b></td></tr>',
      pointFormat: '</table></div>',
      valueDecimals: 0
    },
    plotOptions: {
      areaspline: {
        lineColor: '#F96037',
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 4,
          fillColor: '#F96037'
        }
      },
      series: {
        label: {
          connectorAllowed: false
        }
      }
    },

    series: [
      {
        name: 'Risk',
        data: Object.values(data).map((d) => parseFloat(d)),
        color: '#FED9CF'
      }
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }
      ]
    }
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default RiskLineChart
