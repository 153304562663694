import React from 'react'
import Table from 'react-bootstrap/Table'

function EmployeeConflictTable({ transaction }) {
  return (
    <Table responsive className="border" size="sm">
      <thead>
        <tr>
          <th>Employee Permission Conflicts</th>
        </tr>
      </thead>
      <tbody>
        {transaction.employee.conflicts.map((a) => (
          <tr key={`conflicts-${a.id}`}>
            <td className="border-right">
              {a.name}
              <div
                style={{ fontSize: '0.9rem' }}
                className="text-muted fw-light"
              >
                {a.description}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default EmployeeConflictTable
