import React, { useState, useContext } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { apiPost, apiPatch } from 'api'
import { useMutation } from 'react-query'
import { isNull, isUndefined } from 'lodash'
import AnalyticList from './analytic-list'
import useSections from 'api/hooks/use-sections'
import ModuleIconCard from 'reports/form/module-card-icon'
import useMunicipalityUsers from 'api/hooks/use-municipality-users'
import UserContext from 'context/user-context'
import EmployeeFilter from 'transactions/table/filters/employee'
import VendorFilter from 'transactions/table/filters/vendor'
import AmountFilter from 'reports/form/amount-filter'
import AccountModal from 'reports/account-modal'
import queryString from 'query-string'

function MonitoringForm() {
  const navigate = useNavigate()
  const urlParams = queryString.parse(window.location.search)
  const { getAccessTokenSilently } = useAuth0()
  const { id: user_id } = useContext(UserContext)
  const [validated, setValidated] = useState(false)
  const [activeOverlay, setActiveOverlay] = useState('')
  const [report, setReport] = useState({
    alertUserId: '',
    analyticId: '',
    frequency: '',
    minimumAmount: urlParams.minimum_amount || '',
    maximumAmount: urlParams.maximum_amount || '',
    orgId: urlParams.org_id || '',
    objectId: urlParams.object_id || '',
    segOne: '',
    segTwo: '',
    segThree: '',
    segFour: '',
    segFive: '',
    employees: urlParams.employees
      ? urlParams.employees?.split('+')?.map((e) => ({ id: e }))
      : [],
    vendors: urlParams.vendors
      ? urlParams.vendors?.split('+')?.map((v) => ({ id: v }))
      : []
  })

  const { isSuccess: isSectionsSuccess, data: sections } = useSections({})
  const { isSuccess: isUsersSuccess, data: users } = useMunicipalityUsers({})

  const newMonitoring = useMutation(({ token, params }) => {
    const objectName = 'mole'
    const url = `users/${user_id}/moles`
    return apiPost({ url, token, objectName, params })
  })

  const createMonitoring = async () => {
    const token = await getAccessTokenSilently({ audience })
    const params = {
      id: report.id,
      analytic_id: report.analyticId,
      frequency: report.frequency,
      alert_user_id: report.alertUserId,
      is_enabled: true,
      minimum_amount: report.minimumAmount,
      maximum_amount: report.maximumAmount,
      org_id: report.orgId,
      object_id: report.objectId,
      seg_one_id: report.segOne?.value || '',
      seg_two_id: report.segTwo?.value || '',
      seg_three_id: report.segThree?.value || '',
      seg_four_id: report.segFour?.value || '',
      seg_five_id: report.segFive?.value || '',
      mole_employees_attributes: report.employees.map((e) => ({
        employee_id: e.id
      })),
      mole_vendors_attributes: report.vendors.map((v) => ({
        vendor_id: v.id
      }))
    }
    if (isNull(report.id) || isUndefined(report.id)) {
      newMonitoring.mutate({
        params,
        token
      })
    } else {
      updateMonitoring.mutate({
        params,
        token
      })
    }
  }

  const updateMonitoring = useMutation(({ token, params }) => {
    const objectName = 'mole'
    const url = `moles/${report.id}`
    return apiPatch({ url, token, objectName, params })
  })

  const handleSubmit = (e) => {
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      e.preventDefault()
      createMonitoring()
    }
    setValidated(true)
  }

  return (
    <Form
      className="needs-validation"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Row>
        <Col sm={3}>
          <div className="h5">Select Module</div>
        </Col>
        <Col sm={9}>
          <Row>
            {isSectionsSuccess &&
              sections
                .filter((section) =>
                  ['AP', 'AR', 'GL', 'PO', 'PC', 'Vendor'].includes(
                    section.abbreviation
                  )
                )
                .map((s) => (
                  <Col sm={3} key={`section-card-${s.id}`}>
                    <div className="my-2">
                      <ModuleIconCard
                        section={s}
                        params={report}
                        setParams={setReport}
                      />
                    </div>
                  </Col>
                ))}
          </Row>
        </Col>
      </Row>

      <hr className="my-4" />

      <Row className="mb-3">
        <Col sm={3}>
          <span className="fs-5">Select an Analytic to Monitor</span>
          <div className="text-muted">
            This will be the name of the Monitoring Report
          </div>
        </Col>
        <Col sm={8}>
          <Card className="shadow-sm">
            <Card.Body>
              <AnalyticList
                sectionId={report.sectionId}
                report={report}
                setReport={setReport}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <hr className="my-4" />

      <Row className="mb-3">
        <Col sm={3}>
          <span className="fs-5">Filters</span>
          <div className="text-muted">
            Filter down your transactions further
          </div>
        </Col>
        <Col sm={8}>
          <Card className="shadow-sm">
            <Card.Body>
              <Row className="mb-3">
                <Col sm={3}>
                  <span className="fw-bold">Amount</span>
                </Col>
                <Col sm={8}>
                  <AmountFilter setAuditReport={setReport} />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={3}>
                  <span className="fw-bold">Vendors</span>
                </Col>
                <Col sm={9} className="d-grid">
                  <VendorFilter
                    setFilterParams={setReport}
                    filterParams={report}
                    activeOverlay={activeOverlay}
                    setActiveOverlay={setActiveOverlay}
                    title={'Select Vendors'}
                    wideButton={true}
                  />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col sm={3}>
                  <span className="fw-bold">Employees</span>
                </Col>
                <Col sm={9} className="d-grid">
                  <EmployeeFilter
                    setFilterParams={setReport}
                    filterParams={report}
                    activeOverlay={activeOverlay}
                    setActiveOverlay={setActiveOverlay}
                    title={'Select Employees'}
                    wideButton={true}
                  />
                </Col>
              </Row>
              {false && (
                <Row className="mt-2">
                  <Col sm={3}>
                    <span className="fw-bold mt-1">Account</span>
                  </Col>
                  <Col sm={9} className="d-grid">
                    <AccountModal params={report} setParams={setReport} />
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <hr className="my-4" />

      <Row className="mb-3">
        <Col sm={3}>
          <span className="fs-5">Alerts and Frequency</span>
        </Col>
        <Col sm={8}>
          <Card className="shadow-sm">
            <Card.Body>
              <Row className="mt-2">
                <Col sm={3}>
                  <span className="fw-bold">User Email</span>
                </Col>
                <Col sm={9}>
                  <Form.Group>
                    <Form.Control
                      as="select"
                      onChange={(e) =>
                        setReport({ ...report, alertUserId: e.target.value })
                      }
                    >
                      <option>Email</option>
                      {isUsersSuccess &&
                        users.map((u) => (
                          <option key={`user-${u.id}`} value={u.id}>
                            {u.email}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col sm={3}>
                  <span className="fw-bold">Frequency</span>
                </Col>
                <Col sm={9}>
                  <Button
                    className="me-2"
                    variant={report.frequency === 'daily' ? 'primary' : 'light'}
                    onClick={() => setReport({ ...report, frequency: 'daily' })}
                  >
                    Daily
                  </Button>
                  <Button
                    className="me-2"
                    variant={
                      report.frequency === 'weekly' ? 'primary' : 'light'
                    }
                    onClick={() =>
                      setReport({ ...report, frequency: 'weekly' })
                    }
                  >
                    Weekly
                  </Button>
                  <Button
                    className="me-2"
                    variant={
                      report.frequency === 'monthly' ? 'primary' : 'light'
                    }
                    onClick={() =>
                      setReport({ ...report, frequency: 'monthly' })
                    }
                  >
                    Monthly
                  </Button>
                  <div className="text-muted fw-light">
                    Alerts are only sent if a transaction was flagged during
                    time period
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={3} />
        <Col sm={9}>
          <Button
            variant="primary"
            disabled={newMonitoring.isLoading}
            type="submit"
          >
            {isNull(report.id) || isUndefined(report.id) ? (
              <FontAwesomeIcon icon={faPlus} className="text-warning me-1" />
            ) : (
              <FontAwesomeIcon icon={faEdit} className="text-warning me-1" />
            )}

            <span>
              {isNull(report.id) || isUndefined(report.id)
                ? 'Add Monitoring Event'
                : 'Edit Monitoring'}
            </span>
          </Button>
          {newMonitoring.isSuccess ? navigate('/monitoring') : null}
        </Col>
      </Row>
    </Form>
  )
}

MonitoringForm.propTypes = {}

export default MonitoringForm
