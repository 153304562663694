import { useContext } from 'react'
import { useMutation } from 'react-query'
import { apiPost, apiPatch, apiDelete } from 'api'
import AuthContext from 'context/auth-context'

const createDashboardVendor = async (token, params, id) => {
  const objectName = 'dashboard_vendor'
  const url = `dashboards/${id}/dashboard_vendors`

  const data = await apiPost({ url, token, objectName, params })
  return data
}

export function useCreateDashboardVendor({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return createDashboardVendor(token, params, params.dashboard_id)
    },
    { ...options }
  )
}

const updateDashboardVendor = async (token, params, id) => {
  const objectName = 'dashboard_vendor'
  const url = `dashboard_vendors/${id}`

  const data = await apiPatch({ url, token, objectName, params })
  return data
}

export function useUpdateDashboardVendor({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return updateDashboardVendor(token, params, params.dashboard_vendor_id)
    },
    { ...options }
  )
}

const deleteDashboardVendor = async (token, id) => {
  const url = `dashboard_vendors/${id}`

  const data = await apiDelete({ url, token })
  return data
}

export function useDeleteDashboardVendor({ id, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return deleteDashboardVendor(token, id)
    },
    { ...options }
  )
}