import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ReportStat({ icon, text, colorClass, count }) {
  return (
    <>
      <FontAwesomeIcon icon={icon} className={`${colorClass} me-2`} />
      {count} <span className="text-muted fw-light me-4">{text}</span>
    </>
  )
}

export default ReportStat
