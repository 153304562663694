import React, { useState } from 'react'
import Page from 'layout/sod/page'
import PageTitle from 'layout/page-title'
import { split } from 'lodash'
import DescriptionCard from './description-card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RoleChart from './chart'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import InternalConflict from 'duties/badges/internal-conflict'
import { useConflict } from 'api/hooks/use-conflicts'
import CenteredSpinner from 'shared/centered-spinner'

function Conflict() {
  const path = window.location.pathname
  const conflictId = split(path, '/')[2]
  const [activeTab, setActiveTab] = useState('role')

  const params = {
    id: conflictId
  }

  const { data: conflict, isLoading, isSuccess } = useConflict({ params })

  const crumbs = [
    { name: 'Segregation of Duties', url: 'duties' },
    { name: 'Conflicts', url: 'conflicts' }
  ]

  return (
    <Page
      title={`${isSuccess ? conflict.name : ''}`}
      pageTitle={`Conflict - ${isSuccess ? conflict.name : ''}`}
    >
      <PageTitle breadcrumbs={crumbs} />
      {isLoading && <CenteredSpinner />}

      {isSuccess && (
        <>
          <h4 className="mt-4">
            {conflict.name}
            {/* TODO: SOD create PDF report */}
            {false && (
              <Button
                variant="light-outline"
                className="bg-white ms-2 border-secondary"
                onClick={() =>
                  toast.info('Holden will hand you fake PDF', {
                    position: 'top-center',
                    autoClose: 1000,
                    hideProgressBar: true,
                    progress: undefined,
                    theme: 'light'
                  })
                }
              >
                <FontAwesomeIcon icon={faFilePdf} className="me-2" />
                Export
              </Button>
            )}
          </h4>
          <hr />
          <Row>
            <Col sm={12}>
              <div className="mb-3">
                <DescriptionCard conflict={conflict} params={params} />
              </div>
            </Col>
          </Row>
          <Row className="my-4">
            <Col sm={12}>
              <span className="mt-1 pe-2 fw-light">View By</span>
              <span className="border-end border-2 border-light me-2"></span>
              <Button
                variant={activeTab === 'role' ? 'primary' : 'light'}
                onClick={() => setActiveTab('role')}
                className="me-2 py-1 px-1.5"
              >
                Role
              </Button>
              <Button
                variant={activeTab === 'department' ? 'primary' : 'light'}
                onClick={() => setActiveTab('department')}
                className="me-2 py-1 px-1.5"
              >
                Department
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm={6}>
              <Card>
                <Card.Body>
                  {activeTab === 'role' ? (
                    <RoleChart groupBy={activeTab} conflict={conflict} />
                  ) : (
                    <RoleChart groupBy={activeTab} conflict={conflict} />
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col sm={6}>
              <Card>
                <Card.Body>
                  {activeTab === 'role' && (
                    <Table size="sm">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Conflicting Roles</th>
                        </tr>
                      </thead>
                      <tbody>
                        {conflict.role_ids.map((r) => {
                          // Find the role name using role_id
                          const roleName = conflict.roles.find(
                            (role) => role.id === r.role_id
                          )?.name

                          const conflictRoleNames = r.conflict_roles.map(
                            (role) =>
                              conflict.roles.find((r) => r.id === role)?.name
                          )

                          return (
                            <tr>
                              <td>{roleName}</td>
                              <td>
                                {conflictRoleNames.map((c) =>
                                  roleName === c ? (
                                    <InternalConflict textDisplay={c} />
                                  ) : (
                                    <div>{c}</div>
                                  )
                                )}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  )}
                  {activeTab === 'department' && (
                    <Table size="sm">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Employees with Conflicts</th>
                        </tr>
                      </thead>
                      <tbody>
                        {conflict.department_counts.map((d) => (
                          <tr>
                            <td>{d.department_name}</td>
                            <td>{d.conflict_departments}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Page>
  )
}

export default Conflict
