import React from 'react'
import Table from 'react-bootstrap/Table'
import moment from 'moment'
import WorkflowActionBadge from 'transactions/drawer/workflow-action-badge'
import TableLoading from 'shared/tables/table-loading'
import SortArrow from 'shared/tables/sort-arrow'
import { updateOrdering } from 'shared/tables/utils'

function WorkflowTable({
  params,
  setParams,
  isLoading,
  isSuccess,
  workflows,
  showEmployee = false
}) {
  const mapValues = (process) => {
    if (process === 'API') {
      return 'Inv #:'
    }
    if (process === 'REQ') {
      return 'Req #:'
    }
    if (process === 'POM') {
      return 'PO Change:'
    }
    if (process === 'POE') {
      return 'PO:'
    }
  }

  const displayKey = (workflow) => {
    if (workflow.process === 'API') {
      return workflow.display_key_two
    }
    if (workflow.process === 'REQ') {
      return workflow.display_key
    }
    if (workflow.process === 'POM') {
      return workflow.display_key_two
    }
    if (workflow.process === 'POE') {
      return workflow.display_key_two
    }
  }

  return (
    <Table className="border bg-white border-light my-4" responsive>
      <thead>
        <tr>
          <th>Process Type (Step)</th>
          {showEmployee && (
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => updateOrdering('approver_id', params, setParams)}
            >
              Employee
              <SortArrow
                column="approver_id"
                orderBy={params.orderBy}
                orderDirection={params.orderDirection}
              />
            </th>
          )}
          <th
            style={{ cursor: 'pointer' }}
            onClick={() => updateOrdering('action', params, setParams)}
          >
            Action{' '}
            <SortArrow
              column="action"
              orderBy={params.orderBy}
              orderDirection={params.orderDirection}
            />
          </th>
          <th
            style={{ cursor: 'pointer' }}
            onClick={() =>
              updateOrdering('seconds_between_action', params, setParams)
            }
          >
            Time in Queue
            <SortArrow
              column="seconds_between_action"
              orderBy={params.orderBy}
              orderDirection={params.orderDirection}
            />
          </th>
          <th
            style={{ cursor: 'pointer' }}
            onClick={() => updateOrdering('entered_at', params, setParams)}>
            Entered At
            <SortArrow
              column="entered_at"
              orderBy={params.orderBy}
              orderDirection={params.orderDirection}
            />
          </th>
          <th 
            style={{ cursor: 'pointer' }}
            onClick={() => updateOrdering('action_at', params, setParams)}>
            Action At
            <SortArrow
              column="action_at"
              orderBy={params.orderBy}
              orderDirection={params.orderDirection}
            />
          </th>
          <th>Comment</th>
        </tr>
      </thead>
      <tbody>
        {isLoading && <TableLoading columns={7} />}
        {isSuccess && workflows.data.length === 0 && (
          <tr>
            <td colSpan={7} className="text-center">
              No workflows found
            </td>
          </tr>
        )}
        {isSuccess &&
          workflows.data.map((w) => (
            <tr key={`workflow-${w.id}`}>
              <td>
                {mapValues(w.process)} {displayKey(w)}
                <small className="ms-2 text-muted">({w.step_number})</small>
              </td>
              {showEmployee && <td>{w.approver_external_id}</td>}
              <td>
                <WorkflowActionBadge action={w.action} />
              </td>
              <td>
                {moment
                  .duration(
                    moment(w.action_at, 'YYYY-MM-DD HH:mm:ss').diff(
                      moment(w.entered_at, 'YYYY-MM-DD HH:mm:ss')
                    )
                  )
                  .humanize()}
              </td>
              <td>
                <span className="text-muted fw-light">
                  {moment(w.entered_at, 'YYYY-MM-DD HH:mm:ss').format('L LT')}
                </span>
              </td>
              <td>
                <span className="text-muted fw-light">
                  {moment(w.action_at, 'YYYY-MM-DD HH:mm:ss').format('L LT')}
                </span>
              </td>
              <td>
                <span className="text-muted">{w.comment}</span>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}

export default WorkflowTable
