import React from 'react'
import Form from 'react-bootstrap/Form'
import useMunicipalityUsers from 'api/hooks/use-municipality-users'

function UsersDropdown({ params, setParams, isObject = true, disabled = [] }) {
  const { isSuccess, isLoading, data: users } = useMunicipalityUsers({})

  const updateParams = (value) => {
    if (isObject) {
      setParams({ ...params, user_id: value })
    } else {
      setParams(value)
    }
  }

  return (
    <Form.Group>
      <Form.Select
        as="select"
        onChange={(e) => updateParams(e.target.value)}
        value={isObject ? params.user_id : params}
      >
        <option value="">{isLoading ? 'Loading...' : 'Select User'}</option>
        {isSuccess &&
          users.map((u) => (
            <option
              value={u.id}
              key={`user-${u.id}`}
              disabled={disabled.includes(u.id)}
            >
              {u.first_name} {u.last_name} - {u.email}
            </option>
          ))}
      </Form.Select>
    </Form.Group>
  )
}

export default UsersDropdown
