import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import AmountFilter from './amount'
import IsPending from './purchasing/IsPending'
import ReqToPoCreation from './purchasing/ReqToPoCreation'
import PoStatusCode from './purchasing/PoStatusCode'
import PoMinPoLiquidatedAmount from './purchasing/PoMinPoLiquidatedAmount'
import PoMaxPoLiquidatedAmount from './purchasing/PoMaxPoLiquidatedAmount'
import PoMinPoRemainingAmount from './purchasing/PoMinPoRemainingAmount'
import PoMaxPoRemainingAmount from './purchasing/PoMaxPoRemainingAmount'
import PoMinPoPercentLiquidated from './purchasing/PoMinPoPercentLiquidated'
import PoMaxPoPercentLiquidated from './purchasing/PoMaxPoPercentLiquidated'
import EntryToPaymentDays from './accounts_payable/EntryToPaymentDays'
import TotalDaysToPayment from './accounts_payable/TotalDaysToPayment'
import InvoiceToEntryDays from './accounts_payable/InvoiceToEntryDays'
import CheckType from './accounts_payable/CheckType'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useUpdateDashboard } from 'api/hooks/use-dashboards'

function MoreFilters({ params, setParams, setIsFilterOpen, sectionName }) {
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setIsFilterOpen(false)
    setShow(false)
  }
  const handleShow = () => {
    setIsFilterOpen(true)
    setShow(true)
  }

  const amountParams = {
    minimum_amount: params.minimum_amount,
    maximum_amount: params.maximum_amount
  }

  const { mutate } = useUpdateDashboard({
    params: amountParams,
    id: params.dashboardId
  })

  useEffect(() => {
    mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.minimum_amount, params.maximum_amount])

  const isFiltered =
    params.minimum_amount ||
    params.maximum_amount ||
    params?.poMinReqToPoCreation ||
    params?.poIsPendingWorkflow ||
    params?.poStatusCode ||
    params?.apMinInvoiceToEntryDays ||
    params?.apMinEntryToPaymentDays ||
    params?.apMinTotalDaysToPayment ||
    params?.apCheckType ||
    params?.poMinPoLiquidatedAmount ||
    params?.poMaxPoLiquidatedAmount ||
    params?.poMinPoRemainingAmount ||
    params?.poMaxPoRemainingAmount ||
    params?.poMinPoPercentLiquidated ||
    params?.poMaxPoPercentLiquidated

  return (
    <React.Fragment>
      <label className="mr-2 fw-light text-muted sr-only">
        <small>More Filters</small>
      </label>
      <div>
        <Button
          className={`bg-white ${isFiltered ? 'text-primary' : 'text-info'}`}
          onClick={handleShow}
          style={{ border: isFiltered ? '' : '1px solid #ccc' }}
          variant={isFiltered ? 'outline-primary' : 'outline-light'}
        >
          <FontAwesomeIcon icon={faFilter} /> Filters
        </Button>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>More Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <AmountFilter
                params={params}
                setParams={setParams}
                accessorKey={'minimum_amount'}
                title="Minimum"
              />
            </Col>
            <Col>
              <AmountFilter
                params={params}
                setParams={setParams}
                accessorKey={'maximum_amount'}
                title="Maximum"
              />
            </Col>
          </Row>

          {sectionName === 'Purchasing' && (
            <>
              <h5 className="mt-4">Purchasing Process</h5>
              <Row className="mt-2">
                <Col>
                  <PoStatusCode params={params} setParams={setParams} />
                </Col>
                <Col></Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <ReqToPoCreation params={params} setParams={setParams} />
                </Col>
                <Col></Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <PoMinPoLiquidatedAmount params={params} setParams={setParams} />
                </Col>
                <Col>
                  <PoMaxPoLiquidatedAmount params={params} setParams={setParams} />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <PoMinPoRemainingAmount params={params} setParams={setParams} />
                </Col>
                <Col>
                  <PoMaxPoRemainingAmount params={params} setParams={setParams} />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <PoMinPoPercentLiquidated params={params} setParams={setParams} />
                </Col>
                <Col>
                  <PoMaxPoPercentLiquidated params={params} setParams={setParams} />
                </Col>
              </Row>

              <h5 className="mt-4">Workflow</h5>

              <Row className="mt-2">
                <Col>
                  <IsPending params={params} setParams={setParams} />
                </Col>
              </Row>
            </>
          )}

          {sectionName === 'Accounts Payable' && (
            <>
              <h5 className="mt-4">Invoicing Process</h5>
              <Row className="mt-2">
                <Col>
                  <InvoiceToEntryDays params={params} setParams={setParams} />
                </Col>
                <Col>
                  <EntryToPaymentDays params={params} setParams={setParams} />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <TotalDaysToPayment params={params} setParams={setParams} />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <CheckType params={params} setParams={setParams} />
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default MoreFilters
