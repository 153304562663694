import React, { useState, useEffect } from 'react'
import { useDebounce } from 'api/hooks/use-debounce'
import Form from 'react-bootstrap/Form'


type ReviewProps = {
  initial_comment: string
}

type ReviewCommentProps = {
  review: ReviewProps
  setReview: Function
}

function ReviewComment({ review, setReview }: ReviewCommentProps) {
  const [comment, setComment] = useState(review.initial_comment)

  const debouncedComment = useDebounce(comment, 500)

  useEffect(() => {
    setReview((review: ReviewProps) => ({ ...review, initial_comment: debouncedComment }))
  }, [debouncedComment, setReview])

  return (
    <Form.Control
      onChange={(e) => setComment(e.target.value)}
      value={comment}
      as="textarea"
      rows={4}
      placeholder=""
    />
  )
}

export default ReviewComment
