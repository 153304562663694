import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import { useCreateSavedTransaction } from 'api/hooks/use-saved-transaction'
import DestroyBookmark from 'shared/destroy-bookmark'

type BookmarkTransactionProps = {
  transaction: {
    id: string
  }
}

function BookmarkTransaction({ transaction }: BookmarkTransactionProps) {
  const params = {
    transaction_id: transaction.id
  }
  const { mutate, isLoading } = useCreateSavedTransaction({ params })

  return (
    <React.Fragment>
      {isLoading ? (
        <DestroyBookmark transaction={transaction} />
      ) : (
        <div onClick={() => mutate()}>
          <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faBookmark} />
        </div>
      )}
    </React.Fragment>
  )
}

export default BookmarkTransaction
