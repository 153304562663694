import React from 'react'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faTrash,
  faCog,
  faMinus
} from '@fortawesome/free-solid-svg-icons'

function StatusBadge({ status }) {
  return (
    <React.Fragment>
      {status === 'queued' && (
        <Badge pill className="text-primary bg-light">
          <FontAwesomeIcon icon={faMinus} spin className="me-1" />
          Queued
        </Badge>
      )}
      {status === 'in_progress' && (
        <Badge pill className="text-primary bg-light">
          <FontAwesomeIcon icon={faCog} spin className="me-1" />
          In Progress
        </Badge>
      )}
      {status === 'complete' && (
        <Badge pill className="text-success bg-light">
          <FontAwesomeIcon icon={faCheckCircle} className="me-1" />
          Complete
        </Badge>
      )}
      {status === 'deleting' && (
        <Badge pill className="text-danger bg-light">
          <FontAwesomeIcon icon={faTrash} className="me-1" />
          Deleting
        </Badge>
      )}
    </React.Fragment>
  )
}

export default StatusBadge
