import React, { useRef } from 'react'
import { Popover, Button, Overlay, Form } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import smallDot from 'shared/small-dot.svg'

export default function AvgQueueTimeFilter({
  params,
  setParams,
  activeOverlay,
  setActiveOverlay
}) {
  
  const handleAvgQueueTimeChange = (e) => {
    const value = e.target.value
    setParams({
      ...params,
      avgQueueTime: value
    })
  }

  const handleQuickFilter = (days) => {
    const avgQueueTime = days * 24
    setParams({
      ...params,
      avgQueueTime: avgQueueTime
    })
  }

  const filterTarget = useRef(null)

  const isFiltered = params.avgQueueTime > 0

  const avgQueueTimePopover = (
    <Popover id="popover-avgQueueTime">
      <Popover.Header as="h3">Filter by Avg. Queue Time</Popover.Header>
      <Popover.Body>
        <Form>
          <Form.Group controlId="avgQueueTime">
            <Form.Label>Greater than (hrs)</Form.Label>
            <Form.Control
              type="number"
              value={params.avgQueueTime}
              onChange={handleAvgQueueTimeChange}
            />
          </Form.Group>
          <hr />
          <Row className="mt-2">
            <Col>
              <h6>Quick Filter</h6>
              <Button
                variant="light"
                className="p-1 me-1"
                onClick={() => handleQuickFilter(1)}
              >
                1 day
              </Button>
              <Button
                variant="light"
                className="p-1 me-1"
                onClick={() => handleQuickFilter(2)}
              >
                2 days
              </Button>
              <Button
                variant="light"
                className="p-1"
                onClick={() => handleQuickFilter(3)}
              >
                3 days
              </Button>
            </Col>
          </Row>
        </Form>
      </Popover.Body>
    </Popover>
  )

  return (
    <div className="d-flex justify-content-center">
      <Overlay trigger="click" placement="bottom"
        target={filterTarget.current}
        show={activeOverlay === 'avgQueueTime'}
      >
        {avgQueueTimePopover}
      </Overlay>
      <Button
        className={isFiltered ? 'bg-white text-primary' : 'bg-white text-info'}
        variant={isFiltered ? 'outline-primary' : 'outline-light'}
        style={{ border: isFiltered ? '' : '1px solid #ced4da' }}
        onClick={() =>
          activeOverlay === 'avgQueueTime'
            ? setActiveOverlay('')
            : setActiveOverlay('avgQueueTime')
        }
        ref={filterTarget}
      >
        {isFiltered ? (
          <>
            <img src={smallDot} className="img-fluid" alt={'Filter Applied'} />
            Avg. Time in Queue
          </>
        ) : (
          'Avg. Time in Queue'
        )}
      </Button>
    </div>
  )
}
