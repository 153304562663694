import React from 'react'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'

type NewReviewBadgeProp = {
  showCount?: boolean,
  count: number
}

function NewReviewBadge({ showCount = true, count }: NewReviewBadgeProp) {
  return (
    <Badge className="text-primary badge-primary ms-2">
      <FontAwesomeIcon icon={faBell} /> {showCount ? count : ''} New
    </Badge>
  )
}

export default NewReviewBadge
