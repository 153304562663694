import React from 'react'
import { formatDateTime } from 'helpers/datetime'
import Link from './event-link'
import { actionText, toFromText } from './text-helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faIdCard,
  faUserPlus,
  faUserMinus
} from '@fortawesome/free-solid-svg-icons'
import { faIdCard as faIdCardOpen } from '@fortawesome/free-regular-svg-icons'

function RoleAssignment({ log }) {
  const LogIcon = () => {
    if (log.action === 'A') {
      return (
        <>
          <FontAwesomeIcon icon={faIdCard} />
          <FontAwesomeIcon icon={faUserPlus} className="mx-2" />
        </>
      )
    } else if (log.action === 'D') {
      return (
        <>
          <FontAwesomeIcon icon={faIdCardOpen} />
          <FontAwesomeIcon icon={faUserMinus} className="mx-2" />
        </>
      )
    } else {
      return (
        <>
          <FontAwesomeIcon icon={faIdCard} className="mx-2" />
        </>
      )
    }
  }

  return (
    <li
      className="list-group-item d-flex justify-content-between align-items-start"
      aria-current="true"
    >
      <div className="ms-2 me-auto">
        <div className="fw-bold">
          <LogIcon /> Role {actionText(log.action)}{' '}
        </div>
        <div className="fw-light text-muted">
          {actionText(log.action)}{' '}
          <Link linkObject={log.linkable} objectType="roles" />{' '}
          {toFromText(log.action)}{' '}
          <Link linkObject={log.changeable} objectType="users" />
        </div>
      </div>
      <span className="ms-2 text-muted fw-light">
        {formatDateTime(log.changed_at)} by {log.changed_by.external_id}
      </span>
    </li>
  )
}

export default RoleAssignment
