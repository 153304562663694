import React from 'react'
import Stats from '../stats'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import HealthTable from './health-table'

function OverviewTab() {
  return (
    <>
      <Row>
        <Col sm={12}>
          <div className="mb-4">
            <Stats />
          </div>
        </Col>
        <Col sm={8}></Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Body>
              <HealthTable />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default OverviewTab
