import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getWorkflowSummary = async (token, params, municipalityId) => {
  const url = `municipalities/${municipalityId}/workflow_summaries`

  const data = await apiGet({ url, token, params })
  return data
}

export function useWorkflowSummary({ params, options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const queryKey = [
    `municipalities/${municipality_id}/workflow_summaries`,
    params
  ]
  const queryFn = () => getWorkflowSummary(token, params, municipality_id)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
