import React, { useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import Search from 'shared/forms/search'
import { filter, reject } from 'lodash'
import { useEmployees } from 'api/hooks/use-employees'
import { useQueryClient } from 'react-query'
import Table from 'react-bootstrap/Table'
import { pick } from 'lodash'
import { map } from 'lodash'
import EmployeeItem from './item'
import DeleteEmployee from './delete'
import UpdateEmployee from './update'
import Spinner from 'react-bootstrap/Spinner'

function EmployeeSearch({ params, setParams, setShow }) {
  const queryClient = useQueryClient()
  const defaultSearchParams = {
    search: '',
    section: params.section_id,
    page: 1,
    perPage: 5
  }
  const [searchParams, setSearchParams] = useState(defaultSearchParams)

  const addOrRemoveEmployee = (employee) => {
    const findEmployee = filter(params.employees, {
      id: employee.id
    })
    if (findEmployee.length > 0) {
      const newList = reject(params.employees, { id: employee.id })
      updateEmployees(newList)
      setSearchParams(defaultSearchParams)
      queryClient.invalidateQueries('employees')
      queryClient.invalidateQueries('transactions')
    } else {
      const newList = params.employees
      newList.push({ ...pick(employee, ['id', 'external_id', 'dashboard_employee_id']), options: 'include' })
      updateEmployees(newList)
      setSearchParams(defaultSearchParams)
      queryClient.invalidateQueries('employees')
      queryClient.invalidateQueries('transactions')
    }
  }

  const toggleIncludeExclude = (employee, options) => {
    const newList = map(params.employees, function (a) {
      return a.id === employee.id ? { ...employee, options: options } : a
    })
    updateEmployees(newList)
    queryClient.invalidateQueries('employees')
    queryClient.invalidateQueries('time_periods')
  }

  const updateEmployees = (newList) => {
    setParams({
      ...params,
      employees: newList
    })
  }

  const { isSuccess, isLoading, data } = useEmployees({
    params: searchParams,
    options: {
      enabled: searchParams.search !== '' && searchParams.section.length > 2
    }
  })

  return (
    <>
      <Search
        params={searchParams}
        setParams={setSearchParams}
        placeholder={'Search Employee'}
      />
      <ListGroup variant="flush">
        {isLoading && (
          <ListGroup.Item>
            <div className="text-muted text-center">
              <Spinner animation="grow" variant="warning" className="my-5" />
            </div>
          </ListGroup.Item>
        )}
        {isSuccess &&
          data.data.map((e) => (
            <EmployeeItem
              key={`employee-option-${e.id}`}
              employee={e}
              params={params}
              dashboardId={params.dashboardId}
              addOrRemoveEmployee={addOrRemoveEmployee}
            />
          ))}
      </ListGroup>

      <Table size="sm" className="mt-4">
        <thead>
          <tr>
            <th>Name/ID</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {params.employees.length === 0 && (
            <tr>
              <td colSpan="3" className="text-center">
                No employees selected. Search for and select an employee above
                to include or exclude from the view.
              </td>
            </tr>
          )}
          {params.employees.map((employee) => (
            <tr key={`dashboard-employee-${employee.dashboard_employee_id}`}>
              <td>
                <div className="mt-1">{employee.name || employee.external_id}</div>
              </td>
              <td>
                <UpdateEmployee
                  toggleIncludeExclude={toggleIncludeExclude}
                  employee={employee}
                  dashboardId={params.dashboardId}
                />
              </td>
              <td>
                <DeleteEmployee
                  addOrRemoveEmployee={addOrRemoveEmployee}
                  employee={employee}
                  dashboardId={params.dashboardId}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default EmployeeSearch
