import React from 'react'
import Page from 'layout/page'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageTitle from 'layout/page-title'
import MonitoringForm from './form'
import { DefaultError } from 'shared/default-error'
import { ErrorBoundary } from '@sentry/react'

function NewMonitoring() {
  const crumbs = [{ name: 'Monitoring', url: 'monitoring' }]
  return (
    <Page title="New Continous Monitoring Report">
      <PageTitle breadcrumbs={crumbs} />
      <Row>
        <Col>
          <ErrorBoundary
            fallback={DefaultError}
            beforeCapture={(scope) => {
              scope.setTag('component', 'analyticDescription')
            }}
          >
            <MonitoringForm />
          </ErrorBoundary>
        </Col>
      </Row>
    </Page>
  )
}

export default NewMonitoring
