import React from 'react'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDotCircle,
  faRetweet,
  faExclamation,
  faCheck
} from '@fortawesome/free-solid-svg-icons'

type MultiStatusProps = {
  openCount: number,
  followUpCount: number,
  exceptionCount: number,
  noExceptionCount: number
}

function MultiStatus({
  openCount,
  followUpCount,
  exceptionCount,
  noExceptionCount
}: MultiStatusProps) {
  return (
    <>
      <Badge
        className="border border-secondary me-2 bg-white text-primary"
        style={{ fontSize: '90%' }}
        pill
      >
        <FontAwesomeIcon icon={faDotCircle} className="text-info " />{' '}
        {openCount}
        <FontAwesomeIcon icon={faRetweet} className="text-warning ms-2" />{' '}
        {followUpCount}
      </Badge>
      <Badge
        className="border border-secondary me-1 bg-white text-primary"
        style={{ fontSize: '90%' }}
        pill
      >
        <FontAwesomeIcon icon={faExclamation} className="text-danger" />{' '}
        {exceptionCount}
        <FontAwesomeIcon icon={faCheck} className="text-success ms-2" />{' '}
        {noExceptionCount}
      </Badge>
    </>
  )
}

export default MultiStatus
