import React from 'react'
import Form from 'react-bootstrap/Form'
import propTypes from 'prop-types'

function VendorType({ params, setParams }) {
  return (
    <>
      <Form.Select
        aria-label="Vendor Type"
        value={params.vendorType}
        onChange={(e) => setParams({ ...params, vendorType: e.target.value })}
      >
        <option value="">Type</option>
        <option value="LLP">LLP</option>
        <option value="EMP">EMP</option>
        <option value="IND">IND</option>
        <option value="NULL">NULL</option>
        <option value="LP">LP</option>
        <option value="LLCS">LLCS</option>
        <option value="SCOR">SCOR</option>
        <option value="LLCC">LLCC</option>
        <option value="PYRL">PYRL</option>
        <option value="501C">501C</option>
        <option value="COUN">COUN</option>
        <option value="CCOR">CCOR</option>
        <option value="EDU">EDU</option>
        <option value="PART">PART</option>
        <option value="DEPT">DEPT</option>
        <option value="GOV">GOV</option>
      </Form.Select>
    </>
  )
}

export default VendorType

VendorType.propTypes = {
  params: propTypes.object,
  setParams: propTypes.func,
}