import React, { useState, useContext } from 'react'
import Table from 'vendors/table'
import AmountFilter from 'shared/filters/AmountNew'
import TransactionCount from '../shared/filters/transactions-count'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import { useDebounce } from 'api/hooks/use-debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import MoreFilters from './MoreFilters'
import { ErrorBoundary } from '@sentry/react'
import Status from './Status'
import VendorType from './VendorType'
import UserContext from 'context/user-context'

function Vendors() {
  const emptyState = {
    page: 1,
    perPage: 25,
    search: '',
    status: '',
    orderBy: 'risk_rate',
    orderDirection: 'desc',
    invoiceMinimumAmount: '',
    invoiceMaximumAmount: '',
    checkMinimumAmount: '',
    checkMaximumAmount: '',
    transactionsCount: '',
    vendorType: '',
    excludePcard: false,
    stale: false,
    recentPaymentChange: false,
    po_box: false,
    one_eight_hundred_number: false,
    duplicate_ein: false,
    duplicate_ein_being_paid: false,
  }
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)
  const [params, setParams] = useState(emptyState)
  const { municipality_name } = useContext(UserContext)

  return (
    <Page title="Vendors">
      <PageTitle />
      <Row>
        <Col sm="4">
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <FormControl
              name="vendor-search"
              placeholder="Search Vendor Name/ID"
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col sm="auto">
          <ErrorBoundary
            fallback={<></>}
            beforeCapture={(scope) => {
              scope.setTag('component', 'VendorStatus')
            }}
          >
            <Status params={params} setParams={setParams} />
          </ErrorBoundary>
        </Col>
        {['College Station', 'Sarpy County'].includes(municipality_name) && (
          <Col sm="auto">
            <ErrorBoundary
              fallback={<></>}
              beforeCapture={(scope) => {
                scope.setTag('component', 'VendorType')
              }}
            >
              <VendorType params={params} setParams={setParams} />
            </ErrorBoundary>
          </Col>
        )}
        <Col sm="auto">
          {/* TODO: Update filter for invoice amount and check amount (2 or 1?) */}
          <ErrorBoundary
            fallback={<></>}
            beforeCapture={(scope) => {
              scope.setTag('component', 'AmountFilter')
            }}
          >
            <AmountFilter
              params={params}
              setParams={setParams}
              accessorMin="invoiceMinimumAmount"
              accessorMax="invoiceMaximumAmount"
            />
          </ErrorBoundary>
        </Col>
        <Col sm="auto">
          <ErrorBoundary
            fallback={<></>}
            beforeCapture={(scope) => {
              scope.setTag('component', 'TransactionCount')
            }}
          >
            <TransactionCount
              filterParams={params}
              setFilterParams={setParams}
            />
          </ErrorBoundary>
        </Col>
        <Col>
          <ErrorBoundary
            fallback={<></>}
            beforeCapture={(scope) => {
              scope.setTag('component', 'MoreFilters')
            }}
          >
            <MoreFilters params={params} setParams={setParams} />
          </ErrorBoundary>
        </Col>
      </Row>

      <Table
        setFilterParams={setParams}
        filterParams={params}
        debouncedSearch={debouncedSearch}
      />
    </Page>
  )
}

export default Vendors
