import React, { useState, useContext } from 'react'
import PageTitle from 'layout/page-title'
import Page from 'layout/page'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import useEmployeeWorkflows from 'api/hooks/use-employee-workflows'
import { split } from 'lodash'
import TableFooter from 'shared/tables/table-footer'
import TimeframeSelect from 'dashboard/filters/timeframe-select'
import WorkflowTable from './table'
import WorkflowFilters from './filters'
import FilterContext from 'context/filters-context'

function Workflow() {
  const path = window.location.pathname
  const employeeId = split(path, '/')[2]
  const { filters } = useContext(FilterContext)
  const defaultParams = {
    employee_id: employeeId,
    page: 1,
    perPage: 25,
    orderBy: 'action_at',
    orderDirection: 'desc',
    time_period: filters?.time_period || '30',
    actions: [],
    processes: filters?.processes || []
  }
  const [params, setParams] = useState(defaultParams)
  const {
    isSuccess,
    isLoading,
    data: workflows
  } = useEmployeeWorkflows({ params })
  const crumbs = [{ name: 'Workflow', url: 'workflow' }]

  const stats = [
    {
      name: 'Approval Rate',
      value: isSuccess ? `${workflows.stats?.approval_rate}%` : '0%'
    },
    {
      name: 'Rejection Rate',
      value: isSuccess ? `${workflows.stats?.rejection_rate}%` : '0%'
    },
    {
      name: 'Avg. Queue Time',
      value: isSuccess ? `${workflows.stats?.avg_queue_time} days` : '0'
    },
    {
      name: 'Volume',
      value: isSuccess ? workflows.stats?.total_actions?.toLocaleString() : 0
    }
  ]

  return (
    <Page
      pageTitle={`Workflow: ${isSuccess ? workflows.employee.external_id : ''}`}
      title={
        <Row>
          <Col sm="auto">
            Workflow: {isSuccess ? workflows.employee.external_id : ''}
          </Col>
          <Col sm="auto">
            <TimeframeSelect
              params={params}
              setParams={setParams}
              hideLabel={true}
            />
          </Col>
        </Row>
      }
    >
      <PageTitle breadcrumbs={crumbs} />

      <Row>
        <Col sm={12}>
          <WorkflowFilters params={params} setParams={setParams} />
        </Col>
      </Row>

      <Row className="mt-4">
        {stats.map((stat, index) => (
          <Col key={index} sm={3}>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm="auto">
                    <h4>{stat.value}</h4>
                  </Col>
                </Row>

                <Card.Text className="text-muted">{stat.name}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <Row className="mt-4">
        <Col>
          <WorkflowTable
            isLoading={isLoading}
            isSuccess={isSuccess}
            workflows={workflows}
            params={params}
            setParams={setParams}
          />
        </Col>
      </Row>

      {isSuccess && (
        <TableFooter
          meta={workflows.meta}
          params={params}
          setParams={setParams}
        />
      )}
    </Page>
  )
}

export default Workflow
