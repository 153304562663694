import React from 'react'
import NumberField from 'shared/forms/NumberField'

function PoMaxPoLiquidatedAmount({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="PO Max Liquidated Amount"
      showTitle={true}
      accessorKey="poMaxPoLiquidatedAmount"
    />
  )
}

export default PoMaxPoLiquidatedAmount
