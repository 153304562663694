import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function GroupedRiskBarChart({source, id, data, grouping}) {
  const options = {
    chart: {
      type: 'bar'
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: data.risk.map((r) => r.grouping.name),
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
        align: 'high'
      },
      labels: {
        overflow: 'justify'
      }
    },
    tooltip: {
      shared: true,
      useHTML: true,
      borderRadius: 7,
      headerFormat:
        '<div style="background-color: #FFF; border-radius: 5px"><h6 style="color: #1E2E48; text-align: center"><b>{point.key}</b></h6>' +
        '<table><tr><td style="font-size: 14px">accounts for <b>{point.y}%</b> of the total Risk</td></tr>',
      pointFormat: '</table></div>',
      valueDecimals: 0
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          format: '{y}%'
        },
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'bottom',
      x: -15,
      y: -25,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true
    },
    series: [
      {
        name: `Risk by ${grouping}`,
        data: data.risk.map((r) => ({
          y: Math.floor((1000 * parseInt(r.total_risk)) / data.total_risk) / 10,
          id: r.grouping_id,
          section_id: r.grouping.section_id,
          account_type: r.grouping.account_type
        })),
        color: '#5c80bb',
        states: {
          hover: {
            color: '#1E2E48',
            borderColor: '#5c80bb'
          }
        }
      }
    ]
  }
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default GroupedRiskBarChart
