import React from 'react'
import PropTypes from 'prop-types'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { isInteger } from 'lodash'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

function ConflictBadge({ count, text = '' }) {
  if (isInteger(count)) {
    return (
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{count} Conflicts present</Tooltip>}>
        <Badge pill className="bg-light text-primary mx-1" variant="light">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="text-warning"
          />{' '}
          {count}
        </Badge>
      </OverlayTrigger>
    )
  } else if (text.length > 0) {
    return (
      <Badge pill className="bg-light text-primary mx-1" variant="light">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="text-warning"
        />{' '}
        {text}
      </Badge>
    )
  } else {
    return (
      <Badge pill className="bg-light text-primary mx-1" variant="light">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="text-warning"
        />{' '}
        Conflict
      </Badge>
    )
  }
}

ConflictBadge.propTypes = {
  count: PropTypes.number,
  text: PropTypes.string
}

export default ConflictBadge
