import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faDownload,
  faFileAlt,
  faShieldAlt,
  faUsersCog
} from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import { useCreateExport } from 'api/hooks/use-exports'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import { useNavigate } from 'react-router-dom'
import { pick } from 'lodash'
import NewSample from './new-sample'
import { prepParams } from 'helpers/utils'

export default function DownloadTransactions({
  defaultName = 'Export',
  source = '',
  tableFilters
}) {
  const [show, setShow] = useState(false)
  const [showSample, setShowSample] = useState(false)
  const navigate = useNavigate()

  const tableParams = {
    external_id: tableFilters.external_id || '',
    section_id: tableFilters.section_id || '',
    department_id: tableFilters.department_id || '',
    vendors: tableFilters.vendors?.map((e) => e.id)?.join('+') || [],
    employees: tableFilters.employees?.map((e) => e.id)?.join('+') || [],
    start_on: tableFilters.startOn || '',
    end_on: tableFilters.endOn || '',
    minimum_amount: tableFilters.minimumAmount,
    maximum_amount: tableFilters.maximumAmount,
    analytics: tableFilters.analytics?.map((a) => a.id)?.join('+') || [],
    org_ids: tableFilters.org_ids?.join('+'),
    object_ids: tableFilters.object_ids?.join('+'),
    project_ids: tableFilters.project_ids?.join('+'),
    seg_one_ids: tableFilters.seg_one_ids?.join('+'),
    seg_two_ids: tableFilters.seg_two_ids?.join('+'),
    seg_three_ids: tableFilters.seg_three_ids?.join('+'),
    seg_four_ids: tableFilters.seg_four_ids?.join('+'),
    seg_five_ids: tableFilters.seg_five_ids?.join('+'),
    seg_six_ids: tableFilters.seg_six_ids?.join('+'),
    seg_seven_ids: tableFilters.seg_seven_ids?.join('+'),
    seg_eight_ids: tableFilters.seg_eight_ids?.join('+'),
    unviewed: tableFilters.unviewedOnly,
    open: tableFilters.open,
    inprogress: tableFilters.inProgress,
    exception: tableFilters.exception,
    audit_report_id: tableFilters.auditReportId,
    mole_id: tableFilters.moleId,
    saved: tableFilters.saved,
    org_id: tableFilters.orgId,
    object_id: tableFilters.objectId,
    po_min_req_to_po_creation: tableFilters.poMinReqToPoCreation,
    po_is_pending_workflow: tableFilters.poIsPendingWorkflow
  }

  const urlParams = () => {
    const passParams = [
      'section_id',
      'vendors',
      'employees',
      'start_on',
      'end_on',
      'minimum_amount',
      'maximum_amount',
      'analytics',
      'org_id',
      'object_id',
      'params'
    ]
    const urlParams = pick(tableParams, passParams)
    return new URLSearchParams(urlParams).toString()
  }

  const redirectToMonitoring = () => {
    const searchParams = urlParams()
    return navigate(`/monitoring-new?${searchParams}`)
  }

  const exportParams = {
    name: defaultName,
    source: source,
    status: 'queued',
    params: tableParams
  }
  const [params, setParams] = useState(exportParams)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const { mutate, isSuccess } = useCreateExport({
    params: {
      ...params,
      params: prepParams(tableParams)
    }
  })

  useEffect(() => {
    if (isSuccess) {
      handleClose()
      setParams((params) => ({ ...params, name: defaultName }))
      toast.success('File will be available in Exports shortly')
    }
  }, [defaultName, isSuccess])

  return (
    // <Button  onClick={handleShow} size='sm'>
    <React.Fragment>
      <DropdownButton
        className="text-dark outline-dark bg-light me-2 float-end"
        variant="light"
        id="dropdown-basic-button"
        placement="bottom-start"
        size="sm"
        title={
          <span>
            Actions <FontAwesomeIcon icon={faCaretDown} />
          </span>
        }
      >
        <Dropdown.Item as={Button} onClick={handleShow}>
          <span className="text-primary">
            <FontAwesomeIcon icon={faDownload} className="me-1" /> Export
          </span>
        </Dropdown.Item>
        {/* TODO: Sample Form */}
        <Dropdown.Item as={Button} onClick={() => setShowSample(!showSample)}>
          <span className="text-primary">
            <FontAwesomeIcon icon={faFileAlt} className="me-2" /> Sample Data
          </span>
        </Dropdown.Item>
        {false && (
          <Dropdown.Item>
            <span
              className="text-primary"
              onClick={() => redirectToMonitoring()}
            >
              <FontAwesomeIcon icon={faShieldAlt} className="me-1" /> Create
              Alert
            </span>
          </Dropdown.Item>
        )}
        {false && (
          <Dropdown.Item>
            <span className="text-primary">
              <FontAwesomeIcon icon={faUsersCog} className="me-1" />
              Share View
            </span>
          </Dropdown.Item>
        )}
      </DropdownButton>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="lead fw-normal">
            <FontAwesomeIcon icon={faDownload} className="text-muted mx-2" />
            Export
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label className="text-muted fw-light">
              <small>Export Name</small>
            </Form.Label>
            <Form.Control
              type="text"
              value={params.name}
              onChange={(e) => setParams({ ...params, name: e.target.value })}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="bg-white text-muted"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button variant="primary" onClick={() => mutate()}>
            Export
          </Button>
        </Modal.Footer>
      </Modal>
      <NewSample
        show={showSample}
        setShow={setShowSample}
        params={tableParams}
      />
    </React.Fragment>
  )
}

DownloadTransactions.propTypes = {
  defaultName: PropTypes.string,
  params: PropTypes.object
}
