import React, { useEffect, useState } from 'react'
import { Modal, FormControl, ListGroup, ListGroupItem } from 'react-bootstrap'
import { useDebounce } from 'api/hooks/use-debounce'
import { useSodGlobalSearch } from 'api/hooks/use-sod-global-search'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import RoleResult from 'layout/sod/search/role-result'
import UserResult from 'layout/sod/search/user-result'
import PermissionResult from 'layout/sod/search/permission-result'
import ConflictResult from 'layout/sod/search/conflict-result'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUsers, faUserSlash, faAddressCard, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import { trackEvent } from '../../google-analytics'

const GlobalSearchModal = ({ show, handleClose }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearch = useDebounce(searchQuery, 500)

  const defaultParams = {
    search: debouncedSearch,
    data_type: ''
  }
  const [params, setParams] = useState(defaultParams)

  useEffect(() => {
    if (!show) {
      setSearchQuery('')
    }
  }, [show])

  useEffect(() => {
    trackEvent({
      category: 'Global Search',
      action: 'user_engagement',
      value: debouncedSearch
    })
    setParams((params) => ({
      ...params,
      search: debouncedSearch
    }))
  }, [debouncedSearch])

  const isSearched = searchQuery.length > 0 || params.dataType?.length > 0

  const options = {
    enabled: isSearched
  }

  const { data, isLoading, isSuccess } = useSodGlobalSearch({ params, options })

  const quickFilterButtons = [
    { text: 'Role', icon: faAddressCard, data_type: 'Role' },
    { text: 'User', icon: faUsers, data_type: 'Employee' },
    { text: 'Permission', icon: faToggleOn, data_type: 'Permission' },
    { text: 'Conflict', icon: faUserSlash, data_type: 'Analytic' }
  ]

  const handleQuickFilter = (data_type) => {
    trackEvent({
      category: 'Global Search',
      action: 'user_engagement',
      value: data_type
    })
    setParams((params) => ({
      ...params,
      data_type: data_type
    }))
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <>
          <FormControl
            type="text"
            placeholder="Search for Roles, Users, Permissions or Conflicts"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border-0 m-0"
            size="lg"
            autoFocus
          />
        </>
      </Modal.Header>
      <Modal.Body>
        <div className='mt-2 mb-4'>
          {quickFilterButtons.map((button) => (
            <Button
              variant={params.data_type === button.data_type ? 'primary' : 'light'}
              className="me-2"
              onClick={() => handleQuickFilter(button.data_type)}
            >
              <FontAwesomeIcon icon={button.icon} className="me-2" />
              {button.text}
            </Button>
          ))}
        </div>
        <ListGroup variant="flush">
          {isLoading && (
            <ListGroupItem>
              <Spinner animation="grow" variant="warning" />
            </ListGroupItem>
          )}
          {isSuccess && data.length === 0 && !isSearched && (
            <ListGroupItem className="text-center mt-4">
              Start typing to search
            </ListGroupItem>
          )}
          {isSuccess && data.length === 0 && isSearched && (
            <ListGroupItem className="text-center mt-4">
              No results found
              <Button variant="link" onClick={() => setSearchQuery('')}>
                Clear search
              </Button>
            </ListGroupItem>
          )}
          {isSuccess &&
            data.map((result, index) => (
              <React.Fragment
                key={`${result.data_type}-${result.data_id}-${index}`}
              >
                {result.data_type === 'Role' && <RoleResult result={result} />}
                {result.data_type === 'Employee' && (
                  <UserResult result={result} />
                )}
                {result.data_type === 'Permission' && (
                  <PermissionResult result={result} />
                )}
                {result.data_type === 'Analytic' && (
                  <ConflictResult result={result} />
                )}
              </React.Fragment>
            ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  )
}

export default GlobalSearchModal
