import React from 'react'
import Button from 'react-bootstrap/Button'

function TagButton({ name, tag, addByTag }) {
  return (
    <Button
      variant="light"
      className="ms-1"
      size="sm"
      onClick={() => addByTag(tag)}
    >
      {name}
    </Button>
  )
}

export default TagButton
