import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faUsers, faPlus } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import UsersDropdown from 'shared/forms/users-dropdown'
import { toast } from 'react-toastify'
import { useCreateAuditReportUser } from 'api/hooks/use-audit-report-user'
import UserRow from './user-row'
import UserContext from 'context/user-context'
import { useEffect } from 'react'
import { useQueryClient } from 'react-query'

function ShareButton({ report }) {
  const queryClient = useQueryClient()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [userOption, setUserOption] = useState('')
  const user = useContext(UserContext)
  const isUserCreator = report.user_id === user.id
  const sharedUserIds = report.shared_users.map((u) => u.user_id)
  const allUsers = sharedUserIds.concat(user.id)
  const params = {
    user_id: userOption,
    audit_report_id: report.id
  }

  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries([`reports/${report.id}`])
      queryClient.invalidateQueries('reports')
    }
  }

  const {
    mutate: createMutation,
    isLoading,
    isError,
    isSuccess
  } = useCreateAuditReportUser({
    params,
    options
  })

  useEffect(() => {
    setUserOption('')
  }, [isSuccess])

  return (
    <>
      {isError && toast.error('An error occured')}
      {report.shared_users.length === 0 ? (
        <Button
          className="px-2 py-0 ms-2 bg-light text-primary border border-secondary fw-normal"
          data-testid="share-button"
          onClick={handleShow}
        >
          <small>
            <span className="fw-bolder">
              <FontAwesomeIcon icon={faUserPlus} />
            </span>{' '}
          </small>
        </Button>
      ) : (
        <Button
          className="px-1 py-0 ms-2 bg-light text-primary border border-secondary fw-normal"
          data-testid="share-button"
          onClick={handleShow}
        >
          <small>
            <span className="fw-bolder">
              <FontAwesomeIcon icon={faUsers} /> {report.shared_users.length}
            </span>{' '}
          </small>
        </Button>
      )}
      <Modal show={show} size="md" onHide={handleClose}>
        <Modal.Header className="fw-light d-flex flex-row justify-content-between">
          <div className="d-flex fs-5">
            <span className="fw-normal me-2">Share {report.name}</span>
          </div>
          <div className="d-flex">
            <Button variant="outline-primary" size="sm" onClick={handleClose}>
              Close
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {isUserCreator && (
            <Row>
              <Col sm={8}>
                <UsersDropdown
                  params={userOption}
                  setParams={setUserOption}
                  isObject={false}
                  disabled={allUsers}
                />
              </Col>
              <Col sm={4}>
                <Button
                  variant="primary"
                  onClick={() => createMutation()}
                  disabled={userOption === '' || isLoading}
                >
                  <FontAwesomeIcon icon={faPlus} className="text-warning" /> Add
                  User
                </Button>
              </Col>
            </Row>
          )}

          <h4 className="my-2">People with access</h4>
          <Table className="border border-light">
            <tbody className="border-top">
              <tr className="bg-light">
                <td>
                  {report.owner.first_name} {report.owner.last_name}{' '}
                  <small className="ms-1 text-muted">(Owner)</small>
                  <div className="text-muted fw-light">
                    <small>{report.owner.email}</small>
                  </div>
                </td>
                <td className="float-end border-bottom-0"></td>
              </tr>
              {report.shared_users.map((u) => (
                <UserRow
                  auditReportUser={u}
                  key={`audit-report-user-${u.id}`}
                  edittable={report.user_id === user.id}
                />
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ShareButton
