import React, { useState } from 'react'
import { calculatePercent } from 'helpers/utils'
import { useWorkflowSummary } from 'api/hooks/use-workflow-summary'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import DepartmentDropdown from 'shared/department-dropdown'
import NumberOfActionsFilter from 'workflow/filters/number-of-actions'
import AverageQueueTimeFilter from 'workflow/filters/avg-queue-time'
import Search from 'shared/forms/search'
import TableLoading from 'shared/tables/table-loading'
import TableFooter from 'shared/tables/table-footer'
import { useNavigate } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Process from 'workflow/filters/process'
import Export from 'workflow/filters/export'
import { updateOrdering } from 'shared/tables/utils'
import SortArrow from 'shared/tables/sort-arrow'

function EmployeeTab({ params, setParams }) {
  const navigate = useNavigate()
  const [activeOverlay, setActiveOverlay] = useState('')

  const {
    isLoading,
    isSuccess,
    data: employees
  } = useWorkflowSummary({
    params
  })

  return (
    <>
      <Card className="mb-4">
        <Card.Body>
          <Row>
            <Col sm="3">
              <Search params={params} setParams={setParams} />
            </Col>
            <Col sm="auto">
              <Process
                params={params}
                setParams={setParams}
                activeOverlay={activeOverlay}
                setActiveOverlay={setActiveOverlay}
              />
            </Col>
            <Col sm="2">
              <DepartmentDropdown
                params={params}
                setParams={setParams}
                hideLabel={true}
              />
            </Col>
            <Col sm="auto">
              <NumberOfActionsFilter
                params={params}
                setParams={setParams}
                activeOverlay={activeOverlay}
                setActiveOverlay={setActiveOverlay}
              />
            </Col>
            <Col sm="auto">
              <AverageQueueTimeFilter
                params={params}
                setParams={setParams}
                activeOverlay={activeOverlay}
                setActiveOverlay={setActiveOverlay}
              />
            </Col>
            <Col sm="auto">
              <Export
                params={params}
                setParams={setParams}
                source="employeeWorkflow"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row>
        <Col>
          <Table
            className="bg-white shadow-sm p-1"
            hover
            style={{ border: '1px solid rgb(222 226 230)' }}
          >
            <thead>
              <tr>
                <th>
                  User ID
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    updateOrdering('total_actions', params, setParams)
                  }
                >
                  # of Actions
                  <SortArrow
                    column="total_actions"
                    orderBy={params.orderBy}
                    orderDirection={params.orderDirection}
                  />
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    updateOrdering('percent_approved', params, setParams)
                  }
                >
                  Approval %
                  <SortArrow
                    column="percent_approved"
                    orderBy={params.orderBy}
                    orderDirection={params.orderDirection}
                  />
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    updateOrdering('percent_rejected', params, setParams)
                  }
                >
                  Rejection %
                  <SortArrow
                    column="percent_rejected"
                    orderBy={params.orderBy}
                    orderDirection={params.orderDirection}
                  />
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    updateOrdering('time_in_queue', params, setParams)
                  }
                >
                  Avg. Queue Time (days)
                  <SortArrow
                    column="time_in_queue"
                    orderBy={params.orderBy}
                    orderDirection={params.orderDirection}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <TableLoading columns={5} />}
              {isSuccess &&
                employees.data.map((employee) => (
                  <tr
                    key={`workflow-row-${employee.employee_id}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate(`/workflow/${employee.employee_id}`)
                    }
                  >
                    <td>
                      {employee.external_id}
                      <span className="text-muted ms-2">
                        {employee?.department_name}
                      </span>
                    </td>
                    <td>{employee.total_actions}</td>
                    <td>
                      {calculatePercent(
                        employee.total_approved,
                        employee.total_actions
                      )}
                      %
                    </td>
                    <td>
                      {calculatePercent(
                        employee.total_rejected,
                        employee.total_actions
                      )}
                      %
                    </td>
                    <td>
                      {Math.round((10 * employee.time_in_queue) / 86400) / 10}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {isSuccess && (
        <TableFooter
          meta={employees.meta}
          params={params}
          setParams={setParams}
        />
      )}
    </>
  )
}

export default EmployeeTab
