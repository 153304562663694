import React, { useRef } from 'react'
import { Popover, Button, Overlay, Form } from 'react-bootstrap'
import smallDot from 'shared/small-dot.svg'

export default function NumberOfActionsFilter({
  params,
  setParams,
  activeOverlay,
  setActiveOverlay
}) {
  const handleNumberOfActionsChange = (e) => {
    const value = e.target.value
    setParams({
      ...params,
      numberOfActions: value
    })
  }

  const filterTarget = useRef(null)

  const numberOfActionsPopover = (
    <Popover id="popover-numberOfActions">
      <Popover.Header as="h3">Filter by Number of Actions</Popover.Header>
      <Popover.Body>
        <Form>
          <Form.Group controlId="numberOfActions">
            <Form.Label>Greater than (# of actions)</Form.Label>
            <Form.Control
              type="number"
              value={params.numberOfActions}
              onChange={handleNumberOfActionsChange}
            />
          </Form.Group>
        </Form>
      </Popover.Body>
    </Popover>
  )

  const isFiltered = params.numberOfActions > 0

  return (
    <div className="d-flex justify-content-center">
      <Overlay
        target={filterTarget.current}
        show={activeOverlay === 'numberOfActions'}
        placement="bottom"
        trigger="click"
      >
        {numberOfActionsPopover}
      </Overlay>
      <Button
        className={isFiltered ? 'bg-white text-primary' : 'bg-white text-info'}
        variant={isFiltered ? 'outline-primary' : 'outline-light'}
        style={{ border: isFiltered ? '' : '1px solid #ced4da' }}
        onClick={() =>
          activeOverlay === 'numberOfActions'
            ? setActiveOverlay('')
            : setActiveOverlay('numberOfActions')
        }
        ref={filterTarget}
      >
        {isFiltered ? (
          <>
            <img src={smallDot} className="img-fluid" alt={'Filter Applied'} />#
            of Actions
          </>
        ) : (
          '# of Actions'
        )}
      </Button>
    </div>
  )
}
