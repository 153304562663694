import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug } from '@fortawesome/free-solid-svg-icons'

function TableError({ columns }) {
  return (
    <tr>
      <td colSpan={columns} className="text-center">
        <div className="my-5 py-5">
          <FontAwesomeIcon icon={faBug} className="text-danger" /> Error loading
          the table
        </div>
      </td>
    </tr>
  )
}

export default TableError
