import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Pagination from 'react-bootstrap/Pagination'

type TableFooterProps = {
  params: {
    page: number,
    perPage: number
  },
  setParams: Function,
  meta: {
    pages: number,
    title: string,
    total: number
  },
  disableJump?: boolean
}

function TableFooter({ meta, params, setParams, disableJump = false }: TableFooterProps) {
  const setPage = (p: number) => {
    setParams({ ...params, page: p })
  }
  const setPerPage = (p: number) => {
    setParams({ ...params, perPage: p })
  }

  const { perPage, page } = params

  return (
    <Row>
      <Col xs="1">
        <Form.Group>
          <Form.Select
            as="select"
            value={perPage}
            onChange={(e) => setPerPage(parseInt(e.target.value))}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </Form.Group>
      </Col>
      <Col xs="2">
        <Form.Label className="fw-light mt-1 me-2">Per Page</Form.Label>
      </Col>

      <Col xs="6">
        <Pagination className="mx-auto" style={{ width: '250px' }}>
          {!disableJump && (
            <Pagination.First
              onClick={() => setPage(1)}
              disabled={page === 1}
            />
          )}
          <Pagination.Prev
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
          />
          {page - 2 > 0 ? (
            <Pagination.Item onClick={() => setPage(page - 2)}>
              {page - 2}
            </Pagination.Item>
          ) : (
            ''
          )}
          {page - 1 > 0 ? (
            <Pagination.Item onClick={() => setPage(page - 1)}>
              {page - 1}
            </Pagination.Item>
          ) : (
            ''
          )}
          <Pagination.Item active>{page}</Pagination.Item>
          {page + 1 <= meta.pages ? (
            <Pagination.Item onClick={() => setPage(page + 1)}>
              {page + 1}
            </Pagination.Item>
          ) : (
            ''
          )}
          {page + 2 <= meta.pages ? (
            <Pagination.Item onClick={() => setPage(page + 2)}>
              {page + 2}
            </Pagination.Item>
          ) : (
            ''
          )}
          <Pagination.Next
            onClick={() => setPage(page + 1)}
            disabled={meta.pages <= page}
          />
          {!disableJump && (
            <Pagination.Last
              onClick={() => setPage(meta.pages)}
              disabled={meta.pages <= page}
            />
          )}
        </Pagination>
      </Col>
      <Col xs="3">
        <span className="float-end">
          Showing {(page - 1) * perPage + 1} to{' '}
          {meta.total < perPage ? meta.total : page * perPage} of {meta.total}
        </span>
      </Col>
    </Row>
  )
}

export default TableFooter
