import * as React from 'react'
import Form from 'react-bootstrap/Form'
import useDepartments from 'api/hooks/use-departments'

type DepartmentDropdownProps = {
  params: object,
  setParams: Function
}

type Department = {
  id: string,
  name: string
}

function DepartmentDropdown({ params, setParams }: DepartmentDropdownProps) {
  const { isSuccess: isDepartmentSuccess, data } = useDepartments({options: {}})

  const updateDepartment = (value: string) => {
    setParams({
      ...params,
      department_id: value
    })
  }

  return (
    <>
      {isDepartmentSuccess && (
        <Form.Group>
          <Form.Select as="select" onChange={(e) => updateDepartment(e.target.value)}>
            <option value="">All Departments</option>
            {data.map((d: Department) => (
              <option key={`department-${d.id}`} value={d.id}>
                {d.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}
    </>
  )
}

export default DepartmentDropdown
