import React from 'react'
import NumberField from 'shared/forms/NumberField'

function EntryToPaymentDays({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="Invoice Entry to Check Date (Days)"
      showTitle={true}
      accessorKey="apMinEntryToPaymentDays"
    />
  )
}

export default EntryToPaymentDays
