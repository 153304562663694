import React, { useEffect, useState } from 'react'
import AppProviders from './context'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import { audience } from 'api/helpers'
import RenderApp from './render-app'
import { isNull } from 'lodash'
import EmptyPage from 'layout/empty-page'
import EmptySodPage from 'layout/sod/empty-page'

function App() {
  const { getAccessTokenSilently, isLoading } = useAuth0()
  const [token, setToken] = useState(null)

  useEffect(() => {
    ;(async () => {
      const accessToken = await getAccessTokenSilently({ audience })
      setToken(accessToken)
    })()
  }, [isLoading, getAccessTokenSilently])

  const {
    isSuccess,
    isLoading: isUserLoading,
    data: currentUser
  } = useQuery(['current-user'], async () => {
    const token = await getAccessTokenSilently({ audience })
    const data = await apiGet({ url: 'users', token })
    return data
  })

  if (isUserLoading || isNull(token)) {
    const sodApp = localStorage.getItem('sodApp')
    if (sodApp === 'true') {
      return <EmptySodPage />
    } else {
      return <EmptyPage />
    }
  }

  if (isSuccess && !isNull(token)) {
    return (
      <AppProviders currentUser={currentUser} token={token}>
        <RenderApp />
      </AppProviders>
    )
  }
}

export default App
