import React from 'react'
import { useConflicts } from 'api/hooks/use-conflicts'
import Table from 'react-bootstrap/Table'
import TableFooter from 'shared/tables/table-footer'
import TableLoading from 'shared/tables/table-loading'

function ConflictTable({ filterParams, setFilterParams }) {
  const { data, isSuccess, isLoading } = useConflicts({ params: filterParams })

  return (
    <>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Conflict</th>
            <th>Employees</th>
            <th>Roles</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <TableLoading columns={4} />
          )}
          {isSuccess &&
            data.data.map((conflict, index) => (
              <tr key={`${conflict.analytic_id}`}>
                <td>
                  <a href={`/conflicts/${conflict.id}`}>{conflict.name}</a>
                </td>
                <td className="text-muted">{conflict.employees_count}</td>
                <td className="text-muted">{conflict.roles_count}</td>
                <td className="text-muted">{conflict.description}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="my-4">
        {isSuccess && (
          <TableFooter
            meta={data.meta}
            params={filterParams}
            setParams={setFilterParams}
          />
        )}
      </div>
    </>
  )
}

export default ConflictTable
