import useMunicipalityAnalyticCounts from 'api/hooks/use-municipality-analytic-counts'
import React from 'react'
import AnalyticsByMonth from 'shared/analytics-by-month'

function MunicipalityAnalyticCountContainer({ municipalityAnalyticId }) {
  const params = {
    municipalityAnalyticId: municipalityAnalyticId
  }
  const { data, isSuccess } = useMunicipalityAnalyticCounts({ params })

  return <>{isSuccess && <AnalyticsByMonth data={data.chart_data} />}</>
}

export default MunicipalityAnalyticCountContainer