import React from 'react'
import { useEmployeeDuty } from 'api/hooks/use-employee-duties'
import Table from 'react-bootstrap/Table'
import TableError from 'shared/tables/table-error'
import Spinner from 'react-bootstrap/Spinner'

function ConflictDetails({ employee, analytic, isActive }) {
  const params = {
    employee_id: employee.id,
    analytic_id: analytic.id
  }
  const options = {
    enabled: isActive
  }
  const {
    isSuccess,
    isLoading,
    isError,
    data: conflict
  } = useEmployeeDuty({ params, options })

  return (
    <div>
      <Table
        className="border-light border bg-white shadow-sm"
        responsive
        size="sm"
      >
        <thead>
          {isSuccess && (
            <tr>
              <th>{conflict.permission_name}</th>
              <th>{conflict.conflict_name}</th>
            </tr>
          )}
        </thead>
        <tbody>
          {isLoading && <Spinner animation="grow" variant="warning" />}

          {isSuccess &&
            conflict.conflicts.map((c, i, arr) => (
              <tr
                key={`role-conflict-${i}`}
                className={`border-top ${
                  arr.length - 1 === i ? 'border-light' : 'border-info'
                }`}
              >
                <td>
                  <>
                    <div className="fw-light text-muted mt-1">
                      <small>Permission</small>
                    </div>
                    <div className="mb-2">{c.permission_description}</div>
                    <div className="border-bottom border-light"></div>
                    <div className="fw-light text-muted my-1">
                      <small>Role(s)</small>
                    </div>
                    {c.permission_roles_list.sort().map((r) => (
                      <div className="text-muted fw-light" key={`role-${r}-${i}`}>
                        <small>{r}</small>
                      </div>
                    ))}
                  </>
                </td>
                <td>
                  <div className="fw-light text-muted mt-1">
                    <small>Permission</small>
                  </div>
                  <div className="mb-2">{c.conflict_description}</div>
                  <div className="border-bottom border-light"></div>
                  <div className="fw-light text-muted my-1">
                    <small>Role(s)</small>
                  </div>
                  <div className="text-muted fw-light">
                    {c.conflict_roles_list.sort().map((r) => (
                      <div className="text-muted fw-light" key={`role-${r}-${i}`}>
                        <small>{r}</small>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            ))}
          {isError && <TableError columns={2} />}
        </tbody>
      </Table>
    </div>
  )
}

export default ConflictDetails
