import React, { useState } from 'react'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import GeneralSettings from './general'
import Features from './features'

function Settings() {
  const [activeTab, setActiveTab] = useState('attributes')

  return (
    <Page title="Settings">
      <PageTitle />
      <Row>
        <Col sm={2}>
          <ul className="nav flex-column nav-pills fw-light rounded">
            <li>
              <h6 className="pb-1 ps-0 mt-3 dropdown-header">Organization</h6>
            </li>
            <li className="nav-item" onClick={() => setActiveTab('settings')}>
              <a
                className={`nav-link px-2 py-1 ${
                  activeTab === 'settings' ? 'active' : ''
                }`}
                href="#settings"
              >
                Settings
              </a>
            </li>

            <li className="nav-item" onClick={() => setActiveTab('attributes')}>
              <a
                className={`nav-link px-2 py-1 ${
                  activeTab === 'attributes' ? 'active' : ''
                }`}
                href="#attributes"
              >
                Attributes
              </a>
            </li>
          </ul>
        </Col>
        <Col sm={9}>
          {activeTab === 'attributes' && <Features />}
          {activeTab === 'settings' && <GeneralSettings />}
        </Col>
      </Row>
    </Page>
  )
}

export default Settings
