import React from 'react'
import SelectField from 'shared/forms/SelectField'

function CheckType({ params, setParams }) {
  const types = [
    { value: 'EFT - Register Not Generated', label: 'EFT - Register Not Generated' },
    { value: 'Direct Disbursement', label: 'Direct Disbursement' },
    { value: 'Normal Printed Check', label: 'Normal Printed Check' },
    { value: 'EFT - Register Generated', label: 'EFT - Register Generated' },
    { value: 'Void', label: 'Void' },
    { value: 'Wire', label: 'Wire' },
    { value: 'Stale', label: 'Stale' },
    { value: 'C', label: 'C' },
  ]

  return (
    <SelectField
      params={params}
      setParams={setParams}
      options={types}
      accessorKey="apCheckType"
      title="Payment Type"
      showTitle={true}
      placeholder="Select Payment Type"
    />
  )
}

export default CheckType