import React from 'react'
import AnalyticsByMonth from 'shared/analytics-by-month'
import useVendorAnalyticCounts from 'api/hooks/use-vendor-analytic-counts'

function AnalyticsByMonthContainer({ vendorId }) {
  const params = {
    vendorId: vendorId
  }
  const { data, isSuccess } = useVendorAnalyticCounts({ params })

  return <>{isSuccess && <AnalyticsByMonth data={data.chart_data} />}</>
}

export default AnalyticsByMonthContainer
