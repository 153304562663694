import React, { useState } from 'react'
import Page from 'layout/sod/page'
import PageTitle from 'layout/page-title'
import Table from './table'
import Filters from 'duties/filters'

function Employees() {
  const urlParams = new URLSearchParams(window.location.search)

  const emptyParams = {
    search: '',
    department_id: '',
    permission_id: '',
    conflict_id: '',
    role_id: '',
    orderBy: 'employee_sod_counts.conflicts_count',
    orderDirection: 'desc',
    groupBy: 'employee',
    terminated: urlParams.get('terminated') === 'true' || false,
    perPage: 10,
    page: 1
  }
  const [filterParams, setFilterParams] = useState(emptyParams)
  const crumbs = [
    { name: 'Segregation of Duties', url: 'duties' },
    { name: 'Users', url: '' }
  ]

  return (
    <Page title={'Users'}>
      <PageTitle breadcrumbs={crumbs} />

      <div className="my-4">
        <Filters
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataType="Employee"
        />
      </div>

      <Table filterParams={filterParams} setFilterParams={setFilterParams} />
    </Page>
  )
}

export default Employees
