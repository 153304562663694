import { useContext } from 'react'
import { useMutation } from 'react-query'
import { apiPatch } from 'api'
import AuthContext from 'context/auth-context'

const updateMunicipalityAnalytic = async (token, params) => {
  const objectName = 'municipality_analytic'
  const url = `municipality_analytics/${params.id}`

  apiPatch({ url, token, objectName, params })
}

export function useUpdateMunicipalityAnalytic({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(() => {
    return updateMunicipalityAnalytic(token, params)
  }, 
  { ...options })
}
