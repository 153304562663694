import React, { useContext } from 'react'
import NavDropdown from 'react-bootstrap/NavDropdown'
import SodAppContext from 'context/sod-app-context'
import ThirdLineShieldMuted from 'assets/Shield/Muted/ThirdLine Shield Gray.svg'
import ThirdLineShieldNavy from 'assets/Shield/Navy/ThirdLineShieldNavy.svg'

function AppSwitcher({ currentApp }) {
  const { setSodApp } = useContext(SodAppContext)
  const toggleToSod = () => {
    setSodApp(true)
    localStorage.setItem('sodApp', true)
    window.location.assign('/duties')
  }
  const toggleToAnalytics = () => {
    setSodApp(false)
    localStorage.removeItem('sodApp')
    window.location.assign('/risk')
  }

  return (
    <ul className="navbar-nav px-3">
      <NavDropdown
        title={
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="grid-2"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="svg-inline--fa fa-grid-2 fa-lg"
          >
            <path
              fill="currentColor"
              d="M224 80c0-26.5-21.5-48-48-48H80C53.5 32 32 53.5 32 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80zm0 256c0-26.5-21.5-48-48-48H80c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336zM288 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H336c-26.5 0-48 21.5-48 48zM480 336c0-26.5-21.5-48-48-48H336c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336z"
              className=""
            ></path>
          </svg>
        }
        className="mx-2 text-white"
        align="end"
        menualign="left"
      >
        <div style={{ minWidth: '385px' }}>
          <NavDropdown.Item
            onClick={() => toggleToSod()}
            className={currentApp === 'sod' ? 'bg-light' : ''}
          >
            <>
              <div className="d-flex align-items-center">
                <img
                  src={
                    currentApp === 'sod'
                      ? ThirdLineShieldNavy
                      : ThirdLineShieldMuted
                  }
                  className="img-fluid ms-2 me-3"
                  alt="ThirdLine Shield Black"
                  style={{ maxHeight: '30px' }}
                />
                <div>
                  <span
                    className={`fw-bold ${
                      currentApp === 'sod' ? 'text-primary' : 'text-muted'
                    }`}
                  >
                    Segregation of Duties
                  </span>
                  <div className="text-muted fw-light">
                    Monitor Users, Roles, and Permissions
                  </div>
                </div>
              </div>
            </>
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() => toggleToAnalytics()}
            className={currentApp === 'analytics' ? 'bg-light' : ''}
          >
            <>
              <div className="d-flex align-items-center">
                <img
                  src={
                    currentApp === 'analytics'
                      ? ThirdLineShieldNavy
                      : ThirdLineShieldMuted
                  }
                  className="img-fluid ms-2 me-3"
                  alt="ThirdLine Shield Black"
                  style={{ maxHeight: '30px' }}
                />
                <div>
                  <span
                    className={`fw-bold ${
                      currentApp === 'analytics' ? 'text-primary' : 'text-muted'
                    }`}
                  >
                    ThirdLine Analytics
                  </span>
                  <div className="text-muted fw-light">
                    No code analytics for financial processes
                  </div>
                </div>
              </div>
            </>
          </NavDropdown.Item>
        </div>
      </NavDropdown>
    </ul>
  )
}

export default AppSwitcher
