import React from 'react'
import Form from 'react-bootstrap/Form'

function Status({ params, setParams }) {
  return (
    <>
      <Form.Select
        aria-label="Vendor Status"
        value={params.status}
        
        onChange={(e) => setParams({ ...params, status: e.target.value })}
      >
        <option value="">Status</option>
        <option value="ACTIVE">Active</option>
        <option value="TEMPORARY">Temporary</option>
        <option value="STOP">Stop</option>
        <option value="SELF SERVICE">Self Service</option>
        <option value="ONE TIME PAY">One Time Pay</option>
      </Form.Select>
    </>
  )
}

export default Status