import React from 'react'
import NumberField from 'shared/forms/NumberField'

function InvoiceToEntryDays({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="Invoice Date to Entry Date (Days)"
      showTitle={true}
      accessorKey="apMinInvoiceToEntryDays"
    />
  )
}

export default InvoiceToEntryDays
