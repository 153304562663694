import * as React from 'react'
import PropTypes from 'prop-types'
import UserContext from 'context/user-context'
import AuthContext from 'context/auth-context'
import SodAppContext from 'context/sod-app-context'
import FilterContext from 'context/filters-context'

function AppProviders({ currentUser, children, token }) {
  const app = localStorage.getItem('sodApp')
  const [sodApp, setSodApp] = React.useState(app)
  const [filters, setFilters] = React.useState({})

  return (
    <AuthContext.Provider value={token}>
      <UserContext.Provider value={currentUser}>
        <SodAppContext.Provider value={{ sodApp, setSodApp }}>
          <FilterContext.Provider value={{ filters, setFilters }}>
            {children}
          </FilterContext.Provider>
        </SodAppContext.Provider>
      </UserContext.Provider>
    </AuthContext.Provider>
  )
}

AppProviders.propTypes = {
  currentUser: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  token: PropTypes.string
}

export default AppProviders
