import React from 'react'

type InputHelpTextProps = {
  content: string,
}

function InputHelpText({ content }: InputHelpTextProps) {
  return (
      <div className="fw-light text-muted pt-1" style={{ fontSize: '0.8rem' }}>
        {content}
      </div>
    )
}

export default InputHelpText
