import * as React from 'react'
import Form from 'react-bootstrap/Form'
import useTags from 'api/hooks/use-tags'

type TagDropdownProps = {
  params: {
    tag: string
  },
  setParams: Function
}

type tagProp = {
  id: string,
  name: string
}

function TagDropdown({ params, setParams }: TagDropdownProps) {
  const { isSuccess: isTagSuccess, data: tags } = useTags({ params: {}, options: {} })
  function addSpacesToCamelCaseString(str) {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  return (
    <Form.Group>
      <Form.Select
        as="select"
        value={params.tag}
        className='text-info'
        onChange={(e) => setParams({ ...params, tag: e.target.value, page: 1 })}
      >
        <option value="">Tags</option>
        <optgroup label="General Tags">
          {isTagSuccess &&
            tags.filter((t) => t.category === 'general').map((s: tagProp) => (
              <option key={`tag-option-${s.id}`} value={s.id}>
                {addSpacesToCamelCaseString(s.name)}
              </option>
            ))}
        </optgroup>
        <optgroup label="Tag Type">
          {isTagSuccess &&
            tags.filter((t) => t.category === 'family').map((s: tagProp) => (
              <option key={`tag-option-${s.id}`} value={s.id}>
                {addSpacesToCamelCaseString(s.name)}
              </option>
            ))}
        </optgroup>
        <optgroup label="Fraud Type">
          {isTagSuccess &&
            tags.filter((t) => t.category === 'fraud').map((s: tagProp) => (
              <option key={`tag-option-${s.id}`} value={s.id}>
                {addSpacesToCamelCaseString(s.name)}
              </option>
            ))}
        </optgroup>
      </Form.Select>
    </Form.Group>
  )
}

export default TagDropdown
