import React from 'react'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { faHourglass } from '@fortawesome/free-regular-svg-icons'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

function IssueBadge({ status, count }) {
  return (
    <>
      {status === 'No Issues' && (
        <Badge
          pill
          className={'text-center text-success badge-success fw-normal'}
        >
          <FontAwesomeIcon icon={faCheck} className="me-1" />
          No Issues
        </Badge>
      )}
      {status === 'Pending' && (
        <Badge
          pill
          className={'text-center text-warning badge-warning fw-normal'}
        >
          <FontAwesomeIcon icon={faHourglass} className="me-1" />
          Pending
        </Badge>
      )}
      {status === 'Unresolved' && (
        <Badge
          pill
          className={'text-center text-danger high-risk badge-danger fw-normal'}
        >
          <FontAwesomeIcon icon={faExclamationCircle} className="me-1" />
          {count} Unresolved
        </Badge>
      )}
    </>
  )
}

export default IssueBadge