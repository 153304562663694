import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getVendors = async (token, params, municipalityId) => {
  const url = `municipalities/${municipalityId}/vendors`

  const data = await apiGet({ url, token, params })
  return data
}

export function useVendors({ params, options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const queryKey = ['vendors', params]
  const queryFn = () => getVendors(token, params, municipality_id)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}

const getVendor = async (token, params) => {
  const url = `vendors/${params.id}`

  const data = await apiGet({ url, token, params })
  return data
}

export function useVendor({ params, options }) {
  const token = useContext(AuthContext)
  const queryKey = ['vendor', params]
  const queryFn = () => getVendor(token, params)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
