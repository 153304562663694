import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getObjects = async (token, municipalityId, orgId) => {
  const url = `municipalities/${municipalityId}/objects?org_id=${orgId}`
  const data = await apiGet({ url, token })
  return data
}

export default function useObjects({ orgId, options }) {
  const { municipality_id } = useContext(UserContext)
  const token = useContext(AuthContext)
  const queryKey = ['object', orgId]
  const queryFn = () => getObjects(token, municipality_id, orgId)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
