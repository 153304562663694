import React, { useContext } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import InputHelpText from 'shared/forms/input-help-text'
import UserContext from 'context/user-context'

function GeneralSettings() {
  const { municipality } = useContext(UserContext)

  const {
    fiscal_year_start_month,
    fiscal_year_start_day,
    seg_one_name,
    seg_two_name,
    seg_three_name,
    seg_four_name,
    seg_five_name,
    seg_six_name,
    seg_seven_name,
    seg_eight_name,
    stale_vendor_months
  } = municipality.setting

  function getMonthAbbreviation(monthNumber) {
    const monthAbbreviations = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]
    return monthAbbreviations[monthNumber - 1]
  }

  return (
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <div className="text-primary fw-bold mb-2">Municipality</div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={8}>
                <p className="mt-1">Name</p>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    disabled
                    value={municipality.name}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={8}>
                <p className="mt-1">Abbreviation</p>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    disabled
                    value={municipality.abbreviation}
                    maxLength={3}
                  />
                  <InputHelpText content="Used for testing attribute abbreviations" />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={8}>
                <p className="mt-1">Fiscal Year Start Date</p>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    disabled
                    value={`${getMonthAbbreviation(
                      fiscal_year_start_month
                    )}. ${fiscal_year_start_day}`}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={8}>
                <p className="mt-1">Stale Vendor Months</p>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Control type="text" disabled value={stale_vendor_months} />
                  <InputHelpText content="Number of months since invoice date/check date before a Vendor is considered to be stale" />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <div className="text-primary fw-bold mb-2">
                  Accounts
                  <hr />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={8}>
                <p className="mt-1">Segment One</p>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Control type="text" disabled value={seg_one_name} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={8}>
                <p className="mt-1">Segment Two</p>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Control type="text" disabled value={seg_two_name} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={8}>
                <p className="mt-1">Segment Three</p>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Control type="text" disabled value={seg_three_name} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={8}>
                <p className="mt-1">Segment Four</p>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Control type="text" disabled value={seg_four_name} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={8}>
                <p className="mt-1">Segment Five</p>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Control type="text" disabled value={seg_five_name} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={8}>
                <p className="mt-1">Segment Six</p>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Control type="text" disabled value={seg_six_name} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={8}>
                <p className="mt-1">Segment Seven</p>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Control type="text" disabled value={seg_seven_name} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={8}>
                <p className="mt-1">Segment Eight</p>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Control type="text" disabled value={seg_eight_name} />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default GeneralSettings
