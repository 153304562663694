import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getTransactionWorkflows = async (token, params) => {
  const url = `transactions/${params.transaction_id}/workflows`

  const data = await apiGet({ url, token, params })
  return data
}

export default function useTransactionWorkflows({ params, options }) {
  const token = useContext(AuthContext)
  const queryKey = `transactions/${params.transaction_id}/workflows`
  const queryFn = () => getTransactionWorkflows(token, params)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}