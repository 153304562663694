import React from 'react'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function StatusBadge({ title, icon }) {
  return (
    <Badge
      pill
      bg="light"
      className="border border-white text-primary"
    >
      {icon ? <FontAwesomeIcon icon={icon} /> : null} {title}
    </Badge>
  )
}

export default StatusBadge
