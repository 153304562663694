import { useContext } from 'react'
import { useMutation } from 'react-query'
import { apiPost, apiPatch, apiDelete } from 'api'
import AuthContext from 'context/auth-context'

const createDashboardAccountSearches = async (token, params, id) => {
  const objectName = 'dashboard_account_search'
  const url = `dashboards/${id}/dashboard_account_searches`

  const data = await apiPost({ url, token, objectName, params })
  return data
}

export function useCreateDashboardAccountSearches({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return createDashboardAccountSearches(token, params, params.dashboard_id)
    },
    { ...options }
  )
}

const updateDashboardAccountSearches = async (token, params, id) => {
  const objectName = 'dashboard_account_search'
  const url = `dashboard_account_searches/${id}`

  const data = await apiPatch({ url, token, objectName, params })
  return data
}

export function useUpdateDashboardAccountSearches({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return updateDashboardAccountSearches(
        token,
        params,
        params.dashboard_account_search_id
      )
    },
    { ...options }
  )
}

const deleteDashboardAccountSearches = async (token, id) => {
  const url = `dashboard_account_searches/${id}`

  const data = await apiDelete({ url, token })
  return data
}

export function useDeleteDashboardAccountSearches({ id, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return deleteDashboardAccountSearches(token, id)
    },
    { ...options }
  )
}
