import React, { useRef } from 'react'
import { Popover, Button, Form } from 'react-bootstrap'
import smallDot from 'shared/small-dot.svg'
import Overlay from 'react-bootstrap/Overlay'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

export default function Actions({
  params,
  setParams,
  activeOverlay,
  setActiveOverlay
}) {
  const mapping = {
    A: 'Approvals',
    R: 'Rejections',
    F: 'Forwards',
    H: 'Holds',
    C: 'Changes'
  }

  const filterTarget = useRef(null)

  const handleCheckboxChange = (e) => {
    const value = e.target.value
    const checked = e.target.checked

    let updatedParams
    if (checked) {
      updatedParams = [...params.actions, value]
    } else {
      updatedParams = params.actions.filter((item) => item !== value)
    }

    setParams({
      ...params,
      actions: updatedParams
    })
  }

  const isFiltered = params.actions.length > 0

  const popover = (
    <Popover style={{ minWidth: '225px' }}>
      <Popover.Header>
        <Row>
          <Col sm={6} className="d-grid">
            {isFiltered ? (
              <Button
                variant="link"
                size="sm"
                className="float-end mt-1"
                onClick={() => setParams({ ...params, actions: [] })}
              >
                <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                Clear
              </Button>
            ) : null}
          </Col>
          <Col sm={6} className="d-grid">
            <Button
              variant="primary"
              size="sm"
              className="float-end"
              onClick={() => setActiveOverlay('')}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </Popover.Header>
      <Popover.Body>
        <Form>
          {Object.keys(mapping).map((key) => (
            <Form.Group controlId={key} key={key}>
              <Form.Check
                type="checkbox"
                label={mapping[key]}
                value={key}
                checked={params.actions.includes(key)}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
          ))}
        </Form>
      </Popover.Body>
    </Popover>
  )

  return (
    <div className="d-flex justify-content-center">
      <Button
        className={isFiltered ? 'bg-white text-primary' : 'bg-white text-info'}
        variant={isFiltered ? 'outline-primary' : 'outline-light'}
        style={{ border: isFiltered ? '' : '1px solid #ced4da' }}
        onClick={() => activeOverlay === 'actions' ? setActiveOverlay('') : setActiveOverlay('actions')}
        ref={filterTarget}
      >
        {isFiltered ? (
          <>
            <img src={smallDot} className="img-fluid" alt={'Filter Applied'} />
            Actions
          </>
        ) : (
          'Actions'
        )}
      </Button>
      <Overlay
        target={filterTarget.current}
        show={activeOverlay === 'actions'}
        placement="bottom"
        trigger="click"
      >
        {popover}
      </Overlay>

    </div>
  )
}
