import React, { useState } from 'react'
import Select, { components } from 'react-select'
import { mapObjectsToOptions, mapObjectToOption } from 'helpers/utils'
import { find, isUndefined } from 'lodash'
import StatusIcon from 'shared/status-icon'

function StatusDropdown({ review, setReview, statuses }) {
  const currentStatus = find(statuses, { id: review?.review_status_id })

  const [selected, setSelected] = useState(
    isUndefined(currentStatus)
      ? ''
      : mapObjectToOption(currentStatus, 'name', 'id')
  )

  const options = mapObjectsToOptions(statuses, 'name', 'id')
  const { Option } = components
  const IconOption = (props) => (
    <Option {...props}>
      <StatusIcon name={props.data.label} />
      <span className="ms-2">{props.data.label}</span>
    </Option>
  )
  const SingleValue = ({ children, ...props }) => (
    <React.Fragment>
      <components.SingleValue {...props}>
        <StatusIcon name={props.data.label} /> {children}
      </components.SingleValue>
    </React.Fragment>
  )

  return (
    <Select
      options={options}
      onChange={(val) => {
        setSelected(val)
        setReview({ ...review, review_status_id: val.value })
      }}
      value={selected}
      id={`review-status-selector-${review.id}`}
      components={{ SingleValue, Option: IconOption }}
    />
  )
}

export default StatusDropdown
