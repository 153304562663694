import Form from 'react-bootstrap/Form'

function PoBox({ params, setParams }) {
  const checked = params.po_box === true
  return (
    <>
      <Form.Check
        inline
        checked={checked}
        label="PO Box Address"
        name="po-box-vendors"
        type={'checkbox'}
        onChange={() =>
          setParams({ ...params, po_box: !params.po_box })
        }
        id={'po-box-vendors-checkbox'}
      />
      <div className="ms-4 text-muted fw-light">
        Vendor's main address is a PO Box
      </div>
    </>
  )
}

export default PoBox
