import React from 'react'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import TagBadge from './TagBadge'
import { trackEvent } from 'google-analytics'

function Result({ dataType, title, subtitle, icon, url, tags }) {
  const navigate = useNavigate()

  const handleClick = () => {
    trackEvent({
      category: 'Global Search',
      action: 'click',
      value: dataType
    })
    navigate(url)
  }

  return (
    <ListGroupItem
      onClick={() => handleClick()}
      style={{ cursor: 'pointer' }}
      action
      className="d-flex align-items-center justify-content-between"
    >
      <div className="col-1 d-flex align-items-center justify-content-center">
        <FontAwesomeIcon icon={icon} className="text-primary" />
      </div>
      <div className="col-11">
        <h5 className="m-0">
          {title}
          <small className='float-end'>
            {tags.map((tag, index) => (
              <div className="d-inline ms-2" key={`${tag.title}-${index}`}>
                <TagBadge title={tag.title} />
              </div>
            ))}
          </small>
        </h5>

        <div className="text-muted mt-2">{subtitle}</div>
      </div>
    </ListGroupItem>
  )
}

export default Result
