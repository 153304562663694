import * as React from 'react'
import Form from 'react-bootstrap/Form'
import useSections from 'api/hooks/use-sections'

type SectionDropdownProps = {
  params: {
    section_id: string,
  },
  setParams: Function,
  hasAllOption?: boolean
}

type Section = {
  id: string,
  name: string
}

function SectionDropdown({ params, setParams, hasAllOption = false }: SectionDropdownProps) {
  const { isSuccess: isSectionSuccess, data } = useSections({ options: {} })

  return (
    <>
      {isSectionSuccess && (
        <Form.Group>
          <Form.Select
            as="select"
            value={params.section_id}
            className={params.section_id ? 'text-primary border-primary' : 'text-info'}
            onChange={(e) =>
              setParams({
                ...params,
                section_id: e.target.value,
                page: 1,
                analytic_ids: []
              })
            }
          >
            {hasAllOption && <option value="">Select Module</option>}
            {data.map((s: Section) => (
              <option key={`section-option-${s.id}`} value={s.id}>
                {s.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}
    </>
  )
}

export default SectionDropdown
