import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useQueryClient } from 'react-query'
import { useTransaction } from 'api/hooks/use-transactions'
import FlagCount from 'shared/flag-count'
import DrawerHeader from './header'
import DrawerDetails from './details'
import Workflow from './workflow'
import EmployeeConflictTable from './employee-conflict-table'
import AnalyticsTable from 'transactions/drawer/analytics'
import ClearTransaction from './clear-transaction'
import RiskBadge from 'shared/badges/risk-badge'

function QuickLookDrawer({
  transactions,
  transactionId,
  show,
  setShow,
  dashboardId
}) {
  const handleClose = () => {
    queryClient.invalidateQueries(['transactions'])
    queryClient.invalidateQueries(`transactions/${transactionId}`)
    setShow(false)
  }
  const queryClient = useQueryClient()
  const [currentTransaction, setCurrentTransaction] = useState(transactionId)

  const { isSuccess, data: transaction } = useTransaction({
    id: currentTransaction
  })

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      scroll="true"
      backdrop="true"
      style={{ width: '60%' }}
    >
      {isSuccess && (
        <DrawerHeader
          transactions={transactions}
          currentTransaction={currentTransaction}
          setCurrentTransaction={setCurrentTransaction}
          transaction={transaction}
          handleClose={handleClose}
        />
      )}

      <Offcanvas.Body>
        {isSuccess && !!dashboardId && (
          <div className="mt-2 mb-4">
            <ClearTransaction
              transaction={transaction}
              dashboardId={dashboardId}
            />
          </div>
        )}

        {isSuccess && <DrawerDetails transaction={transaction} />}

        <hr />

        <h5>
          Risk Score{' '}
          {isSuccess && (
            <RiskBadge
              count={parseFloat(transaction.risk_score)}
              showCount={true}
            />
          )}
          <span className="ms-4"></span>
          Analytics Flagged <span className="ms-2"></span>
          {isSuccess && <FlagCount count={transaction.analytics_count} />}
        </h5>
        {isSuccess && <AnalyticsTable analytics={transaction.analytics} />}
        {isSuccess && transaction.employee && false && (
          <EmployeeConflictTable transaction={transaction} />
        )}
        {isSuccess && <Workflow transactionId={transaction.id} />}
      </Offcanvas.Body>
    </Offcanvas>
  )
}

QuickLookDrawer.propTypes = {
  transactions: PropTypes.array.isRequired,
  transactionId: PropTypes.string.isRequired
}

export default QuickLookDrawer
