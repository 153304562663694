import React, { useRef } from 'react'
import { Popover, Button, Overlay, Form } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import smallDot from 'shared/small-dot.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

export default function HoursFilter({ params, setParams, activeOverlay, setActiveOverlay }) {
  const filterTarget = useRef(null)
  const handleHoursChange = (e) => {
    const value = e.target.value
    setParams({
      ...params,
      hours: value
    })
  }

  const handleQuickFilter = (days) => {
    const hours = days * 24
    setParams({
      ...params,
      hours: hours
    })
  }

  const isFiltered = params.hours > 0

  const hoursPopover = (
    <Popover id="popover-hours">
      <Popover.Header>
        <Row>
          <Col sm={6} className="d-grid">
            {isFiltered ? (
              <Button
                variant="link"
                size="sm"
                className="float-end mt-1"
                onClick={() => setParams({ ...params, hours: 0 })}
              >
                <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                Clear
              </Button>
            ) : null}
          </Col>
          <Col sm={6} className="d-grid">
            <Button
              variant="primary"
              size="sm"
              className="float-end "
              onClick={() => setActiveOverlay('')}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </Popover.Header>
      <Popover.Body>
        <Form>
          <Form.Group controlId="hours">
            <Form.Label>Greater than (hrs)</Form.Label>
            <Form.Control
              type="number"
              value={params.hours}
              onChange={handleHoursChange}
            />
          </Form.Group>
          <hr />
          <Row className="mt-2">
            <Col>
              <h6>Quick Filter</h6>
              <Button
                variant="light"
                className="p-1 me-1"
                onClick={() => handleQuickFilter(1)}
              >
                1 day
              </Button>
              <Button
                variant="light"
                className="p-1 me-1"
                onClick={() => handleQuickFilter(3)}
              >
                3 days
              </Button>
              <Button
                variant="light"
                className="p-1"
                onClick={() => handleQuickFilter(7)}
              >
                7 days
              </Button>
            </Col>
          </Row>
        </Form>
      </Popover.Body>
    </Popover>
  )

  return (
    <div className="d-flex justify-content-center">
      <Button
        className={isFiltered ? 'bg-white text-primary' : 'bg-white text-info'}
        variant={isFiltered ? 'outline-primary' : 'outline-light'}
        style={{ border: isFiltered ? '' : '1px solid #ced4da' }}
        onClick={() =>
          activeOverlay === 'hours'
            ? setActiveOverlay('')
            : setActiveOverlay('hours')
        }
        ref={filterTarget}
      >
        {isFiltered ? (
          <>
            <img src={smallDot} className="img-fluid" alt={'Filter Applied'} />
            Time in Queue
          </>
        ) : (
          'Time in Queue'
        )}
      </Button>
      <Overlay
        show={activeOverlay === 'hours'}
        trigger="click"
        placement="bottom"
        target={filterTarget.current}
      >
        {hoursPopover}
      </Overlay>
    </div>
  )
}
