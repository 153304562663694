import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Datetime from 'react-datetime'

function DateFilter({
  auditReport,
  setAuditReport,
  validated,
  startDateValid,
  endDateValid
}) {
  
  return (
    <Row className="mb-3">
      <Col sm={3}>
        <span className="fw-bold">Date(s)</span>
      </Col>
      <Col sm={8}>
        <Row>
          <Col>
            <Datetime
              inputProps={{
                placeholder: 'Start Date',
                required: true,
                pattern:
                  '(0?[1-9]|1[012])\\/(0?[1-9]|[12][0-9]|3[01])\\/\\d{4}',
                id: 'start-on'
              }}
              dateFormat="MM/DD/YYYY"
              timeFormat={false}
              onChange={(e) => setAuditReport({ ...auditReport, startOn: e })}
              value={auditReport.startOn}
            />
            {!startDateValid && validated && (
              <span className="text-danger">MM/DD/YYYY</span>
            )}
          </Col>
          <Col>
            <Datetime
              dateFormat="MM/DD/YYYY"
              timeFormat={false}
              inputProps={{
                placeholder: 'End Date',
                required: true,
                pattern: '(0?[1-9]|1[012])\\/(0?[1-9]|[12][0-9]|3[01])\\/\\d{4}'
              }}
              onChange={(e) => setAuditReport({ ...auditReport, endOn: e })}
              value={auditReport.endOn}
            />
            {!endDateValid && validated && (
              <span className="text-danger">MM/DD/YYYY</span>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default DateFilter
