import { toKebabCase } from 'helpers/utils'
import React from 'react'
import Form from 'react-bootstrap/Form'

function NumberField({
  title,
  showTitle = true,
  placeholder,
  params,
  accessorKey,
  setParams,
  isPercent = false
}) {
  const getDisplayValue = (params, accessorKey, isPercent) => {
    const value = params[accessorKey]
    if (value === undefined || value === '') {
      return ''
    }
    return isPercent ? value * 100 : value
  }

  const handleValueChange = (value, isPercent) => {
    if (value === '') {
      return ''
    }
    return isPercent ? value / 100 : value
  }


  return (
    <>
      <label
        className={`mr-2 fw-light text-muted ${showTitle ? '' : 'sr-only'}`}
      >
        <small>{title}</small>
      </label>
      <Form.Control
        type="number"
        name={toKebabCase(title)}
        placeholder={placeholder}
        value={getDisplayValue(params, accessorKey, isPercent)}
        onChange={(e) =>
          setParams({
            ...params,
            [accessorKey]: handleValueChange(e.target.value, isPercent)
          })
        }
      />
    </>
  )
}

export default NumberField
