import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getPendingWorkflows = async (token, params, municipality_id) => {
  const url = `municipalities/${municipality_id}/pending_workflows`

  const data = await apiGet({ url, token, params })
  return data
}

export function usePendingWorkflows({ params, options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const queryKey = [`municipalities/${municipality_id}/pending_workflows`, params]
  const queryFn = () => getPendingWorkflows(token, params, municipality_id)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
