import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import AccountFilter from 'transactions/table/filters/account'
import smallDot from 'shared/small-dot.svg'

function AccountModal({ params, setParams }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const filterActive =
    params.segOne !== '' ||
    params.segTwo !== '' ||
    params.segThree !== '' ||
    params.segFour !== '' ||
    params.segFive !== '' ||
    params.objectId !== '' ||
    params.orgId !== ''

  return (
    <React.Fragment>
      <Button
        className="bg-white text-info"
        variant={filterActive ? 'outline-primary' : 'outline-light'}
        style={{ border: filterActive ? '' : '1px solid #ced4da' }}
        onClick={handleShow}
      >
        {filterActive ? (
          <>
            <img src={smallDot} alt="Filter applied" className="img-fluid" />{' '}
            Account Selected
          </>
        ) : (
          'Select Accounts'
        )}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <AccountFilter params={params} setParams={setParams} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default AccountModal
