import React from 'react'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import Result from './result'

function RoleResult({ result }) {
  return (
    <Result
      dataType='Role'
      title={result.record.name}
      subtitle={result.record.caption}
      icon={faAddressCard}
      url={`/roles/${result.data_id}`}
      tags={[{ title: result.tags?.status }]}
    />
  )
}

export default RoleResult
