import React from 'react'
import useEmployeeAnalyticCounts from 'api/hooks/use-employee-analytic-counts'
import AnalyticsByMonth from 'shared/analytics-by-month'

function AnalyticsByMonthContainer({ employeeId }) {
  const params = {
    employeeId: employeeId
  }
  const { data, isSuccess } = useEmployeeAnalyticCounts({ params })

  return <>{isSuccess && <AnalyticsByMonth data={data.chart_data} />}</>
}

export default AnalyticsByMonthContainer