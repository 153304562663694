import React from 'react'
import MultiStatus from './multi-status'

type TestingStatusProp = {
  featureSummary: {
    open_count: number,
    follow_up_count: number,
    exception_count: number,
    no_exception_count: number
  }
}

function TestingStatus({ featureSummary }: TestingStatusProp) {
  return (
    <MultiStatus
      openCount={featureSummary.open_count}
      followUpCount={featureSummary.follow_up_count}
      exceptionCount={featureSummary.exception_count}
      noExceptionCount={featureSummary.no_exception_count}
    />
  )
}

export default TestingStatus
