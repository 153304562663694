import React from 'react'
import Badge from 'react-bootstrap/Badge'

type ReportBadgeProps = {
  text: string,
  clickable?: boolean,
}

function ReportBadge({ text, clickable = true }: ReportBadgeProps) {
  return (
    <Badge
      style={{ cursor: clickable ? 'pointer' : 'default' }}
      className="border border-secondary bg-white fw-lighter text-primary me-1 pb-1"
    >
      {text}
    </Badge>
  )
}

export default ReportBadge
