import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function AnalyticsByMonth({ data }) {
  const options = {
    chart: {
      type: 'line'
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: Object.keys(data),
    },
    yAxis: {
      title: {
        text: 'Count'
      }
    },
    tooltip: {
      headerFormat: '{point.y} flags in the month of {point.x}',
      pointFormat: ''
    },
    series: [
      {
        name: 'Analytics Flagged by Month',
        data: Object.values(data).map(v => parseInt(v))
      }
    ]
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default AnalyticsByMonth
