import { useContext } from 'react'
import { useMutation, useQuery } from 'react-query'
import { apiGet, apiPost, apiDelete } from 'api'
import AuthContext from 'context/auth-context'

const getExports = async (token, params) => {
  const url = `exports`

  const data = await apiGet({ url, token, params })
  return data
}

export default function useExports({ params, options }) {
  const token = useContext(AuthContext)

  const queryFn = () => getExports(token, params)
  const queryKey = ['exports', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}

const createExport = async (token, params) => {
  const objectName = 'export'
  const url = `exports`

  const response = apiPost({ url, token, objectName, params })
  return response
}

export function useCreateExport({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return createExport(token, params)
    },
    { ...options }
  )
}

const destroyExport = async (token, id) => {
  const url = `exports/${id}`

  const response = await apiDelete({ url, token })
  return response
}

export function useDestroyExport({ id, options }) {
  const token = useContext(AuthContext)
  return useMutation(
    () => {
      return destroyExport(token, id)
    },
    { ...options }
  )
}
