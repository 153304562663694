import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getAnalytics = async (token, params) => {
  const url = `municipalities/${params.municipality_id}/analytics`

  const data = await apiGet({ url, token, params })
  return data
}

export function useAnalytics({ params, options = {} }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const mergedParams = { ...params, municipality_id: municipality_id }
  const queryFn = () => getAnalytics(token, mergedParams)
  const queryKey = ['analytics', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}

const getAnalytic = async (token, params) => {
  const url = `analytics/${params.id}`

  const data = await apiGet({ url, token, params })
  return data
}

export function useAnalytic({ params, options }) {
  const token = useContext(AuthContext)
  const queryFn = () => getAnalytic(token, params)
  const queryKey = ['analytic', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
