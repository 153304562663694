import React from 'react'

function SelectField({
  params,
  setParams,
  options,
  accessorKey,
  title,
  showTitle = false,
  placeholder = ''
}) {
  return (
    <>
      <label
        className={`mr-2 fw-light text-muted ${showTitle ? '' : 'sr-only'}`}
      >
        <small>{title}</small>
      </label>
      <select
        className="form-select"
        name={title}
        value={params[accessorKey]}
        onChange={(e) =>
          setParams({ ...params, [accessorKey]: e.target.value })
        }
      >
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  )
}

export default SelectField
