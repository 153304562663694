import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getSections = async (token) => {
  const url = 'sections'

  const data = await apiGet({ url, token })
  return data
}

export default function useSections({ options }) {
  const token = useContext(AuthContext)
  const queryFn = () => getSections(token)
  const queryKey = 'sections'

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
