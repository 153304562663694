import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark as faSolidBookmark } from '@fortawesome/free-solid-svg-icons'
import { faBookmark } from '@fortawesome/free-regular-svg-icons'
import {
  useDestroySavedTransaction,
  useCreateSavedTransaction
} from 'api/hooks/use-saved-transaction'
import { useQueryClient } from 'react-query'

function BookmarkButton({ transaction, size = 'sm' }) {
  const queryClient = useQueryClient()
  const params = {
    transaction_id: transaction.id
  }
  const {
    mutate: destroyMutation,
    isSuccess: destroySuccess,
    isLoading: destroyLoading
  } = useDestroySavedTransaction({
    id: transaction.saved_transaction?.id
  })

  const {
    mutate: createMutation,
    isLoading: createSuccess,
    isLoading: createLoading
  } = useCreateSavedTransaction({ params })

  useEffect(() => {
    queryClient.invalidateQueries(`transactions/${transaction.id}`)
  }, [createSuccess, destroySuccess, queryClient, transaction.id])

  return (
    <React.Fragment>
      {transaction.saved_transaction ? (
        <Button
          variant="white"
          size="sm"
          className="mx-2 pt-0 pb-0"
          onClick={() => destroyMutation()}
          disabled={destroyLoading || queryClient.isFetching()}
        >
          <FontAwesomeIcon
            icon={faSolidBookmark}
            className="text-warning"
            style={{ cursor: 'pointer' }}
          />
          {size === 'sm' ? '' : ' Save'}
        </Button>
      ) : (
        <Button
          size="sm"
          variant="outline-light text-primary mx-2 pt-0 pb-0"
          onClick={() => createMutation()}
          disabled={createLoading}
        >
          <FontAwesomeIcon icon={faBookmark} /> {size === 'sm' ? '' : ' Save'}
        </Button>
      )}
    </React.Fragment>
  )
}

export default BookmarkButton
