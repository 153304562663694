import React from 'react'
import { formatDateTime } from 'helpers/datetime'
import { actionText } from './text-helper'
import Link from './event-link'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserMinus, faUserPlus, faUser, faWrench } from "@fortawesome/free-solid-svg-icons"

function UserMaster({ log }) {

  const Icon = () => {
    if (log.action === 'A') {
      return <FontAwesomeIcon icon={faUserPlus} className='mx-2' />
    } else if (log.action === 'D') {
      return <FontAwesomeIcon icon={faUserMinus} className='mx-2' />
    } else {
      return (
        <>
          <FontAwesomeIcon icon={faWrench} />
          <FontAwesomeIcon icon={faUser} className='mx-2' />
        </>
      )
    }
  }


  return (
    <li
      type="button"
      className="list-group-item d-flex justify-content-between align-items-start"
      aria-current="true"
    >
      <div className="ms-2 me-auto">
        <div className="fw-bold">
          <Icon /> User {actionText(log.action)}{' '}
        </div>
        <div className="fw-light text-muted">
          <Link linkObject={log.changeable} objectType="users" /> was updated
          from {log.old_value} to {log.new_value}
        </div>
      </div>
      <span className="ms-2 text-muted fw-light">
        {formatDateTime(log.changed_at)} by {log.changed_by.external_id}
      </span>
    </li>
  )
}

export default UserMaster
