import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug } from '@fortawesome/free-solid-svg-icons'

export function DefaultError() {
  return (
    <div className="text-danger">
      <FontAwesomeIcon icon={faBug} className="me-2" />
      Something went wrong
    </div>
  )
}
