import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronCircleUp,
  faChevronCircleDown,
  faMinusCircle
} from '@fortawesome/free-solid-svg-icons'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'


type RiskBadgeProps = {
  count: number,
  showCount?: boolean
}

function RiskChangeBadge({ count, showCount = true }: RiskBadgeProps) {
  if (count > 0) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip>Risk rate is trending up over the past six months compared to the previous six</Tooltip>}
      >
        <Badge
          pill
          className={'text-center text-danger high-risk badge-danger fw-normal'}
          style={{ cursor: 'pointer' }}
          data-testid={'risk-badge'}
        >
          <FontAwesomeIcon icon={faChevronCircleUp} className='me-1' />
          {showCount && count}
        </Badge>
      </OverlayTrigger>
    )
  }

  if (count < 0) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip>Risk rate is trending down over the past six months compared to the previous six</Tooltip>}
      >
        <Badge
          pill
          className={'text-center text-success badge-success fw-normal'}
          style={{ cursor: 'pointer' }}
          data-testid={'risk-badge'}
        >
          <FontAwesomeIcon icon={faChevronCircleDown} className='me-1' />
          {showCount && Math.abs(count)}
        </Badge>
      </OverlayTrigger>
    )
  }

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>Risk rate is approximately the same</Tooltip>}
    >
      <Badge
        pill
        className={'text-center text-info badge-secondary fw-normal'}
        style={{ cursor: 'pointer' }}
        data-testid={'risk-badge'}
      >
        <FontAwesomeIcon icon={faMinusCircle} className='me-1' />
        {showCount && Math.abs(count)}
      </Badge>
    </OverlayTrigger>
  )
}

export default RiskChangeBadge
