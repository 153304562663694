import React from 'react'
import Table from 'react-bootstrap/Table'
import TableFooter from 'shared/tables/table-footer'
import AnalyticRow from 'shared/analytics/row'
import TableError from 'shared/tables/table-error'
import TableLoading from 'shared/tables/table-loading'
import SortArrow from 'shared/tables/sort-arrow'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

function AnalyticsTable({
  setParams,
  params,
  isLoading,
  isError,
  isSuccess,
  analytics
}) {
  const updateOrdering = (column) => {
    const isCurrentColumn = params.orderBy === column
    const columnOrder = getColumnOrder(isCurrentColumn)

    setParams({
      ...params,
      orderBy: column,
      orderDirection: columnOrder,
      page: 1
    })
  }

  const getColumnOrder = (isCurrentColumn) => {
    if (isCurrentColumn) {
      return params.orderDirection === 'asc' ? 'desc' : 'asc'
    } else {
      return 'desc'
    }
  }

  return (
    <>
      <Table
        hover
        responsive
        className="shadow-sm bg-white"
        style={{ border: '1px solid rgb(222 226 230)' }}
      >
        <thead>
          <tr>
            <th
              style={{ width: '35%', cursor: 'pointer' }}
              onClick={() => updateOrdering('name')}
            >
              Name
              <SortArrow
                column="name"
                orderBy={params.orderBy}
                orderDirection={params.orderDirection}
              />
            </th>
            <th style={{ width: '8%' }} className="text-center">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip>
                    Adjustments to the weight will impact how this analytic
                    affects risk
                  </Tooltip>
                }
              >
                <span style={{ cursor: 'pointer' }}>
                  Weight
                  <FontAwesomeIcon icon={faInfoCircle} className="ms-1" />
                </span>
              </OverlayTrigger>
            </th>
            {/* <th>Toggle</th> */}
            <th
              style={{ width: '8%', cursor: 'pointer' }}
              onClick={() => updateOrdering('municipality_analytics.analytics_count')}
            >
              Flags
              <SortArrow
                column="municipality_analytics.analytics_count"
                orderBy={params.orderBy}
                orderDirection={params.orderDirection}
              />
            </th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <TableLoading columns={4} />}
          {isSuccess && analytics.data.length === 0 && (
            <tr>
              <td colSpan="9" className="text-center py-4">
                <div className="mb-2 h6">No Analytics Found</div>
                <div className="text-muted fw-lighter mb-3">
                  Remove or adjust filters
                </div>
              </td>
            </tr>
          )}
          {isSuccess &&
            analytics.data.map((a) => (
              <AnalyticRow analytic={a} key={`row-${a.key}`} />
            ))}
          {isError && <TableError columns={4} />}
        </tbody>
      </Table>
      {isSuccess && (
        <TableFooter
          meta={analytics.meta}
          params={params}
          setParams={setParams}
        />
      )}
    </>
  )
}

export default AnalyticsTable
