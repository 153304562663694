import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getMunicipalityAnalyticCounts = async (token, municipalityAnalyticId) => {
  const url = `municipality_analytics/${municipalityAnalyticId}/counts`
  const data = await apiGet({ url, token })
  return data
}

export default function useMunicipalityAnalyticCounts({ params, options }) {
  const token = useContext(AuthContext)
  const queryFn = () =>
    getMunicipalityAnalyticCounts(token, params.municipalityAnalyticId)
  const queryKey = `municipality_analytics/${params.municipalityAnalyticId}/counts`
  return useQuery(queryKey, queryFn, { ...options })
}

