import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

function UnauthenticatedApp() {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      const path = window.location.pathname
      loginWithRedirect({
        appState: {
          targetUrl: path
        }
      })
    }
  }, [isAuthenticated, isLoading, loginWithRedirect])

  return <div></div>
}

export default UnauthenticatedApp
