import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { twoDigitMoney } from 'helpers/utils'
import { formatDateSlash } from 'helpers/datetime'
import Card from 'react-bootstrap/Card'
import EmployeeCard from 'transactions/drawer/employee-card'
import VendorCard from 'transactions/vendor-card'
import VendorChange from 'shared/vendor-change-badge'
import DrawerAllocations from './allocations'

function Details({ transaction }) {
  return (
    <>
      <Row className="mb-4">
        <Col>
          <div className="fw-light text-muted">{transaction.section_date_field || 'Date'}</div>
          <div className="h4">
            {formatDateSlash(transaction.transaction_on)}
          </div>
        </Col>
        <Col>
          <div className="fw-light text-muted">{transaction.section_amount_field || 'Amount'}</div>
          <div className="h4">
            {transaction.section_name === 'Vendors' ? (
              <VendorChange />
            ) : (
              twoDigitMoney(transaction.line_amount)
            )}
          </div>
        </Col>
        <Col sm={6}>
          <div className="fw-light">
            {transaction.details?.split('||').map((d, i) => (
              <p key={`transaction-details-${i}`} className="mb-0">
                {d}
              </p>
            ))}
          </div>
        </Col>
      </Row>

      {transaction.allocations.length > 0 && (
        <DrawerAllocations allocations={transaction.allocations} />
      )}

      <Row className="mb-4">
        <>
          <Col sm={6}>
            <Card className="h-100">
              <Card.Body>
                <VendorCard vendor={transaction.vendor} />
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6}>
            <Card className="h-100">
              <Card.Body>
                <EmployeeCard employee={transaction.employee} />
              </Card.Body>
            </Card>
          </Col>
        </>
      </Row>
    </>
  )
}

export default Details
