import { useContext } from 'react'
import { useQuery, useMutation } from 'react-query'
import { apiPost, apiPatch, apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getDashboards = async (token, userId, params) => {
  const url = `users/${userId}/dashboards`

  const data = await apiGet({ url, params, token })
  return data
}

export function useDashboards({ params, options }) {
  const token = useContext(AuthContext)
  const { id } = useContext(UserContext)
  const queryFn = () => getDashboards(token, id, params)
  const queryKey = ['dashboards', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}

const getDashboard = async (token, id, params) => {
  const url = `dashboards/${id}`

  const data = await apiGet({ url, params, token })
  return data
}

export function useDashboard({ params, id, options }) {
  const token = useContext(AuthContext)
  const queryFn = () => getDashboard(token, id, params)
  const queryKey = [`dashboards/${id}`]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}

const createDashboard = async (token, params, id) => {
  const objectName = 'dashboard'
  const url = `users/${id}/dashboards`

  const data = await apiPost({ url, token, objectName, params })
  return data
}

export function useCreateDashboard({ params, options }) {
  const token = useContext(AuthContext)
  const { id } = useContext(UserContext)

  return useMutation(
    () => {
      return createDashboard(token, params, id)
    },
    { ...options }
  )
}

const updateDashboard = async (token, params, id) => {
  const objectName = 'dashboard'
  const url = `dashboards/${id}`

  const data = await apiPatch({ url, token, objectName, params })
  return data
}

export function useUpdateDashboard({ params, id, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return updateDashboard(token, params, id)
    },
    { ...options }
  )
}