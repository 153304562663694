import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'

function PageTitle({ title, subtitle, breadcrumbs = [] }) {
  const navigate = useNavigate()

  const CustomCrumb = ({ name, url }) => {
    return (
      <>
        <span className="fw-lighter text-muted mx-1">/</span>
        {url === '' ? (
          <span className="fw-normal text-muted">{name}</span>
        ) : (
          <Button
            href={`/${url}`}
            onClick={(e) => {
              e.preventDefault()
              navigate(`/${url}`)
            }}
            variant="light-outline"
            className="fw-bolder text-muted p-0"
            size="sm"
          >
            {name}
          </Button>
        )}
      </>
    )
  }

  return (
    <React.Fragment>
      <div className="mb-3">
        <Button
          href="/"
          onClick={(e) => {
            e.preventDefault()
            navigate('/')
          }}
          size="sm"
          variant="light-outline"
          className="fw-bolder text-muted p-0"
        >
          ThirdLine
        </Button>
        {breadcrumbs.length > 0 &&
          breadcrumbs.map((b) => (
            <React.Fragment key={`breadcrumbs-${b.url}`}>
              <CustomCrumb name={b.name} url={b.url} />
            </React.Fragment>
          ))}
      </div>
    </React.Fragment>
  )
}

PageTitle.propTypes = {
  breadcrumbs: PropTypes.array
}

export default PageTitle
