import React, { useState, useEffect, useContext } from 'react'
import UserContext from 'context/user-context'
import { useReviewStatuses } from 'api/hooks/use-review-statuses'
import StatusDropdown from '../status-dropdown'
import Row from 'react-bootstrap/Row'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Tooltip from 'react-bootstrap/Tooltip'
import Col from 'react-bootstrap/Col'
import ReferenceNumber from './reference-number'
import { useUpdateReview } from 'api/hooks/use-reviews'
import moment from 'moment'
import { useQueryClient } from 'react-query'
import ReviewComment from '../review-comment'

function ProcessFeature({ oldReview, activeTab, tabKey }) {
  const { id } = useContext(UserContext)
  const queryClient = useQueryClient()
  const { isSuccess: isStatusSuccess, data: statuses } = useReviewStatuses({})
  const [review, setReview] = useState(oldReview)

  const params = {
    id: oldReview.id,
    review_status_id: review.review_status_id,
    initial_comment: review.initial_comment || '',
    is_viewed: true,
    processed_by_id: oldReview?.user_id || id,
    processed_on: oldReview?.processed_on || moment(),
    reference_number: review.reference_number || ''
  }

  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(`reports/${oldReview.reviewable_id}`)
    }
  }

  const { isSuccess, mutate } = useUpdateReview({ params, options })

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(
        `transactions/${oldReview.transaction_id}/reviews`
      )
    }
  }, [isSuccess, oldReview.transaction_id, queryClient])

  useEffect(() => {
    const statusChanged = review.review_status_id !== oldReview.review_status_id
    const commentChanged = review.initial_comment !== oldReview.initial_comment
    const refNumChange = review.reference_number !== oldReview.reference_number
    if (statusChanged || commentChanged || refNumChange) {
      mutate()
    }
  }, [
    oldReview.review_status_id,
    review.review_status_id,
    review.initial_comment,
    review.reference_number,
    oldReview.initial_comment,
    oldReview.reference_number,
    mutate
  ])

  const title = () => {
    if (tabKey === 'overall') {
      return 'Is this fraudulent or inappropriate?'
    } else {
      return oldReview.feature.name
    }
  }

  const description = () => {
    if (tabKey === 'overall') {
      return 'An Exception would indicate a fraudulent or inappropriate transaction'
    } else {
      return oldReview.feature.description
    }
  }

  return (
    <>
      {activeTab === tabKey && isStatusSuccess && (
        <>
          <Row>
            <Col sm={12}>
              <p>
                {title()}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>{description()}</Tooltip>}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="ms-2 text-primary"
                    />
                  </span>
                </OverlayTrigger>
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <StatusDropdown
                statuses={statuses}
                review={review}
                setReview={setReview}
              />
            </Col>
            <Col sm={8}>
              <ReviewComment review={review} setReview={setReview} />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm={4}>
              <div className="mt-1 float-end">Work Paper Ref #:</div>
            </Col>
            <Col sm={8}>
              <ReferenceNumber params={review} setParams={setReview} />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default ProcessFeature
