import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'


const getRiskBreakdown = async (token, params) => {
  const url = `municipalities/${params.municipality_id}/risk_breakdowns/${params.id}`

  const data = await apiGet({ url, token, params })
  return data
}

export function useRiskBreakdown({ params, options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const newParams = { ...params, municipality_id: municipality_id }

  return useQuery(
    [`${municipality_id}/risk-breakdown`, params],
    () => getRiskBreakdown(token, newParams),
    {
      ...options
    }
  )
}
