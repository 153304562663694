import React from 'react'
import CheckboxField from 'shared/forms/CheckboxField'

function IsPendingWorkflow({ params, setParams }) {
  return (
    // Checkbox
    <CheckboxField
      params={params}
      setParams={setParams}
      accessorKey="poIsPendingWorkflow"
      label="In Pending Workflow"
      helperText="Currently sitting in someone's workflow queue awaiting approval"
    />
  )
}

export default IsPendingWorkflow