import React, { useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import Search from 'shared/forms/search'
import { filter, reject } from 'lodash'
import { useVendors } from 'api/hooks/use-vendors'
import { useQueryClient } from 'react-query'
import Table from 'react-bootstrap/Table'
import { pick } from 'lodash'
import { map } from 'lodash'
import VendorItem from './item'
import DeleteVendor from './delete'
import UpdateVendor from './update'
import Spinner from 'react-bootstrap/Spinner'

function VendorSearch({ params, setParams }) {
  const queryClient = useQueryClient()
  const defaultSearchParams = {
    search: '',
    section: params.section_id,
    page: 1,
    perPage: 5
  }
  const [searchParams, setSearchParams] = useState(defaultSearchParams)

  const addOrRemoveVendor = (vendor) => {
    const findVendor = filter(params.vendors, {
      id: vendor.id
    })
    if (findVendor.length > 0) {
      const newList = reject(params.vendors, { id: vendor.id })
      updateVendors(newList)
      queryClient.invalidateQueries('transactions')
    } else {
      const newList = params.vendors
      newList.push({ ...pick(vendor, ['id', 'name', 'external_id']), options: 'include' })
      updateVendors(newList)
      queryClient.invalidateQueries('transactions')
    }
  }

  const toggleIncludeExclude = (vendor, options) => {
    const newList = map(params.vendors, function (a) {
      return a.id === vendor.id ? { ...vendor, options: options } : a
    })
    updateVendors(newList)
    queryClient.invalidateQueries('vendors')
    queryClient.invalidateQueries('time_periods')
  }

  const updateVendors = (newList) => {
    setParams({
      ...params,
      vendors: newList
    })
  }

  const { isSuccess, isLoading, data } = useVendors({
    params: searchParams,
    options: {
      enabled: searchParams.search !== '' && searchParams.section.length > 2
    }
  })

  return (
    <>
      <Search
        params={searchParams}
        setParams={setSearchParams}
        placeholder={'Search Vendor'}
      />
      <ListGroup variant="flush" className="border border-light mt-2">
        {isLoading && (
          <ListGroup.Item>
            <div className="text-muted text-center">
              <Spinner animation="grow" variant="warning" className="my-5" />
            </div>
          </ListGroup.Item>
        )}
        {isSuccess &&
          data.data.map((v) => (
            <VendorItem
              key={`vendor-option-${v.id}`}
              vendor={v}
              params={params}
              dashboardId={params.dashboardId}
              addOrRemoveVendor={addOrRemoveVendor}
            />
          ))}
      </ListGroup>

      <Table size="sm" className="mt-4">
        <thead>
          <tr>
            <th>Name</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {params.vendors.length === 0 && (
            <tr>
              <td colSpan="3" className="text-center">
                No vendors selected. Search for and select a vendor above to include or
                exclude from the view.
              </td>
            </tr>
          )}
          {params.vendors.map((vendor) => (
            <tr key={`dashboard-vendor-${vendor.id}`}>
              <td>
                <div className="mt-1">{vendor.name}
                  <small className="text-muted ms-2">(ID: {vendor.external_id})</small>
                </div>
              </td>
              <td>
                <UpdateVendor
                  toggleIncludeExclude={toggleIncludeExclude}
                  vendor={vendor}
                  dashboardId={params.dashboardId}
                />
              </td>
              <td>
                <DeleteVendor
                  addOrRemoveVendor={addOrRemoveVendor}
                  vendor={vendor}
                  dashboardId={params.dashboardId}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default VendorSearch
