import React from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Stats({ employee }) {
  const stats = [
    {
      name: 'Total Conflicts',
      value: employee?.conflicts_count,
    },
    {
      name: 'Roles Creating Conflicts',
      value: employee?.roles_with_conflicts_count,
    },
    {
      name: 'High Risk Permissions',
      value: employee?.high_risk_permissions_count,
    },
    {
      name: 'Super User Permissions',
      value: employee?.superuser_permissions_count,
    }
  ]

  return (
    <>
      <Row>
        {stats.map((stat, index) => (
          <Col key={`stat-${index}`} sm={3}>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm="auto">
                    <h4>{stat.value}</h4>
                  </Col>
                </Row>

                <Card.Text className="text-muted">{stat.name}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default Stats
