import { useContext } from 'react'
import { useMutation } from 'react-query'
import { apiPost, apiDelete } from 'api'
import AuthContext from 'context/auth-context'

const createDashboardAnalytic = async (token, params) => {
  const objectName = 'dashboard_analytic'
  const url = `dashboards/${params.dashboard_id}/dashboard_analytics`

  const data = await apiPost({ url, token, objectName, params })
  return data
}

export function useCreateDashboardAnalytic({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return createDashboardAnalytic(token, params, params.id)
    },
    { ...options }
  )
}

const deleteDashboardAnalytic = async (token, id) => {
  const url = `dashboard_analytics/${id}`

  const data = await apiDelete({ url, token })
  return data
}

export function useDeleteDashboardAnalytic({ id, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return deleteDashboardAnalytic(token, id)
    },
    { ...options }
  )
}
