import React, { useEffect } from 'react'
import Logo from '../assets/MainLogo/Gold/ThirdLineMainLogoGold.svg'
import PropTypes from 'prop-types'
import Navbar from './navbar'
import Sidebar from './sidebar.jsx'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Page({ title, subtitle, pageTitle = '', children }) {
  useEffect(() => {
    document.title = pageTitle || title
  }, [title, pageTitle])
  
  return (
    <div style={{ backgroundColor: '#f5f5f5' }} className="vh-100">
      <div className="container-fluid me-1">
        <ToastContainer />
        <Navbar logo={Logo} title={title} subtitle={subtitle} />
        <Sidebar />
        <div className="d-flex flex-column flex-shrink-0 p-3 col-sm-10 px-md-4 ms-sm-auto">
          <main role="main">{children}</main>
        </div>
      </div>
    </div>
  )
}

Page.propTypes = {
  title: PropTypes.any.isRequired,
  subtitle: PropTypes.node,
  children: PropTypes.node.isRequired
}

export default Page
