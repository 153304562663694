import React, { useState, useEffect } from 'react'
import { useUpdateDashboard } from 'api/hooks/use-dashboards'
import { useQueryClient } from 'react-query'

function DashboardTitle({ params, setParams }) {
  const queryClient = useQueryClient()
  const [dashboardName, setDashboardName] = useState(params.name)
  const [isEditing, setIsEditing] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const queryParams = {
    name: dashboardName,
    dashboard_id: params.dashboardId
  }

  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries('dashboards')
      setParams((params) => ({ ...params, name: dashboardName}))
    }
  }

  const { mutate: updateDashboard } = useUpdateDashboard({
    params: queryParams,
    id: params.dashboardId,
    options
  })

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && isEditing) {
      updateDashboard()
      setIsEditing(false)
      setIsHovering(false)
    }
  }

  useEffect(() => {
    setDashboardName(params.name)
  }, [params.name])

  useEffect(() => {
    if (isEditing) {
      updateDashboard()
    }
  }, [isEditing, updateDashboard])

  return (
    <>
      {!isEditing && (
        <div
          onClick={() => setIsEditing(true)}
          style={{ cursor: 'text' }}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          className={
            isHovering ? 'border rounded-0 ps-1 pe-2 mb-0' : 'ps-1 pe-2 mb-0'
          }
        >
          {dashboardName}
        </div>
      )}
      {isEditing && (
        <input
          type="text"
          defaultValue={params.name}
          className="form-control form-inline rounded-0 py-0"
          onChange={(e) => setDashboardName(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
          onBlur={() => {
            setIsEditing(false)
            setIsHovering(false)
          }}
          autoFocus
        />
      )}
    </>
  )
}

export default DashboardTitle
