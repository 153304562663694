import React, { useState, useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { isEmpty } from 'lodash'
import UserContext from 'context/user-context'
import AppSwitcher from 'layout/app-switcher'
import { InputGroup, Form } from 'react-bootstrap'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import SearchModal from 'layout/sod/search-modal'

function Navbar({ logo, title, subtitle, withSearch = true }) {
  const { logout } = useAuth0()
  const currentUser = useContext(UserContext)
  const [show, setShow] = useState(false)

  return (
    <nav
      className="navbar navbar-dark fixed-top bg-primary flex-md-nowrap p-0 shadow-sm"
      style={{ minHeight: '54px' }}
    >
      <a
        className="navbar-brand bg-primary col-sm-3 col-md-2"
        href="/home"
        style={{ minHeight: '54px' }}
      >
        <img
          src={logo}
          className="img-fluid ms-3"
          alt="ThirdLine Logo"
          style={{ maxHeight: '30px' }}
        />
      </a>
      <div className="container-fluid">
        <h5 className="text-left mt-2 text-light">
          {title}
          <small className="ms-2">{!isEmpty(subtitle) && subtitle}</small>
        </h5>

        <div className="d-flex">
          {withSearch && (
            <InputGroup
              className="my-auto me-4"
              style={{ cursor: 'pointer', minWidth: '300px' }}
              size="sm"
            >
              <InputGroup.Text
                id="global-search"
                className="bg-light"
                style={{ cursor: 'pointer' }}
              >
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                style={{ cursor: 'pointer' }}
                placeholder="Search"
                aria-label="Search"
                aria-describedby="global-search"
                onClick={() => setShow(true)}
                className="bg-light"
                readOnly
              />
            </InputGroup>
          )}
          {currentUser.has_analytics_app && <AppSwitcher currentApp={'sod'} />}
          <ul className="navbar-nav px-2">
            <li className="nav-item text-nowrap">
              <button
                className="sod-nav-link btn text-white"
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                <FontAwesomeIcon icon={faSignOutAlt} /> Sign out
              </button>
            </li>
          </ul>
        </div>
      </div>
      {withSearch && (
        <SearchModal show={show} handleClose={() => setShow(false)} />
      )}
    </nav>
  )
}

export default Navbar
