import { useContext } from 'react'
import { useMutation, useQuery } from 'react-query'
import { apiPatch, apiPost, apiDelete, apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getMunicipalityFeatures = async (token, municipalityId) => {
  const url = `municipalities/${municipalityId}/features`
  const data = await apiGet({ url, token })
  return data
}

export default function useMunicipalityFeatures({ options }) {
  const { municipality_id } = useContext(UserContext)
  const token = useContext(AuthContext)
  const queryFn = () => getMunicipalityFeatures(token, municipality_id)
  const queryKey = 'municipality-features'
  return useQuery(queryKey, queryFn, { ...options })
}

const createMunicipalityFeature = async (token, params) => {
  const objectName = 'municipality_feature'
  const url = `municipalities/${params.municipality_id}/features`

  apiPost({ url, token, objectName, params })
}

export function useCreateMunicipalityFeature({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return createMunicipalityFeature(token, params)
    },
    { ...options }
  )
}

const updateMunicipalityFeature = async (token, params) => {
  const objectName = 'municipality_feature'
  const url = `features/${params.id}`

  apiPatch({ url, token, objectName, params })
}

export function useUpdateMunicipalityFeature({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return updateMunicipalityFeature(token, params)
    },
    { ...options }
  )
}

const destroyMunicipalityFeature = async (token, id) => {
  const url = `features/${id}`

  apiDelete({ url, token })
}

export function useDestroyMunicipalityFeature({ id, options }) {
  const token = useContext(AuthContext)
  return useMutation(
    () => {
      return destroyMunicipalityFeature(token, id)
    },
    { ...options }
  )
}
