import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Search from 'shared/forms/search'
import SectionDropdown from 'shared/filters/section-dropdown'
import TagDropdown from 'shared/filters/tag-dropdown'

function AnalyticsFilters({ params, setParams }) {
  return (
    <Row>
      <Col sm={4}>
        <Search params={params} setParams={setParams} />
      </Col>
      <Col sm={2}>
        <SectionDropdown
          params={params}
          setParams={setParams}
          hasAllOption={true}
        />
      </Col>
      <Col sm={2}>
        <TagDropdown params={params} setParams={setParams} />
      </Col>
      <Col></Col>
    </Row>
  )
}

export default AnalyticsFilters
