import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getReportPreview = async (token, params) => {
  const url = 'report_previews'
  const data = await apiGet({ url, token, params })
  return data
}

export default function useReportPreview({ options, params, auditReport }) {
  const token = useContext(AuthContext)
  const queryFn = () => getReportPreview(token, params)
  const queryKey = ['report-preview', params]
  return useQuery(queryKey, queryFn, { ...options })
}
