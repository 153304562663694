import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getSimilarRoles = async (token, params) => {
  const url = `roles/${params.role_id}/similar_roles`

  const data = await apiGet({ url, token, params })
  return data
}

export function useSimilarRoles({ params, options = {} }) {
  const token = useContext(AuthContext)

  return useQuery(
    [`roles/${params.role_id}/similar_roles`, params],
    () => getSimilarRoles(token, params),
    {
      ...options
    }
  )
}
