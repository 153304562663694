import React from 'react'
import NumberField from 'shared/forms/NumberField'

function PoMinPoLiquidatedAmount({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="PO Min Liquidated Amount"
      showTitle={true}
      accessorKey="poMinPoLiquidatedAmount"
    />
  )
}

export default PoMinPoLiquidatedAmount
