import { useContext } from 'react'
import { useInfiniteQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getSodAuditLogs = async ({ token, municipalityId, params }) => {
  const url = `municipalities/${municipalityId}/sod_audit_logs`
  const data = await apiGet({ url, token, params })
  return data
}

export function useSodAuditLogs({ params, options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const queryFn = () =>
    getSodAuditLogs({ token, municipalityId: municipality_id, params })
  const queryKey = ['sod_audit_logs']
  return useInfiniteQuery(queryKey, queryFn, {
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.length === 25 ? allPages.length + 1 : undefined
    },
    ...options
  })
}
