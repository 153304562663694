import React, { useContext } from 'react'
import SparkLineTable from 'charts/sparkline-table'
import TableFooter from 'shared/tables/table-footer'
import TableLoading from 'shared/tables/table-loading'
import RiskBadge from 'shared/badges/risk-badge'
import { noDigitMoney } from 'helpers/utils'
import { useVendors } from 'api/hooks/use-vendors'
import { useEffect } from 'react'
import SortArrow from 'shared/tables/sort-arrow'
import StatusBadge from 'shared/badges/status-badge'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faClone } from '@fortawesome/free-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import VendorAttribute from 'shared/badges/vendor-attribute'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import UserContext from 'context/user-context'

function Table({ setFilterParams, filterParams, debouncedSearch }) {
  const navigate = useNavigate()
  const { municipality } = useContext(UserContext)
  const updateOrdering = (column) => {
    const isCurrentColumn = filterParams.orderBy === column
    const columnOrder = getColumnOrder(isCurrentColumn)

    setFilterParams({
      ...filterParams,
      orderBy: column,
      orderDirection: columnOrder,
      page: 1
    })
  }

  const getColumnOrder = (isCurrentColumn) => {
    if (isCurrentColumn) {
      return filterParams.orderDirection === 'asc' ? 'desc' : 'asc'
    } else {
      return 'desc'
    }
  }

  useEffect(() => {
    setFilterParams((filterParams) => ({
      ...filterParams,
      search: debouncedSearch,
      page: 1
    }))
  }, [debouncedSearch, setFilterParams])

  const {
    isSuccess,
    isLoading,
    data: vendors
  } = useVendors({ params: filterParams })

  return (
    <>
      <SparkLineTable>
        <thead>
          <tr className="text-primary">
            <th
              onClick={() => updateOrdering('name')}
              style={{ cursor: 'pointer' }}
            >
              Name{' '}
              <SortArrow
                column="name"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              onClick={() => updateOrdering('risk_rate')}
              style={{ cursor: 'pointer' }}
            >
              Risk
              <SortArrow
                column="risk_rate"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              onClick={() => updateOrdering('analytics_count')}
              style={{ cursor: 'pointer' }}
            >
              Analytics
              <SortArrow
                column="analytics_count"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              onClick={() => updateOrdering('transactions_count')}
              style={{ cursor: 'pointer' }}
            >
              Transactions{' '}
              <SortArrow
                column="transactions_count"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              onClick={() => updateOrdering('vendor_invoice_sum')}
              style={{ cursor: 'pointer' }}
            >
              Invoiced{' '}
              <SortArrow
                column="vendor_invoice_sum"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              onClick={() => updateOrdering('vendor_check_amount_sum')}
              style={{ cursor: 'pointer' }}
            >
              Paid{' '}
              <SortArrow
                column="vendor_check_amount_sum"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <TableLoading columns={6} />}

          {isSuccess &&
            vendors.data.map((v) => (
              <tr
                key={`vendor-row-${v.id}`}
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/vendors/${v.id}`)}
              >
                <td>
                  <span className="me-2">{v.name}</span>
                  {v.is_pcard ? (
                    <span className="me-2">
                      <StatusBadge
                        title="PCard"
                        icon={faCreditCard}
                        tooltipText="Vendor generated from PCard file"
                      />
                    </span>
                  ) : (
                    <small className="text-muted">
                      ID: {v.external_id}
                      {v.status && (
                        <span className="text-muted ms-2">{v.status}</span>
                      )}
                    </small>
                  )}
                  <div className="mt-1">
                    {v.recent_payment_change && (
                      <VendorAttribute
                        title="Recent Payment Change"
                        tooltipText={`Vendor payment method changed in the last 30 days: ${v.last_payment_method_change_on}`}
                      />
                    )}
                    {v.stale && (
                      <VendorAttribute
                        title="Stale"
                        tooltipText={`Vendor has not sent invoice or recieved a check in ${municipality.stale_vendor_months} months. Last Invoice: ${v.last_invoice_on} Last Check: ${v.last_check_on}`}
                      />
                    )}
                    {v.po_box && (
                      <VendorAttribute
                        title="PO Box"
                        tooltipText={'Vendor has a PO Box address'}
                      />
                    )}
                    {v.one_eight_hundred && (
                      <VendorAttribute
                        title="1-800"
                        tooltipText={'Vendor has an 800 number'}
                      />
                    )}
                    {v.is_duplicate_ein && (
                      <VendorAttribute
                        title={
                          <span>
                            <FontAwesomeIcon icon={faClone} /> EIN
                          </span>
                        }
                        tooltipText={`Vendor has a duplicated EIN with another active vendor: ${v.duplicate_ein_vendors
                          .split('||')
                          .join(', ')}`}
                      />
                    )}
                    {v.is_duplicate_ein_being_paid && (
                      <VendorAttribute
                        title={
                          <span>
                            <FontAwesomeIcon icon={faClone} /> EIN $
                          </span>
                        }
                        tooltipText={`Multiple Vendors with the same EIN have been paid in the past year: ${v.duplicate_ein_being_paid_vendors
                          .split('||')
                          .join(', ')}`}
                      />
                    )}
                  </div>
                </td>
                <td className="align-middle">
                  <div>
                    <RiskBadge count={v.risk_rate} showCount={true} />
                  </div>
                </td>
                <td className="align-middle fw-bold">
                  {Math.floor(v.analytics_count)}
                </td>
                {/* TODO: <td data-sparkline={`${fourNumbers()}`}></td> */}
                <td className="text-muted align-middle">
                  {Math.floor(v.transactions_count)}
                </td>
                <td className="text-muted align-middle">
                  {noDigitMoney(v.vendor_invoice_sum)}
                </td>
                <td className="text-muted align-middle">
                  {v.is_pcard
                    ? noDigitMoney(v.transactions_amount)
                    : noDigitMoney(v.vendor_check_amount_sum)}
                </td>
              </tr>
            ))}
        </tbody>
      </SparkLineTable>
      {isSuccess && (
        <TableFooter
          meta={vendors.meta}
          params={filterParams}
          setParams={setFilterParams}
        />
      )}
    </>
  )
}

export default Table
