import React from 'react'
import { formatDateTime } from 'helpers/datetime'
import Link from './event-link'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faIdCard, faMinus, faWrench, faPlus } from "@fortawesome/free-solid-svg-icons"

function RoleMaster({ log }) {

  const LogIcon = () => {
    if (log.action === 'A') {
      return <><FontAwesomeIcon icon={faPlus} />
        <FontAwesomeIcon icon={faIdCard} className='mx-2' />
      </>
    } else if (log.action === 'D') {
      return <><FontAwesomeIcon icon={faMinus} />
        <FontAwesomeIcon icon={faIdCard} className='mx-2' />
      </>
    } else {
      return <><FontAwesomeIcon icon={faWrench} />
        <FontAwesomeIcon icon={faIdCard} className='mx-2' />
      </>
    }
  }

  const actionText = (action) => {
    switch (action) {
      case 'A':
        return 'Created'
      case 'D':
        return 'Deleted'
      case 'U':
        return 'Updated'
      default:
        return action
    }
  }

  const LogText = () => {
    if(log.action === 'U') {
      return (
        <>updated
          from: <div className="fw-bold">{log.old_value}</div> to{' '}
          <div className="fw-bold">{log.new_value}</div></>
      )
    } else if(log.action === 'D') {
      return (
        <>
          removed
        </>
      )
    } else {
      return (
        <>
          added
        </>
      )
    }
  }

  return (
    <li
      type="button"
      className="list-group-item d-flex justify-content-between align-items-start"
      aria-current="true"
    >
      <div className="ms-2 me-auto">
        <div className="fw-bold">
          <LogIcon />
          Role {actionText(log.action)}{' '}
        </div>
        <div className="fw-light text-muted">
          <Link linkObject={log.changeable} objectType="roles" /> was{' '}
          <LogText />
        </div>
      </div>
      <span className="ms-2 text-muted fw-light">
        {formatDateTime(log.changed_at)} by {log.changed_by.external_id}
      </span>
    </li>
  )
}

export default RoleMaster
