import React, { useContext, useRef } from 'react'
import UserContext from 'context/user-context'
import { Button, Form, Overlay, Popover, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import smallDot from 'shared/small-dot.svg'
import FilterContext from 'context/filters-context'

export default function Process({
  params,
  setParams,
  activeOverlay,
  setActiveOverlay
}) {
  const { section_abbreviations } = useContext(UserContext)
  const { filters, setFilters } = useContext(FilterContext)
  const filterTarget = useRef(null)

  const mapping = [
    { code: 'API', name: 'AP Invoice', section_abbreviation: 'AP' },
    { code: 'POE', name: 'Purchase Order', section_abbreviation: 'PO' },
    { code: 'POM', name: 'Change Order', section_abbreviation: 'PO' },
    { code: 'REQ', name: 'Requisition', section_abbreviation: 'PO' },
    // { code: 'GLJ', name: 'GL Journal Approvals', section_abbreviation: 'GL' },
    {
      code: 'VIA',
      name: 'Vendor Internal Addition',
      section_abbreviation: 'Vendor'
    },
    { code: 'VEA', name: 'Vendor External Addition', section_abbreviation: 'Vendor' },
    { code: 'VIU', name: 'Vendor Internal Update', section_abbreviation: 'Vendor' }

  ]
  const filteredMapping = mapping.filter((item) =>
    section_abbreviations.includes(item.section_abbreviation)
  )

  const handleCheckboxChange = (e) => {
    const value = e.target.value
    const checked = e.target.checked

    let updatedParams
    if (checked) {
      updatedParams = [...params.processes, value]
    } else {
      updatedParams = params.processes.filter((item) => item !== value)
    }

    setParams({
      ...params,
      processes: updatedParams
    })
    setFilters({ ...filters, processes: updatedParams })
  }

  const handleClear = () => {
    setParams({ ...params, processes: [] })
    setFilters({ ...filters, processes: [] })
  }

  const isFiltered = params.processes.length > 0

  const popover = (
    <Popover style={{ minWidth: '225px' }}>
      <Popover.Header>
        <Row>
          <Col sm={6} className="d-grid">
            {isFiltered ? (
              <Button
                variant="link"
                size="sm"
                className="float-end mt-1"
                onClick={() => handleClear()}
              >
                <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                Clear
              </Button>
            ) : null}
          </Col>
          <Col sm={6} className="d-grid">
            <Button
              variant="primary"
              size="sm"
              className="float-end"
              onClick={() => setActiveOverlay('')}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </Popover.Header>
      <Popover.Body>
        <h5>Process</h5>
        <Form>
          {filteredMapping.map((item) => (
            <Form.Group controlId={item.code} key={item.code}>
              <Form.Check
                type="checkbox"
                label={item.name}
                value={item.code}
                checked={params.processes.includes(item.code)}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
          ))}
        </Form>
      </Popover.Body>
    </Popover>
  )

  return (
    <div className="d-flex justify-content-center">
      <Button
        className={isFiltered ? 'bg-white text-primary' : 'bg-white text-info'}
        variant={isFiltered ? 'outline-primary' : 'outline-light'}
        style={{ border: isFiltered ? '' : '1px solid #ced4da' }}
        onClick={() =>
          activeOverlay === 'processes'
            ? setActiveOverlay('')
            : setActiveOverlay('processes')
        }
        ref={filterTarget}
      >
        {isFiltered ? (
          <>
            <img src={smallDot} className="img-fluid" alt={'Filter Applied'} />
            Process
          </>
        ) : (
          'Process'
        )}
      </Button>
      <Overlay
        target={filterTarget.current}
        show={activeOverlay === 'processes'}
        placement="bottom"
        trigger="click"
      >
        {popover}
      </Overlay>
    </div>
  )
}
