import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import VendorSearch from './vendors'
import smallDot from 'shared/small-dot.svg'

function MoreFilters({ params, setParams, setIsFilterOpen }) {
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setIsFilterOpen(false)
    setShow(false)
  }
  const handleShow = () => {
    setIsFilterOpen(true)
    setShow(true)
  }
  const isFiltered = params.vendors.length > 0

  return (
    <React.Fragment>
      <label className="mr-2 fw-light text-muted sr-only">
        <small>Vendor</small>
      </label>
      <div>
        <Button
          className={`bg-white ${isFiltered ? 'text-primary' : 'text-info'}`}
          onClick={handleShow}
          style={{ border: isFiltered ? '' : '1px solid #ccc' }}
          variant={isFiltered ? 'outline-primary' : 'outline-light'}
        >
          {isFiltered && (
            <img
              alt="small dot"
              src={smallDot}
              style={{ height: '10px', width: '10px' }}
            />
          )}{' '}
          Vendor
        </Button>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Vendor Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VendorSearch
            params={params}
            setParams={setParams}
            setShow={setShow}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default MoreFilters
