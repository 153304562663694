import Form from 'react-bootstrap/Form'
import useSections from 'api/hooks/use-sections'
import { useNavigate } from 'react-router-dom'

function SectionDropdown({ sectionId, size = 'md' }) {
  const navigate = useNavigate()
  const { isSuccess: isSectionSuccess, data } = useSections({ options: {} })
  const limitedSections = isSectionSuccess
    ? data.filter(
        (s) =>
          ['AP', 'PC', 'PO', 'GL', 'Vendor'].includes(s.abbreviation)
      )
    : []

  const getUrlFromSectionId = (sectionId) => {
    const section = limitedSections.find((s) => s.id === sectionId)
    return section ? section.name.replace(/\s+/g, '-').toLowerCase() : ''
  }

  return (
    <>
      {isSectionSuccess && (
        <Form.Group>
          <label className="mr-2 fw-light text-muted sr-only">
            <small>Module</small>
          </label>
          <Form.Select
            as="select"
            value={sectionId}
            size={size}
            onChange={(e) => navigate(`/dashboards/${getUrlFromSectionId(e.target.value)}`)}
            required
          >
            {limitedSections.map((s) => (
              <option key={`section-option-${s.id}`} value={s.id}>
                {s.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}
    </>
  )
}

export default SectionDropdown
