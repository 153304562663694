import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getConflictOptions = async (token, municipality_id) => {
  const url = `municipalities/${municipality_id}/analytics?sod_conflicts=true&perPage=1000`
  const data = await apiGet({ url, token })
  return data
}

export default function useConflictOptions({ options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)

  const queryFn = () => getConflictOptions(token, municipality_id)
  const queryKey = 'conflicts'
  return useQuery(queryKey, queryFn, { ...options })
}


const getConflicts = async (token, municipality_id, params) => {
  const url = `municipalities/${municipality_id}/conflicts`
  const data = await apiGet({ url, token, params })
  return data
}

export function useConflicts({ params, options = {} }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)

  const queryFn = () => getConflicts(token, municipality_id, params)
  const queryKey = ['conflicts', params]
  return useQuery(queryKey, queryFn, { ...options })
}

const getConflict = async (token, municipality_id, conflict_id) => {
  const url = `municipalities/${municipality_id}/conflicts/${conflict_id}`
  const data = await apiGet({ url, token })
  return data
}

export function useConflict({ params }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)

  const queryFn = () => getConflict(token, municipality_id, params.id)
  const queryKey = ['conflicts', params.id]
  return useQuery(queryKey, queryFn)
}