import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getDepartments = async (token, municipalityId) => {
  const url = `municipalities/${municipalityId}/departments`

  const data = await apiGet({ url, token })
  return data
}

export default function useDepartments({ options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const queryFn = () => getDepartments(token, municipality_id)
  const queryKey = 'departments'

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
