import React from 'react'
import {
  faUserSlash,
} from '@fortawesome/free-solid-svg-icons'
import Result from './result'

function ConflictResult({ result }) {
  return (
    <Result
      dataType='Conflict'
      title={result.record.name}
      subtitle={result.record.description}
      icon={faUserSlash}
      url={`/conflicts/${result.data_id}`}
      tags={[]}
    />
  )
}

export default ConflictResult
