import React, { useState } from 'react'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import TableLoading from 'shared/tables/table-loading'
import { useAnalytics } from 'api/hooks/use-analytics'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import TableError from 'shared/tables/table-error'
import FlagCount from 'shared/flag-count'

function AnalyticList({ sectionId, report, setReport }) {
  const [perPage, setPerPage] = useState(10)
  const params = { perPage: perPage, section_id: sectionId }
  const {
    isSuccess,
    isLoading,
    isError,
    data: analytics
  } = useAnalytics({ params })

  const [analyticId, setAnalyticId] = useState('')

  useEffect(() => {
    setReport((report) => ({ ...report, analyticId: analyticId }))
  }, [analyticId, setReport])

  return (
    <div>
      <Table className="border border-light" responsive>
        <thead>
          <tr>
            <th style={{ minWidth: '135px' }}>Select</th>
            <th>Analytic Name / Description</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <TableLoading columns={3} />}
          {isError && <TableError columns={3} />}
          {isSuccess && isEmpty(sectionId) && (
            <tr>
              <td colSpan={3} className="text-center">
                Select module to get started
              </td>
            </tr>
          )}
          {isSuccess &&
            analytics.data.map((a) => (
              <tr
                className={
                  a.id === analyticId ? 'table-light border border-light' : ''
                }
                key={`analytic-${a.id}`}
              >
                <td className="align-middle">
                  <div className="d-grid gap-2">
                    <Button
                      variant={a.id === analyticId ? 'primary' : 'light'}
                      onClick={() => setAnalyticId(a.id)}
                    >
                      {a.id === analyticId ? (
                        <>
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-warning me-1"
                          />{' '}
                          Selected
                        </>
                      ) : (
                        'Select'
                      )}
                    </Button>
                  </div>
                </td>
                <td className="align-middle">
                  {a.name}
                  <div className="text-muted fw-light">{a.description}</div>
                </td>
                <td>
                  <FlagCount count={a.analytics_count} />
                </td>
              </tr>
            ))}
          {isSuccess && analytics.data.length > 0 && perPage !== 100 && (
            <tr>
              <td colSpan={3}>
                <Button
                  variant="link"
                  size="sm"
                  onClick={() => setPerPage(100)}
                >
                  Load All
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default AnalyticList
