import React, { useState, useContext } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import useOrgs from 'api/hooks/use-orgs'
import useObjects from 'api/hooks/use-objects'
import useSegments from 'api/hooks/use-segments'
import Select from 'react-select'
import UserContext from 'context/user-context'

function AccountFilter({ params, setParams }) {
  const { municipality } = useContext(UserContext)

  const {
    seg_one_name,
    seg_two_name,
    seg_three_name,
    seg_four_name,
    seg_five_name,
    seg_six_name,
    seg_seven_name,
    seg_eight_name
  } = municipality.setting

  const [objectValue, setObjectValue] = useState('')
  const [orgValue, setOrgValue] = useState('')
  const [segOne, setSegOne] = useState(params.segOne)
  const [segTwo, setSegTwo] = useState(params.segTwo)
  const [segThree, setSegThree] = useState(params.segThree)
  const [segFour, setSegFour] = useState(params.segFour)
  const [segFive, setSegFive] = useState(params.segFive)
  const [segSix, setSegSix] = useState(params.segSix)
  const [segSeven, setSegSeven] = useState(params.segSeven)
  const [segEight, setSegEight] = useState(params.segEight)
  const { isSuccess, data: orgs } = useOrgs({})
  const { isSuccess: objSuccess, data: objects } = useObjects({
    orgId: params.orgId
  })

  const segOneParams = {
    seg: 'one'
  }
  const { isSuccess: segmentsSuccess, data: segments } = useSegments({
    params: segOneParams
  })

  const segTwoParams = {
    seg: 'two',
    seg_one: segOne?.value || ''
  }
  const { isSuccess: segmentTwoSuccess, data: segTwos } = useSegments({
    params: segTwoParams
  })

  const segThreeParams = {
    seg: 'three',
    seg_two: segTwo?.value || ''
  }
  const { isSuccess: segmentThreeSuccess, data: segThrees } = useSegments({
    params: segThreeParams
  })

  const segFourParams = {
    seg: 'four',
    seg_three: segThree?.value || ''
  }
  const { isSuccess: segmentFourSuccess, data: segFours } = useSegments({
    params: segFourParams
  })

  const segFiveParams = {
    seg: 'five',
    seg_four: segFour?.value || ''
  }
  const { isSuccess: segmentFiveSuccess, data: segFives } = useSegments({
    params: segFiveParams
  })

  const segSixParams = {
    seg: 'six',
    seg_five: segFive?.value || ''
  }
  const { isSuccess: segmentSixSuccess, data: segSixes } = useSegments({
    params: segSixParams
  })

  const segSevenParams = {
    seg: 'seven',
    seg_six: segSix?.value || ''
  }
  const { isSuccess: segmentSevenSuccess, data: segSevens } = useSegments({
    params: segSevenParams
  })

  const segEightParams = {
    seg: 'eight',
    seg_seven: segSeven?.value || ''
  }
  const { isSuccess: segmentEightSuccess, data: segEights } = useSegments({
    params: segEightParams
  })

  const mapCodesToOptions = (accountCodes) => {
    return accountCodes.map((obj) => ({
      label: `${obj['name']} (${obj['external_id']})`,
      value: obj['id']
    }))
  }

  const orgSelectOptions = isSuccess ? mapCodesToOptions(orgs) : []
  const objectSelectOptions = objSuccess ? mapCodesToOptions(objects) : []
  const segOneSelectOptions = segmentsSuccess ? mapCodesToOptions(segments) : []
  const segTwoSelectOptions = segmentTwoSuccess
    ? mapCodesToOptions(segTwos)
    : []
  const segThreeSelectOptions = segmentThreeSuccess
    ? mapCodesToOptions(segThrees)
    : []
  const segFourSelectOptions = segmentFourSuccess
    ? mapCodesToOptions(segFours)
    : []
  const segFiveSelectOptions = segmentFiveSuccess
    ? mapCodesToOptions(segFives)
    : []
  const segSixSelectOptions = segmentSixSuccess
    ? mapCodesToOptions(segSixes)
    : []
  const segSevenSelectOptions = segmentSevenSuccess
    ? mapCodesToOptions(segSevens)
    : []
  const segEightSelectOptions = segmentEightSuccess
    ? mapCodesToOptions(segEights)
    : []

  const clearAccountFilters = () => {
    setObjectValue('')
    setOrgValue('')
    setSegOne('')
    setSegTwo('')
    setSegThree('')
    setSegFour('')
    setSegFive('')
    setSegSix('')
    setSegSeven('')
    setSegEight('')
    setParams({
      ...params,
      orgId: '',
      objectId: '',
      segOne: '',
      segtwo: '',
      segThree: '',
      segFour: '',
      segFive: '',
      segSix: '',
      segSeven: '',
      segEight: ''
    })
  }

  return (
    <>
      <Row className="mb-4">
        <Col sm={12}>
          <h5>
            Accounts
            {params.segOne ||
            params.segTwo ||
            params.segThree ||
            params.segFour ||
            params.segFive ||
            params.segSix ||
            params.segSeven ||
            params.segEight ? (
              <Button
                variant="link"
                size="sm"
                className="ms-4"
                onClick={() => clearAccountFilters()}
              >
                Clear
              </Button>
            ) : null}
          </h5>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <div className="mt-2 fw-bold">Org</div>
        </Col>
        <Col sm={9}>
          <Form.Group>
            {isSuccess && (
              <Select
                options={orgSelectOptions}
                onChange={(val) => {
                  setOrgValue(val)
                  setParams({
                    ...params,
                    orgId: val?.value || '',
                    page: 1
                  })
                }}
                isClearable
                value={orgValue}
                id="org-selector"
              />
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={3}>
          <div className="mt-2 fw-bold">Object</div>
        </Col>
        <Col sm={9}>
          <Form.Group>
            <Select
              options={objectSelectOptions}
              onChange={(val) => {
                setObjectValue(val)
                setParams({
                  ...params,
                  objectId: val?.value || '',
                  page: 1
                })
              }}
              isClearable
              value={objectValue}
              id="object-selector"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={3}>
          <div className="mt-2 fw-bold">{seg_one_name || 'Seg One'}</div>
        </Col>
        <Col sm={9}>
          <Form.Group>
            <Select
              options={segOneSelectOptions}
              onChange={(val) => {
                setSegOne(val)
                setParams({
                  ...params,
                  segOne: val,
                  page: 1
                })
              }}
              isClearable
              value={segOne}
              id="seg-one-selector"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={3}>
          <div className="mt-2 fw-bold">{seg_two_name || 'Seg Two'}</div>
        </Col>
        <Col sm={9}>
          <Form.Group>
            <Select
              options={segTwoSelectOptions}
              onChange={(val) => {
                setSegTwo(val)
                setParams({
                  ...params,
                  segTwo: val,
                  page: 1
                })
              }}
              isClearable
              value={segTwo}
              id="seg-two-selector"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={3}>
          <div className="mt-2 fw-bold">{seg_three_name || 'Seg Three'}</div>
        </Col>
        <Col sm={9}>
          <Form.Group>
            <Select
              options={segThreeSelectOptions}
              onChange={(val) => {
                setSegThree(val)
                setParams({
                  ...params,
                  segThree: val,
                  page: 1
                })
              }}
              isClearable
              value={segThree}
              id="seg-three-selector"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={3}>
          <div className="mt-2 fw-bold">{seg_four_name || 'Seg Four'}</div>
        </Col>
        <Col sm={9}>
          <Form.Group>
            <Select
              options={segFourSelectOptions}
              onChange={(val) => {
                setSegFour(val)
                setParams({
                  ...params,
                  segFour: val,
                  page: 1
                })
              }}
              isClearable
              value={segFour}
              id="seg-four-selector"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={3}>
          <div className="mt-2 fw-bold">{seg_five_name || 'Seg Five'}</div>
        </Col>
        <Col sm={9}>
          <Form.Group>
            <Select
              options={segFiveSelectOptions}
              onChange={(val) => {
                setSegFive(val)
                setParams({
                  ...params,
                  segFive: val,
                  page: 1
                })
              }}
              isClearable
              value={segFive}
              id="seg-five-selector"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={3}>
          <div className="mt-2 fw-bold">{seg_six_name || 'Seg Six'}</div>
        </Col>
        <Col sm={9}>
          <Form.Group>
            <Select
              options={segSixSelectOptions}
              onChange={(val) => {
                setSegSix(val)
                setParams({
                  ...params,
                  segSix: val,
                  page: 1
                })
              }}
              isClearable
              value={segSix}
              id="seg-six-selector"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={3}>
          <div className="mt-2 fw-bold">{seg_seven_name || 'Seg Seven'}</div>
        </Col>
        <Col sm={9}>
          <Form.Group>
            <Select
              options={segSevenSelectOptions}
              onChange={(val) => {
                setSegSeven(val)
                setParams({
                  ...params,
                  segSeven: val,
                  page: 1
                })
              }}
              isClearable
              value={segSeven}
              id="seg-seven-selector"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={3}>
          <div className="mt-2 fw-bold">{seg_eight_name || 'Seg Eight'}</div>
        </Col>
        <Col sm={9}>
          <Form.Group>
            <Select
              options={segEightSelectOptions}
              onChange={(val) => {
                setSegEight(val)
                setParams({
                  ...params,
                  segEight: val,
                  page: 1
                })
              }}
              isClearable
              value={segEight}
              id="seg-eight-selector"
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default AccountFilter
