import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getRoleDuties = async (token, params) => {
  const url = `roles/${params.id}/duties`

  const data = await apiGet({ url, token, params })
  return data
}

export function useRoleDuties({ params, options }) {
  const token = useContext(AuthContext)
  const queryFn = () => getRoleDuties(token, params)
  const queryKey = ['roles-duties', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}

const getRoleDuty = async (token, params) => {
  const url = `roles/${params.role_id}/duties/${params.analytic_id}`

  const data = await apiGet({ url, token, params })
  return data
}

export function useRoleDuty({ params, options }) {
  const token = useContext(AuthContext)
  const queryFn = () => getRoleDuty(token, params)
  const queryKey = ['roles-duty', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
