import { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faIdCard,
  faTimesCircle,
  faUserSlash,
  faCreditCard
} from '@fortawesome/free-solid-svg-icons'
import RiskBadge from 'shared/badges/risk-badge'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { truncate, isObject } from 'lodash'
import Button from 'react-bootstrap/Button'
import StatusBadge from 'shared/badges/status-badge'

function EmployeeCard({ employee }) {
  const [showConflicts, setShowConflicts] = useState(false)

  return (
    <>
      {isObject(employee) ? (
        <Row>
          <Col sm={12}>
            <div className="align-middle">
              <div className="mb-2">
                <div className="text-primary fw-bold small mb-2">
                  <FontAwesomeIcon icon={faIdCard} className="me-1" />
                  Employee ID
                  {employee.status === 'inactive' ? (
                    <span className="ms-1">
                      <StatusBadge
                        title="Disabled"
                        icon={faUserSlash}
                        tooltipText="No longer an active employee"
                      />
                    </span>
                  ) : null}
                  {employee.is_pcard && (
                    <StatusBadge
                      title="PCard"
                      icon={faCreditCard}
                      tooltipText="Employee generated from PCard file"
                    />
                  )}
                  <span className="float-end">
                    <RiskBadge count={employee.analytics_per_transaction} />
                  </span>
                </div>
                <div>
                  <a href={`/employees/${employee.id}`} target="_blank" rel="noreferrer" >
                    {truncate(employee.external_id, { length: 42 })}{' '}
                  </a>
                  <span className="fw-light text-muted ms-4">
                    {employee.department_name}
                  </span>
                </div>
              </div>
            </div>
            {isObject(employee) && (
              <>
                <div>
                  <span className="me-2 fw-bolder">
                    {employee.total_analytics.toLocaleString()}
                  </span>
                  <span className="text-muted fw-light">Total Flags</span>
                </div>
                <div>
                  <span className="me-2 fw-bolder">
                    {employee.total_transactions.toLocaleString()}
                  </span>
                  <span className="text-muted fw-light">Transactions</span>
                </div>
                <div>
                  <span className="me-2 fw-bolder">
                    {Math.floor(
                      (10 * employee.total_analytics) /
                        employee.total_transactions
                    ) / 10}
                  </span>
                  <span className="text-muted fw-light">
                    Flags / Transaction
                  </span>
                </div>
              </>
            )}
          </Col>
        </Row>
      ) : (
        <div>
          <h5>No Employee Data Found</h5>
        </div>
      )}
      {isObject(employee) && showConflicts && (
        <Row>
          <Col sm={12}>
            {employee.conflicts.length > 0 ? (
              <>
                <Button
                  variant={showConflicts ? 'outline-danger' : 'outline-light'}
                  size="sm"
                  className="mt-2"
                  onClick={() => setShowConflicts(!showConflicts)}
                >
                  <div className="text-danger">
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="text-danger me-2"
                    />
                    {employee.conflicts.length} permission conflicts
                  </div>
                </Button>
              </>
            ) : null}
          </Col>
        </Row>
      )}
    </>
  )
}

EmployeeCard.propTypes = {
  employee: PropTypes.object.isRequired
}

export default EmployeeCard
