import React from 'react'
import Card from 'react-bootstrap/Card'
import { truncate } from 'lodash'

type StatProps = {
  activeTab: string,
  setActiveTab: Function,
  text: string,
  subtext: string,
  name: string
}

function Stat({ activeTab, setActiveTab, text, subtext, name }: StatProps) {
  return (
    <Card
      className={activeTab === name ? 'h-100 shadow-sm' : 'h-100 shadow-sm'}
      style={{
        background: activeTab === name ? '#f0f5f7' : '',
        border: activeTab === name ? '1px solid #6ea0b5' : '',
        cursor: 'pointer'
      }}
      onClick={() => setActiveTab(name)}
    >
      <Card.Body>
          <div className="mt-2">
            <div className="h4 text-primary">
              {truncate(text, { length: 25 })}
            </div>{' '}
            <span className="text-muted">{subtext}</span>
          </div>
      </Card.Body>
    </Card>
  )
}

export default Stat
