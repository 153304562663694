import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { lowerCase } from 'lodash'

type ConfirmDeleteProps = {
  id: string,
  handleDelete: Function,
  extraConfirm?: boolean
}

function ConfirmDelete({ id, handleDelete, extraConfirm = false }: ConfirmDeleteProps) {
  const [textMatch, setTextMatch] = useState(false)
  const [hoverEdit, setHoverEdit] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [text, setText] = useState('')

  useEffect(() => {
    if (text === 'delete' || !extraConfirm) {
      setTextMatch(true)
    } else {
      setTextMatch(false)
    }
  }, [text, extraConfirm])

  const clickedDelete = () => {
    handleDelete(id)
    setShowConfirmDelete(false)
  }

  return (
    <>
      <Button
        onMouseEnter={() => setHoverEdit(true)}
        onMouseLeave={() => setHoverEdit(false)}
        className={
          hoverEdit
            ? 'btn btn-sm badge-danger border-danger'
            : 'btn btn-sm btn-light border-secondary text-primary'
        }
        onClick={() => setShowConfirmDelete(true)}
        size="sm"
        data-testid="delete-button"
      >
        <FontAwesomeIcon icon={faTrash} className="text-danger" />
      </Button>
      <Modal
        show={showConfirmDelete}
        onHide={() => setShowConfirmDelete(false)}
      >
        <Modal.Body>
          <p className="my-2 p-2">
            Are you sure you want to delete this? All data captured will be
            lost. This cannot be undone.
          </p>
          {extraConfirm && (
            <div className="my-4">
              <p>
                Type <kbd>delete</kbd> below to permanently delete this report
              </p>
              <Form.Group>
                <Form.Control
                  type="text"
                  value={text}
                  data-testid="confirm-delete-input"
                  onChange={(e) => setText(lowerCase(e.target.value))}
                  placeholder='Type "delete"'
                />
              </Form.Group>
            </div>
          )}

          <div>
            <Button
              variant="light"
              className="float-end"
              onClick={() => clickedDelete()}
              disabled={!textMatch}
            >
              <FontAwesomeIcon icon={faTrash} className="text-danger" /> Yes,
              delete forever
            </Button>
            <Button
              variant="link"
              className="float-end me-3"
              onClick={() => setShowConfirmDelete(false)}
            >
              No, back to safety
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ConfirmDelete
