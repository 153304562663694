import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'

const defaultOptions = {
  chart: {
    backgroundColor: null,
    borderWidth: 0,
    type: 'area',
    margin: [2, 0, 2, 0],
    width: 300,
    height: 40,
    style: {
      overflow: 'visible'
    },
    // small optimalization, saves 1-2 ms each sparkline
    skipClone: true
  },
  title: {
    text: ''
  },
  credits: {
    enabled: false
  },
  xAxis: {
    labels: {
      enabled: false
    },
    title: {
      text: null
    },
    startOnTick: false,
    endOnTick: false,
    tickPositions: []
  },
  yAxis: {
    endOnTick: false,
    startOnTick: false,
    labels: {
      enabled: false
    },
    title: {
      text: null
    },
    tickPositions: [0]
  },
  legend: {
    enabled: false
  },
  tooltip: {
    backgroundColor: 'white',
    borderWidth: 1,
    zIndex: 1000,
    hideDelay: 0,
    shared: true,
    padding: 8,
    borderColor: 'silver',
    borderRadius: 3,
    useHTML: true,
    headerFormat: '<span style="font-size:10px">Month{point.x}: </span><br/>',
    pointFormat: '<b>{point.y:.2f}%</b>',
    style: {
      zIndex: 1000
    }
  },
  plotOptions: {
    series: {
      softThreshold: true,
      animation: false,
      lineWidth: 1,
      shadow: false,
      color: '#E77152',
      negativeColor: '#5c80bb',
      states: {
        hover: {
          lineWidth: 1
        }
      },
      marker: {
        radius: 2,
        states: {
          hover: {
            radius: 2
          }
        }
      },
      fillOpacity: 0.25
    },
    column: {
      color: '#E77152',
      negativeColor: '#5c80bb',
      borderColor: 'silver'
    }
  },

  series: [
    {
      data: ['1', '2', '3']
    }
  ]
}

class SparkLine extends React.Component {
  componentDidMount() {
    const options = Highcharts.merge(defaultOptions, this.props.options)
    this.chart = Highcharts.chart(this.container, options)
  }

  componentWillUnmount() {
    this.chart.destroy()
  }

  render() {
    return <td ref={(container) => (this.container = container)}></td>
  }
}

SparkLine.propTypes = {
  options: PropTypes.object.isRequired
}

export default SparkLine
