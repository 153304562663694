import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDotCircle,
  faRetweet,
  faExclamation,
  faCheck
} from '@fortawesome/free-solid-svg-icons'

type StatusIconProps = {
  name: string
}

function StatusIcon({ name }: StatusIconProps) {
  if (name === 'Open') {
    return <FontAwesomeIcon icon={faDotCircle} className="text-muted" />
  } else if (name === 'In-progress') {
    return <FontAwesomeIcon icon={faRetweet} className="text-warning" />
  } else if (name === 'Exception') {
    return <FontAwesomeIcon icon={faExclamation} className="text-danger" />
  } else if (name === 'No Exception') {
    return <FontAwesomeIcon icon={faCheck} className="text-success" />
  } else {
    return <FontAwesomeIcon icon={faDotCircle} className="text-muted" />
  }
}

export default StatusIcon
