import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Shield from 'assets/Shield/Gold/ThirdLineShieldGold.svg'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useQueryClient } from 'react-query'
import { useEffect } from 'react'
import InputHelpText from 'shared/forms/input-help-text'
import { useCreateMunicipalityFeature } from 'api/hooks/use-municipality-features'
import UserContext from 'context/user-context'

function AddAttribute() {
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [criteria, setCriteria] = useState('')
  const queryClient = useQueryClient()
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const { municipality_id } = useContext(UserContext)

  const params = {
    municipality_id: municipality_id,
    name: name,
    description: description,
    criteria: criteria
  }

  const queryOptions = {
    onSuccess: () => {
      setTimeout(function () {
        queryClient.invalidateQueries('municipality-features')
      }, 500)
    }
  }

  const { isSuccess, mutate } = useCreateMunicipalityFeature({
    params,
    options: queryOptions
  })

  useEffect(() => {
    if (isSuccess) {
      new Promise((resolve) => {
        setTimeout(resolve, 2000)
        queryClient.invalidateQueries(`feature`)
      })
      handleClose()
      clearForm()
    }
  }, [isSuccess, queryClient])

  const clearForm = () => {
    setName('')
    setDescription('')
    setCriteria('')
  }

  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow}>
        <img src={Shield} style={{ width: 15 }} alt="ThirdLine Shield" /> Add
        Attribute
      </Button>{' '}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Attribute</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-3">
            <Col sm={2}>
              <span className="mt-1 text-primary">Name</span>
            </Col>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder=""
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <InputHelpText content="Attribute should be in the form of a yes / no question" />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={2}>
              <span className="mt-1 text-primary">Criteria</span>
            </Col>
            <Col sm={10}>
              <Form.Control
                type="text"
                value={criteria}
                placeholder=""
                onChange={(e) => setCriteria(e.target.value)}
              />
              <InputHelpText content="Name the rule/policy/other source for this attribute" />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={2}>
              <span className="mt-1 text-primary">Description</span>
            </Col>
            <Col sm={10}>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  rows="5"
                  required
                  value={description}
                  placeholder="Description of the conditions that result in a yes or no"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="float-end"
            variant="primary"
            disabled={name.length === 0}
            onClick={() => mutate()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default AddAttribute
