import React from 'react'
import {
  useCreateDashboardAnalytic,
  useDeleteDashboardAnalytic
} from 'api/hooks/use-dashboard-analytics'
import ListGroup from 'react-bootstrap/ListGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'


function CreateOrRemoveAnalytic({ analytic, params, setParams }) {
  const selectedAnalytics = params.analytics.map((a) => a.id)
  const id = params.analytics.find(
    (a) => a.id === analytic.id
  )?.dashboard_analytic_id

  const { mutate: destroy } = useDeleteDashboardAnalytic({
    id: id,
    options: {
      onSuccess: () => {
        setParams((params) => ({
          ...params,
          analytics: params.analytics.filter(
            (a) => a.id !== analytic.id
          )
        }))
      }
    }
  })

  const { mutate: create } = useCreateDashboardAnalytic({
    params: {
      dashboard_id: params.dashboardId,
      analytic_id: analytic.id,
      options: 'include'
    },
    options: {
      onSuccess: (res) => {
        setParams((params) => ({
          ...params,
          analytics: [
            ...params.analytics,
            {
              id: res.analytic_id,
              dashboard_analytic_id: res.id,
              dashboard_id: res.dashboard_id
            }
          ]
        }))
      }
    }
  })

  const handleChange = () => {
    if (selectedAnalytics.includes(analytic.id)) {
      destroy()
    } else {
      create()
    }
  }

  return (
    <React.Fragment key={`analytic-${analytic.id}`}>
      <ListGroup.Item
        action
        active={selectedAnalytics.includes(analytic.id) ? true : false}
        className={selectedAnalytics.includes(analytic.id) ? 'bg-light' : ''}
        onClick={() => {
          handleChange()
        }}
      >
        <span className="text-primary">{analytic.name}</span>
        {selectedAnalytics.includes(analytic.id) ? (
          <FontAwesomeIcon icon={faCheckCircle} className="text-primary ms-1" />
        ) : null}
        <div className="fw-light text-muted">{analytic.description}</div>
      </ListGroup.Item>
    </React.Fragment>
  )
}

export default CreateOrRemoveAnalytic
