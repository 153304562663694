import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import AuditLog from 'duties/overview/audit-log'

function AuditLogTab({ role }) {
  return (
    <Row>
      <Col sm={12}>
        <Card className="border">
          <Card.Header className="bg-white">
            <div className="text-info">Roles</div>
          </Card.Header>
          <Card.Body>
            <AuditLog roleId={role.id} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default AuditLogTab

