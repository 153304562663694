import React from 'react'
import PropTypes from 'prop-types'
import Table from 'react-bootstrap/Table'
import TableLoading from 'shared/tables/table-loading'
import TableFooter from 'shared/tables/table-footer'
import Badge from 'react-bootstrap/Badge'
import { useNavigate } from 'react-router-dom'
import SortArrow from 'shared/tables/sort-arrow'
import ConflictBadge from '../badges/conflict'
import TableError from 'shared/tables/table-error'
import useDuties from 'api/hooks/use-duties'
import InternalConflict from 'duties/badges/internal-conflict'

function RoleTable({ filterParams, setFilterParams }) {
  const navigate = useNavigate()

  const updateOrdering = (column) => {
    const isCurrentColumn = filterParams.orderBy === column
    const columnOrder = getColumnOrder(isCurrentColumn)

    setFilterParams({
      ...filterParams,
      orderBy: column,
      orderDirection: columnOrder,
      page: 1
    })
  }

  const getColumnOrder = (isCurrentColumn) => {
    if (isCurrentColumn) {
      return filterParams.orderDirection === 'asc' ? 'desc' : 'asc'
    } else {
      return 'desc'
    }
  }

  const {
    isSuccess,
    isLoading,
    isError,
    data: roles
  } = useDuties({ params: filterParams })

  return (
    <>
      <Table
        responsive
        hover
        size="sm"
        className="border shadow-sm bg-white"
        style={{ cursor: 'pointer' }}
      >
        <thead>
          <tr>
            <th colSpan="1" className="text-center border-left border-right">
              Role
            </th>
            <th colSpan="7" className="text-center border-right">
              Counts
            </th>
          </tr>
          <tr>
            <th
              className="border-left border-right"
              onClick={() => updateOrdering('name')}
              style={{ cursor: 'pointer' }}
            >
              Name
              <SortArrow
                column="name"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th style={{ width: '7%', cursor: 'pointer' }}>Status</th>
            <th
              style={{ width: '15%', cursor: 'pointer' }}
              onClick={() =>
                updateOrdering('role_sod_counts.internal_conflicts_count')
              }
            >
              Internal Conflicts
              <SortArrow
                column="role_sod_counts.internal_conflicts_count"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              style={{ width: '15%', cursor: 'pointer' }}
              onClick={() => updateOrdering('role_sod_counts.conflicts_count')}
            >
              Total Conflicts
              <SortArrow
                column="role_sod_counts.conflicts_count"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              style={{ width: '15%', cursor: 'pointer' }}
              onClick={() => updateOrdering('role_sod_counts.employees_count')}
            >
              Employees
              <SortArrow
                column="role_sod_counts.employees_count"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th style={{ width: '15%', cursor: 'pointer' }}>
              Functional Categories
            </th>
            <th
              style={{ width: '15%', cursor: 'pointer' }}
              onClick={() =>
                updateOrdering('role_sod_counts.high_risk_permissions_count')
              }
            >
              High Risk Permissions
              <SortArrow
                column="role_sod_counts.high_risk_permissions_count"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              style={{ width: '15%', cursor: 'pointer' }}
              onClick={() =>
                updateOrdering('role_sod_counts.superuser_permissions_count')
              }
            >
              Super User Permissions
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <TableLoading columns={6} />}
          {isSuccess &&
            roles.data.map((d) => (
              <tr
                key={`role-row-${d.id}`}
                className="border-right"
                onClick={() => navigate(`/roles/${d.id}`)}
              >
                <td>
                  {d.name}

                  {d.caption && <div className="text-muted">{d.caption}</div>}
                </td>
                <td className="text-center">
                  {d.status === 'Active' ? (
                    <Badge
                      pill
                      className="bg-light text-primary mx-1"
                      variant="light"
                    >
                      {' '}
                      Active
                    </Badge>
                  ) : (
                    <Badge
                      pill
                      className="bg-light text-info mx-1"
                      variant="light"
                    >
                      Inactive
                    </Badge>
                  )}
                </td>
                <td className="text-center">
                  {d.internal_conflicts > 0 && (
                    <InternalConflict count={d.internal_conflicts} />
                  )}
                </td>
                <td className="text-center">
                  {d.conflicts_count > 0 && (
                    <ConflictBadge count={d.conflicts_count} />
                  )}
                </td>
                <td className="text-center text-muted">{d.employees_count}</td>
                <td className="text-center text-muted">
                  {Object.keys(d.functional_categories || {}).length}
                </td>
                <td className="text-center">
                  <div className="text-muted">
                    {d.high_risk_permissions_count}
                  </div>
                </td>
                <td className="text-center">
                  <div className="text-muted">
                    {d.superuser_permissions_count}
                  </div>
                </td>
              </tr>
            ))}
          {isError && <TableError columns={8} />}
        </tbody>
      </Table>
      {isSuccess && (
        <TableFooter
          meta={roles.meta}
          params={filterParams}
          setParams={setFilterParams}
        />
      )}
    </>
  )
}

RoleTable.propTypes = {
  filterParams: PropTypes.object.isRequired,
  setFilterParams: PropTypes.func.isRequired
}

export default RoleTable
