import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getEmployeeDuties = async (token, params) => {
  const url = `employees/${params.id}/duties`

  const data = await apiGet({ url, token, params })
  return data
}

export default function useEmployeeDuties({ params, options }) {
  const token = useContext(AuthContext)
  const queryFn = () => getEmployeeDuties(token, params)
  const queryKey = ['employees-duties', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}

const getEmployeeDuty = async (token, params) => {
  const url = `employees/${params.employee_id}/duties/${params.analytic_id}`

  const data = await apiGet({ url, token, params })
  return data
}

export function useEmployeeDuty({ params, options }) {
  const token = useContext(AuthContext)
  const queryFn = () => getEmployeeDuty(token, params)
  const queryKey = ['employees-duty', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
