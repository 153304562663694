import React from 'react'
import { trackEvent } from '../../../../google-analytics'
import { useNavigate } from 'react-router-dom'

function EventLink({ linkObject, objectType }) {
  const url = `/${objectType}/${linkObject?.id}`
  const navigate = useNavigate()
  const onClick = () => {
    trackEvent({
      category: 'Audit Log',
      action: 'click',
      value: objectType
    })
    navigate(url)
  }

  if (linkObject) {
    return (
      <button
        rel="noreferrer"
        onClick={onClick}
        className='btn btn-link px-0 py-0.5'
      >
        {objectType === 'users' ? linkObject.external_id : linkObject.name}
      </button>
    )
  } else {
    return <span>Unknown {objectType}</span>
  }
}

export default EventLink
