import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getSegments = async (token, municipalityId, params) => {
  const url = `municipalities/${municipalityId}/segments`
  const data = await apiGet({ url, token, params })
  return data
}

export default function useSegments({ options, params }) {
  const { municipality_id } = useContext(UserContext)
  const token = useContext(AuthContext)
  const queryKey = ['segments', params]
  const queryFn = () => getSegments(token, municipality_id, params)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
