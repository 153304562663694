import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function SidebarLink({ path, title, icon }) {
  const currentPath = window.location.pathname

  return (
    <li
      className={`nav-item ${
        currentPath.includes(path) ? 'border-right border-primary' : ''
      }`}
    >
      <a
        className={`nav-link sod-nav-link ${
          currentPath.includes(path) ? 'active' : 'fw-light'
        }`}
        href={`/${path}`}
      >
        <FontAwesomeIcon
          className={currentPath.includes(path) ? '' : 'feather'}
          style={{ minWidth: '30' }}
          icon={icon}
        />{' '}
        {title}
      </a>
    </li>
  )
}

export default SidebarLink
