import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import RiskBadge from 'shared/badges/risk-badge'
import { truncate, isObject } from 'lodash'
import StatusBadge from 'shared/badges/status-badge'
import { noDigitMoney } from 'helpers/utils'

function VendorCard({ vendor }) {
  return (
    <Row>
      <Col sm={12}>
        <div className="align-middle">
          <div className="mb-2 small">
            <span className="text-primary fw-bold me-2">
              <FontAwesomeIcon icon={faBuilding} className="me-1" />
              Vendor
            </span>
            <span className="float-end">
              {isObject(vendor) && (
                <RiskBadge count={vendor.analytics_per_transaction} />
              )}
            </span>
          </div>
          {isObject(vendor) && (
            <div>
              <a href={`/vendors/${vendor.id}`} target="_blank" rel="noreferrer">
                {truncate(vendor.name, { length: 25 })}
              </a>
              <span className="text-muted fw-light ms-2">
                {vendor.is_pcard ? (
                  <span className="ms-2">
                    <StatusBadge
                      title="PCard"
                      icon={faCreditCard}
                      tooltipText="Employee generated from PCard file"
                    />
                  </span>
                ) : (
                  vendor.external_id
                )}
              </span>
            </div>
          )}
        </div>
        {vendor ? (
          <>
            <div className="mt-4">
              <span className="me-2 fw-bold">
                {noDigitMoney(vendor.total_transactions_amount)}
              </span>
              <span className="text-muted fw-light">Total Paid</span>
            </div>
            <div>
              <span className="me-2 fw-bold">
                {vendor.total_analytics.toLocaleString()}
              </span>
              <span className="text-muted fw-light">Total Flags</span>
            </div>
            <div>
              <span className="me-2 fw-bold">
                {vendor.total_transactions.toLocaleString()}
              </span>
              <span className="text-muted fw-light">Transactions</span>
            </div>
            <div>
              <span className="me-2 fw-bold">
                {Math.floor(
                  (10 * vendor.total_analytics) / vendor.total_transactions
                ) / 10}
              </span>

              <span className="text-muted fw-light">Flags / Transaction</span>
            </div>
          </>
        ) : (
          <div>
            <h5>No Vendor Data Found</h5>
          </div>
        )}
      </Col>
    </Row>
  )
}

VendorCard.propTypes = {
  vendor: PropTypes.object.isRequired
}

export default VendorCard
