import React, { useState } from 'react'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { split } from 'lodash'
import { useMutation } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { apiDelete } from 'api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import { redirect } from 'react-router-dom'
import ConfirmDelete from 'shared/confirm-delete'
import { useMole } from 'api/hooks/use-moles'
import MoleDetailTable from './detail-table'
import Card from 'react-bootstrap/Card'
import { Badge } from 'react-bootstrap'
import TransactionTable from 'transactions/table/index'
import Button from 'react-bootstrap/Button'
import EditModal from './edit-modal'
import EmptyPage from 'layout/empty-page'
import { DefaultError } from 'shared/default-error'
import { ErrorBoundary } from '@sentry/react'

function MoleShow() {
  const path = window.location.pathname
  const moleId = split(path, '/')[2]
  const { getAccessTokenSilently } = useAuth0()
  const [showEdit, setShowEdit] = useState(false)
  const [hoverEdit, setHoverEdit] = useState(false)

  const { isSuccess, isLoading, data: mole } = useMole({ id: moleId })

  const deleteMonitoring = useMutation(({ token, id }) => {
    const url = `moles/${id}`
    return apiDelete({ url, token })
  })

  const deleteMonitoringData = async (id) => {
    const token = await getAccessTokenSilently({ audience })
    deleteMonitoring.mutate({
      token,
      id
    })
  }

  const crumbs = [{ name: 'Monitoring', url: 'monitoring' }]

  const columns = [
    'processing_modal',
    'single-status',
    'transaction_on',
    'line_amount',
    'vendor',
    'employee'
  ]

  if (isLoading) {
    return <EmptyPage />
  }

  return (
    <Page
      title={
        <>
          {`Monitor: ${mole.analytic.name}`}
          <span className="me-3">
            <Button
              onMouseEnter={() => setHoverEdit(true)}
              onMouseLeave={() => setHoverEdit(false)}
              className={
                hoverEdit
                  ? 'btn btn-sm badge-warning border-warning ms-2'
                  : 'btn btn-sm btn-light border-secondary text-primary ms-2'
              }
              onClick={() => setShowEdit(!showEdit)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </span>
          <ConfirmDelete id={mole.id} handleDelete={deleteMonitoringData} />

          {deleteMonitoring.isSuccess ? redirect('/monitoring') : null}
        </>
      }
      pageTitle={`Monitoring - ${mole.analytic.name}`}
    >
      <PageTitle breadcrumbs={crumbs} />
      <Row>
        <Col>
          <ErrorBoundary
            fallback={DefaultError}
            beforeCapture={(scope) => {
              scope.setTag('component', 'homeTransactionTable')
            }}
          >
            <MoleDetailTable mole={mole} />
          </ErrorBoundary>
        </Col>
      </Row>

      {mole.reviews_count === 0 && (
        <Card>
          <Card.Body>
            <div className="mt-5 mx-auto align-middle d-flex justify-content-center">
              <div className="d-flex">
                <Badge pill className={'badge-success fw-normal fs-5'}>
                  <FontAwesomeIcon icon={faCheckDouble} /> Created
                </Badge>
              </div>
            </div>
            <div className="mb-5 mt-2 mx-auto align-middle d-flex justify-content-center">
              <div className="d-flex">
                <div className="text-muted text-center">
                  No transactions yet. Your first report will be emailed to{' '}
                  {mole.alert_user.email}
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      )}

      {isSuccess && mole.reviews_count > 0 && (
        <TransactionTable
          filters={['open', 'in-progress', 'exception']}
          orderBy={'transaction_on'}
          source={mole.name}
          sectionId={mole.section_id}
          columns={columns}
          moleId={moleId}
        />
      )}

      {showEdit && (
        <EditModal setShow={setShowEdit} show={showEdit} mole={mole} />
      )}
    </Page>
  )
}

export default MoleShow
