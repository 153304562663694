import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getSodGlobalSearch = async (municipality_id, params, token) => {
  const url = `municipalities/${municipality_id}/search`

  const data = await apiGet({ url, params, token })
  return data
}

export function useSodGlobalSearch({ params, options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const queryFn = () => getSodGlobalSearch(municipality_id, params, token)
  const queryKey = ['search', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
