import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

type SortArrowProps = {
  column: string,
  orderBy: string,
  orderDirection: string
}

function SortArrow({ column, orderBy, orderDirection }: SortArrowProps) {
  const sameColumn = orderBy === column

  const determineIcon = () => {
    if (sameColumn) {
      return orderDirection === 'desc' ? faSortDown : faSortUp
    } else {
      return faSort
    }
  }

  return (
    <FontAwesomeIcon
      icon={determineIcon()}
      className={`${sameColumn ? 'text-primary' : 'text-info'} float-end`}
    />
  )
}

export default SortArrow
