import { useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { apiPost, apiDelete } from 'api'
import AuthContext from 'context/auth-context'
import { toast } from 'react-toastify'

const createSavedTransaction = async (token, params) => {
  const objectName = 'saved_transaction'
  const url = `saved_transactions`

  apiPost({ url, token, objectName, params })
}

export function useCreateSavedTransaction({ params }) {
  const queryClient = useQueryClient()
  const token = useContext(AuthContext)
  return useMutation(
    () => {
      return createSavedTransaction(token, params)
    },
    {
      onSuccess: () => queryClient.invalidateQueries('transactions')
    }
  )
}

const destroySavedTransaction = async (token, id) => {
  const url = `saved_transactions/${id}`

  apiDelete({ url, token })
}

export function useDestroySavedTransaction({ id }) {
  const queryClient = useQueryClient()
  const token = useContext(AuthContext)
  return useMutation(
    () => {
      return destroySavedTransaction(token, id)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('transactions')
        toast.warn('Transaction was removed from Saved', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined
        })
      }
    }
  )
}
