import React from 'react'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDotCircle,
  faRetweet,
  faExclamation,
  faCheck
} from '@fortawesome/free-solid-svg-icons'

function ReviewStatusColumn({ transaction }) {
  return (
    <>
      <Badge
        className="border border-secondary me-2 bg-white text-primary"
        style={{ fontSize: '90%' }}
        pill
      >
        {transaction.review_summary.open_count === 1 && (
          <span>
            <FontAwesomeIcon icon={faDotCircle} className="text-info me-1" />
            Open
          </span>
        )}
        {transaction.review_summary.follow_up_count === 1 && (
          <span>
            <FontAwesomeIcon icon={faRetweet} className="text-warning me-1" />
            In Progress
          </span>
        )}
        {transaction.review_summary.exception_count === 1 && (
          <span>
            <FontAwesomeIcon
              icon={faExclamation}
              className="text-danger me-1"
            />
            Exception
          </span>
        )}
        {transaction.review_summary.no_exception_count === 1 && (
          <span>
            <FontAwesomeIcon icon={faCheck} className="text-success me-1" /> No
            Exception
          </span>
        )}
      </Badge>
    </>
  )
}

export default ReviewStatusColumn
