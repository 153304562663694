import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearchDollar,
  faChalkboardTeacher,
  faTachometerAlt,
  faChartLine,
  faStopwatch,
  faFileInvoice,
  faUnlockAlt
} from '@fortawesome/free-solid-svg-icons'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

function TagIcon({ tagName, tagDescription }) {
  const determineIcon = (tagName) => {
    if (tagName === 'Audit') {
      return <FontAwesomeIcon icon={faFileInvoice} />
    } else if (tagName === 'Fraud') {
      return <FontAwesomeIcon icon={faSearchDollar} />
    } else if (
      ['EmployeeTraining', 'ApproverTraining', 'AdminTraining'].includes(
        tagName
      )
    ) {
      return <FontAwesomeIcon icon={faChalkboardTeacher} />
    } else if (tagName === 'RiskAssessment') {
      return <FontAwesomeIcon icon={faTachometerAlt} />
    } else if (tagName === 'Timeliness') {
      return <FontAwesomeIcon icon={faStopwatch} />
    } else if (tagName === 'Control') { 
      return <FontAwesomeIcon icon={faUnlockAlt} />
    } else if (tagName === 'KPI') {
      return <FontAwesomeIcon icon={faChartLine} />
    }
  }

  const condenseName = (tagName) => {
    if (
      ['EmployeeTraining', 'ApproverTraining', 'AdminTraining'].includes(
        tagName
      )
    ) {
      return 'Training'
    } else if (tagName === 'RiskAssessment') {
      return 'Risk'
    } else if (tagName === 'Timeliness') {
      return 'Time'
    } else {
      return tagName
    }
  }

  return (
    <OverlayTrigger
      key={`tag-${tagName}`}
      placement="bottom"
      trigger={['hover', 'focus']}
      overlay={<Tooltip id={'tooltip'}>{tagDescription || tagName}</Tooltip>}
    >
      <Badge
        pill
        className="border border-light text-primary bg-white"
        style={{ cursor: 'help' }}
      >
        {determineIcon(tagName)} {condenseName(tagName)}
      </Badge>
    </OverlayTrigger>
  )
}

TagIcon.propTypes = {
  tagName: PropTypes.string.isRequired,
  tagDescription: PropTypes.string
}

export default TagIcon
