import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getEmployeeAnalyticCounts = async (token, employeeId) => {
  const url = `employees/${employeeId}/counts`
  const data = await apiGet({ url, token })
  return data
}

export default function useEmployeeAnalyticCounts({ params, options }) {
  const token = useContext(AuthContext)
  const queryFn = () =>
    getEmployeeAnalyticCounts(token, params.employeeId)
  const queryKey = `employees/${params.employeeId}/counts`
  return useQuery(queryKey, queryFn, { ...options })
}
