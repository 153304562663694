import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import TagIcon from 'tag/icon'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard, faCheck } from '@fortawesome/free-solid-svg-icons'
import { toast, Flip } from 'react-toastify'

function DescriptionCard({ analytic }) {
  const [active, setActive] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const copiedAlert = (message) => {
    setIsCopied(true)
    toast.info(`Copied ${message} to clipboard`, {
      position: 'top-center',
      autoClose: 1000,
      transition: Flip,
      hideProgressBar: true,
      progress: undefined,
      theme: 'light'
    })
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <span className="fw-bold">Module</span>
              <div className="fw-light mt-2">{analytic.section_name}</div>
            </Col>
            <Col>
              <span className="fw-bold">Tags</span>
              <div className="pt-2">
                {analytic.tags.map((t) => (
                  <span
                    className="me-1"
                    key={`analytic-${analytic.id}-tag-${t.id}`}
                  >
                    <TagIcon tagName={t.name} tagDescription={t.description} />
                  </span>
                ))}
              </div>
            </Col>
          </Row>
          <div
            onClick={() => {
              navigator.clipboard.writeText(analytic.description)
              copiedAlert('analytic description')
            }}
            className={active ? 'mt-4 bg-light rounded text-primary' : 'mt-4'}
            style={{ cursor: 'pointer' }}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
          >
            <div className="fw-bold">
              Description
              {isCopied && (
                <small className="text-success fw-lighter">
                  <FontAwesomeIcon icon={faCheck} className="ms-1" /> Copied
                </small>
              )}
              {!isCopied && active && (
                <small className="text-muted fw-lighter">
                  <FontAwesomeIcon icon={faClipboard} className="ms-1" /> Copy
                </small>
              )}
            </div>
            <div className="fw-light mt-2">{analytic.description}</div>
          </div>
          {analytic.fraud_tags.length > 0 && (
            <>
              <div className="fw-bold mt-4">Possible Fraud Schemes</div>
              <div className="pt-2">
                {analytic.fraud_tags.map((t) => (
                  <span
                    className="me-1"
                    key={`analytic-${analytic.id}-tag-${t.id}`}
                  >
                    <TagIcon tagName={t.name} tagDescription={t.description} />
                  </span>
                ))}
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default DescriptionCard
