import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ModuleIconCard from './module-card-icon'
import useSections from 'api/hooks/use-sections'

function ModulePicker({ setParams, params, limitModules = [] }) {
  const { isSuccess: isSectionsSuccess, data: sections } = useSections({})
  const allSections = isSectionsSuccess ? sections : []

  const sectionsFiltered =
    limitModules.length > 0
      ? allSections.filter((s) => {
          return limitModules.includes(s.abbreviation)
        })
      : allSections

  return (
    <Row>
      {isSectionsSuccess &&
        sectionsFiltered.map((s) => (
          <Col sm={3} key={`section-card-${s.id}`}>
            <div className="my-2">
              <ModuleIconCard
                section={s}
                params={params}
                setParams={setParams}
              />
            </div>
          </Col>
        ))}
    </Row>
  )
}

export default ModulePicker
