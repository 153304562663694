import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Pagination from 'react-bootstrap/Pagination'

type TableFooterProps = {
  params: {
    page: number,
    perPage: number
  },
  setParams: Function,
  meta: {
    pages: number,
    title: string,
  },
  disableJump?: boolean
}

function TableFooter({
  meta,
  params,
  setParams,
  disableJump = false
}: TableFooterProps) {
  const setPage = (p: number) => {
    setParams({ ...params, page: p })
  }
  const setPerPage = (p: number) => {
    setParams({ ...params, perPage: p })
  }

  const { perPage, page } = params

  return (
    <Row>
      <Col xs="1">
        <Form.Group>
          <Form.Select
            as="select"
            value={perPage}
            onChange={(e) => setPerPage(parseInt(e.target.value))}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </Form.Group>
      </Col>
      <Col xs="2">
        <Form.Label className="fw-light mt-1 me-2">Per Page</Form.Label>
      </Col>

      <Col xs="6">
        <Pagination className="mx-auto" style={{ width: '250px' }}>

          <Pagination.Prev
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
          >Previous</Pagination.Prev>
          {page > 1 && (
            <Pagination.Item onClick={() => setPage(page - 1)}>
              {page - 1}
            </Pagination.Item>
          )}
          <Pagination.Item active>{page}</Pagination.Item>
          <Pagination.Item onClick={() => setPage(page + 1)}>
            {page + 1}
          </Pagination.Item>
          <Pagination.Next
            onClick={() => setPage(page + 1)}
          >Next</Pagination.Next>
        </Pagination>
      </Col>

      <Col xs="3">
      </Col>
    </Row>
  )
}

export default TableFooter
