import React, { useState } from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Table from 'react-bootstrap/Table'
import TableLoading from 'shared/tables/table-loading'
import Button from 'react-bootstrap/Button'
import { useMoles } from 'api/hooks/use-moles'
import { useNavigate } from 'react-router-dom'
import { formatDateSlash } from 'helpers/datetime'
import { startCase } from 'lodash'
import FlagCount from 'shared/flag-count'
import NewReviewBadge from 'shared/badges/new-review-badge'
import TableFooter from 'shared/tables/table-footer'
import TableError from 'shared/tables/table-error'

function MolesTable() {
  const navigate = useNavigate()
  const defaultParams = {
    page: 1,
    perPage: 10
  }
  const [params, setParams] = useState(defaultParams)
  const { isSuccess, isLoading, isError, data: moles } = useMoles({ params })

  return (
    <>
      <Table responsive hover className="border shadow-sm mt-2 bg-white">
        <thead>
          <tr>
            <th>Analytic Name</th>
            <th>Module</th>
            <th>Created On</th>
            <th>Alert</th>
            <th>Frequency</th>
            <th>Flags to Date</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <TableLoading columns={5} />}
          {isError && <TableError columns={5} />}
          {isSuccess && moles.data.length === 0 && (
            <tr>
              <td colSpan="6" className="text-center py-4">
                <div className="mb-2 h6">No monitoring</div>
                <div className="text-muted fw-lighter mb-3">
                  Get started by creating a continuous monitoring report
                </div>
                <Button
                  variant="primary"
                  className="me-2"
                  href="/monitoring-new"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/monitoring-new')
                  }}
                  size="sm"
                >
                  <FontAwesomeIcon icon={faPlus} className="text-warning" /> New
                  Monitor
                </Button>
              </td>
            </tr>
          )}
          {isSuccess &&
            moles.data.map((m) => (
              <tr
                key={`transaction-row-${m.id}`}
                onClick={() => navigate(`/monitoring/${m.id}`)}
                style={{ cursor: 'pointer' }}
              >
                <td>
                  {m.analytic.name}
                  {m.new_reviews > 0 && (
                    <NewReviewBadge count={m.new_reviews} />
                  )}
                </td>
                <td className="align-middle">{m.section.name}</td>
                <td className="align-middle">
                  {formatDateSlash(m.created_at)}
                </td>
                <td className="align-middle">{m.alert_user.email}</td>
                <td className="align-middle">{startCase(m.frequency)}</td>
                <td className="align-middle">
                  <FlagCount count={m.reviews_count || 0} />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {isSuccess && (
        <TableFooter meta={moles.meta} params={params} setParams={setParams} />
      )}
    </>
  )
}

export default MolesTable
