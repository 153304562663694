import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import AnnotationsModule from 'highcharts/modules/annotations'
import moment from 'moment'
import { noDigitMoney } from 'helpers/utils'

AnnotationsModule(Highcharts)

function CumulativeSpend({ spend, title }) {
  const data = spend.map((s) => {
    return [moment(s.transaction_on).valueOf(), parseFloat(s.cumulative_spend)]
  })

  const options = {
    chart: {
      zoomType: 'x'
    },
    title: {
      text: title
    },
    subtitle: {
      text:
        document.ontouchstart === undefined
          ? 'Click and drag in the plot area to zoom in'
          : 'Pinch the chart to zoom in'
    },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: function () {
          return Highcharts.dateFormat("%b'%y", this.value)
        }
      },
      startOnTick: true,
      endOnTick: true
    },
    yAxis: {
      title: {
        text: 'Total Amount Invoiced'
      }
    },
    tooltip: {
      shared: true,
      formatter: function () {
        return (
          'Cumulative Invoiced of <strong> ' +
          noDigitMoney(this.y) +
          '</strong> as of ' +
          moment(this.x).format('MMM Do, YYYY') +
          '<br/>'
        )
      }
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      area: {
        marker: {
          radius: 2
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        threshold: null
      }
    },
    annotations: [
      {
        labels: [
          {
            align: 'left',
            point: '0'
          },
          {
            align: 'right',
            point: '1'
          },
          {
            verticalAlign: 'top',
            point: '2'
          },
          {
            distance: 20,
            point: '4'
          }
        ],
        labelOptions: {
          point: '1',
          y: 0,
          allowOverlap: true
        }
      }
    ],
    series: [
      {
        type: 'area',
        name: 'Cumulative Invoice Amounts',
        data: data,
        color: '#27AB83',
        opacity: 0.75
      }
    ]
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

CumulativeSpend.propTypes = {
  spend: PropTypes.array.isRequired
}

export default CumulativeSpend
