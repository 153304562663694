import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import AmountFilter from './amount'
import DateFilter from './date'


function MoreFilters({ filterParams, enabledFilters, setFilterParams, setActiveOverlay }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => {
    setActiveOverlay('')
    setShow(true)
  }
  const dateApplied = filterParams.startOn !== '' || filterParams.endOn !== ''
  const amountApplied =
    filterParams.minimumAmount !== '' || filterParams.maximumAmount !== ''
  const accountApplied =
    filterParams.segOne !== '' ||
    filterParams.segTwo !== '' ||
    filterParams.segThree !== '' ||
    filterParams.segFour !== '' ||
    filterParams.segFive !== ''
  const isFilterApplied = dateApplied || amountApplied || accountApplied

  return (
    <React.Fragment>
      <Button
        className={
          isFilterApplied ? 'bg-white text-primary' : 'bg-white text-info'
        }
        variant={isFilterApplied ? 'outline-primary' : ''}
        style={{ border: isFilterApplied ? '' : '1px solid #ced4da' }}
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faFilter} /> More
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="text-center" closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <AmountFilter
              filterParams={filterParams}
              setFilterParams={setFilterParams}
            />
            <hr className="my-4 border-light" />
            <DateFilter
              filterParams={filterParams}
              setFilterParams={setFilterParams}
            />
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default MoreFilters
