import React from 'react'
import NumberField from 'shared/forms/NumberField'

function ReqToPoCreation({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="RQ to PO Creation (Days)"
      showTitle={true}
      accessorKey="poMinReqToPoCreation"
    />
  )
}

export default ReqToPoCreation
