import React from 'react'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import ExportTable from './table'

function Exports() {
  return (
    <Page title="Exports">
      <PageTitle />
      <ExportTable />
    </Page>
  )
}

export default Exports
