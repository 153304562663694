import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getMoles = async (token, userId, params) => {
  const url = `users/${userId}/moles`

  const data = await apiGet({ url, params, token })
  return data
}

export function useMoles({ params, options }) {
  const token = useContext(AuthContext)
  const { id } = useContext(UserContext)
  const queryFn = () => getMoles(token, id, params)
  const queryKey = ['moles', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}

const getMole = async (token, id, params) => {
  const url = `moles/${id}`

  const data = await apiGet({ url, params, token })
  return data
}

export function useMole({ params, id, options }) {
  const token = useContext(AuthContext)
  const queryFn = () => getMole(token, id, params)
  const queryKey = [`mole/${id}`, params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
