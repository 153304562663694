import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import TableFooter from 'shared/tables/table-footer'
import TableLoading from 'shared/tables/table-loading'
import HighRiskPermissionBadge from 'duties/badges/high-risk-permission'
import SuperUserPermissionBadge from 'duties/badges/superuser-permission'
import { useRolePermissions } from 'api/hooks/use-role-permissions'
import Category from './category'

function PermissionsTab({ role }) {
  const urlParams = new URLSearchParams(window.location.search)
  const category = urlParams.get('category')

  const defaultParams = { 
    role_id: role.id,
    is_high_risk: false,
    is_super_user: false,
    is_expansive: true,
    category: '',
    page: 1,
    perPage: 25
  }
  
  useEffect(() => {
    setParams((params) => ({ ...params, category: category || '' }))
  }, [category])

  const [params, setParams] = useState(defaultParams)
  const { data, isSuccess, isLoading } = useRolePermissions({ params })

  return (
    <Card className="border">
      <Card.Header className="bg-white">
        <Row>
          <Col sm="auto">
            <div className="text-info">Permissions</div>
          </Col>
          <Col sm="auto">
            <Category params={params} setParams={setParams} role={role} />
          </Col>

          <Col sm="auto">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="show-non-zero-values"
                onChange={(e) =>
                  setParams({ ...params, is_expansive: e.target.checked })
                }
                checked={params.is_expansive}
              />
              <label className="form-check-label" for="show-non-zero-values">
                Show "On" Values Only
              </label>
            </div>
          </Col>
          <Col sm="auto">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="show-high-risk-only"
                onChange={(e) =>
                  setParams({ ...params, is_high_risk: e.target.checked })
                }
                checked={params.is_high_risk}
              />
              <label className="form-check-label" for="show-high-risk-only">
                Show High Risk Only
              </label>
            </div>
          </Col>
          <Col sm="auto">
            <div className="form-check form-switch ms-4">
              <input
                className="form-check-input"
                type="checkbox"
                id="show-super-only"
                onChange={(e) =>
                  setParams({ ...params, is_super_user: e.target.checked })
                }
                checked={params.is_super_user}
              />
              <label className="form-check-label" for="show-high-risk-only">
                Show Super User Only
              </label>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Category</th>
              <th>Description</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && <TableLoading columns={4} />}
            {isSuccess &&
              data.data.map((rp) => (
                <tr key={`permissions-row-${rp.id}`}>
                  <td>
                    {rp.permission.name}
                    {rp.permission.is_high_risk && (
                      <HighRiskPermissionBadge count={1} displayCount={false} />
                    )}
                    {rp.permission.is_superuser && (
                      <SuperUserPermissionBadge
                        count={1}
                        displayCount={false}
                      />
                    )}
                  </td>
                  <td>
                    <div className="fw-light text-muted">
                      {rp.permission.category}
                    </div>
                  </td>
                  <td>{rp.permission.description}</td>
                  <td>{rp.permission_value}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        {isSuccess && (
          <TableFooter
            meta={data.meta}
            params={params}
            setParams={setParams}
            total={data.data.length}
          />
        )}
      </Card.Body>
    </Card>
  )
}

export default PermissionsTab
