import React, { useContext } from 'react'
import { ListGroup } from 'react-bootstrap'
import { useCreateDashboardVendor } from 'api/hooks/use-dashboard-vendors'
import VendorAttribute from 'shared/badges/vendor-attribute'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone } from '@fortawesome/free-regular-svg-icons'
import { useQueryClient } from 'react-query'
import UserContext from 'context/user-context'

function VendorItem({ vendor, params, dashboardId, addOrRemoveVendor }) {
  const queryClient = useQueryClient()
  const { municipality } = useContext(UserContext)
  const queryParams = {
    vendor_id: vendor.id,
    dashboard_id: params.dashboardId,
    options: vendor.options
  }
  const { mutate } = useCreateDashboardVendor({
    params: queryParams,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(`dashboards/${dashboardId}`)
      }
    }
  })

  return (
    <ListGroup.Item
      action
      key={`vendor-option-${vendor.id}`}
      active={params.vendors.includes(vendor.id) ? true : false}
      className={params.vendors.includes(vendor.id) ? 'py-2 bg-light' : 'py-2'}
      onClick={() => {
        addOrRemoveVendor(vendor)
        mutate()
      }}
    >
      {vendor.name}{' '}
      <small className="text-muted">(ID: {vendor.external_id})</small>
      {vendor.status && (
        <span className="text-muted ms-2">{vendor.status}</span>
      )}
      <div className="mt-1">
        {vendor.recent_payment_change && (
          <VendorAttribute
            title="Recent Payment Change"
            tooltipText={`Vendor payment method changed in the last 30 days: ${vendor.last_payment_method_change_on}`}
          />
        )}
        {vendor.stale && (
          <VendorAttribute
            title="Stale"
            tooltipText={`Vendor has not sent invoice or recieved a check in ${municipality.stale_vendor_months} months. Last Invoice: ${vendor.last_invoice_on} Last Check: ${vendor.last_check_on}`}
          />
        )}
        {vendor.po_box && (
          <VendorAttribute
            title="PO Box"
            tooltipText={'Vendor has a PO Box address'}
          />
        )}
        {vendor.one_eight_hundred && (
          <VendorAttribute
            title="1-800"
            tooltipText={'Vendor has an 800 number'}
          />
        )}
        {vendor.is_duplicate_ein && (
          <VendorAttribute
            title={
              <span>
                <FontAwesomeIcon icon={faClone} /> EIN
              </span>
            }
            tooltipText={`Vendor has a duplicated EIN with another active vendor: ${vendor.duplicate_ein_vendors
              .split('||')
              .join(', ')}`}
          />
        )}
        {vendor.is_duplicate_ein_being_paid && (
          <VendorAttribute
            title={
              <span>
                <FontAwesomeIcon icon={faClone} /> EIN $
              </span>
            }
            tooltipText={`Multiple Vendors with the same EIN have been paid in the past year: ${vendor.duplicate_ein_being_paid_vendors
              .split('||')
              .join(', ')}`}
          />
        )}
      </div>
    </ListGroup.Item>
  )
}

export default VendorItem