import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import RiskChangeTable from './risk-change-table'
import { capitalize } from 'lodash'

function RiskAssessment() {
  const [currentTab, setCurrentTab] = useState('employee')

  return (
    <Page title="Continuous Risk Assessment">
      <PageTitle />

      <Row className="mt-2 pb-4">
        <Col>
          {['employee', 'vendor', 'module'].map((t) => (
            <span
              key={`tab-${t}`}
              className={`mx-2 ${
                currentTab === t
                  ? 'fw-bold text-primary border-bottom border-primary border-3 pb-2'
                  : 'text-muted'
              }`}
              style={{ cursor: 'pointer' }}
              onClick={() => setCurrentTab(t)}
            >
              {capitalize(t)}
            </span>
          ))}
        </Col>
      </Row>
      <Row>
        {currentTab === 'module' && (
          <RiskChangeTable currentTab={currentTab} groupBy="section" />
        )}
        {currentTab === 'employee' && (
          <RiskChangeTable currentTab={currentTab} groupBy="employee" />
        )}
        {currentTab === 'vendor' && (
          <RiskChangeTable currentTab={currentTab} groupBy="vendor" />
        )}
        {false && currentTab === 'account' && (
          <RiskChangeTable currentTab={currentTab} groupBy="account_code" />
        )}
      </Row>
    </Page>
  )
}

export default RiskAssessment
