import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faArchive } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import useReports from 'api/hooks/use-reports'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReportStatusBadge from './status-badge'
import ConfirmDelete from 'shared/confirm-delete'
import { useMutation, useQueryClient } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { apiDelete, apiPatch } from 'api'
import AnalyticsShield from 'shared/analytics-shield'
import TableFooter from 'shared/tables/table-footer'
import TableLoading from 'shared/tables/table-loading'
import TableError from 'shared/tables/table-error'
import ShareButton from './share-button'
import { useNavigate } from 'react-router-dom'

function Reports() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [mouseOver, setMouseOver] = useState('')
  const defaultParams = { perPage: 10, page: 1, show_archived: false }
  const [params, setParams] = useState(defaultParams)
  const {
    isSuccess,
    isLoading,
    isError,
    data: reports
  } = useReports({ params })
  const { getAccessTokenSilently } = useAuth0()

  const deleteReport = useMutation(({ token, id }) => {
    const url = `audit_reports/${id}`
    return apiDelete({ url, token })
  })

  const deleteReportData = async (id) => {
    const token = await getAccessTokenSilently({ audience })
    deleteReport.mutate(
      {
        token,
        id
      },
      { onSuccess: () => queryClient.invalidateQueries(['reports']) }
    )
  }

  const updateReport = async (id) => {
    const token = await getAccessTokenSilently({ audience })
    const params = {
      id: id,
      status: 'archived'
    }
    updateReportMutation.mutate(
      {
        params,
        token
      },
      { onSuccess: () => queryClient.invalidateQueries(['reports']) }
    )
  }

  const updateReportMutation = useMutation(({ token, params }) => {
    const objectName = 'audit_report'
    const url = `audit_reports/${params.id}`
    return apiPatch({ url, token, objectName, params })
  })

  return (
    <Page
      title="Samples"
      subtitle={
        <Button
          variant="primary"
          size="sm"
          className="ms-2"
          href="/samples/new"
          onClick={(e) => {
            e.preventDefault()
            navigate('/samples/new')
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="text-warning" /> New Samples
        </Button>
      }
    >
      <PageTitle />
      <Row>
        <Col>
          <Button
            variant={mouseOver === 'open' ? 'default' : 'white'}
            onMouseEnter={() => setMouseOver('open')}
            onMouseLeave={() => setMouseOver('')}
            className={
              params.show_archived
                ? 'border-dark float-end'
                : 'fw-light float-end'
            }
            onClick={() =>
              setParams({ ...params, show_archived: !params.show_archived })
            }
          >
            <FontAwesomeIcon
              icon={faArchive}
              className={params.show_archived ? 'text-primary' : 'text-muted'}
            />{' '}
            Show Archived
          </Button>
        </Col>
      </Row>
      <Table
        size="sm"
        className="bg-white shadow-sm"
        style={{ border: '1px solid rgb(222 226 230)' }}
      >
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Status</th>
            <th>Owner</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {isLoading && <TableLoading columns={5} />}
          {isError && <TableError columns={5} />}
          {isSuccess && reports.data.length === 0 && (
            <tr>
              <td colSpan="9" className="text-center py-4">
                <div className="mb-2 h6">No Samples</div>
                <div className="text-muted fw-lighter mb-3">
                  Get started by creating a Sample
                </div>
                <Button
                  variant="primary"
                  className="me-2"
                  href="/samples/new"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/samples/new')
                  }}
                  size="sm"
                >
                  <FontAwesomeIcon icon={faPlus} className="text-warning" /> New
                  Sample
                </Button>
              </td>
            </tr>
          )}
          {isSuccess &&
            reports.data.map((r) => (
              <tr key={`report-row-${r.id}`}>
                <td></td>
                <td>
                  <a
                    href={`samples/${r.id}`}
                    className="me-2"
                    onClick={(e) => {
                      e.preventDefault()
                      navigate(`/samples/${r.id}`)
                    }}
                  >
                    {r.name}
                  </a>{' '}
                  <AnalyticsShield
                    count={r.analytics_count}
                    analytics={r.analytics}
                  />
                  <ShareButton report={r} />
                </td>
                <td>
                  <ReportStatusBadge status={r.status} />
                </td>
                <td>
                  {r.owner.first_name} {r.owner.last_name}
                </td>
                <td>
                  {r.status === 'archived' && (
                    <ConfirmDelete
                      id={r.id}
                      handleDelete={deleteReportData}
                      extraConfirm={true}
                    />
                  )}
                  {r.status !== 'deleting' && r.status !== 'archived' && (
                    <OverlayTrigger
                      key={`tooltip-archive-${r.id}`}
                      placement="top"
                      overlay={<Tooltip>Archive Report</Tooltip>}
                    >
                      <Button
                        variant="default"
                        size="sm"
                        onClick={() => updateReport(r.id)}
                      >
                        <FontAwesomeIcon
                          icon={faArchive}
                          className="text-primary"
                        />
                      </Button>
                    </OverlayTrigger>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {isSuccess && (
        <TableFooter
          meta={reports.meta}
          params={params}
          setParams={setParams}
        />
      )}
    </Page>
  )
}

export default Reports
