import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useDeleteDashboardVendor } from 'api/hooks/use-dashboard-vendors'
import { useQueryClient } from 'react-query'

function DeleteVendor({ addOrRemoveVendor, vendor, dashboardId}) {
  const queryClient = useQueryClient()
  const { mutate } = useDeleteDashboardVendor({
    id: vendor.dashboard_vendor_id,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `dashboards/${dashboardId}`
        )
      }
    }
  })

  return (
    <button
      className="btn btn-sm btn-link text-danger"
      onClick={() => {
        addOrRemoveVendor(vendor)
        mutate()
      }}
    >
      <FontAwesomeIcon icon={faTrash} />
    </button>
  )
}

export default DeleteVendor