import React, { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { useQueryClient } from 'react-query'
import { useUpdateMunicipalityFeature } from 'api/hooks/use-municipality-features'
import { useEffect } from 'react'
import UserContext from 'context/user-context'

function EditFeature({ oldFeature }) {
  const [show, setShow] = useState(false)
  const [name, setName] = useState(oldFeature?.name || '')
  const [description, setDescription] = useState(oldFeature?.description || '')
  const [criteria, setCriteria] = useState(oldFeature?.criteria || '')
  const queryClient = useQueryClient()
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const { municipality } = useContext(UserContext)

  const params = {
    id: oldFeature.id,
    name: name,
    description: description,
    criteria: criteria
  }

  const queryOptions = {
    onSuccess: () => {
      setTimeout(function () {
        queryClient.invalidateQueries('municipality-features')
      }, 500)
    }
  }

  const { isSuccess, mutate } = useUpdateMunicipalityFeature({
    params,
    options: queryOptions
  })

  useEffect(() => {
    if (isSuccess) {
      handleClose()
    }
  }, [isSuccess, queryClient])

  return (
    <React.Fragment>
      <Button variant="light" size="sm" onClick={handleShow}>
        <FontAwesomeIcon className="text-info" icon={faEdit} />
      </Button>{' '}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Attribute {municipality.abbreviation}-{oldFeature.key}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-3">
            <Col sm={2}>
              <span className="mt-1 text-primary">Name</span>
            </Col>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Attribute should be in the form of a yes / no question"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={2}>
              <span className="mt-1 text-primary">Criteria</span>
            </Col>
            <Col sm={10}>
              <Form.Control
                type="text"
                value={criteria}
                placeholder="Name the rule/policy/other source for this attribute"
                onChange={(e) => setCriteria(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={2}>
              <span className="mt-1 text-primary">Description</span>
            </Col>
            <Col sm={10}>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  rows="5"
                  required
                  value={description}
                  placeholder="Description of the conditions that result in a yes or no"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="float-end"
            variant="primary"
            disabled={name.length === 0}
            onClick={() => mutate()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default EditFeature
