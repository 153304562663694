import React, { useContext } from 'react'
import UserContext from 'context/user-context'
import EditFeature from './edit'

function FeatureRow({ feature }) {
  const { municipality } = useContext(UserContext)
  return (
    <tr>
      <td>
        {municipality.abbreviation}-{feature.key}
      </td>
      <td>{feature.name}</td>
      <td>{feature.criteria}</td>
      <td>
        <EditFeature oldFeature={feature} />
      </td>
    </tr>
  )
}

export default FeatureRow
