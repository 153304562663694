import CheckboxField from 'shared/forms/CheckboxField'

function RecentPaymentChange({ params, setParams }) {
  return (
    <>
      <CheckboxField
        params={params}
        setParams={setParams}
        accessorKey="recentPaymentChange"
        label="Recent Payment Change"
        helperText="Vendor has had a change in payment method in the past 30 days"
      />
    </>
  )
}

export default RecentPaymentChange
