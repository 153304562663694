import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

function VendorChange() {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>Change to the Vendor</Tooltip>}
    >
      <Badge
        pill
        style={{ cursor: 'pointer' }}
        className={'border border-warning me-1 text-body bg-white fw-lighter'}
        data-testid={'vendor-change-badge'}
      >
        <FontAwesomeIcon icon={faWrench} className='text-primary' /> Vendor
      </Badge>
    </OverlayTrigger>
  )
}

export default VendorChange
