import React from 'react'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

function InternalConflict({ count, textDisplay = '' }) {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <div className="tooltip">
          <div className="tooltip-arrow" />
          <div className="tooltip-inner">
            Conflict internal to the role.
          </div>
        </div>
      }
    >
      <Badge pill className="text-danger badge-danger">
        <FontAwesomeIcon icon={faExclamationTriangle} className="me-1" />
        {textDisplay ? textDisplay : count}
      </Badge>
    </OverlayTrigger>
  )
}

export default InternalConflict
