import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard, faCheck } from '@fortawesome/free-solid-svg-icons'
import { toast, Flip } from 'react-toastify'
import { calculatePercent } from 'helpers/utils'

function DescriptionCard({ conflict }) {
  const [active, setActive] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const copiedAlert = (message) => {
    setIsCopied(true)
    toast.info(`Copied ${message} to clipboard`, {
      position: 'top-center',
      autoClose: 1000,
      transition: Flip,
      hideProgressBar: true,
      progress: undefined,
      theme: 'light'
    })
  }

  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <span className="fw-bold">Functional Area</span>
                  <div className="fw-light mt-2">{conflict.functional_area}</div>
                </Col>
              </Row>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(conflict.description)
                  copiedAlert('analytic description')
                }}
                className={
                  active ? 'mt-4 bg-light rounded text-primary' : 'mt-4'
                }
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setActive(true)}
                onMouseLeave={() => setActive(false)}
              >
                <div className="fw-bold">
                  Description
                  {isCopied && (
                    <small className="text-success fw-lighter">
                      <FontAwesomeIcon icon={faCheck} className="ms-1" /> Copied
                    </small>
                  )}
                  {!isCopied && active && (
                    <small className="text-muted fw-lighter">
                      <FontAwesomeIcon icon={faClipboard} className="ms-1" />{' '}
                      Copy
                    </small>
                  )}
                </div>
                <div className="fw-light mt-2">{conflict.description}</div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body className="bg-white">
              <Row>
                <Col className="border-end border-1 border-dark">
                  <div>
                    <h5>{conflict.employees_count}</h5>
                    <span className="text-muted float-end fw-light text-sm">
                      {calculatePercent(
                        conflict.employees_count,
                        conflict.total_employees_count
                      )}
                      %
                    </span>
                    <Card.Text className="text-muted">Employees</Card.Text>
                  </div>
                </Col>
                <Col>
                  <div>
                    <h5>{conflict.roles_count}</h5>
                    <span className="text-muted float-end fw-light text-sm">
                      {calculatePercent(
                        conflict.roles_count,
                        conflict.total_roles_count
                      )}
                      %
                    </span>
                    <Card.Text className="text-muted">Roles</Card.Text>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-2">
            <Card.Body className="bg-white">
              <Row>
                <Col className="border-end border-1 border-dark">
                  <div>
                    <h5>{conflict.permission_variable.name}</h5>
                    <Card.Text className="text-muted">
                      {conflict.permission_variable.permissions.length} Permisssions
                    </Card.Text>
                  </div>
                </Col>
                <Col>
                  <div>
                    <h5>{conflict.conflicting_variable.name}</h5>
                    <Card.Text className="text-muted">
                      {conflict.conflicting_variable.permissions.length} Permissions
                    </Card.Text>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default DescriptionCard
