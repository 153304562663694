import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ProcessFeature from 'transactions/drawer/process-feature'
import UserContext from 'context/user-context'

type FeaturesProps = {
  transactionReview: {
    id: string
    review_status: {
      name: string
    }
  },
  features: {
    id: string,
    feature: {
      key: string
    }
    review_status: {
      name: string
    }
  }[]
}


function Features({ transactionReview, features }: FeaturesProps) {
  const [activeTab, setActiveTab] = useState('overall')
  const { municipality }: any = useContext(UserContext)

  return (
    <>
      <ButtonGroup className="mb-2">
        <Button
          variant={activeTab === 'overall' ? 'primary' : 'light'}
          onClick={() => setActiveTab('overall')}
        >
          <span className='ms-2'>Overall</span>
        </Button>
        {features.map((f) => (
          <Button
            key={`feature-button-${f.feature.key}`}
            variant={f.feature.key === activeTab ? 'primary' : 'light'}
            onClick={() => setActiveTab(f.feature.key)}
          >
            <span className="ms-2">{municipality.abbreviation}-{f.feature.key}</span>
          </Button>
        ))}
      </ButtonGroup>
      <Card>
        <Card.Body>
          <ProcessFeature
            key={`process-transaction-${transactionReview.id}`}
            activeTab={activeTab}
            oldReview={transactionReview}
            tabKey={'overall'}
          />
          {features.map((f) => (
            <ProcessFeature
              key={`process-feature-${f.id}`}
              activeTab={activeTab}
              oldReview={f}
              tabKey={f.feature.key}
            />
          ))}
        </Card.Body>
      </Card>
    </>
  )
}

export default Features
