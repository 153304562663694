import React from 'react'
import PropTypes from 'prop-types'
import TagIcon from 'tag/icon'
import { useNavigate } from 'react-router-dom'
import FlagCount from 'shared/flag-count'
import Badge from 'react-bootstrap/Badge'
import {
  faBars,
  faTimesCircle,
  faChevronCircleDown,
  faChevronCircleUp
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function AnalyticRow({ analytic, includeFlags = true }) {
  const navigate = useNavigate()

  /* Enum values 
    0 = off
    1 = low
    2 = med
    3 = high
  */
  const weightBadge = () => {
    if (analytic.weight === 0) {
      return (
        <Badge className="badge-secondary">
          <FontAwesomeIcon icon={faTimesCircle} className="me-1" /> Off
        </Badge>
      )
    } else if (analytic.weight === 1) {
      return (
        <Badge className="badge-primary">
          <FontAwesomeIcon icon={faChevronCircleDown} className="me-1" /> Low
        </Badge>
      )
    } else if (analytic.weight === 2) {
      return (
        <Badge className="badge-primary">
          <FontAwesomeIcon icon={faBars} className="me-1" /> Med
        </Badge>
      )
    } else if (analytic.weight === 3) {
      return (
        <Badge className="badge-primary">
          <FontAwesomeIcon icon={faChevronCircleUp} className="me-1" /> High
        </Badge>
      )
    }
  }

  const tableUrl = () => {
    return `/analytics/${analytic.id}`
  }

  return (
    <tr
      key={`analytic-${analytic.id}`}
      onClick={() => navigate(tableUrl())}
      style={{ cursor: 'pointer' }}
    >
      <td>
        {analytic.name}{' '}
        <small className="fw-light ms-2 text-muted">
          {analytic.section_name}
        </small>
        <div className="mt-1">
          {analytic.is_control && (
            <TagIcon tagName="Control" tagDescription="Related to a control" />
          )}
          {analytic.tags.map((t) => (
            <span key={`analytic-${analytic.id}-tag-${t.id}`} className="me-1">
              <TagIcon tagName={t.name} tagDescription={t.description} />
            </span>
          ))}
        </div>
      </td>
      {includeFlags && <td className="align-middle text-center">{weightBadge()}</td>}
      {includeFlags && (
        <td className="align-middle">
          <FlagCount count={analytic.analytics_count} />
        </td>
      )}
      <td className="text-muted fw-light align-middle">
        {analytic.description}
      </td>
    </tr>
  )
}

AnalyticRow.propTypes = {
  analytic: PropTypes.object.isRequired
}

export default AnalyticRow
