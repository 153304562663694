import React, { useState, useEffect } from 'react'
import Page from 'layout/page'
import Row from 'react-bootstrap/Row'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import { useMutation } from 'react-query'
import SelectAnalytics from './form/select-analytics'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { apiPost } from 'api'
import moment from 'moment'
import { pick, isEmpty } from 'lodash'
import PageTitle from 'layout/page-title'
import ReportName from './form/report-name'
import ModulePicker from './form/module-picker'
import FilterStats from './filter-stats'
import DateFilter from './form/date-filter'
import AmountFilter from './form/amount-filter'
import SampleHighRisk from './form/sample-high-risk'
import SampleRandom from './form/sample-random'
import EmployeeFilter from 'transactions/table/filters/employee'
import VendorFilter from 'transactions/table/filters/vendor'
import useReportPreview from 'api/hooks/use-report-previews'
import AccountModal from './account-modal'
import queryString from 'query-string'
import { formatDateSlash } from 'helpers/datetime'

function Report() {
  const navigate = useNavigate()
  const urlParams = queryString.parse(window.location.search)
  const [sampleCount, setSampleCount] = useState(0)
  const [bigSample, setBigSample] = useState(true)
  const [validated, setValidated] = useState(false)
  const [needsSample, setNeedsSample] = useState(false)
  const [startDateValid, setStartDateValid] = useState(false)
  const [endDateValid, setEndDateValid] = useState(false)
  const [activeOverlay, setActiveOverlay] = useState('')
  /*
  const [keywordInput, setKeywordInput] = useState('')
  const [keywords, setKeywords] = useState([])
   */

  const emptyState = {
    name: '',
    sectionId: urlParams.section_id || '',
    department_id: '',
    startOn:
      moment(formatDateSlash(urlParams.start_on), 'MM/DD/YYYY', true) || '',
    endOn: moment(formatDateSlash(urlParams.end_on), 'MM/DD/YYYY', true) || '',
    highRisk: 0,
    randomSample: 0,
    minimumAmount: urlParams.minimum_amount || '',
    maximumAmount: urlParams.maximum_amount || '',
    orgId: urlParams.org_id || '',
    objectId: urlParams.object_id || '',
    segOne: '',
    segTwo: '',
    segThree: '',
    segFour: '',
    segFive: '',
    includedAnalytics:
      urlParams.analytics?.split('+')?.map((a) => ({ id: a })) || [],
    employees: urlParams.employees
      ? urlParams.employees?.split('+')?.map((e) => ({ id: e }))
      : [],
    vendors: urlParams.vendors
      ? urlParams.vendors?.split('+')?.map((v) => ({ id: v }))
      : []
  }

  const { getAccessTokenSilently } = useAuth0()
  const [auditReport, setAuditReport] = useState(emptyState)
  const validDate = () => {
    const startValid = isValidDate(auditReport.startOn)
    const endValid = isValidDate(auditReport.endOn)

    return startValid && endValid
  }

  const runQuery = () => {
    const startEmpty = isEmpty(auditReport.startOn)
    const endEmpty = isEmpty(auditReport.endOn)
    return (
      (validDate() || (startEmpty && endEmpty)) &&
      auditReport.sectionId.length > 0
    )
  }

  const isValidDate = (date) => {
    return moment(date, 'MM/DD/YYYY', true).isValid()
  }

  const params = {
    department_id: auditReport.department_id,
    section_id: auditReport.sectionId,
    start_on: auditReport.startOn,
    end_on: auditReport.endOn,
    high_risk: auditReport.highRisk,
    random_sample: auditReport.randomSample,
    minimum_amount: auditReport.minimumAmount || 0,
    maximum_amount: auditReport.maximumAmount || 0,
    org_id: auditReport.orgId || '',
    object_id: auditReport.objectId || '',
    seg_one_id: auditReport.segOne?.value || '',
    seg_two_id: auditReport.segTwo?.value || '',
    seg_three_id: auditReport.segThree?.value || '',
    seg_four_id: auditReport.segFour?.value || '',
    seg_five_id: auditReport.segFive?.value || '',
    analytics: [...auditReport.includedAnalytics.map((a) => a.id)].join('+'),
    employees: [...auditReport.employees.map((e) => e.id)].join('+'),
    vendors: [...auditReport.vendors.map((v) => v.id)].join('+')
  }
  const previewOptions = { enabled: runQuery() }
  const pickParams = [
    'section_id',
    'start_on',
    'end_on',
    'analytics',
    'employees',
    'vendors',
    'minimum_amount',
    'maximum_amount',
    'org_id',
    'object_id',
    'seg_one_id',
    'seg_two_id',
    'seg_three_id',
    'seg_four_id',
    'seg_five_id'
  ]

  const {
    isSuccess: isReportSummarySuccess,
    isRefetching,
    data: reportSummary
  } = useReportPreview({
    params: pick(params, pickParams),
    options: previewOptions,
    auditReport
  })

  const newAuditReport = useMutation(({ token, params }) => {
    const objectName = 'audit_report'
    const url = 'audit_reports'
    return apiPost({ url, token, objectName, params })
  })

  const createAuditReport = async () => {
    const token = await getAccessTokenSilently({ audience })
    const params = {
      name: auditReport.name,
      department_id: auditReport.department_id,
      section_id: auditReport.sectionId,
      start_on: auditReport.startOn,
      end_on: auditReport.endOn,
      high_risk: auditReport.highRisk,
      random_sample: auditReport.randomSample,
      minimum_amount: auditReport.minimumAmount,
      maximum_amount: auditReport.maximumAmount,
      org_id: auditReport.orgId,
      object_id: auditReport.objectId,
      seg_one_id: auditReport.segOne?.value || '',
      seg_two_id: auditReport.segTwo?.value || '',
      seg_three_id: auditReport.segThree?.value || '',
      seg_four_id: auditReport.segFour?.value || '',
      seg_five_id: auditReport.segFive?.value || '',
      audit_report_analytics_attributes: auditReport.includedAnalytics.map(
        (a) => ({
          analytic_id: a.id
        })
      ),
      audit_report_employees_attributes: auditReport.employees.map((e) => ({
        employee_id: e.id
      })),
      audit_report_vendors_attributes: auditReport.vendors.map((v) => ({
        vendor_id: v.id
      }))
    }
    newAuditReport.mutate({
      params,
      token
    })
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget
    if (sampleCount === 0) {
      e.preventDefault()
      e.stopPropagation()
      setNeedsSample(true)
    } else if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      e.preventDefault()
      createAuditReport()
    }
    setValidated(true)
  }

  useEffect(() => {
    setSampleCount(
      parseInt(auditReport.highRisk) + parseInt(auditReport.randomSample)
    )
  }, [auditReport.highRisk, auditReport.randomSample])

  useEffect(() => {
    setBigSample(sampleCount >= 100 ? true : false)
  }, [sampleCount])

  useEffect(() => {
    setEndDateValid(isValidDate(auditReport.endOn))
  }, [auditReport.endOn])

  useEffect(() => {
    setStartDateValid(isValidDate(auditReport.startOn))
  }, [auditReport.startOn])

  useEffect(() => {
    if (sampleCount > 0) {
      setNeedsSample(false)
    }
  }, [sampleCount])

  const crumbs = [{ name: 'Samples', url: 'samples' }]

  return (
    <Page title="Create a Samples">
      <PageTitle breadcrumbs={crumbs} />
      <Form
        className="needs-validation"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <ReportName setAuditReport={setAuditReport} auditReport={auditReport} />

        <hr className="my-4" />

        <Row>
          <Col sm={3}>
            <div className="h5">Select Module</div>
          </Col>
          <Col sm={9}>
            <ModulePicker
              setParams={setAuditReport}
              params={auditReport}
              limitModules={['AP', 'AR', 'GL', 'PO', 'PC', 'Vendor']}
            />
          </Col>
        </Row>

        <hr className="my-4" />

        <Row>
          <Col sm={3}>
            <div className="h5">Select Analytics</div>
          </Col>
          <Col sm={9}>
            {auditReport.sectionId.length > 0 ? (
              <SelectAnalytics
                report={auditReport}
                setReport={setAuditReport}
                reportSummary={reportSummary}
                isReportSummarySuccess={!isRefetching && isReportSummarySuccess}
              />
            ) : (
              <Card className="shadow-sm">
                <Card.Body>
                  <div className="d-flex justify-content-center">
                    <Card.Text className="">No Module Selected</Card.Text>
                  </div>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>

        <hr className="my-4" />

        <Row>
          <Col sm={3}>
            <div className="h5">Filter Transactions</div>
            {isReportSummarySuccess && (
              <FilterStats reportSummary={reportSummary} />
            )}
          </Col>
          <Col sm={9}>
            <Card className="shadow-sm">
              <Card.Body>
                <DateFilter
                  auditReport={auditReport}
                  setAuditReport={setAuditReport}
                  startDateValid={startDateValid}
                  validated={validated}
                  endDateValid={endDateValid}
                />

                <Row className="mb-3">
                  <Col sm={3}>
                    <span className="fw-bold">Amount</span>
                  </Col>
                  <Col sm={8}>
                    <AmountFilter
                      setAuditReport={setAuditReport}
                      auditReport={auditReport}
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col sm={3}>
                    <span className="fw-bold mt-1">Employee</span>
                  </Col>
                  <Col sm={8} className="d-grid">
                    <EmployeeFilter
                      setFilterParams={setAuditReport}
                      filterParams={auditReport}
                      activeOverlay={activeOverlay}
                      setActiveOverlay={setActiveOverlay}
                      title={'Select Employees'}
                      wideButton={true}
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col sm={3}>
                    <span className="fw-bold mt-1">Vendor</span>
                  </Col>
                  <Col sm={8} className="d-grid">
                    <VendorFilter
                      setFilterParams={setAuditReport}
                      filterParams={auditReport}
                      activeOverlay={activeOverlay}
                      setActiveOverlay={setActiveOverlay}
                      title={'Select Vendors'}
                      wideButton={true}
                    />
                  </Col>
                </Row>
                {false && (
                  <Row>
                    <Col sm={3}>
                      <span className="fw-bold mt-1">Account</span>
                    </Col>
                    <Col sm={8} className="d-grid">
                      <AccountModal
                        params={auditReport}
                        setParams={setAuditReport}
                      />
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <hr className="my-4" />

        <Row>
          <Col sm={3}>
            <div className="h5">Select # of Transactions</div>
          </Col>
          <Col sm={9}>
            {needsSample && (
              <Alert className="bg-white badge-danger py-2 border border-danger">
                Must select at least one transaction for your sample.
              </Alert>
            )}

            <Card className="shadow-sm">
              <Card.Body>
                <Row>
                  <Col sm={3}>
                    <strong>Sampling</strong>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={3} />
                  <Col sm={3}>
                    <SampleHighRisk
                      auditReport={auditReport}
                      setAuditReport={setAuditReport}
                      needsSample={needsSample}
                    />
                  </Col>
                  <Col sm={6}>
                    <div className="mt-2 text-muted">
                      Number of High Risk Transactions
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={3} />
                  <Col sm={3}>
                    <SampleRandom
                      auditReport={auditReport}
                      setAuditReport={setAuditReport}
                      needsSample={needsSample}
                    />
                  </Col>
                  <Col sm={6}>
                    <div className="mt-2 text-muted">
                      Number of Random Transactions
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <hr className="my-4" />

        <Row className="mt-2">
          <Col sm={3}></Col>
          <Col sm={9}>
            <Row>
              <Col sm={4}></Col>
              <Col sm={4} className="d-grid">
                <Button
                  variant="primary"
                  disabled={newAuditReport.isLoading}
                  type="submit"
                >
                  <FontAwesomeIcon icon={faPlus} className="text-warning" />{' '}
                  <span>
                    {newAuditReport.isLoading
                      ? 'Generating...'
                      : 'Generate Sample'}
                  </span>
                </Button>
                <Row className="mt-2">
                  <Col sm={12}>
                    {bigSample &&
                    !isEmpty(auditReport.name) &&
                    !isEmpty(auditReport.sectionId) ? (
                      <span className="text-danger">
                        {' '}
                        <FontAwesomeIcon icon={faExclamationTriangle} /> Large
                        Sample Size: You are about to sample {sampleCount}{' '}
                        transactions. Consider narrowing your report parameters.
                      </span>
                    ) : null}
                  </Col>
                </Row>
              </Col>
              <Col sm={4}></Col>
            </Row>
          </Col>
          {newAuditReport.isSuccess ? navigate('/samples') : null}
        </Row>
        <Row className="mt-5 mb-5 pt-5 pb-5"></Row>
      </Form>
    </Page>
  )
}

export default Report
