export const updateOrdering = (column, params, setParams) => {
  const isCurrentColumn = params.orderBy === column
  const columnOrder = getColumnOrder(isCurrentColumn, params)

  setParams({
    ...params,
    orderBy: column,
    orderDirection: columnOrder,
    page: 1
  })
}

export const getColumnOrder = (isCurrentColumn, params) => {
  console.table(params)
  if (isCurrentColumn) {
    return params.orderDirection === 'asc' ? 'desc' : 'asc'
  } else {
    return 'desc'
  }
}