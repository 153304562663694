import React from 'react'
import Form from 'react-bootstrap/Form'

function Category({ params, setParams, categories }) {
  return (
    <Form.Group>
      <Form.Select
        as="select"
        value={params.category_id}
        onChange={(e) =>
          setParams({
            ...params,
            categoryCaption: e.target.value
          })
        }
      >
        <option value="">Select Caption</option>
        {categories.map((category) => (
          <option key={category.category_caption} value={category.category_caption}>
            {category.category_caption}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  )
}

export default Category
