import React from 'react'
import NumberField from 'shared/forms/NumberField'

function PoMaxPoPercentLiquidated({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="PO Max Percent Liquidated"
      showTitle={true}
      accessorKey="poMaxPoPercentLiquidated"
      isPercent={true}
    />
  )
}

export default PoMaxPoPercentLiquidated
