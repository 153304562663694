import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Alert from 'react-bootstrap/Alert'
import TagIcon from 'tag/icon'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import TableLoading from 'shared/tables/table-loading'
import TableError from 'shared/tables/table-error'
import { concat, reject, filter, uniqBy } from 'lodash'
import FlagCount from 'shared/flag-count'
import TagButton from './tag-button'
import { useDebounce } from 'api/hooks/use-debounce'
import { useAnalytics } from 'api/hooks/use-analytics'

function SelectAnalyticsModal({
  report,
  setReport,
  reportSummary,
  isReportSummarySuccess
}) {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)

  const params = {
    section_id: report.sectionId,
    search: debouncedSearch,
    perPage: 500,
    orderBy: 'municipality_analytics.analytics_count',
    orderDirection: 'desc'
  }

  const {
    isLoading: analyticsLoading,
    isSuccess: analyticsSuccess,
    isError: analyticsError,
    data: analytics
  } = useAnalytics({ params })

  const addAllAnalytics = () => {
    const analyticIds = analytics.data.map((a) => ({ id: a.id }))
    const newList = uniqBy(
      [...report.includedAnalytics, ...analyticIds],
      'id'
    )
    updateAnalytics(newList)
  }

  const updateAnalytics = (newList) => {
    setReport({
      ...report,
      includedAnalytics: newList
    })
  }

  const addByTag = (tagName) => {
    const filtered = filter(analytics.data, { tags: [{ name: tagName }] })
    const analyticIds = filtered.map((a) => ({ id: a.id }))
    const newList = uniqBy(
      [...report.includedAnalytics, ...analyticIds],
      'id'
    )
    updateAnalytics(newList)
  }

  const addOrRemoveAnalytic = (id) => {
    const findAnalytic = filter(report.includedAnalytics, { id: id })
    if (findAnalytic.length > 0) {
      const newList = reject(report.includedAnalytics, { id: id })
      updateAnalytics(newList)
    } else {
      const newList = concat(report.includedAnalytics, { id: id })
      updateAnalytics(newList)
    }
  }

  const tags = [
    { name: 'Audit', tag: 'Audit' },
    { name: 'Risk', tag: 'RiskAssessment' },
    { name: 'Fraud', tag: 'Fraud' },
    { name: 'Employee Training', tag: 'EmployeeTraining' },
    { name: 'Approver Training', tag: 'ApproverTraining' },
    { name: 'Admin Training', tag: 'AdminTraining' },
    { name: 'Timeliness', tag: 'Timeliness' },
    { name: 'KPI', tag: 'KPI' }
  ]

  return (
    <React.Fragment>
      <Card className="shadow-sm">
        <Card.Body>
          <Row className="mt-2">
            <Col sm={8}>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <FormControl
                  placeholder="Search Name or Description"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={4}>
              <Button variant="light" onClick={() => addAllAnalytics()}>
                Select All
              </Button>
              <Button
                variant="light"
                onClick={() =>
                  setReport({
                    ...report,
                    includedAnalytics: []
                  })
                }
                className="ms-1"
              >
                Deselect All
              </Button>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col sm={12}>
              <div className="mx-auto">
                {tags.map((t) => (
                  <TagButton
                    key={`tag-${t.tag}`}
                    name={t.name}
                    tag={t.tag}
                    addByTag={addByTag}
                  />
                ))}
              </div>
            </Col>
          </Row>

          <hr className="my-4" />

          <Row>
            <Col sm={12}>
              <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
                <Table>
                  <thead>
                    <tr>
                      <th>Include</th>
                      <th>Analytic</th>
                      <th>Flags</th>
                    </tr>
                  </thead>
                  <tbody>
                    {analyticsLoading && <TableLoading columns={3} />}
                    {analyticsError && <TableError columns={3} />}
                    {analyticsSuccess && analytics.data.length === 0 && (
                      <tr>
                        <td colSpan="3" className="text-center">
                          <Alert variant="warning">No analytics found</Alert>
                        </td>
                      </tr>
                    )}
                    
                    {analyticsSuccess &&
                      analytics.data.map((a) => (
                        <tr key={`${a.key}-${a.id}`}>
                          <td className="align-middle text-center">
                            <div className="form-check form-switch ms-3">
                              <input
                                className="form-check-input lead"
                                checked={
                                  filter(report.includedAnalytics, {
                                    id: a.id
                                  }).length > 0
                                }
                                type="checkbox"
                                role="switch"
                                onChange={() => addOrRemoveAnalytic(a.id)}
                                id="flexSwitchCheckDefault"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="fw-bold">
                              <span className="me-2">{a.name}</span>
                              {a.is_control && (
                                <TagIcon
                                  tagName="Control"
                                  tagDescription="Related to a control"
                                />
                              )}
                              {a.tags.map((t) => (
                                <span
                                  key={`analytic-${a.id}-tag-${t.id}`}
                                  className="me-1"
                                >
                                  <TagIcon tagName={t.name} tagDescription={t.description} />
                                </span>
                              ))}
                              <div className="fw-light text-muted">
                                {' '}
                                {a.description}
                              </div>
                            </div>
                          </td>
                          <td>
                            <FlagCount count={a.analytics_count} />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}

SelectAnalyticsModal.propTypes = {
  report: PropTypes.object.isRequired,
  setReport: PropTypes.func.isRequired,
  reportSummary: PropTypes.object.isRequired,
  isReportSummarySuccess: PropTypes.bool.isRequired
}

export default SelectAnalyticsModal
