import React from 'react'
import Form from 'react-bootstrap/Form'

function DuplicatedEinBeingPaid({ params, setParams }) {
  const checked = params.duplicate_ein_being_paid === true

  return (
    <>
      <Form.Check
        inline
        checked={checked}
        label="Vendors Paid in Last Year with Duplicated Tax ID"
        name="duplicated-ein-vendors-being-paid"
        type={'checkbox'}
        onChange={() =>
          setParams({
            ...params,
            duplicate_ein_being_paid: !params.duplicate_ein_being_paid
          })
        }
        id={'duplicate-ein-vendors-being-paid-checkbox'}
      />
      <div className="ms-4 text-muted fw-light">
        Vendors who have been paid in the past year AND have a duplicated tax ID
      </div>
    </>
  )
}

export default DuplicatedEinBeingPaid
