import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

function VendorAttribute({ title, tooltipText }) {
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>{tooltipText}</Tooltip>}
    >
      <Badge pill variant="light" className="bg-light border-white ms-1">
        <span className="text-primary">
          {title}
        </span>
      </Badge>
    </OverlayTrigger>
  )
}

export default VendorAttribute
