import React from 'react'
import NumberField from 'shared/forms/NumberField'

function PoMinPoRemainingAmount({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="PO Min Remaining Amount"
      showTitle={true}
      accessorKey="poMinPoRemainingAmount"
    />
  )
}

export default PoMinPoRemainingAmount
