import React from 'react'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { getIcon } from 'shared/icons'

function ModuleCardIcon({ section, setParams, params }) {
  const icon = getIcon(section.abbreviation)
  const active = params.sectionId === section.id

  return (
    <Card
      className={'shadow-sm'}
      style={{
        cursor: 'pointer',
        background: active ? '#f0f5f7' : '',
        border: active ? '1px solid #6ea0b5' : ''
      }}
      onClick={(e) =>
        setParams({
          ...params,
          sectionId: section.id,
          includedAnalytics: []
        })
      }
    >
      <Card.Body>
        {active ? (
          <span>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-primary ms-1"
            />
          </span>
        ) : (
          <span>
            {' '}
            <FontAwesomeIcon icon={faCheckCircle} className="text-white ms-1" />
          </span>
        )}
        <div className="d-flex justify-content-center">
          <img
            src={icon}
            className="img-fluid"
            style={{ height: '35px' }}
            alt={section.name}
          />
        </div>
        <div className="d-flex justify-content-center">
          <Card.Text className="fw-lighter my-2">{section.name}</Card.Text>
        </div>
      </Card.Body>
    </Card>
  )
}

export default ModuleCardIcon
