import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import Stale from './Stale'
import RecentPaymentChange from './RecentPaymentChange'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import UserContext from 'context/user-context'
import ExcludePcard from './ExcludePcard'
import OneEightHundred from './OneEightHundred'
import PoBox from './PoBox'
import DuplicatedEin from './DuplicatedEin'
import DuplicatedEinBeingPaid from './DuplicatedEinBeingPaid'

function MoreFilters({ params, setParams }) {
  const [show, setShow] = useState(false)
  const { has_custom_pcard } = useContext(UserContext)
  const handleClose = () => setShow(false)
  const handleShow = () => {
    setShow(true)
  }
  const { stale, recentPaymentChange, excludePcard, po_box, one_eight_hundred_number, duplicate_ein, duplicate_ein_being_paid } = params

  const isFilterApplied = stale || recentPaymentChange || excludePcard || po_box || one_eight_hundred_number || duplicate_ein || duplicate_ein_being_paid

  return (
    <React.Fragment>
      <Button
        className={
          isFilterApplied ? 'bg-white text-primary' : 'bg-white text-info'
        }
        variant={isFilterApplied ? 'outline-primary' : ''}
        style={{ border: isFilterApplied ? '' : '1px solid #ced4da' }}
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faFilter} /> Attributes
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="text-center" closeButton>
          <Modal.Title>Filter by Vendor Attributes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={1}></Col>
            <Col sm={10}>
              <Row className="mb-4 mt-2">
                <Col>
                  {has_custom_pcard && (
                    <ExcludePcard params={params} setParams={setParams} />
                  )}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col>
                  <Stale params={params} setParams={setParams} />
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col>
                  <RecentPaymentChange params={params} setParams={setParams} />
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col>
                  <OneEightHundred params={params} setParams={setParams} />
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col>
                  <PoBox params={params} setParams={setParams} />
                </Col>
              </Row>

              <Row className="mb-4">
                <Col>
                  <DuplicatedEin params={params} setParams={setParams} />
                </Col>
              </Row>

              <Row className="mb-4">
                <Col>
                  <DuplicatedEinBeingPaid params={params} setParams={setParams} />
                </Col>
              </Row>
            </Col>
            <Col sm={1}></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default MoreFilters
