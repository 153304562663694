import React from 'react'

function InactiveUsersToggle({ params, setParams }) {
  return (
    <div className="form-check form-switch mt-1">
      <input
        className="form-check-input"
        type="checkbox"
        id="active"
        checked={params.active}
        onChange={() => setParams({ ...params, active: !params.active })}
      />
      <label className="form-check-label" htmlFor="active">
        Hide Disabled
      </label>
    </div>
  )
}

export default InactiveUsersToggle
