import React, { useContext } from 'react'
import { titleize } from 'helpers/utils'
import UserContext from 'context/user-context'

function FilteredBadge({ codeType, params, setParams }) {
  let codeName = codeType
  const { municipality } = useContext(UserContext)

  const {
    seg_one_name,
    seg_two_name,
    seg_three_name,
    seg_four_name,
    seg_five_name,
    seg_six_name,
    seg_seven_name,
    seg_eight_name
  } = municipality.setting

  if (codeType === 'segOnes') {
    codeName = seg_one_name
  }
  if (codeType === 'segTwos') {
    codeName = seg_two_name
  }
  if (codeType === 'segThrees') {
    codeName = seg_three_name
  }
  if (codeType === 'segFours') {
    codeName = seg_four_name
  }
  if (codeType === 'segFives') {
    codeName = seg_five_name
  }
  if (codeType === 'segSixes') {
    codeName = seg_six_name
  }
  if (codeType === 'segSevens') {
    codeName = seg_seven_name
  }
  if (codeType === 'segEights') {
    codeName = seg_eight_name
  }


  return (
    <span
      key={`account-code-${codeType}`}
      className="badge badge-primary me-2 mb-2"
      style={{ cursor: 'pointer' }}
    >
      <span key={`account-code-${codeType}`}>
        {titleize(codeName)}
        {': '} {params[codeType].length}
      </span>
      <span
        className="ms-2"
        onClick={() => setParams({ ...params, [codeType]: [] })}
      >
        &times;
      </span>
    </span>
  )
}

export default FilteredBadge
