import React from 'react'
import AuditLog from 'duties/overview/audit-log'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function AuditLogTab({ employeeId }) {
  return (
    <Card className="border">
      <Card.Header className="bg-white">
        <Row>
          <Col sm="auto">
            <div className="text-info">Audit Log</div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <AuditLog userId={employeeId} />
      </Card.Body>
    </Card>
  )
}

export default AuditLogTab