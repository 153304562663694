import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimes,
  faCreditCard,
  faUserSlash
} from '@fortawesome/free-solid-svg-icons'
import { titleize } from 'helpers/utils'
import Button from 'react-bootstrap/Button'
import SparkLineTable from 'charts/sparkline-table'
import TableFooter from 'shared/tables/table-footer'
import useRiskAssessments from 'api/hooks/use-risk-assessments'
import TableLoading from 'shared/tables/table-loading'
import SortArrow from 'shared/tables/sort-arrow'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Search from 'shared/forms/search'
import TransactionCount from 'shared/filters/transactions-count'
import { isEmpty } from 'lodash'
import StatusBadge from 'shared/badges/status-badge'
import Form from 'react-bootstrap/Form'
import RiskDrawer from './risk-drawer'
import RiskChangeBadge from 'shared/badges/risk-change-badge'

function RiskChangeTable({ currentTab, groupBy }) {
  const [params, setParams] = useState({
    page: 1,
    perPage: 10,
    groupBy: groupBy,
    orderBy: 'one_year_transactions',
    orderDirection: 'desc',
    search: '',
    minimumAmount: '',
    maximumAmount: '',
    transactionsCount: '',
    segment: ''
  })
  const [show, setShow] = useState(false)
  const [activeDrawer, setActiveDrawer] = useState('')
  const [activeDrawerId, setActiveDrawerId] = useState('')

  const handleShow = (name, id) => {
    setShow(true)
    setActiveDrawer(name)
    setActiveDrawerId(id)
  }

  const {
    isSuccess,
    isFetching,
    isLoading,
    data: risks
  } = useRiskAssessments({ params })

  const updateOrdering = (column) => {
    const isCurrentColumn = params.orderBy === column
    const columnOrder = getColumnOrder(isCurrentColumn)

    setParams({
      ...params,
      orderBy: column,
      orderDirection: columnOrder,
      page: 1
    })
  }

  const getColumnOrder = (isCurrentColumn) => {
    if (isCurrentColumn) {
      return params.orderDirection === 'asc' ? 'desc' : 'asc'
    } else {
      return 'desc'
    }
  }

  const isFilterable = currentTab !== 'module'
  const { search, minimumAmount, maximumAmount, transactionsCount } = params
  const isFilterActive =
    !isEmpty(search) ||
    !isEmpty(minimumAmount) ||
    !isEmpty(maximumAmount) ||
    !isEmpty(transactionsCount)
  return (
    <>
      {isFilterable && (
        <Row className="mb-4">
          {groupBy === 'account_code' && (
            <Col sm="2">
              <Form.Group>
                <Form.Select
                  as="select"
                  onChange={(e) =>
                    setParams({ ...params, segment: e.target.value })
                  }
                >
                  <option value="">Select Org/Object</option>
                  <option value="object">Object</option>
                  <option value="org">Org</option>
                </Form.Select>
              </Form.Group>
            </Col>
          )}
          <Col>
            <Search params={params} setParams={setParams} />
          </Col>
          <Col>
            <span className="me-2"></span>
            <TransactionCount
              filterParams={params}
              setFilterParams={setParams}
            />
            {isFilterActive && (
              <Button
                variant="link"
                onClick={() => {
                  setParams({
                    ...params,
                    search: '',
                    minimumAmount: '',
                    maximumAmount: '',
                    transactionsCount: ''
                  })
                }}
              >
                <FontAwesomeIcon icon={faTimes} /> Clear
              </Button>
            )}
          </Col>
        </Row>
      )}

      <SparkLineTable>
        <thead>
          <tr>
            <th></th>
            <th className="border-start border-light text-center" colSpan={4}>
              Previous 12-Months
            </th>
          </tr>
          <tr>
            <th>{titleize(currentTab)}</th>
            <th></th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => updateOrdering('current_risk_trend')}
            >
              Trend
              <SortArrow
                column="current_risk_trend"
                orderBy={params.orderBy}
                orderDirection={params.orderDirection}
              />
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => updateOrdering('average_risk_rate')}
            >
              Risk Rate
              <SortArrow
                column="average_risk_rate"
                orderBy={params.orderBy}
                orderDirection={params.orderDirection}
              />
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => updateOrdering('one_year_transactions')}
            >
              Transactions
              <SortArrow
                column="one_year_transactions"
                orderBy={params.orderBy}
                orderDirection={params.orderDirection}
              />
            </th>
            <th
              style={{ cursor: 'pointer' }}
              onClick={() => updateOrdering('one_year_risk')}
            >
              Sum of Risk
              <SortArrow
                column="one_year_risk"
                orderBy={params.orderBy}
                orderDirection={params.orderDirection}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {(isLoading || isFetching) && <TableLoading columns={7} />}
          {isSuccess &&
            !isFetching &&
            risks.data.map((r) => (
              <tr
                key={`${currentTab}-${r.source_id}`}
                style={{ cursor: 'pointer' }}
                onClick={() => handleShow(r.source_type, r.source_id)}
              >
                <td>
                  {r.source.name || 'Blank'}
                  {['Employee', 'Vendor'].includes(r.source_type) &&
                    r.source.is_pcard && (
                      <span className="ms-2">
                        <StatusBadge
                          title="PCard"
                          icon={faCreditCard}
                          tooltipText="Employee generated from PCard file"
                        />
                      </span>
                    )}
                  {r.source_type === 'Employee' &&
                  r.source.status === 'inactive' ? (
                    <span className="ms-2">
                      <StatusBadge
                        title="Disabled"
                        icon={faUserSlash}
                        tooltipText="No longer an active employee"
                      />
                    </span>
                  ) : null}
                  {r.source_type === 'AccountCode' && (
                    <div className="text-muted fw-light">
                      {titleize(r.source.account_type)}
                    </div>
                  )}
                </td>
                <td
                  data-categories={`${Object.keys(r.monthly_chart)}`}
                  data-sparkline={`${Object.values(r.monthly_chart)}; area`}
                ></td>
                <td>
                  <RiskChangeBadge
                    count={Math.round(100 * r.current_risk_trend) / 100}
                  />
                </td>
                <td>{Math.round(100 * r.average_risk_rate) / 100}</td>
                <td>{Math.round(r.one_year_transactions)}</td>
                <td>{Math.round(r.one_year_risk)}</td>
              </tr>
            ))}
        </tbody>
      </SparkLineTable>
      {isSuccess && (
        <TableFooter meta={risks.meta} params={params} setParams={setParams} />
      )}
      <RiskDrawer
        show={show}
        setShow={setShow}
        source={activeDrawer}
        id={activeDrawerId}
      />
    </>
  )
}

export default RiskChangeTable
