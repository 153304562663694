import React from 'react'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { titleize } from 'helpers/utils'
import Tooltip from 'react-bootstrap/Tooltip'

function WeightBadge({ weight }) {
  const getWeightColor = (weight) => {
    if (weight === 'low') {
      return 'text-primary'
    } else if (weight === 'med') {
      return 'text-warning'
    } else if (weight === 'high') {
      return 'text-danger'
    }
  }

  

  return (
    <OverlayTrigger
      placement="bottom"
      trigger={['hover', 'focus']}
      overlay={
        <Tooltip id={'tooltip'}>
          This analytic's weight, used in calculating the transaction risk.
        </Tooltip>
      }
    >
      <Badge
        bg="light"
        className="bg-white border border-light"
        style={{ cursor: 'help' }}
      >
        <FontAwesomeIcon
          className={`${getWeightColor(weight)} fa-xs`}
          icon={faCircle}
        />{' '}
        <span className="text-primary">{titleize(weight)}</span>
      </Badge>
    </OverlayTrigger>
  )
}

export default WeightBadge