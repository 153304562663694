import React from 'react'
import Alert from 'react-bootstrap/Alert'

function StatusAlert({ status }) {
  return (
    <>
      {status === 'queued' && (
        <Alert variant="warning" className='py-2'>
          Queued for Update: This analytic is in the queue to update risk for transactions it has flagged. Risk scores may not reflect this weighting yet.
        </Alert>
      )}
      {status === 'processed' && (
        <Alert variant="warning">
          Processing: Updating risk scores for this analytic
        </Alert>
      )}
    </>
  )
}

export default StatusAlert
