import React from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { startCase, replace } from 'lodash'

function StatCard({ stat }) {


  const displayValue = () => {
    if (stat.dataType === 'currency') {
      return '$' + Math.round(stat.value).toLocaleString()
    } else if (stat.dataType === 'percent') {
      return Math.round(stat.value).toLocaleString() + '%'
    } else if (stat.dataType === 'number') {
      return Math.round(stat.value).toLocaleString()
    } else if (stat.dataType === 'date') {
      return new Date(stat.value).toLocaleDateString()
    }
  }

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col sm="auto">
            <h4>{displayValue()}</h4>
          </Col>
          <Col></Col>
        </Row>

        <Card.Text className="text-muted">
          {startCase(replace(stat.title, '_', ' '))}
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default StatCard
