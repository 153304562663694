import { useContext } from 'react'
import { useMutation } from 'react-query'
import { apiPost, apiDelete } from 'api'
import AuthContext from 'context/auth-context'

const createAuditReportUser = async (token, params) => {
  const objectName = 'audit_report_user'
  const url = `audit_reports/${params.audit_report_id}/share`

  apiPost({ url, token, objectName, params })
}

export function useCreateAuditReportUser({ params, options }) {
  const token = useContext(AuthContext)
  return useMutation(
    () => {
      return createAuditReportUser(token, params)
    },
    { ...options }
  )
}

const destroyAuditReportUser = async (token, id) => {
  const url = `share/${id}`

  apiDelete({ url, token })
}

export function useDestroyAuditReportUser({ id, options }) {
  const token = useContext(AuthContext)
  return useMutation(
    () => {
      return destroyAuditReportUser(token, id)
    },
    { ...options }
  )
}
