import Form from 'react-bootstrap/Form'

function OneEightHundred({ params, setParams }) {
  const checked = params.one_eight_hundred_number === true
  return (
    <>
      <Form.Check
        inline
        checked={checked}
        label="1-800 Vendor Phone Number"
        name="one-eight-hundred-vendors"
        type={'checkbox'}
        onChange={() => setParams({ ...params, one_eight_hundred_number: !params.one_eight_hundred_number })}
        id={'1800-vendors-checkbox'}
      />
      <div className="ms-4 text-muted fw-light">
        Vendor has a 1-800 number as the contact phone number
      </div>
    </>
  )
}

export default OneEightHundred
