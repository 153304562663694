import React, { useState, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

function FlagCount({ params, setParams }) {
  const [activeOverlay, setActiveOverlay] = useState(false)
  const amountTarget = useRef(null)
  const filterApplied = params.flagCount !== ''

  return (
    <>
      <Button
        variant={filterApplied ? 'outline-primary' : 'outline-light'}
        className="bg-white text-info"
        ref={amountTarget}
        onClick={() => setActiveOverlay(true)}
        style={{ border: filterApplied ? '' : '1px solid #ced4da' }}
        data-testid="amount-button-trigger"
      >
        {filterApplied ? <span className='text-primary'>{params.flagCount} Flags</span> : 'Flag Count'}
      </Button>
      <Overlay
        target={amountTarget.current}
        show={activeOverlay}
        placement="bottom"
      >
        <Popover style={{ minWidth: '125px' }}>
          <Popover.Header>
            <Row>
              <Col sm={6} className="d-grid">
                {filterApplied ? (
                  <Button
                    variant="link"
                    size="sm"
                    className="float-end mt-1"
                    onClick={() =>
                      setParams({
                        ...params,
                        flagCount: ''
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                    Clear
                  </Button>
                ) : null}
              </Col>
              <Col sm={6} className="d-grid">
                <Button
                  variant="primary"
                  size="sm"
                  className="float-end "
                  onClick={() => setActiveOverlay(null)}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Popover.Header>
          <Popover.Body>
            <Row>
              <Col>
                <InputGroup>
                  <Form.Control
                    type="number"
                    onChange={(e) =>
                      setParams({
                        ...params,
                        flagCount: e.target.value,
                        page: 1
                      })
                    }
                    min={0}
                    value={params.flagCount}
                    required
                    data-testid="flag-count"
                    placeholder=""
                  />
                  <InputGroup.Text id="inputGroupPrepend">
                    {'>'} flags
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  )
}

export default FlagCount
