import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import { useDashboards } from 'api/hooks/use-dashboards'
import TableLoading from 'shared/tables/table-loading'
import NewView from 'dashboard/new'
import { useNavigate } from 'react-router-dom'
import Search from 'shared/forms/search'
import SectionDropdown from 'shared/filters/section-dropdown'

function Dashboard() {
  const navigate = useNavigate()
  const defaultParams = {
    search: '',
    section_id: ''
  }
  const [params, setParams] = React.useState(defaultParams)

  const { data: dashboards, isSuccess, isLoading } = useDashboards({ params })

  return (
    <Page title="Views">
      <PageTitle />
      <Row className="mt-5">
        <Col>
          <Search params={params} setParams={setParams} />
        </Col>
        <Col sm={2}>
          <SectionDropdown
            params={params}
            setParams={setParams}
            hasAllOption={true}
          />
        </Col>
        <Col>
          <div className="text-end">
            <NewView />
          </div>
        </Col>
      </Row>

      <Row className="mt-2 pb-4">
        <Col>
          <Table
            responsive
            hover
            className="border shadow-sm mt-2 bg-white"
            size="sm"
          >
            <thead>
              <tr>
                <th>View Name</th>
                <th>Section</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <TableLoading columns={2} />}
              {isSuccess && dashboards.length === 0 && (
                <tr>
                  <td colSpan="3" className="text-center py-4">
                    <div className="mb-2 h6">No Views Created</div>
                    <div className="text-muted fw-lighter mb-3">
                      Get started by creating a new view
                    </div>
                    <NewView />
                  </td>
                </tr>
              )}

              {isSuccess &&
                dashboards.map((dashboard) => (
                  <tr
                    key={dashboard.id}
                    onClick={() =>
                      navigate(
                        `/dashboards/${dashboard.section_name
                          .toLowerCase()
                          .replace(/\s+/g, '-')
                          .replace(' ', '-')}?dashboard_id=${dashboard.id}`
                      )
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <td>{dashboard.name}</td>
                    <td>{dashboard.section_name}</td>
                    <td>{dashboard.user_full_name}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Page>
  )
}

export default Dashboard
