import React from 'react'
import StatusBadge from 'shared/badges/status-badge'
import { ListGroup } from 'react-bootstrap'
import { useCreateDashboardEmployee } from 'api/hooks/use-dashboard-employees'
import { useQueryClient } from 'react-query'
import {
  faUserSlash
} from '@fortawesome/free-solid-svg-icons'

function EmployeeItem({ employee, params, dashboardId, addOrRemoveEmployee }) {
  const queryClient = useQueryClient()
  const queryParams = {
    employee_id: employee.id,
    dashboard_id: params.dashboardId,
    options: employee.options
  }
  const { mutate } = useCreateDashboardEmployee({
    params: queryParams,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(`dashboards/${dashboardId}`)
      }
    }
  })

  return (
    <ListGroup.Item
      action
      key={`employee-option-${employee.id}`}
      active={params.employees.includes(employee.id) ? true : false}
      className={
        params.employees.includes(employee.id) ? 'py-2 bg-light' : 'py-2'
      }
      onClick={() => {
        addOrRemoveEmployee(employee)
        mutate()
      }}
    >
      {employee.external_id}
      {employee.status === 'inactive' ? (
        <span className="ms-2">
          <StatusBadge
            title="Disabled"
            icon={faUserSlash}
            tooltipText="No longer an active employee"
          />
        </span>
      ) : null}
    </ListGroup.Item>
  )
}

export default EmployeeItem