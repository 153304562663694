import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getTags = async (token, params) => {
  const url = 'tags'
  const data = await apiGet({ url, token, params })
  return data
}

export default function useTags({ params, options }) {
  const token = useContext(AuthContext)
  const queryKey = ['tags', params]
  const queryFn = () => getTags(token, params)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
