import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Badge from 'react-bootstrap/Badge'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

function HighRiskPermissionBadge({ count, displayCount = true }) {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>High Risk Permissions</Tooltip>}
    >
      <Badge
        pill
        className={
          'text-center text-danger bg-light fw-normal ms-2'
        }
        style={{ cursor: 'pointer' }}
        data-testid={'risk-badge'}
      >
        <FontAwesomeIcon icon={faExclamationCircle} />{' '}
        {displayCount ? count : 'High Risk'}
      </Badge>
    </OverlayTrigger>
  )
}

export default HighRiskPermissionBadge