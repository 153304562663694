import React from 'react'
import { Row, Col } from 'react-bootstrap'
import MoreFilters from './MoreFilters'
import VendorFilter from './vendor-filter'
import EmployeeFilter from './employee-filter'
import AccountsFilter from '../../shared/filters/accounts'
import AnalyticsFilters from './analytics'
import Button from 'react-bootstrap/Button'

function DashboardFilters({
  params,
  setParams,
  isFilterOpen,
  setIsFilterOpen,
  sectionName,
  showStats,
  setShowStats
}) {
  return (
    <Row>
      {sectionName !== 'General Ledger' && (
        <Col sm="auto">
          <VendorFilter
            params={params}
            setParams={setParams}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
          />
        </Col>
      )}
      <Col sm="auto">
        <EmployeeFilter
          params={params}
          setParams={setParams}
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
      </Col>
      {['General Ledger', 'Accounts Payable'].includes(sectionName) && (
        <Col sm="auto">
          <AccountsFilter
            params={params}
            setParams={setParams}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            buttonSize=""
          />
        </Col>
      )}
      <Col sm="auto">
        <AnalyticsFilters
          params={params}
          setParams={setParams}
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
      </Col>
      {sectionName !== 'Vendor' && (
        <Col sm="auto">
          <MoreFilters
            params={params}
            setParams={setParams}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            sectionName={sectionName}
          />
        </Col>
      )}

      {false && (
        <Col className="ml-auto text-right">
          <Button variant="link" onClick={() => setShowStats(!showStats)}>
            {showStats ? 'Hide' : 'Show'} Stats
          </Button>
        </Col>
      )}
    </Row>
  )
}

export default DashboardFilters
