import React from 'react'
import Form from 'react-bootstrap/Form'

function SampleRandom({ setAuditReport, auditReport, needsSample }) {
  return (
    <Form.Group>
      <Form.Control
        type="number"
        min={0}
        value={auditReport.randomSample}
        onChange={(e) =>
          setAuditReport({
            ...auditReport,
            randomSample: e.target.value
          })
        }
        placeholder=""
      />
      {needsSample && (
        <Form.Control.Feedback className="text-danger">
          Enter at least one High Risk or one Random sample
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

export default SampleRandom
