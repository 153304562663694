import * as React from 'react'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

type FlagCountProps = {
  count: string,
  color?: string
}

function FlagCount({ count, color = 'grey' }: FlagCountProps) {
  const classes =
    color === 'grey'
      ? 'border border-light me-1 text-body bg-light fw-lighter'
      : 'border border-light me-1 text-primary bg-white fw-lighter'

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>Number of analytic flags</Tooltip>}
    >
      <Badge
        className={`${classes}`}
        style={{ fontSize: '75%', cursor: 'help' }}
        pill
        data-testid="analytic-flag-badge"
      >
        <FontAwesomeIcon icon={faFlag} className="text-danger" />{' '}
        {count.toLocaleString()}
      </Badge>
    </OverlayTrigger>
  )
}

export default FlagCount
