import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './custom.scss'
import { createBrowserHistory } from 'history'
import { Auth0Provider } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { BrowserRouter, useNavigate } from 'react-router-dom'

export const history = createBrowserHistory()

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState) => {
    navigate((appState && appState.targetUrl) || window.location.pathname)
  }

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  )
}

ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithRedirectCallback
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={audience}
    >
      <App />
    </Auth0ProviderWithRedirectCallback>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals()
