import React from 'react'
import MultiStatus from './multi-status'

type MultiReviewStatusColumnProps = {
  reviewSummary: {
    open_count: number,
  follow_up_count: number,
  exception_count: number,
  no_exception_count: number
  }
}

function MultiReviewStatusColumn({ reviewSummary }: MultiReviewStatusColumnProps) {
  return (
    <MultiStatus
      openCount={reviewSummary.open_count}
      followUpCount={reviewSummary.follow_up_count}
      exceptionCount={reviewSummary.exception_count}
      noExceptionCount={reviewSummary.no_exception_count}
    />
  )
}

export default MultiReviewStatusColumn
