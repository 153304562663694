import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Datetime from 'react-datetime'
import moment from 'moment'
import { useEffect } from 'react'
import DateCard from './date-card'

function DateFilter({ filterParams, setFilterParams }) {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [dateSelect, setDateSelect] = useState('range')

  const isValidDate = (date) => {
    return moment(date, 'MM/DD/YYYY', true).isValid()
  }

  useEffect(() => {
    if (isValidDate(startDate)) {
      setFilterParams((filterParams) => ({
        ...filterParams,
        startOn: startDate,
        dateRangeSelected: '',
        page: 1
      }))
    }
  }, [startDate, setFilterParams])

  useEffect(() => {
    if (isValidDate(endDate)) {
      setFilterParams((filterParams) => ({
        ...filterParams,
        endOn: endDate,
        dateRangeSelected: '',
        page: 1
      }))
    }
  }, [endDate, setFilterParams])

  const isRange = dateSelect === 'range'
  const isDateSelect = dateSelect === 'datepicker'

  return (
    <>
      <Row className="mb-4">
        <Col>
          <h5>
            Transaction Date
            {filterParams.startOn || filterParams.endOn ? (
              <Button
                variant="link"
                size="sm"
                className="ms-4"
                onClick={() => {
                  setStartDate('')
                  setEndDate('')

                  setFilterParams({
                    ...filterParams,
                    startOn: '',
                    endOn: '',
                    dateRangeSelected: ''
                  })
                }}
              >
                Clear
              </Button>
            ) : null}
          </h5>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col sm={3}></Col>
        <Col sm={6}>
          <Button
            variant={isRange ? 'primary' : 'outline-light'}
            onClick={() => setDateSelect('range')}
            style={{ border: isRange ? '' : '1px solid #ced4da' }}
            className={`mx-2 ${isRange ? 'text-white' : 'text-info'}`}
          >
            Date Range
          </Button>
          <Button
            variant={!isRange ? 'primary' : 'outline-light'}
            onClick={() => setDateSelect('datepicker')}
            style={{ border: isDateSelect ? '' : '1px solid #ced4da' }}
            className={`mx-2 ${isDateSelect ? 'text-white' : 'text-info'}`}
          >
            Choose Dates
          </Button>
        </Col>
        <Col sm="3"></Col>
      </Row>

      {isDateSelect && (
        <Row>
          <Col sm={6}>
            <Datetime
              input={false}
              dateFormat="MM/DD/YYYY"
              timeFormat={false}
              onChange={(e) => setStartDate(e)}
              value={startDate}
            />
          </Col>

          <Col sm={6}>
            <Datetime
              dateFormat="MM/DD/YYYY"
              input={false}
              timeFormat={false}
              onChange={(e) => setEndDate(e)}
              value={endDate}
            />
          </Col>
        </Row>
      )}

      {isRange && (
        <Row>
          <Col sm={2}>
            <DateCard
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              rangeString="one-month"
              title="1 Month"
            />
          </Col>
          <Col sm={2}>
            <DateCard
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              rangeString="three-months"
              title="3 Months"
            />
          </Col>
          <Col sm={2}>
            <DateCard
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              rangeString="six-months"
              title="6 Months"
            />
          </Col>
          <Col sm={2}>
            <DateCard
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              rangeString="twelve-months"
              title="12 Months"
            />
          </Col>
          <Col sm={2}>
            <DateCard
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              rangeString="this-year"
              title="This Year"
            />
          </Col>
          <Col sm={2}>
            <DateCard
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              rangeString="last-year"
              title="Last Year"
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default DateFilter
