import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getAccountCodes = async (token, params, municipalityId) => {
  const url = `municipalities/${municipalityId}/account_codes`
  const data = await apiGet({ url, params, token })
  return data
}

export function useAccountCodes({ params, options }) {
  const { municipality_id } = useContext(UserContext)
  const token = useContext(AuthContext)
  const queryKey = ['accountCodes', params]
  const queryFn = () => getAccountCodes(token, params, municipality_id)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}

const getAccountCode = async (token, params) => {
  const url = `account_codes/${params.id}`

  const data = await apiGet({ url, token, params })
  return data
}

export function useAccountCode({ params, options }) {
  const token = useContext(AuthContext)
  const queryFn = () => getAccountCode(token, params)
  const queryKey = ['account-code', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
