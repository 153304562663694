import React from 'react'
import PropTypes from 'prop-types'
import SparkLine from './sparkline.jsx'
import Table from 'react-bootstrap/Table'


class SparkLineTable extends React.Component {
  toSparkLine(children) {
    let header

    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) return child

      if (child.type === 'th') header = child.props.children

      if (child.props['data-sparkline']) {
        return this.sparkLine(child, header)
      }

      if (child.props.children) {
        child = React.cloneElement(child, {
          children: this.toSparkLine(child.props.children)
        })
      }

      return child
    })
  }

  sparkLine(element) {
    const dataAttr = element.props['data-sparkline'].split('; ')
    const categoryAttr = element.props['data-categories'].split('; ')
    const data = dataAttr[0].split(',').map(Number)

    const options = {
      series: [
        {
          data
        }
      ],
      exporting: {
        enabled: false
      },
      xAxis: {
        categories: categoryAttr[0]
          .split(',')
      },
      tooltip: {
        headerFormat: '<span style="font-sze:10px">{point.x} risk per transaction: </span><br/>',
        pointFormat: '<b>{point.y:.2f}</b>'
      },
      chart: {
        type: dataAttr[3] || 'area'
      }
    }

    return <SparkLine options={options} />
  }

  render() {
    return (
      <Table
        hover
        responsive
        className="bg-white shadow-sm"
        style={{ border: '1px solid rgb(222 226 230)' }}
      >
        {this.toSparkLine(this.props.children)}
      </Table>
    )
  }
}

SparkLineTable.propTypes = {
  children: PropTypes.array.isRequired,
  header: PropTypes.object
}

export default SparkLineTable
