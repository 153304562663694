import React, { useState, useRef, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faTimesCircle,
  faCreditCard,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons'
import ListGroup from 'react-bootstrap/ListGroup'
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'
import smallDot from 'shared/small-dot.svg'
import StatusBadge from 'shared/badges/status-badge'
import { useQuery } from 'react-query'
import { reject, filter, uniqBy } from 'lodash'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { apiGet } from 'api'
import UserContext from 'context/user-context'
import { truncate } from 'lodash'

function VendorFilter({
  setFilterParams,
  filterParams,
  activeOverlay,
  setActiveOverlay,
  title = 'Vendor',
  wideButton = false
}) {
  const [vendorSearch, setVendorSearch] = useState('')
  const [vendorPage, setVendorPage] = useState(1)
  const [allVendors, setAllVendors] = useState([])
  const vendorTarget = useRef(null)

  const vendorParams = {
    search: vendorSearch,
    page: vendorPage
  }

  const addOrRemoveVendor = (vendor) => {
    const findVendor = filter(filterParams.vendors, {
      id: vendor.id
    })
    if (findVendor.length > 0) {
      const newList = reject(filterParams.vendors, { id: vendor.id })
      updateVendors(newList)
    } else {
      const newList = filterParams.vendors
      newList.push(vendor)
      updateVendors(newList)
    }
  }

  const updateVendors = (newList) => {
    setFilterParams({
      ...filterParams,
      vendors: newList
    })
  }

  /*
    TODO: Use the Infinity query
    https://react-query.tanstack.com/reference/useInfiniteQuery
  */
  const { getAccessTokenSilently } = useAuth0()
  const { municipality_id } = useContext(UserContext)

  const { isLoading: isVendorsLoading } = useQuery(
    ['vendors', vendorParams],
    async () => {
      const url = `municipalities/${municipality_id}/vendors`
      const token = await getAccessTokenSilently({ audience })
      const data = await apiGet({ url, token, params: vendorParams })
      return data
    },
    {
      onSuccess: (data) => {
        setAllVendors(uniqBy(allVendors.concat(data.data), 'id'))
      },
      enabled: activeOverlay === 'vendor'
    }
  )

  const handleVendorScroll = (event) => {
    const target = event.target
    /* When there are no more pages to fetch
        if (vendorPage >= vendors.meta.pages) {
        return
        }
      */
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      setVendorPage(vendorPage + 1)
    }
  }

  useEffect(() => {
    setAllVendors([])
    setVendorPage(1)
  }, [vendorParams.search])

  const vendorCount = filterParams.vendors.length
  const selectedVendors = filterParams.vendors.map((v) => v.id)

  return (
    <>
      <Button
        data-toggle="dropdown"
        ref={vendorTarget}
        onClick={() => setActiveOverlay('vendor')}
        style={{ border: vendorCount > 0 ? '' : '1px solid #ced4da' }}
        aria-haspopup="true"
        aria-expanded="false"
        className="bg-white text-info"
        variant={
          vendorCount || activeOverlay === 'vendor'
            ? 'outline-primary'
            : 'outline-light'
        }
      >
        {vendorCount === 0 && title}
        {vendorCount === 1 && (
          <>
            <img src={smallDot} className="img-fluid" alt={'Filter Applied'} />{' '}
            <span className="text-primary">
              {filterParams.vendors[0].name
                ? truncate(filterParams.vendors[0].name, {
                    length: wideButton ? 70 : 10
                  })
                : `${filterParams.vendors.length} Vendor`}
            </span>
          </>
        )}
        {vendorCount > 1 && (
          <>
            <img src={smallDot} className="img-fluid" alt={'Filter Applied'} />{' '}
            <span className="text-primary">{vendorCount} Vendors</span>
          </>
        )}
      </Button>

      <Overlay
        target={vendorTarget.current}
        show={activeOverlay === 'vendor'}
        placement="bottom-start"
      >
        <Popover
          id="popover-basic"
          style={{
            maxHeight: 400,
            minWidth: wideButton ? '35%' : '25%',
            overflowY: 'auto'
          }}
          onScroll={handleVendorScroll}
        >
          <Popover.Header className="sticky-top">
            <Row>
              <Col sm={12}>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Search Vendors"
                    onChange={(e) => setVendorSearch(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col sm={6} className="d-grid">
                {vendorCount > 0 ? (
                  <Button
                    variant="link"
                    size="sm"
                    className="float-end mt-1"
                    onClick={() =>
                      setFilterParams({ ...filterParams, vendors: [] })
                    }
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                    Clear
                  </Button>
                ) : null}
              </Col>
              <Col sm={6} className="d-grid">
                <Button
                  variant="primary"
                  size="sm"
                  className="float-end mt-1"
                  onClick={() => {
                    setActiveOverlay('')
                    setVendorSearch('')
                  }}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Popover.Header>
          <ListGroup variant="flush">
            {allVendors.length > 0 &&
              allVendors.map((v) => (
                <React.Fragment key={`vendor-option-${v.id}`}>
                  <ListGroup.Item
                    action
                    active={selectedVendors.includes(v.id) ? true : false}
                    className={selectedVendors.includes(v.id) ? 'bg-light' : ''}
                    onClick={() => {
                      addOrRemoveVendor(v)
                    }}
                  >
                    <>
                      <span className="text-primary fw-bold">{v.name}</span>{' '}
                      {v.is_pcard ? (
                        <span className="ms-2">
                          <StatusBadge
                            title="PCard"
                            icon={faCreditCard}
                            tooltipText="Employee generated from PCard file"
                          />
                        </span>
                      ) : null}
                      <span className="text-muted fw-light float-end">
                        ID:{' '}
                        {truncate(v.external_id, {
                          length: 10
                        })}
                      </span>
                      {selectedVendors.includes(v.id) ? (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-primary ms-1"
                        />
                      ) : null}
                    </>
                  </ListGroup.Item>
                </React.Fragment>
              ))}
            {isVendorsLoading && (
              <ListGroup.Item>
                <Spinner animation="grow" variant="warning" />
              </ListGroup.Item>
            )}
          </ListGroup>
        </Popover>
      </Overlay>
    </>
  )
}

VendorFilter.propTypes = {
  setFilterParams: PropTypes.func.isRequired,
  filterParams: PropTypes.object.isRequired,
  activeOverlay: PropTypes.string.isRequired,
  setActiveOverlay: PropTypes.func.isRequired
}

export default VendorFilter
