import * as React from 'react'
import Badge from 'react-bootstrap/Badge'
import { isNull } from 'lodash'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

type RiskBadgeProps = {
  count: number,
  showCount?: boolean,
  mid?: number,
  high?: number
}

function RiskBadge({ count, showCount = false, mid = 1, high = 2 }: RiskBadgeProps) {
  const riskTooltip = 'Transaction risk based on your analytic weighting'
  if (count === undefined) {
    return <React.Fragment></React.Fragment>
  }
  if (count === null) { }
  if (count >= high) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip>{riskTooltip}</Tooltip>}
      >
        <Badge
          pill
          className={'text-center text-danger high-risk badge-danger fw-normal'}
          style={{ cursor: 'help' }}
          data-testid={'risk-badge'}
        >
          {showCount ? count : 'High Risk'}
        </Badge>
      </OverlayTrigger>
    )
  }

  if (count >= mid) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip>{riskTooltip}</Tooltip>}
      >
        <Badge
          pill
          className={'text-center badge-warning fw-normal'}
          style={{ cursor: 'help' }}
          data-testid={'risk-badge'}
        >
          {showCount ? count : 'Med Risk'}
        </Badge>
      </OverlayTrigger>
    )
  }

  if (count >= 0 || isNull(count)) {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip>{riskTooltip}</Tooltip>}
      >
        <Badge
          pill
          className={'text-center text-success badge-success fw-normal'}
          style={{ cursor: 'help' }}
          data-testid={'risk-badge'}
        >
          {showCount ? count : 'Low Risk'}
        </Badge>
      </OverlayTrigger>
    )
  }
}

export default RiskBadge
