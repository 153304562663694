import React from 'react'
import {
  faToggleOn
} from '@fortawesome/free-solid-svg-icons'
import Result from './result'

function PermissionResult({ result }) {
  const highRisk = result.tags?.is_high_risk ? { title: 'High Risk' } : null
  const superUser = result.tags?.is_superuser ? { title: 'Super User' } : null

  const tags = [{ title: result.tags?.caption }, highRisk, superUser].filter(
    Boolean
  )

  return (
    <Result
      dataType="Permission"
      title={result.record.name}
      subtitle={result.record.description}
      icon={faToggleOn}
      url={`/permissions/${result.data_id}`}
      tags={tags}
    />
  )

}

export default PermissionResult
