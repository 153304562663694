import React, { useState } from 'react'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import TransactionsByMonth from 'shared/transactions-by-month'
import AnalyticsByMonthContainer from './AnalyticsByMonthContainer'
import AnalyticCountChart from 'shared/analytic-count-chart'
import CumulativeSpend from 'shared/cumulative-spend'
import Stat from 'shared/stat'
import { split } from 'lodash'
import RiskBadge from 'shared/badges/risk-badge'
import { getMax } from 'helpers/utils'
import TransactionTable from 'transactions/table'
import { noDigitMoney } from 'helpers/utils'
import { useEmployee } from 'api/hooks/use-employees'
import { faCreditCard, faUserSlash } from '@fortawesome/free-solid-svg-icons'
import EmptyPage from 'layout/empty-page'
import { DefaultError } from 'shared/default-error'
import { ErrorBoundary } from '@sentry/react'
import StatusBadge from '../shared/badges/status-badge'

function Employee() {
  const path = window.location.pathname
  const employeeId = split(path, '/')[2]
  const [activeTab, setActiveTab] = useState('flags')

  const params = { id: employeeId }

  const chartDescription = () => {
    if (activeTab === 'flags') {
      return 'Analytic Flags Over Time'
    } else if (activeTab === 'transactions') {
      return 'Transactions Over Time'
    } else if (activeTab === 'analytic') {
      return 'Top Analytics Count'
    } else if (activeTab === 'spend') {
      const isPCardEmployee = isSuccess && employee.is_pcard
      return isPCardEmployee ? 'PCard Spend Over Time' : 'Amount Over Time'
    }
  }

  const { isSuccess, isLoading, data: employee } = useEmployee({ params })
  const crumbs = [{ name: 'Employees', url: 'employees' }]

  if (isLoading) {
    return <EmptyPage />
  }
  return (
    <Page
      title={
        <span className="text-primary">
          <span className="fw-light me-2">Employee ID</span>
          {employee.external_id}
          <span className="lead ms-2">
            <RiskBadge count={employee.risk} showCount={true} />
          </span>
          {employee.is_pcard ? (
            <span className="ms-2">
              <StatusBadge
                title="PCard"
                icon={faCreditCard}
                tooltipText="Employee generated from PCard file"
              />
            </span>
          ) : null}
          {employee.status === 'inactive' ? (
            <span className="ms-2">
              <StatusBadge
                title="Disabled"
                icon={faUserSlash}
                tooltipText="No longer an active employee"
              />
            </span>
          ) : null}
        </span>
      }
      subtitle={<span className="text-muted">{employee.department_name}</span>}
      pageTitle='Employee'
    >
      <Row>
        <Col>{isSuccess && <PageTitle breadcrumbs={crumbs} />}</Col>
      </Row>

      {isSuccess && (
        <React.Fragment>
          <Row className="mt-4">
            <Col sm={3}>
              <ErrorBoundary
                fallback={DefaultError}
                beforeCapture={(scope) => {
                  scope.setTag('component', 'Stat')
                }}
              >
                <Stat
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  text={Math.round(employee.analytics_count).toLocaleString()}
                  subtext={'Total Flags'}
                  name={'flags'}
                />
              </ErrorBoundary>
            </Col>
            <Col sm={3}>
              <ErrorBoundary
                fallback={DefaultError}
                beforeCapture={(scope) => {
                  scope.setTag('component', 'Stat')
                }}
              >
                <Stat
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  text={Math.round(
                    employee.transactions_count
                  ).toLocaleString()}
                  subtext={'Total Transactions'}
                  name={'transactions'}
                />
              </ErrorBoundary>
            </Col>
            <Col sm={3}>
              <ErrorBoundary
                fallback={DefaultError}
                beforeCapture={(scope) => {
                  scope.setTag('component', 'Stat')
                }}
              >
                <Stat
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  text={getMax(employee.count_by_analytic)}
                  subtext={'Top Analytic'}
                  name={'analytic'}
                />
              </ErrorBoundary>
            </Col>
            <Col sm={3}>
              <ErrorBoundary
                fallback={DefaultError}
                beforeCapture={(scope) => {
                  scope.setTag('component', 'Stat')
                }}
              >
                <Stat
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  text={noDigitMoney(employee.transactions_amount)}
                  subtext={'Total Amount'}
                  name={'spend'}
                />
              </ErrorBoundary>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm={12}>
              <Card>
                <Card.Header className="bg-white">
                  {chartDescription()}
                </Card.Header>

                <Card.Body>
                  {activeTab === 'flags' && (
                    <ErrorBoundary
                      fallback={DefaultError}
                      beforeCapture={(scope) => {
                        scope.setTag('component', 'AnalyticsByMonth')
                      }}
                    >
                      <AnalyticsByMonthContainer
                        employeeId={employee.id}
                      />
                    </ErrorBoundary>
                  )}
                  {activeTab === 'transactions' && (
                    <ErrorBoundary
                      fallback={DefaultError}
                      beforeCapture={(scope) => {
                        scope.setTag('component', 'TransactionsByMonth')
                      }}
                    >
                      <TransactionsByMonth
                        countByMonth={employee.transactions_by_month}
                      />
                    </ErrorBoundary>
                  )}
                  {activeTab === 'analytic' && (
                    <ErrorBoundary
                      fallback={DefaultError}
                      beforeCapture={(scope) => {
                        scope.setTag('component', 'CountByAnalytic')
                      }}
                    >
                      <AnalyticCountChart
                        analyticCounts={employee.count_by_analytic}
                      />
                    </ErrorBoundary>
                  )}
                  {activeTab === 'spend' && (
                    <ErrorBoundary
                      fallback={DefaultError}
                      beforeCapture={(scope) => {
                        scope.setTag('component', 'CumulativeSpend')
                      }}
                    >
                      <CumulativeSpend
                        spend={employee.cumulative_spend}
                        title={''}
                      />
                    </ErrorBoundary>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </React.Fragment>
      )}

      <div className="my-5"></div>

      {isSuccess && (
        <Row className="mt-4">
          <Col>
            <ErrorBoundary
              fallback={DefaultError}
              beforeCapture={(scope) => {
                scope.setTag('component', 'TransactionTable')
              }}
            >
              <TransactionTable
                employees={[employee]}
                filtersEnabled={true}
                filters={[
                  'external-id',
                  'vendor',
                  'amount',
                  'analytics',
                  'date',
                  'flag-count',
                  'more-filters'
                ]}
                title={'Transactions for Employee'}
                source={employee.external_id}
                downloadButton={false}
                columns={[
                  'save',
                  'id',
                  'risk_score',
                  'analytics_count',
                  'transaction_on',
                  'line_amount',
                  'vendor'
                ]}
              />
            </ErrorBoundary>
          </Col>
        </Row>
      )}
    </Page>
  )
}

export default Employee
