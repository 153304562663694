import React, { useState } from 'react'
import Shield from 'assets/Shield/Gold/ThirdLineShieldGold.svg'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'

type AnalyticsShieldProps = {
  count: string,
  analytics: {
    id: string;
    name: string;
    description: string;
  }[]
}

function AnalyticsShield({ count, analytics }: AnalyticsShieldProps) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <React.Fragment>
      <Button
        className="px-1 py-0 bg-light text-primary border border-secondary fw-normal"
        onClick={handleShow}
        data-testid="analytics-shield-button"
      >
        <img src={Shield} className="me-2" alt="" style={{ height: '15px' }} />
        <small>
          <span className="fw-bolder">{count}</span> Analytics
        </small>
      </Button>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header className="fw-light d-flex flex-row justify-content-between">
          <div className="d-flex fs-5">
            <img
              src={Shield}
              className="me-4"
              alt=""
              style={{ height: '30px' }}
            />
            <span className="fw-normal me-2">Analytics</span> {count}
          </div>
          <div className="d-flex">
            <Button variant="outline-primary" size="sm" onClick={handleClose}>
              Close
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Table className="border border-light">
            <thead>
              <tr className="text-muted">
                <th>Name</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody className="border-top">
              {analytics.map((a) => (
                <tr key={`analytic-modal-row-${a.id}`}>
                  <td className="fw-bolder">{a.name}</td>
                  <td className="text-muted">{a.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default AnalyticsShield
