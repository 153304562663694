import React, { useState } from 'react'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AnalyticsTable from './table'
import { useAnalytics } from 'api/hooks/use-analytics'
import { useEffect } from 'react'
import AnalyticsFilters from 'shared/analytics/filters'

function Analytics() {
  const emptyParams = {
    search: '',
    page: 1,
    perPage: 25,
    section_id: '',
    tag: '',
    fraudTag: '',
    orderBy: 'name',
    orderDirection: 'asc'
  }
  const [params, setParams] = useState(emptyParams)

  useEffect(() => {
    setParams((params) => ({ ...params, page: 1 }))
  }, [params.perPage, params.section, params.tag, params.debouncedSearch])

  const {
    isSuccess,
    isError,
    isLoading,
    data: analytics
  } = useAnalytics({ params })

  return (
    <Page title="Analytics">
      <PageTitle />

      <AnalyticsFilters setParams={setParams} params={params} />
      <Row className="mt-4">
        <Col>
          <AnalyticsTable
            params={params}
            setParams={setParams}
            isSuccess={isSuccess}
            analytics={analytics}
            isError={isError}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </Page>
  )
}

export default Analytics
