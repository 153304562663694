import React, { useState, useEffect } from 'react'
import FormControl from 'react-bootstrap/FormControl'
import { useDebounce } from 'api/hooks/use-debounce'

function ReferenceNumber({ params, setParams }) {
  const [referenceNumber, setReferenceNumber] = useState(
    params.reference_number
  )
  const debouncedReference = useDebounce(referenceNumber, 500)

  useEffect(() => {
    setParams((params) => ({
      ...params,
      reference_number: debouncedReference
    }))
  }, [debouncedReference, setParams])

  return (
    <FormControl
      type="text"
      data-testid="workpaper-reference-input"
      value={referenceNumber}
      onChange={(e) => setReferenceNumber(e.target.value)}
      placeholder="Provide workpaper reference for evidence"
    />
  )
}

export default ReferenceNumber
