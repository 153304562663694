import React from 'react'
import Form from 'react-bootstrap/Form'

function SampleHighRisk({ auditReport, setAuditReport }) {
  return (
    <Form.Group>
      <Form.Control
        type="number"
        min={0}
        value={auditReport.highRisk}
        onChange={(e) =>
          setAuditReport({
            ...auditReport,
            highRisk: e.target.value
          })
        }
        placeholder=""
      />
    </Form.Group>
  )
}

export default SampleHighRisk
