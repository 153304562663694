import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronCircleUp,
  faChevronCircleDown
} from '@fortawesome/free-solid-svg-icons'

interface ChangeStatProps {
  data: string;
}

function ChangeStat({ data }: ChangeStatProps) {
  const change = Math.round(100 * parseFloat(data)) / 100 || 0
  const colorClass = change < 0 ? 'text-success' : 'text-danger'
  const icon = change < 0 ? faChevronCircleDown : faChevronCircleUp

  return (
    <div>
      <div className="text-muted">Change in Risk Rate</div>
      <div className="mt-2 h4">
        <FontAwesomeIcon
          icon={icon}
          data-testid="change-icon"
          className={colorClass}
        />{' '}
        <span data-testid="change-value" className="fw-bold">
          {change}
        </span>
      </div>
    </div>
  )
}

export default ChangeStat
