import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function TransactionsByMonth({ countByMonth }) {
  const options = {
    chart: {
      type: 'line'
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: Object.keys(countByMonth)
    },
    yAxis: {
      title: {
        text: 'Count'
      }
    },
    tooltip: {
      headerFormat: '{point.y} transactions in the month of {point.x}',
      pointFormat: ''
    },
    series: [
      {
        name: 'Transaction by Month',
        data: Object.values(countByMonth)
      }
    ]
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

TransactionsByMonth.propTypes = {
  countByMonth: PropTypes.object.isRequired
}

export default TransactionsByMonth
