import React from 'react'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useDestroyAuditReportUser } from 'api/hooks/use-audit-report-user'
import { useQueryClient } from 'react-query'

function UserRow({ auditReportUser, edittable }) {
  const queryClient = useQueryClient()
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries([
        `reports/${auditReportUser.audit_report_id}`
      ])
      queryClient.invalidateQueries('reports')
    }
  }
  const { mutate: destroyMutation, isLoading } = useDestroyAuditReportUser({
    id: auditReportUser.id,
    options
  })

  return (
    <tr>
      <td>
        {auditReportUser.first_name} {auditReportUser.last_name}
        <div className="text-muted fw-light">
          <small>{auditReportUser.email}</small>
        </div>
      </td>
      <td className="float-end border-bottom-0">
        {edittable && (
          <Button
            variant="light"
            className="bg-light"
            onClick={() => destroyMutation()}
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={faTrash} className="text-danger" />
          </Button>
        )}
      </td>
    </tr>
  )
}

export default UserRow
