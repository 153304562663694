import { useContext } from 'react'
import { useQueryClient, useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getTransactions = async (token, params) => {
  const url = 'transactions'

  const data = await apiGet({ url, token, params })
  return data
}

export default function useTransactions({ params, options }) {
  const token = useContext(AuthContext)
  const queryKey = ['transactions', params]
  const queryFn = () => getTransactions(token, params)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}

const getTransaction = async (token, id, params) => {
  const url = `transactions/${id}`

  const data = await apiGet({ url, token, params })
  return data
}

export function useTransaction({ id, params, options }) {
  const token = useContext(AuthContext)
  const queryKey = `transactions/${id}`
  const queryFn = () => getTransaction(token, id, params)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}

export function usePrefetchTransaction({ id }) {
  const queryClient = useQueryClient()
  const token = useContext(AuthContext)
  const queryKey = `transactions/${id}`
  const params = {}

  if (typeof id === 'string' && id.length > 0) {
    queryClient.prefetchQuery(
      queryKey,
      () => getTransaction(token, id, params),
      {
        staleTime: 30000
      }
    )
  }
}
