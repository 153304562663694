import {
  isEmpty,
  isNumber,
  isNaN,
  pickBy
} from 'lodash'

export const getMax = (object) => {
  let max = Math.max(...Object.values(object))
  return Object.keys(object).filter((key) => object[key] === max)
}

export const calculatePercent = (num, denom) => {
  if (denom === 0 || num === null || denom === null) {
    return 0
  } else {
    return Math.round((100 * num) / denom)
  }
}

export const calculateDifference = (first, second) => {
  if (first === null || second === null) {
    return 0
  } else {
    return (first - second).toFixed(2)
  }
}

export const flagsPerTransaction = (num, denom) => {
  if (denom === 0 || num === null || denom === null) {
    return 0
  } else {
    return Math.round(num / denom)
  }
}

export const noDigitMoney = (money) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }).format(money)
}

export const twoDigitMoney = (money) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).format(money)
}

export const mapObjectsToOptions = (
  arrayOfObj,
  labelKey = 'name',
  valKey = 'id'
) => arrayOfObj.map((obj) => ({ label: obj[labelKey], value: obj[valKey] }))

export const mapObjectToOption = (obj, labelKey = 'name', valKey = 'id') => {
  return { label: obj[labelKey], value: obj[valKey] }
}

export const isBlank = (value) => {
  return (isEmpty(value) && !isNumber(value)) || isNaN(value)
}

export function titleize(sentence) {
  if (!sentence || !sentence.split) return sentence

  const _titleizeWord = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

  sentence = sentence.replace(/_/g, ' ')
  sentence = sentence.replace('/', ' / ')
  const result = sentence.split(' ').map((word) => _titleizeWord(word))

  return result.join(' ')
}

export function pickPresentParams(params) {
  return pickBy(params, (value) => !!value && value.length !== 0)
}

export function toKebabCase(str) {
  return str
    .toLowerCase() // Convert to lowercase
    .trim() // Trim leading/trailing spaces
    .replace(/\s+/g, '-') // Replace spaces with hyphens
}

export function displayPercent(value) {
  return value ? `${Math.round(100 * value)}%` : ''
}

// Convert snake_case to camelCase
export function toCamelCase(obj) {
  if (Array.isArray(obj)) {
    return obj.map((v) => toCamelCase(v)) // Handle arrays recursively
  } else if (obj !== null && obj.constructor === Object) {
    const newObj = {}
    Object.keys(obj).forEach((key) => {
      const camelKey = key.replace(/(_\w)/g, (k) => k[1].toUpperCase())
      newObj[camelKey] = toCamelCase(obj[key])
    })
    return newObj
  }
  return obj
}

// Convert camelCase to snake_case
export function toSnakeCase(obj) {
  if (Array.isArray(obj)) {
    return obj.map((v) => toSnakeCase(v))
  } else if (obj !== null && obj.constructor === Object) {
    const newObj = {}
    Object.keys(obj).forEach((key) => {
      const snakeKey = key.replace(/([A-Z])/g, '_$1').toLowerCase()
      newObj[snakeKey] = toSnakeCase(obj[key])
    })
    return newObj
  }
  return obj
}

export function prepParams(params) {
  return toSnakeCase(pickPresentParams(params))
}