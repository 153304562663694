import React from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Alert, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { useSodCounts } from 'api/hooks/use-sod-counts'
import { calculatePercent } from 'helpers/utils'
import { useNavigate } from 'react-router-dom'


const DiffBadge = ({ diff, subtext }) => {
  const value = Math.abs(diff)

  return (
    <Badge
      pill
      className={`text-center fw-normal ${'text-secondary badge-secondary'}`}
    >
      <span className="ms-1">
        {value}% {subtext}
      </span>
    </Badge>
  )
}

function Stats() {
  const navigate = useNavigate()
  const { isSuccess, data: sodCounts } = useSodCounts({})

  const stats = isSuccess
    ? [
        {
          name: 'Roles Creating Conflicts',
          value: sodCounts?.roles_creating_conflicts_count,
          diff: calculatePercent(
            sodCounts?.roles_creating_conflicts_count,
            sodCounts?.roles_count
          ),
          subtext: 'of All Roles'
        },
        {
          name: 'Roles with Internal Conflicts',
          value: sodCounts?.roles_internal_conflicts_count,
          diff: calculatePercent(
            sodCounts?.roles_internal_conflicts_count,
            sodCounts?.roles_count
          ),
          subtext: 'of All Roles'
        },
        {
          name: 'High Risk Permissions',
          value: sodCounts?.users_high_risk_permissions_count,
          diff: calculatePercent(
            sodCounts?.users_high_risk_permissions_count,
            sodCounts?.active_users_count
          ),
          subtext: 'of Active Users'
        },
        {
          name: 'Super User Permissions',
          value: sodCounts?.superuser_permissions_count,
          diff: calculatePercent(
            sodCounts?.superuser_permissions_count,
            sodCounts?.active_users_count
          ),
          subtext: 'of Active Users'
        }
      ]
    : []
  // TODO: SOD Replace with real data
  const isTerminatedActive = sodCounts?.terminated_users_count > 0

  return (
    <>
      {isTerminatedActive && (
        <Alert
          variant="light"
          className="alert-danger-custom"
          onClick={() => navigate('/users?terminated=true')}
        >
          <Row>
            <Col sm="auto">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-danger"
              />
            </Col>
            <Col>
              <strong className="text-danger">
                {sodCounts?.terminated_users_count} Terminated Users with Active
                Accounts
              </strong>

              <div>
                An active user account has been detected on a terminated
                employee. Please review and take action.
              </div>
            </Col>
          </Row>
        </Alert>
      )}
      <Row>
        {isSuccess &&
          stats.map((stat, index) => (
            <Col key={index} sm={3}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col sm="auto">
                      <h4>{stat.value}</h4>
                    </Col>
                    <Col>
                      {' '}
                      <span className="float-end">
                        <DiffBadge diff={stat.diff} subtext={stat.subtext} />
                      </span>
                    </Col>
                  </Row>

                  <Card.Text className="text-muted">{stat.name}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
    </>
  )
}

export default Stats
