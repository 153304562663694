import React from 'react'
import SelectField from 'shared/forms/SelectField'

function PoStatus({ params, setParams }) {
  const codes = [
    { value: 'Closed', label: 'Closed' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'Created', label: 'Created' },
    { value: 'Allocated', label: 'Allocated' },
    { value: 'Released', label: 'Released' },
    { value: 'Posted', label: 'Posted' },
    { value: 'Printed', label: 'Printed' },
    { value: 'Carry Forward', label: 'Carry Forward' },
  ]

  return (
    <SelectField
      params={params}
      setParams={setParams}
      options={codes}
      accessorKey="poStatusCode"
      title="PO Status"
      showTitle={true}
      placeholder="Select PO Status"
    />
  )
}

export default PoStatus