import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getRiskAssessments = async (token, municipality_id, params) => {
  const url = `municipalities/${municipality_id}/risk_assessments`

  const data = await apiGet({ url, token, params })
  return data
}

export default function useRiskAssessments({ params, options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const queryKey = [`${municipality_id}/risk-assessments`, params]
  const queryFn = () => getRiskAssessments(token, municipality_id, params)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}

const getRiskAssessment = async (token, params) => {
  const url = `municipalities/${params.municipality_id}/risk_assessments/${params.id}`

  const data = await apiGet({ url, token, params })
  return data
}

export function useRiskAssessment({ params, options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const newParams = { ...params, municipality_id: municipality_id }

  return useQuery(
    [`${municipality_id}/risk-assessment`, params],
    () => getRiskAssessment(token, newParams),
    {
      ...options
    }
  )
}
