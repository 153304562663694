import React, { useState, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

function TransactionCount({ filterParams, setFilterParams }) {
  const filterApplied = filterParams.transactionsCount.length !== 0
  const [activeOverlay, setActiveOverlay] = useState(false)
  const transactionsTarget = useRef(null)

  const displayAmountText = () => {
    if (filterParams.transactionsCount.length !== 0) {
      return `> ${filterParams.transactionsCount}`
    } else {
      return '# Transactions'
    }
  }

  return (
    <>
      <Button
        variant={filterApplied ? 'outline-primary' : ''}
        className={
          filterApplied ? 'bg-white text-primary' : 'bg-white text-info'
        }
        ref={transactionsTarget}
        onClick={() => setActiveOverlay(!activeOverlay)}
        style={{ border: filterApplied ? '' : '1px solid #ced4da' }}
        data-testid="transaction-count-button"
      >
        {displayAmountText()}
      </Button>
      <Overlay
        target={transactionsTarget.current}
        show={activeOverlay}
        placement="bottom"
      >
        <Popover style={{ minWidth: '225px' }} className="shadow-sm">
          <Popover.Header>
            <Row>
              <Col sm={6} className="d-grid">
                {filterApplied > 0 ? (
                  <Button
                    variant="link"
                    size="sm"
                    className="float-end mt-1"
                    onClick={() =>
                      setFilterParams({
                        ...filterParams,
                        transactionsCount: ''
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                    Clear
                  </Button>
                ) : null}
              </Col>
              <Col sm={6} className="d-grid">
                <Button
                  variant="primary"
                  size="sm"
                  className="float-end"
                  onClick={() => setActiveOverlay(false)}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Popover.Header>
          <Popover.Body>
            <Row>
              <Col>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">
                    {'>'}
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    data-testid="transaction-count-field"
                    onChange={(e) =>
                      setFilterParams({
                        ...filterParams,
                        transactionsCount: e.target.value,
                        page: 1
                      })
                    }
                    min={0}
                    value={filterParams.transactionsCount}
                    required
                    placeholder=""
                  />
                  <InputGroup.Text id="inputGroupPrepend">
                    Transactions
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  )
}

export default TransactionCount
