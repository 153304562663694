import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import IssueBadge from './issue-badge'
import { Card } from 'react-bootstrap'

function IssueModal({ issue, show, setShow, markAsPending }) {
  return (
    <React.Fragment>
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Issue Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {issue && (
            <>
              <Card className="mt-8 mb-4">
                <Card.Body>
                  <Row>
                    <Col sm={9} className="border-right border-light">
                      {issue.name}
                    </Col>
                    <Col sm={3}>
                      <div className="align-middle">
                        <IssueBadge status={issue.status} />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <hr className="my-4" />

              <Row>
                <Col>
                  <h6>
                    <strong>Resolution</strong>
                  </h6>
                  <p>{issue.resolution}</p>
                </Col>
              </Row>

              <ul>
                {issue.actions.map((action, index) => (
                  <li key={`action-${index}`}>
                    <a href={`/${issue.dataType}/${action.id}`}>{action.name}</a>
                  </li>
                ))}
              </ul>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {issue && (
            <>
              <Button variant="light" onClick={() => setShow(false)}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setShow(false)
                  markAsPending()
                }}
                disabled={issue.status !== 'Unresolved'}
              >
                Mark as Pending
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default IssueModal
