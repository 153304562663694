import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark as faSolidBookmark } from '@fortawesome/free-solid-svg-icons'
import { useDestroySavedTransaction } from 'api/hooks/use-saved-transaction'
import CreateBookmark from 'shared/create-bookmark'

function BookmarkTransaction({ transaction }) {
  const { mutate: destroyMutation, isLoading: destroyLoading } =
    useDestroySavedTransaction({
      id: transaction.saved_transaction?.id
    })

  return (
    <React.Fragment>
      {destroyLoading ? (
        <CreateBookmark transaction={transaction} />
      ) : (
        <div onClick={() => destroyMutation()}>
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            className={'text-warning'}
            icon={faSolidBookmark}
          />
        </div>
      )}
    </React.Fragment>
  )
}

export default BookmarkTransaction
