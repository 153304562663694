import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getVendorAnalyticCounts = async (token, vendorId) => {
  const url = `vendors/${vendorId}/counts`
  const data = await apiGet({ url, token })
  return data
}

export default function useVendorAnalyticCounts({ params, options }) {
  const token = useContext(AuthContext)
  const queryFn = () => getVendorAnalyticCounts(token, params.vendorId)
  const queryKey = `vendors/${params.vendorId}/counts`
  return useQuery(queryKey, queryFn, { ...options })
}
