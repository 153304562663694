import React, { useState } from 'react'
import Table from 'react-bootstrap/Table'
import IssueModal from './issue-modal'
import IssueBadge from './issue-badge'

function HealthTable() {
  const [show, setShow] = useState(false)
  const [currentIssue, setCurrentIssue] = useState(null)
  const defaultIssues = [
    {
      name: 'A User in a specific department that has access to other departments information',
      status: 'No Issues',
      resolution: 'Restrict access to the following Users',
      count: 0,
      dataType: 'users',
      actions: []
    },
    {
      name: 'Users able enter fictitious vendor invoices and then render payment to the vendor',
      status: 'Unresolved',
      resolution: 'Fix the segregation of duty issues for the following Users',
      count: 2,
      dataType: 'users',
      actions: [
        { id: 'b40c2402-c745-4f93-8661-29e05b6e1218', name: 'Elma Kuphal21' },
        { id: '2f1ebf13-71a5-48cc-8573-af21f0e50663', name: 'Lacy Nicolas97' }
      ]
    },
    {
      name: 'Roles that allow users to create vendors and issue payments to that vendor',
      status: 'Unresolved',
      resolution: 'Fix the segregation of duty issues for the following Roles',
      count: 3,
      dataType: 'roles',
      actions: [
        {
          id: '0f09d5bb-43fe-4d62-a8e6-0c56b242bf43',
          name: 'Corporate Community-Services Executive'
        },
        {
          id: '695e6fb0-18b1-444e-938b-87a596dcaa2d',
          name: 'Global Manufacturing Associate'
        },
        {
          id: 'ba0734f5-654f-4477-887d-e75ef327e024',
          name: 'Government Orchestrator'
        }
      ]
    },
    {
      name: 'Large Number of Users with Access to Risky Permission',
      status: 'Unresolved',
      resolution: 'Clear or fix Risky Permissions given Users',
      count: 10,
      dataType: 'users',
      actions: [
        { id: 'ce31e55f-76ac-480d-af1f-98983ef072af', name: 'Luis Senger2' },
        {
          id: '7c1649cc-cf54-497f-913b-6d573477e503',
          name: "Diego O'Connell IV99"
        },
        {
          id: '32d9f88c-8512-4a9e-a579-d290c973b9a4',
          name: 'Mr. Jesse Ratke23'
        },
        {
          id: '967be073-12de-425d-afe9-a6db6fb633ed',
          name: 'Lewis Turcotte70'
        },
        { id: '75c5971b-b5f1-420b-9ab3-aad8fa505402', name: 'Tawny Bayer15' },
        { id: '92426f64-348a-4352-a9b2-c3f493eecb72', name: 'Shena Hahn1' },
        {
          id: '6f50a9af-a62f-4a84-81dc-2e94589f83eb',
          name: 'Brigette Schimmel Jr.17'
        },
        { id: '5fa8285b-3328-43f1-a6bf-46bee7a521d2', name: 'Erich Haley74' },
        {
          id: 'b66575f3-a2c3-4d7e-b2b6-060e4cbfb085',
          name: 'Miss Regine VonRueden34'
        },
        {
          id: 'c13f0046-7449-44ee-b439-da0248a2c8ca',
          name: 'Marline Gorczany22'
        }
      ]
    },
    {
      name: 'Duplicate Roles',
      status: 'Unresolved',
      resolution:
        'Consolidate the Roles with the same permissions to reduce the number of Roles',
      count: 2,
      dataType: 'roles',
      actions: [
        {
          id: 'd203d274-8684-422f-becf-b92b97cb238a',
          name: 'Lead Real-Estate Coordinator'
        },
        {
          id: '44af5c32-c6bc-4607-ae2d-0e7482dbb5b8',
          name: 'Customer Associate'
        }
      ]
    },
    {
      name: 'Users with Menu Access they have not used in 12 months',
      status: 'Unresolved',
      resolution: 'Update Menu access for the following Users',
      count: 3,
      dataType: 'users',
      actions: [
        {
          id: '86342939-d571-46dd-9290-c07609c92e63',
          name: 'Elfrieda Walker97'
        },
        {
          id: '776ff74c-edb5-4a7e-bce2-cac5bfdd0bbb',
          name: 'Rep. Darron Waters39'
        },
        {
          id: 'a34dfa6f-5292-4c4f-96ac-8ad7ff427cd3',
          name: 'Riley Ruecker CPA38'
        }
      ]
    },
    {
      name: 'A User that has permissions that span across multiple functional areas (AP, payroll)',
      status: 'Unresolved',
      resolution: 'Review the permissions for the following Users',
      count: 1,
      dataType: 'users',
      actions: [
        {
          id: 'ae5729f6-b78a-40ba-a0e1-818d345b5d63',
          name: 'Doreatha Jacobson3'
        }
      ]
    },
    {
      name: 'A Role that has permissions that span across multiple functional areas (AP, Payroll)',
      status: 'Unresolved',
      resolution: 'Review the permissions for the following Roles',
      count: 4,
      dataType: 'roles',
      actions: [
        {
          id: 'c9100107-e687-4ac7-b8b2-b9a0ab397abc',
          name: 'Customer IT Technician'
        },
        {
          id: '0f09d5bb-43fe-4d62-a8e6-0c56b242bf43',
          name: 'Corporate Community-Services Executive'
        },
        {
          id: '695e6fb0-18b1-444e-938b-87a596dcaa2d',
          name: 'Global Manufacturing Associate'
        },
        {
          id: 'ba0734f5-654f-4477-887d-e75ef327e024',
          name: 'Government Orchestrator'
        }
      ]
    },
    {
      name: 'Terminated Employees with Enabled ERP Access',
      status: 'No Issues',
      resolution: 'Disable access for the following Users',
      count: 3,
      dataType: 'users',
      actions: [
        { id: '75c5971b-b5f1-420b-9ab3-aad8fa505402', name: 'Tawny Bayer15' },
        { id: '92426f64-348a-4352-a9b2-c3f493eecb72', name: 'Shena Hahn1' },
        {
          id: '6f50a9af-a62f-4a84-81dc-2e94589f83eb',
          name: 'Brigette Schimmel Jr.17'
        }
      ]
    },
    {
      name: 'Active Roles with No Users',
      status: 'No Issues',
      resolution: 'Disable or remove unused roles',
      count: 2,
      dataType: 'roles',
      actions: [
        {
          id: 'ba3d7993-bf93-4d6f-816d-59aa8b4d46cd',
          name: 'Administration Designer'
        },
        {
          id: '5b685951-3bda-4308-bf7d-e3b7d859ee8a',
          name: 'Hospitality Assistant'
        }
      ]
    }
  ]
  const [issues, setIssues] = useState(defaultIssues)

  const markAsPending = () => {
    const updatedIssues = issues.map((issue) => {
      if (issue.name === currentIssue.name) {
        return { ...issue, status: 'Pending' }
      }
      return issue
    })
    setIssues(updatedIssues)
  }

  return (
    <>
      <Table bordered hover responsive size="sm">
        <thead>
          <tr>
            <th>Status</th>
            <th>Issue</th>
          </tr>
        </thead>
        <tbody>
          {issues.map((issue, index) => (
            <tr
              key={`issue-${index}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShow(true)
                setCurrentIssue(issue)
              }}
            >
              <td className="align-middle text-center">
                <IssueBadge status={issue.status} count={issue.count} />
              </td>
              <td className="p-2">{issue.name}</td>
            </tr>
          ))}
          <IssueModal
            show={show}
            setShow={setShow}
            issue={currentIssue}
            markAsPending={markAsPending}
          />
        </tbody>
      </Table>
    </>
  )
}

export default HealthTable
