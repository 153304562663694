import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPaper, faCheck, faShare, faTimesCircle, faPen } from '@fortawesome/free-solid-svg-icons'
import Badge from 'react-bootstrap/Badge'

function WorkflowActionBadge({ action }) {
  const badgeClass = () => {
    if (action === 'A') {
      return 'badge-success'
    } else if (action === 'R') return 'badge-danger'
    else return 'badge-secondary'
  }

  const badgeIcon = () => {
    if (action === 'A') {
      return faCheck
    } else if (action === 'R') return faTimesCircle
    else if(action === 'F') return faShare
    else if(action === 'C') return faPen
    else if(action === 'H') return faHandPaper
  }

  return (
    <Badge pill className={`text-center fw-normal ${badgeClass()}`}>
      <FontAwesomeIcon icon={badgeIcon()} className="me-1" />
      {action}
    </Badge>
  )
}

export default WorkflowActionBadge
  