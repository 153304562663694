import React from 'react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import AuthenticatedApp from './authenticated-app'
import { useAuth0 } from '@auth0/auth0-react'
import UnauthenticatedApp from './unauthenticated-app'
import { split } from 'lodash'
import EmptyPage from 'layout/empty-page'
import EmptyAdminPage from 'layout/sod/empty-page'

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://5fa2229c6a084d67bd0115a68399ab0b@o1181705.ingest.sentry.io/6295303',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  })
}

function App() {
  const queryClient = new QueryClient()
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const fullPath = window.location.pathname
  const path = split(fullPath, '/')[1]

  if (path === 'login') {
    loginWithRedirect()
  }

  if (isLoading) {
    const sodApp = localStorage.getItem('sodApp')
    if (sodApp) {
      return <EmptyAdminPage />
    } else {
      return <EmptyPage />
    }
  }

  return (
    <div>
      {isAuthenticated ? (
        <QueryClientProvider client={queryClient}>
          <AuthenticatedApp />
          {process.env.REACT_APP_ENV !== 'production' && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      ) : (
        <UnauthenticatedApp />
      )}
    </div>
  )
}

export default App
