import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Badge from 'react-bootstrap/Badge'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'

function SuperUserPermissionBadge({ count, displayCount = true }) {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>Super User Permissions</Tooltip>}
    >
      <Badge
        pill
        className={'text-center text-danger bg-light fw-normal ms-2'}
        style={{ cursor: 'pointer' }}
        data-testid={'risk-badge'}
      >
        <FontAwesomeIcon icon={faPowerOff} />{' '}
        {displayCount ? count : 'Superuser'}
      </Badge>
    </OverlayTrigger>
  )
}

export default SuperUserPermissionBadge
