import * as React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'
import { toPairs, fromPairs, orderBy, capitalize } from 'lodash'

HighchartsExporting(Highcharts)

function RoleCountChart({ groupBy, conflict }) {
  const analyticCounts = groupBy === 'role' ? conflict.role_counts.reduce((acc, role) => {
    // Use the role_name as the key and conflict_roles as the value for each entry in the resulting object
    acc[role.role_name] = role.conflict_roles
    return acc
  }, {}) : conflict.department_counts.reduce((acc, department) => {
    // Use the department_name as the key and count as the value for each entry in the resulting object
    acc[department.department_name] = department.conflict_departments
    return acc
  }, {})

  // Convert to array, sort by value, then convert back to object
  const sortedAnalyticCountsArray = orderBy(toPairs(analyticCounts), 1, 'desc')

  // If you need the sorted array back as an object
  const sortedAnalyticCountsObject = fromPairs(sortedAnalyticCountsArray)

  const counts =
    sortedAnalyticCountsObject

  const options = {
    chart: {
      type: 'bar'
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    exporting: {
      enabled: true
    },
    xAxis: {
      categories: Object.keys(counts),
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: groupBy === 'role' ? 'Number of Conflicting Roles' : 'Number of Employees with Conflicts by Departments',
      },
      labels: {
        overflow: 'justify'
      }
    },
    tooltip: {
      valueSuffix: ''
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        }
      }
    },
    legend: {
      enabled: false,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 20,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts?.defaultOptions?.legend?.backgroundColor || '#FFFFFF',
      shadow: true
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: `Number of Conflicting ${capitalize(groupBy)}`,
        data: Object.values(counts),
        color: '#E9C46A'
      }
    ]
  }

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        oneToOne={true}
      />
    </div>
  )
}

export default RoleCountChart
