import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchDollar } from '@fortawesome/free-solid-svg-icons'

type FilterStatsProps = {
  reportSummary: {
    total_transactions: number,
    total_analytics: number
  }
}

function FilterStats({ reportSummary }: FilterStatsProps) {
  const transactionValue = reportSummary.total_transactions > 10000 ? '10k+' : reportSummary.total_transactions.toLocaleString()

  return (
    <div className="mt-2">
      <Row>
        <Col sm={12}>
          <div className="my-2 me-2 text-primary">
            Based on your filters, there are{' '}
            <div className="fw-bold my-2">
              <FontAwesomeIcon
                icon={faSearchDollar}
                className="text-primary me-2"
              />
              <span className="me-2">
                {transactionValue}
              </span>
              <span className="fw-light text-info">Transactions</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default FilterStats
