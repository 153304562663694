import * as React from 'react'
import Spinner from 'react-bootstrap/Spinner'

type TableLoadingProps = {
  columns: number
}

function TableLoading({ columns }: TableLoadingProps) {
  return (
    <tr>
      <td colSpan={columns} className="text-center">
        <div className="my-5 py-5">
          <Spinner animation="grow" variant="warning" className="my-5" />
        </div>
      </td>
    </tr>
  )
}

export default TableLoading
