import React from 'react'
import Button from 'react-bootstrap/Button'
import DashboardTitle from 'dashboard/title'

function Tab({
  active,
  setDashboardId,
  dashboard,
  isDashboardLoading,
  params,
  setParams
}) {

  const handleClick = (dashboardId) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('dashboard_id', dashboardId)
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`
    window.history.pushState(null, '', newUrl)
    setDashboardId(dashboardId)
  }


  return (
    <>
      {active ? (
        <Button
          className={
            'fw-bolder border-left border-right border-light rounded-0 my-0 py-2 px-3'
          }
          variant={'white'}
          onClick={() => setDashboardId(dashboard.id)}
        >
          {isDashboardLoading ? (
            dashboard.name
          ) : (
            <DashboardTitle params={params} setParams={setParams} />
          )}
        </Button>
      ) : (
        <Button
          className={
            'border-left border-right border-light rounded-0 my-0 py-2 px-3'
          }
          style={{ backgroundColor: '#f7f7f7' }}
          variant={'link'}
          onClick={() => handleClick(dashboard.id)}
        >
          {dashboard.name}
        </Button>
      )}
    </>
  )
}

export default Tab
