import React from 'react'
import FeatureTable from './table'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AddAttribute from './new'

function Features() {
  return (
    <Row>
      <Col>
        <Card>
          <Card.Body>
            <>
              <div className="d-flex">
                <div className="mx-2">
                  <AddAttribute />
                </div>
              </div>
              <hr />
              <FeatureTable />
            </>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default Features
