import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

function ReportName({ auditReport, setAuditReport }) {
  return (
    <Row>
      <Col sm="3">
        <div className="h5 mt-2">Sample Name</div>
      </Col>
      <Col sm="9">
        <Form.Group>
          <Form.Control
            type="text"
            required
            className="form-control-lg"
            placeholder="Untitled Sample"
            autoFocus
            onChange={(e) =>
              setAuditReport({
                ...auditReport,
                name: e.target.value
              })
            }
          />
          <Form.Control.Feedback type="invalid">
            Enter a sample name
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
  )
}

export default ReportName
