import React, { useContext } from 'react'
import UserContext from 'context/user-context'
import SodAppContext from 'context/sod-app-context'
import AuthenticatedSod from 'authenticated-sod.js'
import AuthenticatedUser from 'authenticated-user.js'

function RenderApp() {
  const currentUser = useContext(UserContext)
  const { sodApp } = useContext(SodAppContext)

  // If the user only has access to the sod app, redirect them to the sod app
  let isSodApp = currentUser.has_sod_app && (sodApp || sodApp === 'true')
  if (currentUser.has_sod_app && !currentUser.has_analytics_app) {
    localStorage.setItem('sodApp', 'true')
    isSodApp = 'true'
  }

  return <>{isSodApp === 'true' ? <AuthenticatedSod /> : <AuthenticatedUser />}</>
}

export default RenderApp
