import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'
import { omit } from 'lodash'

const getDashboardStats = async (token, params, municipalityId) => {
  const url = `municipalities/${municipalityId}/dashboard_stats`
  const data = await apiGet({ url, token, params })
  return data
}

export default function useDashboardStats({ params = {}, options = {} }) {
  const { municipality_id } = useContext(UserContext)
  
  const token = useContext(AuthContext)
  const queryParams = omit(params, ['municipality_id'])
  const queryFn = () => getDashboardStats(token, queryParams, municipality_id)
  const queryKey = [
    `municipalities/${municipality_id}/dashboard_stats`,
    queryParams
  ]
  return useQuery(queryKey, queryFn, { ...options })
}
