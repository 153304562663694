import React, { useState } from 'react'
import useTimePeriods from 'api/hooks/use-time-periods'
import TransactionTable from 'transactions/table'
import Download from 'transactions/table/actions'
import ColumnSelect from 'dashboard/column-select'
import { Row, Col } from 'react-bootstrap'

function TopTransactions({
  setParams,
  params,
  sectionUnit,
  sectionName,
  dashboardId,
  columns = []
}) {
  const showCleared = useState(false)
  const timePeriodParams = {
    time_period: params.time_period
  }
  const { isSuccess: isTimePeriodSuccess, data: timePeriods } = useTimePeriods({
    params: timePeriodParams,
    options: {}
  })

  const startOn = isTimePeriodSuccess ? timePeriods.start_date : ''
  const endOn = isTimePeriodSuccess ? timePeriods.end_date : ''

  const queryParams = {
    perPage: 25,
    orderBy: 'risk_score',
    order: 'desc',
    section_id: params.section_id,
    startOn: startOn,
    endOn: endOn,
    minimumAmount: params.minimum_amount,
    maximumAmount: params.maximum_amount,
    showCleared: showCleared,
    vendors: params.vendors.filter((v) => v.options === 'include'),
    excludedVendors: params.vendors.filter((v) => v.options === 'exclude'),
    employees: params.employees.filter((e) => e.options === 'include'),
    excludedEmployees: params.employees.filter((e) => e.options === 'exclude'),
    analytics: params.analytics,
    org_ids: params.orgs,
    object_ids: params.objects,
    project_ids: params.projects,
    seg_one_ids: params.segOnes,
    seg_two_ids: params.segTwos,
    seg_three_ids: params.segThrees,
    seg_four_ids: params.segFours,
    seg_five_ids: params.segFives,
    seg_six_ids: params.segSixes,
    seg_seven_ids: params.segSevens,
    seg_eight_ids: params.segEights,
    poIsPendingWorkflow: params?.poIsPendingWorkflow,
    poMinPoLiquidatedAmount: params?.poMinPoLiquidatedAmount,
    poMaxPoLiquidatedAmount: params?.poMaxPoLiquidatedAmount,
    poMinPoRemainingAmount: params?.poMinPoRemainingAmount,
    poMaxPoRemainingAmount: params?.poMaxPoRemainingAmount,
    poMinPoPercentLiquidated: params?.poMinPoPercentLiquidated,
    poMaxPoPercentLiquidated: params?.poMaxPoPercentLiquidated,
    poMinReqToPoCreation: params?.poMinReqToPoCreation,
    apMinInvoiceToEntryDays: params?.apMinInvoiceToEntryDays,
    apMinEntryToPaymentDays: params?.apMinEntryToPaymentDays,
    apMinTotalDaysToPayment: params?.apMinTotalDaysToPayment,
    apCheckType: params?.apCheckType,
    poStatusCode: params?.poStatusCode
  }

  return (
    <div
      className="bg-white border border-1 rounded"
      style={{ borderColor: '#D2D2D2' }}
    >
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center p-2">
            <div className="fw-bold">{sectionUnit && `${sectionUnit}s`}</div>
            <div className="d-flex">
              <div className="fw-bold">
                {false && (
                  <ColumnSelect
                    params={params}
                    setParams={setParams}
                    sectionId={params.section_id}
                  />
                )}
              </div>
              <div className="fw-bold me-2">
                <Download
                  defaultName={'Export'}
                  source="Views"
                  tableFilters={queryParams}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {isTimePeriodSuccess && queryParams.section_id.length > 0 && (
        <TransactionTable
          filtersEnabled={false}
          downloadButton={false}
          sectionId={queryParams.section_id}
          orderBy={queryParams.orderBy}
          perPage={queryParams.perPage}
          startOn={queryParams.startOn}
          endOn={queryParams.endOn}
          vendors={queryParams.vendors}
          minimumAmount={queryParams.minimumAmount}
          maximumAmount={queryParams.maximumAmount}
          showCleared={queryParams.showCleared}
          excludedVendors={queryParams.excludedVendors}
          analytic={queryParams.analytics}
          employees={queryParams.employees}
          excludedEmployees={queryParams.excludedEmployees}
          orgs={queryParams.org_ids}
          objects={queryParams.object_ids}
          projects={queryParams.project_ids}
          segOnes={queryParams.seg_one_ids}
          segTwos={queryParams.seg_two_ids}
          segThrees={queryParams.seg_three_ids}
          segFours={queryParams.seg_four_ids}
          segFives={queryParams.seg_five_ids}
          segSixes={queryParams.seg_six_ids}
          segSevens={queryParams.seg_seven_ids}
          segEights={queryParams.seg_eight_ids}
          riskScore={queryParams.riskScore}
          poIsPendingWorkflow={queryParams.poIsPendingWorkflow}
          poMinReqToPoCreation={queryParams.poMinReqToPoCreation}
          poStatusCode={queryParams.poStatusCode}
          poMinPoLiquidatedAmount={queryParams.poMinPoLiquidatedAmount}
          poMaxPoLiquidatedAmount={queryParams.poMaxPoLiquidatedAmount}
          poMinPoRemainingAmount={queryParams.poMinPoRemainingAmount}
          poMaxPoRemainingAmount={queryParams.poMaxPoRemainingAmount}
          poMinPoPercentLiquidated={queryParams.poMinPoPercentLiquidated}
          poMaxPoPercentLiquidated={queryParams.poMaxPoPercentLiquidated}
          apMinInvoiceToEntryDays={queryParams.apMinInvoiceToEntryDays}
          apMinEntryToPaymentDays={queryParams.apMinEntryToPaymentDays}
          apMinTotalDaysToPayment={queryParams.apMinTotalDaysToPayment}
          apCheckType={queryParams.apCheckType}
          source={`${sectionName} - ${sectionUnit}s`}
          columns={columns}
          footerEnabled={true}
          title={''}
          dashboardId={dashboardId}
          showStats={true}
        />
      )}
    </div>
  )
}

export default TopTransactions
