import React from 'react'
import { useRiskBreakdown } from 'api/hooks/use-risk-breakdowns'
import GroupedRiskBarChart from './grouped-risk-bar-chart';

function GroupedChartContainer({ source, id, timeframe, grouping }) {
  const params = {
    source: source,
    id: id,
    timeframe: timeframe,
    grouping: grouping
  }
  const { isSuccess, data } = useRiskBreakdown({ params })
  
  return (
    <div>
      {isSuccess && data.risk.length === 0 && (
        <div
          style={{ minHeight: '300px' }}
          className="d-flex align-items-center justify-content-center"
        >
          <h3>No {grouping} Data Found</h3>
        </div>
      )}
      {isSuccess && data.risk.length > 0 && (
        <GroupedRiskBarChart
          source={source}
          id={id}
          data={data}
          grouping={grouping}
        />
      )}
    </div>
  )
}

export default GroupedChartContainer