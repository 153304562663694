import { useContext } from 'react'
import { useMutation } from 'react-query'
import { apiPatch, apiPost } from 'api'
import AuthContext from 'context/auth-context'

const createReview = async (token, params) => {
  const objectName = 'review'
  const url = `transactions/${params.reviewable_id}/reviews`

  const response = await apiPost({ url, token, objectName, params })
  return response
}

export function useCreateReview({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return createReview(token, params)
    },
    { ...options }
  )
}

const updateReview = async (token, params) => {
  const objectName = 'review'
  const url = `reviews/${params.id}`

  const response = await apiPatch({ url, token, objectName, params })
  return response
}

export function useUpdateReview({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return updateReview(token, params, options)
    },
    { ...options }
  )
}
