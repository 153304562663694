import React from 'react'
import Page from 'layout/page'
import Spinner from 'react-bootstrap/Spinner'

function EmptyPage() {
  return (
    <Page title="">
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <Spinner animation="grow" variant="warning" />
      </div>
    </Page>
  )
}

export default EmptyPage
