import React from 'react'
import Table from 'react-bootstrap/Table'
import { twoDigitMoney } from 'helpers/utils'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Allocations({ allocations }) {
  return (
    <Row className="mb-4">
      <Col>
        <h5>Allocations</h5>
        <Table className="border border-light" size="sm">
          <thead className="border-bottom border-light">
            <tr>
              <th>Org</th>
              <th>Obj</th>
              <th>Line</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {allocations.map((a) => (
              <tr key={`allocations-${a.id}`}>
                <td>
                  {a.org.name} ({a.org.external_id})
                </td>
                <td>
                  {a.object.name} ({a.object.external_id})
                </td>
                <td>
                  {a.line}
                </td>
                <td>{twoDigitMoney(a.amount)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export default Allocations
