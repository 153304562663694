import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getRoleConflict = async (token, params) => {
  const url = `roles/${params.role_id}/conflicts/${params.analytic_id}`

  const data = await apiGet({ url, token, params })
  return data
}

export function useRoleConflict({ params, options = {} }) {
  const token = useContext(AuthContext)

  return useQuery(['role', params], () => getRoleConflict(token, params), {
    ...options
  })
}
