import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useDeleteDashboardEmployee } from 'api/hooks/use-dashboard-employees'
import { useQueryClient } from 'react-query'

function DeleteEmployee({ addOrRemoveEmployee, employee, dashboardId}) {
  const queryClient = useQueryClient()
  const { mutate } = useDeleteDashboardEmployee({
    id: employee.dashboard_employee_id,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `dashboards/${dashboardId}`
        )
      }
    }
  })

  return (
    <button
      className="btn btn-sm btn-link text-danger"
      onClick={() => {
        addOrRemoveEmployee(employee)
        mutate()
      }}
    >
      <FontAwesomeIcon icon={faTrash} />
    </button>
  )
}

export default DeleteEmployee