import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import TableLoading from 'shared/tables/table-loading'
import StatusBadge from 'shared/badges/status-badge'
import { faUserSlash } from '@fortawesome/free-solid-svg-icons'
import { useRoleEmployees } from 'api/hooks/use-role-employees'

function EmployeesTab({ role }) {
  const defaultParams = { 
    role_id: role.id 
  }
  const { data, isSuccess, isLoading } = useRoleEmployees({ params: defaultParams })

  return (
    <Row>
      <Col sm={12}>
        <Card className="border">
          <Card.Header className="bg-white">
            <div className="text-info">Roles</div>
          </Card.Header>
          <Card.Body>
            <Table responsive size="sm">
              <thead>
                <tr>
                  <th>Name</th>
                </tr>
              </thead>

              <tbody>
                {isLoading && <TableLoading columns={1} />}
                {isSuccess &&
                  data.map((a) => (
                    <tr key={`role-row-${a.id}`}>
                      <td>
                        <a
                          href={`/users/${a.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {a.external_id}{' '}
                        </a>
                        <span className="ms-2 fw-light text-muted">
                          {a.department_name}
                        </span>
                        {a.status === 'inactive' ? (
                          <span className="ms-2">
                            <StatusBadge
                              title="Disabled"
                              icon={faUserSlash}
                              tooltipText="Account is not active"
                            />
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default EmployeesTab