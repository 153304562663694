import React from 'react'
import { calculatePercent } from 'helpers/utils'
import ProgressBar from 'react-bootstrap/ProgressBar'
import {
  faRetweet,
  faExclamation,
  faDotCircle,
  faCheck
} from '@fortawesome/free-solid-svg-icons'
import ReportStat from './report-stat'

type SummaryProps = {
  report: {
    open_count: number,
    in_progress_count: number,
    exception_count: number,
    no_exception_count: number,
    total_count: number,
    complete_count: number,
    transactions_count: number
  }
  
}

function Summary({ report }: SummaryProps) {
  const stats = [
    {
      text: 'Unprocessed',
      icon: faDotCircle,
      count: report.total_count - report.complete_count,
      colorClass: 'text-muted'
    },
    {
      text: 'In Progress',
      icon: faRetweet,
      count: report.in_progress_count,
      colorClass: 'text-warning'
    },
    {
      text: 'Exceptions',
      icon: faExclamation,
      count: report.exception_count,
      colorClass: 'text-danger'
    },
    {
      text: 'No Exception',
      icon: faCheck,
      count: report.no_exception_count,
      colorClass: 'text-success'
    }
  ]
  
  return (
    <React.Fragment>
      <p>
        {stats.map((s) => (
          <ReportStat
            key={`report-stat-${s.text}`}
            icon={s.icon}
            text={s.text}
            count={s.count}
            colorClass={s.colorClass}
          />
        ))}
      </p>

        <ProgressBar 
            now={calculatePercent(
              report.complete_count,
              report.total_count
            )} 
            label={`${calculatePercent(
            report.complete_count,
            report.total_count
          )}%`}
         />
      <p className="mt-1 mb-0 text-primary ms-1">
        {calculatePercent(report.complete_count, report.total_count)}%{' '}
        <span className="fw-light text-muted">
          {' '}
          of Total Transactions Processed
        </span>
      </p>
    </React.Fragment>
  )
}

export default Summary
