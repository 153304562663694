import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getRoles = async (token, municipalityId) => {
  const url = `municipalities/${municipalityId}/roles?as_options=true`
  const data = await apiGet({ url, token })
  return data
}

export default function useRoles({ options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const queryFn = () => getRoles(token, municipality_id)
  const queryKey = 'roles?as_options=true'
  return useQuery(queryKey, queryFn, { ...options })
}

const getRole = async (token, params) => {
  const url = `roles/${params.id}`

  const data = await apiGet({ url, token, params })
  return data
}

export function useRole({ params }) {
  const token = useContext(AuthContext)

  return useQuery(['role', params], () => getRole(token, params))
}
