import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import ConflictDetails from './conflict-details'
import InternalConflict from '../badges/internal-conflict'
import Card from 'react-bootstrap/Card'

function ConflictsTab({ employee, activeKey, setActiveKey, isSuccess}) {
  return (
    <Row>
      <Col sm={12}>
        {isSuccess && employee.analytics.length === 0 && (
          <Card className='py-4'>
            <Card.Body>
              <div className="text-center text-muted">No conflicts found</div>
            </Card.Body>
          </Card>
        )}
        <Accordion activeKey={activeKey} flush>
          {isSuccess &&
            employee.analytics.map((a) => (
              <Accordion.Item eventKey={`${a.id}`} key={`conflict-${a.id}`}>
                <Accordion.Header
                  onClick={() =>
                    setActiveKey(activeKey === a.id ? '' : `${a.id}`)
                  }
                >
                  <h6>
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="text-warning me-2"
                    />
                    {a.name}{' '}
                    {employee.internal_conflicts.includes(a.id) && (
                      <InternalConflict textDisplay="Internal" />
                    )}
                    <div className="text-muted small mt-1">{a.description}</div>
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <ConflictDetails
                    analytic={a}
                    employee={employee}
                    isActive={activeKey === a.id}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
        </Accordion>
      </Col>
    </Row>
  )
}

export default ConflictsTab