import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Badge from 'react-bootstrap/Badge'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTimesCircle,
  faChevronCircleDown,
  faBars,
  faChevronCircleUp
} from '@fortawesome/free-solid-svg-icons'
import { upperCase } from 'lodash'

function WeightForm({ activeBadge, setActiveBadge }) {
  const [badgeHover, setBadgeHover] = useState('')

  const weightOptions = [
    { value: 'off', icon: faTimesCircle },
    { value: 'low', icon: faChevronCircleDown },
    { value: 'med', icon: faBars },
    { value: 'high', icon: faChevronCircleUp }
  ]

  return (
    <Card className="mt-2">
      <Card.Body>
        <Row>
          <Col sm={4}>
            <div className="align-middle fw-bold mt-1">Weight</div>
          </Col>
          <Col sm={8}>
            <div className="text-center">
              {weightOptions.map((w) => (
                <Badge
                  key={`weighting-${w.value}`}
                  className={`text-primary p-2 ms-1 ${
                    activeBadge === w.value
                      ? 'badge-primary'
                      : badgeHover === w.value
                      ? 'bg-light'
                      : 'bg-white'
                  }`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setActiveBadge(w.value)
                  }}
                  onMouseEnter={() => setBadgeHover(w.value)}
                  onMouseLeave={() => setBadgeHover('')}
                >
                  {activeBadge === w.value && (
                    <FontAwesomeIcon icon={w.icon} className="me-1" />
                  )}
                  {upperCase(w.value)}
                </Badge>
              ))}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default WeightForm