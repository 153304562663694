import React from 'react'
import Table from 'react-bootstrap/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDotCircle,
  faExclamation,
  faCheck,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { calculatePercent } from 'helpers/utils'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import FeatureRow from './row'
import AddAttribute from './create'

function FeatureTable({ features, report }) {
  return (
    <React.Fragment>
      <Table className="border border-light" size="sm">
        <thead>
          <tr>
            <th>Key</th>
            <th>Name</th>
            <th className="text-center" style={{ width: '13%' }}>
              <FontAwesomeIcon icon={faDotCircle} className="text-muted" /> Open
            </th>
            <th className="text-center" style={{ width: '13%' }}>
              <FontAwesomeIcon icon={faExclamation} className="text-danger" />{' '}
              Exception
            </th>
            <th className="text-center" style={{ width: '13%' }}>
              <FontAwesomeIcon icon={faCheck} className="text-success" /> No
              Exception
            </th>
            <th>% Complete</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr key={`overall-${report.id}`}>
            <td>Overall</td>
            <td width="33%">
              <>
                {report.name} - Transactions
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Transaction level test</Tooltip>}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="ms-2 text-primary"
                    />
                  </span>
                </OverlayTrigger>
              </>
            </td>
            <td className="text-center">{report.open_count}</td>
            <td className="text-center">{report.exception_count}</td>
            <td className="text-center">{report.no_exception_count}</td>
            <td>
              <div className="progress mt-2">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${calculatePercent(
                      report.complete_count,
                      report.total_count
                    )}%`,
                    backgroundColor: '#35517E'
                  }}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {calculatePercent(report.complete_count, report.total_count) >
                  15
                    ? `${calculatePercent(
                        report.complete_count,
                        report.total_count
                      )}%`
                    : null}
                </div>
              </div>
            </td>
            <td></td>
          </tr>
          {features.map((f) => (
            <FeatureRow
              feature={f}
              report={report}
              key={`feature-row-${f.id}`}
            />
          ))}
          {features.length === 0 && (
            <tr>
              <td colSpan="7" className="text-center py-4">
                <div className="mb-2 h6">No Testing Attributes</div>
                <div className="text-muted fw-lighter mb-3">
                  Get started by creating adding an Attribute
                </div>
                <AddAttribute
                  analytics={report.analytics}
                  reportId={report.id}
                  selectedAnalytics={report.features.map((f) => f.analytic?.id)}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </React.Fragment>
  )
}

export default FeatureTable
