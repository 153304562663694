import React from 'react'
import Form from 'react-bootstrap/Form'

function DuplicatedEin({ params, setParams }) {
  const checked = params.duplicate_ein === true
  
  return (
    <>
      <Form.Check
        inline
        checked={checked}
        label="Active Vendors with Duplicated Tax ID"
        name="duplicated-ein-vendors"
        type={'checkbox'}
        onChange={() =>
          setParams({
            ...params,
            duplicate_ein: !params.duplicate_ein,
          })
        }
        id={'duplicated-ein-vendors-checkbox'}
      />
      <div className="ms-4 text-muted fw-light">
        Active vendor has a duplicated tax ID with another active vendor
      </div>
    </>
  )

}

export default DuplicatedEin