import { useContext } from 'react'
import { useMutation } from 'react-query'
import { apiPost, apiPatch, apiDelete } from 'api'
import AuthContext from 'context/auth-context'


const createDashboardEmployee = async (token, params, id) => {
  const objectName = 'dashboard_employee'
  const url = `dashboards/${id}/dashboard_employees`

  const data = await apiPost({ url, token, objectName, params })
  return data
}

export function useCreateDashboardEmployee({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return createDashboardEmployee(token, params, params.dashboard_id)
    },
    { ...options }
  )
}

const updateDashboardEmployee = async (token, params, id) => {
  const objectName = 'dashboard_employee'
  const url = `dashboard_employees/${id}`

  const data = await apiPatch({ url, token, objectName, params })
  return data
}

export function useUpdateDashboardEmployee({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return updateDashboardEmployee(token, params, params.dashboard_employee_id)
    },
    { ...options }
  )
}

const deleteDashboardEmployee = async (token, id) => {
  const url = `dashboard_employees/${id}`

  const data = await apiDelete({ url, token })
  return data
}

export function useDeleteDashboardEmployee({ id, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return deleteDashboardEmployee(token, id)
    },
    { ...options }
  )
}
