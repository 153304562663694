import React from 'react'
import Table from 'react-bootstrap/Table'
import AddAttribute from './new'
import useMunicipalityFeatures from 'api/hooks/use-municipality-features'
import TableLoading from 'shared/tables/table-loading'
import FeatureRow from './row'

function FeatureTable() {
  const { isSuccess, isLoading, data: features } = useMunicipalityFeatures({})

  return (
    <React.Fragment>
      <Table className="border border-light" size="sm">
        <thead>
          <tr>
            <th>Key</th>
            <th>Name</th>
            <th>Criteria</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <TableLoading columns={5} />}
          {isSuccess &&
            features.map((f) => (
              <FeatureRow feature={f} key={`municipality-feature-${f.id}`} />
            ))}
          {isSuccess && features.length === 0 && (
            <tr>
              <td colSpan="5" className="text-center py-4">
                <div className="mb-2 h6">No Testing Attributes</div>
                <div className="text-muted fw-lighter mb-3">
                  Get started by creating adding an Attribute
                </div>
                <AddAttribute />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </React.Fragment>
  )
}

export default FeatureTable
