import * as React from 'react'
import PropTypes from 'prop-types'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import FormControl from 'react-bootstrap/FormControl'
import { useDebounce } from 'api/hooks/use-debounce'

type ExternalIdProps = {
  params: object,
  setParams: Function
}

function ExternalId({ params, setParams }: ExternalIdProps) {
  const [search, setSearch] = React.useState('')
  const debouncedSearch = useDebounce(search, 300)

  React.useEffect(() => {
    setParams((params: object) => ({ ...params, external_id: debouncedSearch, page: 1 }))
  }, [debouncedSearch, setParams])

  return (
    <InputGroup>
      <InputGroup.Text>
        <FontAwesomeIcon icon={faSearch} />
      </InputGroup.Text>
      <FormControl
        placeholder="ID"
        data-testid="search-input"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </InputGroup>
  )
}

ExternalId.propTypes = {
  setParams: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired
}

export default ExternalId
