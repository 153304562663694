import React from 'react'
import Card from 'react-bootstrap/Card'
import AddAttribute from './create'
import FeatureTable from './table'

type ReportProgressProps = {
  report: {
    id: String,
    analytics: Object,
    features: {
      analytic: {
        id: string
      }
    }[]
  }
}

function ReportProgress({ report }: ReportProgressProps) {
  return (
    <>
      <Card className="mt-4" bg="white">
        <Card.Body>
          <>
            <div className="d-flex">
              <div className="mx-2">
                <AddAttribute
                  analytics={report.analytics}
                  reportId={report.id}
                  selectedAnalytics={report.features.map((f) => f.analytic?.id)}
                />
              </div>
            </div>
            <hr />
            <FeatureTable features={report.features} report={report} />
          </>
        </Card.Body>
      </Card>
    </>
  )
}

export default ReportProgress
