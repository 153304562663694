import React, { useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faCog } from '@fortawesome/free-solid-svg-icons'
import { isEmpty, isUndefined } from 'lodash'
import NavDropdown from 'react-bootstrap/NavDropdown'
import UserContext from 'context/user-context'
import AppSwitcher from 'layout/app-switcher'


function TopNavbar({ logo, title, subtitle }) {
  const { logout } = useAuth0()
  const { first_name, last_name, has_sod_app } = useContext(UserContext)
  const firstName = isUndefined(first_name) ? '' : first_name
  const lastName = isUndefined(last_name) ? '' : last_name
  const fullName = `${firstName} ${lastName}`
  if (fullName.length > 0) {
    localStorage.setItem('fullName', fullName)
  }
  const name = localStorage.getItem('fullName')

  return (
    <nav
      className="navbar navbar-light fixed-top bg-white flex-md-nowrap p-0 shadow-sm"
      style={{ minHeight: '54px' }}
    >
      <a
        className="navbar-brand bg-primary col-sm-3 col-md-2"
        href="/"
        style={{ minHeight: '54px' }}
      >
        <img
          src={logo}
          className="img-fluid ms-3"
          alt="ThirdLine Logo"
          style={{ maxHeight: '30px' }}
        />
      </a>

      <div className="container-fluid">
        <h5 className="text-left mt-2">
          {title}
          {!isEmpty(subtitle) && <small className="ms-2">{subtitle}</small>}
        </h5>
        <div className="d-flex">
          {has_sod_app && (
            <AppSwitcher currentApp={'analytics'} />
          )}

          <ul className="navbar-nav px-2">
            <NavDropdown
              id="nav-dropdown-dark-example"
              title={name}
              align="end"
            >
              <NavDropdown.Item href="/settings">
                <FontAwesomeIcon icon={faCog} /> Settings
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                <FontAwesomeIcon icon={faSignOutAlt} /> Sign out
              </NavDropdown.Item>
            </NavDropdown>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default TopNavbar
