import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { useDebounce } from 'api/hooks/use-debounce'

function AmountFilter({ setAuditReport, auditReport }) {
  const [min, setMin] = useState(auditReport?.minimumAmount || '')
  const [max, setMax] = useState(auditReport?.maximumAmount || '')
  const debouncedMin = useDebounce(min, 300)
  const debouncedMax = useDebounce(max, 300)

  useEffect(() => {
    setAuditReport((auditReport) => ({
      ...auditReport,
      minimumAmount: debouncedMin || ''
    }))
  }, [debouncedMin, setAuditReport])

  useEffect(() => {
    setAuditReport((auditReport) => ({
      ...auditReport,
      maximumAmount: debouncedMax || ''
    }))
  }, [debouncedMax, setAuditReport])

  return (
    <>
      <Form.Group>
        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
              <Form.Control
                type="number"
                onChange={(e) => setMin(e.target.value)}
                min={0}
                value={min}
                max={''}
                placeholder=""
              />
              <InputGroup.Text id="inputGroupPrepend">min</InputGroup.Text>
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>

              <Form.Control
                type="number"
                onChange={(e) => setMax(e.target.value)}
                min={0}
                value={max}
                placeholder=""
              />
              <InputGroup.Text id="inputGroupPrepend">max</InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
      </Form.Group>
    </>
  )
}

export default AmountFilter
