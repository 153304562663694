import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getReviewStatuses = async (token, params) => {
  const url = 'review_statuses'

  const data = await apiGet({ url, token, params })
  return data
}

export function useReviewStatuses({ params, options }) {
  const token = useContext(AuthContext)
  const queryFn = () => getReviewStatuses(token, params)
  const queryKey = ['review_statuses', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
