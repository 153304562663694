import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useCreateReview, useUpdateReview } from 'api/hooks/use-reviews'
import { useReviewStatuses } from 'api/hooks/use-review-statuses'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useQueryClient } from 'react-query'

function ClearTransaction({ transaction, dashboardId }) {
  const queryClient = useQueryClient()
  const [hover, setHover] = useState('')
  const reviewStatus = transaction?.review?.review_status_id || ''
  const [reviewStatusId, setReviewStatusId] = useState(reviewStatus || '')
  const { data: reviewStatuses, isSuccess: isStatusSuccess } =
    useReviewStatuses({})

  // TODO: If review exists, update review, else create review
  const initialParams = {
    id: transaction?.review?.id || '',
    reviewable_type: 'Transaction',
    reviewable_id: transaction.id,
    is_viewed: true,
    source_record_type: 'Dashboard',
    source_record_id: dashboardId
  }
  const [params, setParams] = useState(initialParams)
  const { mutate: createReview } = useCreateReview({ params })

  const statusIdFromName = (name) => {
    const status = reviewStatuses.find((status) => status.name === name)
    return status.id
  }

  const { mutate: updateReview } = useUpdateReview({ params, options: {
    onSuccess: () => {
      queryClient.invalidateQueries(['transactions'])
    }
}})

  const saveOrUpdateReview = () => {
    const review = transaction.review
    if (review) {
      updateReview()
    } else {
      createReview()
    }
  }

  useEffect(() => {
    setReviewStatusId(reviewStatus)
  }, [reviewStatus, transaction.id])

  useEffect(() => {
    if(reviewStatusId !== '') {
      saveOrUpdateReview()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewStatusId])

  return (
    <Row>
      <Col>
        <Card bg="white">
          <Card.Body>
            <Row>
              <Col sm="3">
                <h6 className="fw-bolder my-auto">
                  Process transaction{' '}
                  <small>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip>
                          Mark transactions as exceptions for control failures. Clear transactions that do not have any issues.
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}
                    >
                      <span style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="text-muted ms-2"
                        />
                      </span>
                    </OverlayTrigger>
                  </small>
                </h6>
                </Col>
                <Col sm="6">
                {isStatusSuccess && (
                  <div>
                    <Button
                      size="sm"
                      variant={
                        reviewStatusId === statusIdFromName('No Exception')
                          ? 'primary'
                          : 'outline-primary'
                      }
                      className={`me-2 ${
                        hover === 'clear' ? 'badge-primary' : ''
                      } ${
                        reviewStatusId === statusIdFromName('No Exception')
                          ? 'text-white'
                          : ''
                      }`}
                      onClick={() => {
                        setReviewStatusId(statusIdFromName('No Exception'))
                        setParams({
                          ...params,
                          review_status_id: statusIdFromName('No Exception')
                        })
                      }}
                      onMouseOver={() => {
                        setHover('clear')
                      }}
                      onMouseOut={() => {
                        setHover('')
                      }}
                    >
                      Clear Transaction
                    </Button>
                    <Button
                      size="sm"
                      variant={
                        reviewStatusId === statusIdFromName('Exception')
                          ? 'danger'
                          : 'outline-danger'
                      }
                      className={`me-2 ${
                        hover === 'exception' ? 'badge-danger' : ''
                      } ${
                        reviewStatusId === statusIdFromName('Exception')
                          ? 'text-white'
                          : ''
                      }`}
                      onClick={() => {
                        setReviewStatusId(statusIdFromName('Exception'))
                        setParams({
                          ...params,
                          review_status_id: statusIdFromName('Exception')
                        })
                      }}
                      onMouseOver={() => {
                        setHover('exception')
                      }}
                      onMouseOut={() => {
                        setHover('')
                      }}
                    >
                      Mark as Exception
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default ClearTransaction
