import React, { useState, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { noDigitMoney } from 'helpers/utils'

function Amount({ params, setParams, accessorMin, accessorMax }) {
  const [activeOverlay, setActiveOverlay] = useState(false)
  const amountTarget = useRef(null)
  const filterApplied =
    params[accessorMin].length !== 0 ||
    params[accessorMax].length !== 0
  const bothApplied =
    params[accessorMin].length !== 0 &&
    params[accessorMax].length !== 0

  const displayAmountText = () => {
    if (bothApplied) {
      return `${noDigitMoney(params[accessorMin])} - ${noDigitMoney(
        params[accessorMax]
      )}`
    } else if (params[accessorMin].length !== 0) {
      return `> ${noDigitMoney(params[accessorMin])}`
    } else if (params[accessorMax].length !== 0) {
      return `< ${noDigitMoney(params[accessorMax])}`
    } else {
      return '$ Invoiced'
    }
  }

  return (
    <>
      <Button
        variant={filterApplied ? 'outline-primary' : ''}
        className={
          filterApplied ? 'bg-white text-primary' : 'bg-white text-info'
        }
        style={{ border: filterApplied ? '' : '1px solid #ced4da' }}
        ref={amountTarget}
        onClick={() => setActiveOverlay(!activeOverlay)}
        data-testid="amount-button-trigger"
      >
        {displayAmountText()}
      </Button>
      <Overlay
        target={amountTarget.current}
        show={activeOverlay}
        placement="bottom"
      >
        <Popover style={{ minWidth: '450px' }}>
          <Popover.Header>
            <Row>
              <Col sm={6} className="d-grid">
                {filterApplied > 0 ? (
                  <Button
                    variant="link"
                    size="sm"
                    className="float-end mt-1"
                    onClick={() =>
                      setParams({
                        ...params,
                        [accessorMin]: '',
                        [accessorMax]: ''
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                    Clear
                  </Button>
                ) : null}
              </Col>
              <Col sm={6} className="d-grid">
                <Button
                  variant="primary"
                  size="sm"
                  className="float-end "
                  onClick={() => setActiveOverlay(null)}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Popover.Header>
          <Popover.Body>
            <Row>
              <Col>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    onChange={(e) =>
                      setParams({
                        ...params,
                        [accessorMin]: e.target.value,
                        page: 1
                      })
                    }
                    min={0}
                    value={params[accessorMin]}
                    required
                    data-testid={`${accessorMin}-amount`}
                    placeholder=""
                  />
                  <InputGroup.Text id="inputGroupPrepend">min</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col>
                <InputGroup>
                  <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>

                  <Form.Control
                    type="number"
                    onChange={(e) =>
                      setParams({
                        ...params,
                        [accessorMax]: e.target.value,
                        page: 1
                      })
                    }
                    min={0}
                    value={params[accessorMax]}
                    required
                    placeholder=""
                  />
                  <InputGroup.Text id="inputGroupPrepend">max</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  )
}

export default Amount
