import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getTableColumns = async (token, userId, params) => {
  const url = 'table_columns'

  const data = await apiGet({ url, params, token })
  return data
}

export function useTableColumns({ params, options }) {
  const token = useContext(AuthContext)
  const { id } = useContext(UserContext)
  const queryFn = () => getTableColumns(token, id, params)
  const queryKey = ['table-columns', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
