export const actionText = (action) => {
  switch (action) {
    case 'A':
      return 'Assigned'
    case 'D':
      return 'Removed'
    case 'U':
      return 'Updated'
    default:
      return action
  }
}

export const toFromText = (action) => {
  switch (action) {
    case 'A':
      return 'to'
    case 'D':
      return 'from'
    case 'U':
      return 'for'
    default:
      return action
  }
}
