import React from 'react'
import {
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import Result from './result'

function UserResult({ result }) {
  return (
    <Result
      dataType='User'
      title={`User: ${result.record.external_id}`}
      subtitle={''}
      icon={faUsers}
      url={`/users/${result.data_id}`}
      tags={[{ title: result.tags?.status }]}
    />
  )
}

export default UserResult
