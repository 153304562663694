import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getAccounts = async (token, params) => {
  const url = `municipalities/${params.municipality_id}/accounts`

  const data = await apiGet({ url, token, params })
  return data
}

export function useAccounts({ params, options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const mergedParams = { ...params, municipality_id: municipality_id }
  const queryFn = () => getAccounts(token, mergedParams)
  const queryKey = ['accounts', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
