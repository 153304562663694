import React from 'react'
import { usePendingWorkflows } from 'api/hooks/use-pending-workflows'
import moment from 'moment'
import Table from 'react-bootstrap/Table'
import TableLoading from 'shared/tables/table-loading'
import TableFooter from 'shared/tables/table-footer'
import Alert from 'react-bootstrap/Alert'

function Pending({ params, setParams }) {
  const { isLoading, isSuccess, data } = usePendingWorkflows({ params })

  const mapValues = (process) => {
    if (process === 'API') {
      return 'Inv #:'
    }
    if (process === 'REQ') {
      return 'Req #:'
    }
    if (process === 'POM') {
      return 'PO Change:'
    }
    if (process === 'POE') {
      return 'PO:'
    }
  }

  const displayKey = (workflow) => {
    if (workflow.process === 'API') {
      return workflow.display_key_two
    }
    if (workflow.process === 'REQ') {
      return workflow.display_key
    }
    if (workflow.process === 'POM') {
      return workflow.display_key_two
    }
    if (workflow.process === 'POE') {
      return workflow.display_key_two
    }
  }
  const currentDate = moment().format('YYYY-MM-DD')

  return (
    <div>
      <Alert variant="warning" className="mb-0 py-1">
         <strong>Note:</strong> Pending workflows are actions sitting in the
        queue waiting for approval. This data is refreshed nightly and does not
        reflect real-time data. For best results, use in the morning.
      </Alert>
      <Table className="border bg-white border-light my-4" responsive>
        <thead>
          <tr>
            <th>Process Type (Step)</th>
            <th>Approver</th>
            <th>Approval Type</th>
            <th>Days in Queue</th>
            <th>Entered Queue On</th>
            <th>Hold</th>
          </tr>
        </thead>
        {isLoading && <TableLoading columns={6} />}
        {isSuccess && data.data.length === 0 && (
          <div className="text-center mt-4">
            <h5>No pending workflows found</h5>
          </div>
        )}
        {isSuccess && data.data.length > 0 && (
          <tbody>
            {data.data.map((w, i) => (
              <tr key={`${displayKey(w)}-${w.approver_external_id}-${i}`}>
                <td>
                  {mapValues(w.process)} {displayKey(w)}
                  <small className="ms-2 text-muted">({w.step_number})</small>
                </td>
                <td>{w.approver_external_id}</td>
                <td>{w.approval_type}</td>
                <td>
                  {w.entered_at
                    ? moment
                        .duration(
                          moment(w.entered_at, 'YYYY-MM-DD HH:mm:ss').diff(
                            moment(currentDate, 'YYYY-MM-DD HH:mm:ss')
                          )
                        )
                        .humanize()
                    : ''}
                </td>
                <td className="text-muted fw-light">
                  {w.entered_at &&
                    moment(w.entered_at, 'YYYY-MM-DD').format('L')}
                </td>
                <td className="text-muted fw-light">
                  {w.pending_hold_at
                    ? moment(w.pending_hold_at, 'YYYY-MM-DD').format('L')
                    : ''}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>

      {isSuccess && (
        <TableFooter meta={data.meta} params={params} setParams={setParams} />
      )}
    </div>
  )
}

export default Pending
