import Form from 'react-bootstrap/Form'

function ExcludePcard({ params, setParams }) {
  const checked = params.excludePcard === true
  return (
    <>
      <Form.Check
        inline
        checked={checked}
        label="Exclude PCard Vendors"
        name="exclude-pcard"
        type={'checkbox'}
        onChange={() =>
          setParams({ ...params, excludePcard: !params.excludePcard })
        }
        id={'exclude-pcard-checkbox'}
      />
      <div className="ms-4 text-muted fw-light">
        Exclude vendors that come from PCard file
      </div>
    </>
  )
}

export default ExcludePcard
