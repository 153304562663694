import { useContext } from 'react'
import { useMutation } from 'react-query'
import { apiPatch, apiPost, apiDelete } from 'api'
import AuthContext from 'context/auth-context'

const createReportFeature = async (token, params) => {
  const objectName = 'report_feature'
  const url = `audit_reports/${params.audit_report_id}/features`

  apiPost({ url, token, objectName, params })
}

export function useCreateReportFeature({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return createReportFeature(token, params)
    },
    { ...options }
  )
}

const updateReportFeature = async (token, params) => {
  const objectName = 'report_feature'
  const url = `features/${params.id}`

  apiPatch({ url, token, objectName, params })
}

export function useUpdateReportFeature({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return updateReportFeature(token, params)
    },
    { ...options }
  )
}

const destroyReportFeature = async (token, id) => {
  const url = `features/${id}`

  apiDelete({ url, token })
}

export function useDestroyReportFeature({ id, options }) {
  const token = useContext(AuthContext)
  return useMutation(
    () => {
      return destroyReportFeature(token, id)
    },
    { ...options }
  )
}
