import React from 'react'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCalendar } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

function DateCard({ title, rangeString, filterParams, setFilterParams }) {
  const handleClick = (value) => {
    if (rangeString === 'one-month') {
      updateDates(moment().subtract(1, 'months'), moment())
    } else if (rangeString === 'three-months') {
      updateDates(moment().subtract(3, 'months'), moment())
    } else if (rangeString === 'six-months') {
      updateDates(moment().subtract(6, 'months'), moment())
    } else if (rangeString === 'twelve-months') {
      updateDates(moment().subtract(12, 'months'), moment())
    } else if (rangeString === 'last-year') {
      updateDates(
        moment().subtract(1, 'year').startOf('year'),
        moment().subtract(1, 'year').endOf('year')
      )
    } else if (rangeString === 'this-year') {
      updateDates(moment().startOf('year'), moment().endOf('year'))
    }
  }

  const updateDates = (start, end) => {
    setFilterParams({
      ...filterParams,
      startOn: start,
      endOn: end,
      dateRangeSelected: rangeString,
      page: 1
    })
  }

  return (
    <Card
      className={'shadow-sm'}
      style={{
        cursor: 'pointer',
        background:
          filterParams.dateRangeSelected === rangeString ? '#f0f5f7' : '',
        border:
          filterParams.dateRangeSelected === rangeString
            ? '1px solid #6ea0b5'
            : ''
      }}
      onClick={() => handleClick()}
    >
      <Card.Body className="p-1">
        {filterParams.dateRangeSelected === rangeString ? (
          <span>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-primary ms-1"
            />
          </span>
        ) : (
          <span>
            {' '}
            <FontAwesomeIcon icon={faCheckCircle} className="text-white ms-1" />
          </span>
        )}
        <div className="d-flex justify-content-center">
          <FontAwesomeIcon icon={faCalendar} />
        </div>
        <div className="d-flex justify-content-center">
          <Card.Text className="fw-lighter my-2 text-center">{title}</Card.Text>
        </div>
      </Card.Body>
    </Card>
  )
}

export default DateCard
