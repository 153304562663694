import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

function CenteredSpinner() {
  return (
    <div className="d-flex justify-content-center py-auto">
      <div>
        <Spinner animation="grow" variant="warning" className="my-5" />
      </div>
    </div>
  )
}

export default CenteredSpinner