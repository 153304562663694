import React from 'react'
import Form from 'react-bootstrap/Form'
import { toKebabCase } from 'helpers/utils'

function CheckboxField({
  params,
  setParams,
  accessorKey,
  label,
  helperText = ''
}) {
  const checked = params[accessorKey] === true

  return (
    <>
      <Form.Check
        inline
        checked={checked}
        label={label}
        name={toKebabCase(label)}
        type={'checkbox'}
        onChange={() =>
          setParams({
            ...params,
            [accessorKey]: !params[accessorKey]
          })
        }
        id={`${toKebabCase(label)}-checkbox`}
      />
      {helperText && (
        <div className="ms-4 text-muted fw-light">{helperText}</div>
      )}
    </>
  )
}

export default CheckboxField