import React from 'react'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import MonitoringTable from './table'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

function Monitoring() {
  const navigate = useNavigate()

  return (
    <Page
      title="Monitoring"
      subtitle={
        <Button
          variant="primary"
          size="sm"
          className="ms-2"
          onClick={(e) => {
            e.preventDefault()
            navigate('/monitoring-new')
          }}
          href="/monitoring-new"
        >
          <FontAwesomeIcon icon={faPlus} className="text-warning" /> New
          Monitoring
        </Button>
      }
    >
      <PageTitle />
      <MonitoringTable />
    </Page>
  )
}

export default Monitoring
