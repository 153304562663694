import React from 'react'
import Page from 'layout/sod/page'
import PageTitle from 'layout/page-title'
import Card from 'react-bootstrap/Card'
import ConflictTable from './table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Search from 'shared/forms/search'

function Conflicts() {
  const crumbs = [
    { name: 'Segregation of Duties', url: 'duties' },
    { name: 'Conflicts', url: '' }
  ]
  const emptyParams = {
    search: '',
    orderBy: 'conflict_sod_counts.employees_count',
    orderDirection: 'desc',
    perPage: 25,
    page: 1
  }
  const [filterParams, setFilterParams] = React.useState(emptyParams)

  return (
    <Page title={'Conflicts'}>
      <PageTitle breadcrumbs={crumbs} />

      <Row className="mb-4 mt-2">
        <Col sm={3}>
          <Search
            params={filterParams}
            setParams={setFilterParams}
            placeholder="Search Conflicts"
          />
        </Col>
      </Row>

      <Card>
        <Card.Body>
          <ConflictTable
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        </Card.Body>
      </Card>
    </Page>
  )
}

export default Conflicts
