import React from 'react'
import Table from 'react-bootstrap/Table'
import TableError from 'shared/tables/table-error'
import SortArrow from 'shared/tables/sort-arrow'
import TableFooter from 'shared/tables/table-footer'
import TableLoading from 'shared/tables/table-loading'
import HighRiskPermissionBadge from 'duties/badges/high-risk-permission'
import SuperUserPermissionBadge from 'duties/badges/superuser-permission'
import { useNavigate } from 'react-router-dom'


function PermissionsTable({ params, setParams, isLoading, isSuccess, isError, permissions }) {
  const navigate = useNavigate()
  const updateOrdering = (column) => {
    const isCurrentColumn = params.orderBy === column
    const columnOrder = getColumnOrder(isCurrentColumn)

    setParams({
      ...params,
      orderBy: column,
      orderDirection: columnOrder,
      page: 1
    })
  }

  const getColumnOrder = (isCurrentColumn) => {
    if (isCurrentColumn) {
      return params.orderDirection === 'asc' ? 'desc' : 'asc'
    } else {
      return 'desc'
    }
  }

  return (
    <>
      <Table
        hover
        responsive
        size="sm"
        className="border shadow-sm bg-white"
        style={{ border: '1px solid rgb(222 226 230)' }}
      >
        <thead>
          <tr>
            <th onClick={() => updateOrdering('name')}>
              Name
              <SortArrow
                column="name"
                orderBy={params.orderBy}
                orderDirection={params.orderDirection}
              />
            </th>
            <th onClick={() => updateOrdering('category_caption')}>
              Caption
              <SortArrow
                column="category_caption"
                orderBy={params.orderBy}
                orderDirection={params.orderDirection}
              />
            </th>
            <th onClick={() => updateOrdering('is_high_risk')}>
              High Risk
              <SortArrow
                column="is_high_risk"
                orderBy={params.orderBy}
                orderDirection={params.orderDirection}
              />
            </th>
            <th onClick={() => updateOrdering('is_superuser')}>
              Super User
              <SortArrow
                column="is_superuser"
                orderBy={params.orderBy}
                orderDirection={params.orderDirection}
              />
            </th>
            <th onClick={() => updateOrdering('employees_count')}>
              Employee Count
              <SortArrow
                column="employees_count"
                orderBy={params.orderBy}
                orderDirection={params.orderDirection}
              />
            </th>
            <th onClick={() => updateOrdering('roles_count')}>
              Role Count
              <SortArrow
                column="roles_count"
                orderBy={params.orderBy}
                orderDirection={params.orderDirection}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <TableLoading columns={6} />}
          {isSuccess &&
            permissions.data.map((p) => (
              <tr
                key={`permission-${p.id}`}
                onClick={() => navigate(`/permissions/${p.id}`)}
                style={{ cursor: 'pointer' }}
              >
                <td>
                  {p.description}
                  <div className="text-muted">{p.name}</div>
                </td>

                <td>{p.category_caption}</td>
                <td>
                  {p.is_high_risk && (
                    <HighRiskPermissionBadge count={1} displayCount={false} />
                  )}
                </td>
                <td>
                  {p.is_superuser && (
                    <SuperUserPermissionBadge count={1} displayCount={false} />
                  )}
                </td>
                <td>{p.employees_count}</td>
                <td>{p.roles_count}</td>
              </tr>
            ))}
          {isError && <TableError columns={6} />}
        </tbody>
      </Table>
      {isSuccess && (
        <TableFooter
          meta={permissions.meta}
          params={params}
          setParams={setParams}
        />
      )}
    </>
  )
}

export default PermissionsTable
